import { createStyles, Image, rem, Table, Text } from "@mantine/core"
import React from "react"
import { useAppSelector } from "../../../redux/hooks"
import { RootState } from "../../../redux/store"
import { ActionsButtonEmployee } from "../actions-button-employee"

export const useStyles = createStyles((theme) => ({
  header: {
    zIndex: 10,
    position: "sticky",
    top: 0,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `${rem(1)} solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.gray[2]
      }`,
    },
  },
}))

export const EmployeesTable = () => {
  const { classes } = useStyles()
  const { employees } = useAppSelector((state: RootState) => state.employees)

  const rows =
    employees &&
    employees.map((employee: any) => (
      <tr key={employee.employeeUuid}>
        <td>{employee.name}</td>

        <td>{employee.email}</td>
        <td>{employee.role}</td>

        <td>
          <ActionsButtonEmployee employeeUuid={employee.employeeUuid} />
        </td>
      </tr>
    ))

  return employees && employees.length ? (
    <Table w={"100%"} highlightOnHover>
      <thead className={classes.header}>
        <tr>
          <th>Nume</th>
          <th>E-mail</th>
          <th>Post</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  ) : (
    <>
      <Text align={"center"} pt="md">
        Nu aveți nici un angajat adăugat!
      </Text>

      <Image
        maw={450}
        mx="auto"
        radius="lg"
        alt="No found employees"
        src="/add_employee.svg"
      />
    </>
  )
}
