import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { freeDays } from "../../components/account-details/utils-account-details"
import { setErrorNotification } from "../../redux/notification/slice"
import { IUserResponse } from "../../types/user-types"
import { RoutesOptions, userUrl } from "../../utils/constants"
import { getGenericErrorMessage } from "../../utils/error-messages"

export const useGetUser = (
  successCallBack?: (data: IUserResponse["data"]) => void
) => {
  const authToken = sessionStorage.getItem("authToken")
  const userUuid = sessionStorage.getItem("uuid")
  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useQuery(
    ["read-user-key"],
    () => axios.get<IUserResponse>(userUrl.concat(`/${userUuid}`), config),
    {
      onSuccess: (data: IUserResponse) => {
        sessionStorage.setItem("isTrial", data.data.response.isTrial.toString())
        sessionStorage.setItem(
          "subscriptionExpireDate",
          data.data.response.subscriptionExpireDate
        )
        sessionStorage.setItem("firstName", data.data.response.firstName)
        sessionStorage.setItem(
          "activeModalSubscription",
          freeDays(data.data.response.subscriptionExpireDate) <= 5
            ? "true"
            : "false"
        )
        return successCallBack ? successCallBack(data.data) : data.data
      },
      onError: (error: any) => {
        if ([401].includes(error.response.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(
            setErrorNotification(
              getGenericErrorMessage(error.response.data.message)
            )
          )
        }
      },
      retry: true,
      enabled: false,
    }
  )
}
