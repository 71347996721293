import React, { useState } from "react"
import {
  Group,
  Navbar,
  Image,
  Flex,
  Header,
  Indicator,
  Menu,
  Button,
  Burger,
  Drawer,
  Stack,
  Modal,
} from "@mantine/core"
import {
  IconChevronUp,
  IconLogout,
  IconNotes,
  IconReportMoney,
  IconUsers,
  IconAddressBook,
  IconChecklist,
  IconId,
  IconUserCircle,
  IconHelp,
  IconAdjustmentsAlt,
} from "@tabler/icons-react"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import { useLocation, useNavigate } from "react-router-dom"
import {
  maxWidthMobile,
  maxWidthMobileSmall,
  maxWidthTablet,
  RoutesOptions,
} from "../../utils/constants"
import getBrowserName from "../../utils/browserDetection"
import { WarningButton } from "../warning-button/warningButton"
import { freeDays } from "../account-details/utils-account-details"
import { useStyles } from "./navbar.style"
import { NavbarNotification } from "./navbar.utils"
import { renderMessage } from "../login/login.utils"
import SupportContent from "../suport-modal/suport-modal"
import { reverseFormaterDiacritics } from "../../utils/strings"

export const Navigationbar = () => {
  const { classes, cx } = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const [active, setActive] = useState(location.pathname)
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const isMobileS = useMediaQuery(maxWidthMobileSmall)
  const [opened, { toggle }] = useDisclosure(false)
  const browserName = getBrowserName()
  const firstNameUser = sessionStorage.getItem("firstName")
  const [openedDrawer, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false)
  const [openedSupport, { open: openSupport, close: closeSupport }] =
    useDisclosure(false)
  const subscriptionExpireDate = sessionStorage.getItem(
    "subscriptionExpireDate"
  )

  const isTrial = sessionStorage.getItem("isTrial") === "true" ? true : false

  const logOut = () => {
    navigate(RoutesOptions.LOGIN)
    setTimeout(() => sessionStorage.clear(), 500)
  }

  const laptopData = [
    {
      link: RoutesOptions.CUSTOMIZE,
      label: "Personalizare",
      icon: IconAdjustmentsAlt,
      onClickFct: null,
    },
    {
      link: RoutesOptions.CLIENTS,
      label: "Clienți",
      icon: IconNotes,
      onClickFct: null,
    },
    {
      link: RoutesOptions.WORKITEMS,
      label: "Lucrări",
      icon: IconReportMoney,
      onClickFct: null,
    },
    {
      link: RoutesOptions.EMPLOYEES,
      label: "Angajați",
      icon: IconUsers,
      onClickFct: null,
    },
    {
      link: RoutesOptions.INVENTORY,
      label: "Inventar",
      icon: IconChecklist,
      onClickFct: null,
    },

    {
      link: RoutesOptions.PROFILE,
      label: "Profil",
      icon: IconAddressBook,
      onClickFct: null,
    },
  ]

  const mobileData = [
    ...laptopData,
    {
      link: RoutesOptions.ACCOUNT_DETAILS,
      label: "Detalii cont",
      icon: IconUserCircle,
      onClickFct: null,
    },
    {
      link: RoutesOptions.SUPPORT,
      label: "Suport",
      icon: IconHelp,
      onClickFct: () => {
        toggle()
        closeDrawer()
        openSupport()
      },
    },
    {
      link: RoutesOptions.LOGOUT,
      label: "Ieșire cont",
      icon: IconLogout,
      onClickFct: null,
    },
  ]

  const links = isTablet
    ? mobileData.map((item) => (
        <span
          className={cx(classes.link, {
            [classes.linkActive]: item.link === active,
          })}
          key={item.label}
          onClick={(event) => {
            if (item.onClickFct) {
              item.onClickFct()
            } else {
              event.preventDefault()
              setActive(item.label)
              item.link.includes(RoutesOptions.LOGOUT)
                ? logOut()
                : navigate(item.link)
            }
          }}
        >
          <item.icon className={classes.linkIcon} stroke={1.5} />
          <span>{item.label}</span>
        </span>
      ))
    : laptopData.map((item) => (
        <span
          className={cx(classes.link, {
            [classes.linkActive]: item.link === active,
          })}
          key={item.label}
          onClick={(event) => {
            event.preventDefault()
            setActive(item.label)
            item.link.includes(RoutesOptions.LOGOUT)
              ? logOut()
              : navigate(item.link)
          }}
        >
          <item.icon className={classes.linkIcon} stroke={1.5} />
          <span>{item.label}</span>
        </span>
      ))

  return (
    <>
      {isTablet ? (
        <>
          <Header height={"auto"} zIndex="200">
            <Indicator position="bottom-center" label="v2.0" size={12}>
              <Flex justify={"space-between"} mx="xs" align={"center"}>
                <Image
                  src="/Logo_Topo_CRM.png"
                  alt="Logo Topo"
                  width={isMobile ? "60%" : isTablet ? "45%" : "auto"}
                  m="xs"
                />
                <Group spacing={isMobileS ? 0 : 5}>
                  <Group spacing={5}>
                    {browserName === "Safari" && (
                      <WarningButton
                        text="Unele dintre funcționalitățile aplicației TOPO CRM nu sunt compatibile cu browser-ul Safari."
                        isWarning={true}
                      />
                    )}
                    {isTrial ? (
                      <WarningButton
                        text={renderMessage(
                          isTrial,
                          freeDays(subscriptionExpireDate as string)
                        )}
                        isWarning={false}
                      />
                    ) : freeDays(subscriptionExpireDate as string) <= 5 ? (
                      <WarningButton
                        text={renderMessage(
                          isTrial,
                          freeDays(subscriptionExpireDate as string)
                        )}
                        isWarning={false}
                      />
                    ) : null}
                  </Group>

                  <Burger
                    opened={opened}
                    onClick={() => {
                      toggle()
                      opened ? closeDrawer() : openDrawer()
                    }}
                    color="#3C4048"
                  />
                </Group>
              </Flex>
            </Indicator>
          </Header>
          <Drawer
            opened={openedDrawer}
            onClose={() => {
              closeDrawer()
              toggle()
            }}
            position="right"
            size="xs"
            zIndex={1000}
            title="Meniu"
          >
            <Stack mt="xl" spacing="0">
              <div>{links}</div>
            </Stack>
          </Drawer>
        </>
      ) : (
        <Navbar height={"100%"} maw={230} pt="xl" pb="xs" px="xs" zIndex="200">
          <Navbar.Section grow>
            <Group className={classes.header} position="apart">
              <Indicator position="top-end" label="v2.0" size={16}>
                <Image src="/Logo_Topo_CRM.png" width={"90%"} alt="Logo Topo" />
              </Indicator>

              {isTrial ? (
                <NavbarNotification
                  isTrial={isTrial}
                  freeDays={freeDays(subscriptionExpireDate as string)}
                />
              ) : freeDays(subscriptionExpireDate as string) <= 5 ? (
                <NavbarNotification
                  isTrial={isTrial}
                  freeDays={freeDays(subscriptionExpireDate as string)}
                />
              ) : null}
            </Group>
            {links}
          </Navbar.Section>
          <Navbar.Section className={classes.footer}>
            <Group spacing={5} align="center">
              <Menu position="top">
                <Menu.Target>
                  <Button
                    variant="light"
                    size="xs"
                    radius="xl"
                    rightIcon={<IconChevronUp size="1.05rem" stroke={1.5} />}
                    pr={12}
                    w="100%"
                  >
                    Contul meu
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>
                    Bine ai venit,
                    {firstNameUser && reverseFormaterDiacritics(firstNameUser)}!
                  </Menu.Label>

                  <Menu.Item
                    my="xs"
                    icon={<IconId size={14} />}
                    color="brand.6"
                    onClick={() => navigate(RoutesOptions.ACCOUNT_DETAILS)}
                  >
                    Detalii cont
                  </Menu.Item>
                  <Menu.Item
                    my="xs"
                    icon={<IconHelp size={14} />}
                    color="brand.6"
                    onClick={() => openSupport()}
                  >
                    Suport
                  </Menu.Item>
                  <Menu.Item
                    my="xs"
                    icon={<IconLogout size={14} />}
                    color="brand.6"
                    onClick={logOut}
                  >
                    Ieșire cont
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Navbar.Section>
        </Navbar>
      )}
      <Modal
        size={isMobile ? "calc(100vw - 2rem)" : "md"}
        xOffset={0}
        zIndex={2000}
        opened={openedSupport}
        onClose={closeSupport}
        title="Suport"
      >
        <SupportContent />
      </Modal>
    </>
  )
}
