import { BrowserRouter } from "react-router-dom"

export function base64toBlob(
  base64Data: string,
  contentType = "application/octet-stream"
) {
  let data = base64Data
  if (data.startsWith("data:")) {
    const parts = data.split(",")
    if (parts.length > 1) {
      data = parts[1]
    }
  }

  const byteCharacters = atob(data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export const downloadDoc = (docDetails: {
  filename: string
  content: string
}) => {
  const linkSource = base64toBlob(docDetails.content)
  const downloadLink = document.createElement("a")
  const fileName = docDetails.filename
  downloadLink.href = window.URL.createObjectURL(linkSource)
  downloadLink.download = fileName
  downloadLink.click()
}

export const options = {
  cMapUrl: "cmaps/",
  standardFontDataUrl: "standard_fonts/",
}
