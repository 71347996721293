import { createStyles } from "@mantine/core"

export const useStyles = createStyles((theme) => ({
  input: {
    borderColor: theme.colors.brand[9],
    background: "transparent",
    color: "#3C4048",
  },
  error: { display: "flex", justifyContent: "center" },
  hoverImage: {
    cursor: "pointer",
  },
}))
