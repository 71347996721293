export const normaliseDiacritics = (value: string) => {
  return value.normalize("NFD").replace(/\p{Diacritic}/gu, "")
}
export const formaterDiacritics = (value: string) => {
  if (value) {
    return value
      .replace(/ș/g, "s^")
      .replace(/ț/g, "t^")
      .replace(/î/g, "i^")
      .replace(/Ș/g, "S^")
      .replace(/Ț/g, "T^")
      .replace(/Î/g, "I^")
  } else {
    return value
  }
}
export const reverseFormaterDiacritics = (value: string) => {
  if (value) {
    return value
      .replace(/s\^/g, "ș")
      .replace(/t\^/g, "ț")
      .replace(/i\^/g, "î")
      .replace(/S\^/g, "Ș")
      .replace(/T\^/g, "Ț")
      .replace(/I\^/g, "Î")
  } else {
    return value
  }
}

export const diacriticsCorrector = (word: string | null) => {
  if (word) {
    if (word.includes("mar?i")) {
      return word.replace("mar?i", "marți")
    } else if (word.includes("s?mb?t?")) {
      return word.replace("s?mb?t?", "sâmbătă")
    } else if (word.includes("duminic?")) {
      return word.replace("duminic?", "duminică")
    }
  }
  return word
}
