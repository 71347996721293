/* eslint-disable react-hooks/rules-of-hooks */
import { useMediaQuery } from "@mantine/hooks"
import {
  colorBlack,
  colorWhite,
  maxWidthLaptopSmall,
  maxWidthMobile,
  minWidthLaptop,
} from "../../utils/constants"

export const luminationCalcul = (hexColor: string) => {
  const r = parseInt(hexColor.slice(1, 3), 16) / 255
  const g = parseInt(hexColor.slice(3, 5), 16) / 255
  const b = parseInt(hexColor.slice(5, 7), 16) / 255
  const lumination = 0.299 * r + 0.587 * g + 0.114 * b
  if (lumination > 0.5 || isNaN(lumination)) return colorBlack
  else return colorWhite
}

export const duplicateLabel = (
  labelBadge: string,
  arrayBadge: any,
  type: "status" | "typeWorkitem" | "measurementUnit" | "calcFormula"
) => {
  return arrayBadge
    ? arrayBadge.some((item: any) =>
        type === "status"
          ? item.label === labelBadge
          : type === "calcFormula"
          ? item.name === labelBadge
          : item === labelBadge
      )
    : false
}

export const initialValuesPriceFormula = () => {
  const initalValue = {
    name: "",
    workItemMeasurementUnit: "",
    workItemType: "",
    basePrice: 0,
  }
  return initalValue
}

export const validateFormPriceFormula = {
  name: (value: string) =>
    value && value.length > 2
      ? null
      : "Numele trebuie să conțină cel puțin 3 caractere.",

  basePrice: (value: number) => {
    if (value) {
      if (value >= 1) return null
      else return "Prețul trebuie să fie mai mare ca 0. "
    }
  },
}
export const panelWidth = () => {
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isLaptopLarge = useMediaQuery(minWidthLaptop)
  const isMobile = useMediaQuery(maxWidthMobile)
  if (isMobile) {
    return "100%"
  } else if (isLaptopS) {
    return "50%"
  } else if (isLaptopLarge) {
    return "25%"
  } else {
    return "30%"
  }
}
