import { createStyles } from "@mantine/core"

export const useStyles = createStyles((theme) => ({
  iconInputPssword: { color: theme.colors.brand[6] },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  error: { marginLeft: "0.5rem" },
  input: {
    borderColor: theme.colors.brand[9],
  },
  label: { marginLeft: "0.5rem" },
  hoverImage: {
    cursor: "pointer",
  },
  checkbox: { borderColor: theme.colors.brand[9] },
  errorCheckbox: { borderColor: "red", color: "red" },
  inputAffiliateCode: {
    borderColor: theme.colors.brand[9],
    maxHeight: 30,
    height: "auto",
    width: 280,
    [theme.fn.smallerThan("lg")]: { width: "105%" },
    [theme.fn.smallerThan("md")]: { width: "100%" },
  },
  grid: {
    alignItems: "center",
    gap: "1rem 2rem",
    [theme.fn.smallerThan("xs")]: {
      gap: "0.5rem",
      gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    },
  },
}))
