import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'

const initialState: {
  currentPageInventoryObjects: number
  currentPageFixedAssets: number
} = {
  currentPageInventoryObjects: 1,
  currentPageFixedAssets: 1,
}

export const intemInventorySlice = createSlice({
  name: 'inventory_slice',
  initialState: initialState,
  reducers: {
    setCurrentPageInventoryObjects: (state, action) => {
      state.currentPageInventoryObjects = action.payload
    },
    setCurrentPageFixedAssets: (state, action) => {
      state.currentPageFixedAssets = action.payload
    },
    resetCurrentPageInventory: (state) => {
      state.currentPageInventoryObjects = initialState.currentPageInventoryObjects
      state.currentPageFixedAssets = initialState.currentPageFixedAssets
    },
  },
})
export const {
  setCurrentPageInventoryObjects,
  setCurrentPageFixedAssets,
  resetCurrentPageInventory,
} = intemInventorySlice.actions
export const selectorItemInventory = (state: RootState) => {
  return state.itemsInventory
}
export default intemInventorySlice.reducer
