import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Paper,
  Text,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useGetWorkItems } from "../../api/work-items/use-get-work-items"
import { RootState } from "../../redux/store"
import { IDeleteClientModal } from "../../types/generic-modal-types"
import { limitPage, maxWidthMobile } from "../../utils/constants"

export const DeleteClientModal: FunctionComponent<IDeleteClientModal> = ({
  isOpenModal,
  setOpenModal,
  onHandle,
  title,
  subTitle,
  mainActionText,
  clientName,
}) => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const { currentPageWorkItems } = useSelector(
    (state: RootState) => state.workItems
  )
  const [totalOfWorkItems, setTotalOfWorkItems] = useState(0)
  const { data, isLoading, isRefetching, refetch } = useGetWorkItems(
    currentPageWorkItems,
    limitPage,
    clientName
  )

  useEffect(() => {
    refetch()
  }, [isOpenModal])

  useEffect(() => {
    if (data) {
      setTotalOfWorkItems(data.data.response.length)
    }
  }, [data])

  return (
    <Modal
      opened={isOpenModal}
      onClose={() => setOpenModal(false)}
      title={mainActionText}
      centered
      size={isMobile ? "calc(100vw - 2rem)" : "md"}
      xOffset={0}
      zIndex={2000}
    >
      <Paper p="xs">
        <Text>
          {totalOfWorkItems && totalOfWorkItems > 0
            ? `Urmează să ștergi clientul care are ${totalOfWorkItems} lucrări.`
            : title}
        </Text>
        <Text>
          {totalOfWorkItems && totalOfWorkItems > 0
            ? `Pentru a putea șterge clientul trebuie să ștergi toate lucrările asignate clientului.`
            : subTitle}
        </Text>
      </Paper>
      <Group position="center" mt="md" noWrap>
        <Button
          radius="xl"
          type="submit"
          onClick={() => onHandle()}
          disabled={totalOfWorkItems && totalOfWorkItems > 0 ? true : false}
        >
          {mainActionText}
        </Button>
        <Button
          variant={"light"}
          radius="xl"
          type="submit"
          onClick={() => {
            setOpenModal(false)
          }}
        >
          Anulează
        </Button>
      </Group>
      <LoadingOverlay visible={isLoading || isRefetching} />
    </Modal>
  )
}
