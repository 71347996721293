import { IconX } from "@tabler/icons-react"
import { Notification } from "@mantine/core"
export const registerNotification = (
  message: string,
  type: string,
  isMobile: boolean
) => (
  <Notification
    radius="lg"
    color={type === "checkEmail" ? "yellow" : "red"}
    mx="md"
    mt="md"
    title=""
    withCloseButton={false}
    icon={type === "checkEmail" ? null : <IconX size="1.1rem" />}
    w={isMobile ? "auto%" : "50%"}
  >
    {message}
  </Notification>
)
