import { Button, Center, Menu, Text } from '@mantine/core'
import { IconChevronDown, IconEdit, IconHttpDelete } from '@tabler/icons-react'
import { FunctionComponent, useState } from 'react'
import { useGetEmployee } from '../../api/employee/use-get-employee'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setErrorNotification, setMessageNotification } from '../../redux/notification/slice'
import { RootState } from '../../redux/store'
import { GenericModal } from '../generic-modal/generic-modal'
import { EditAddEmployee } from './employee-edit-add'
import { useDeleteEmployees } from '../../api/employee/use-delete-employee'
import { resetCurrentPage } from '../../redux/employee/employee-slice'
export const ActionsButtonEmployee: FunctionComponent<{
  employeeUuid?: string
}> = ({ employeeUuid }) => {
  const [openModalEditEmployee, setOpenModalEditEmployee] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { employees } = useAppSelector((state: RootState) => state.employees)
  const employeeDetails = employees.find((el) => el.employeeUuid === employeeUuid)
  const { refetch } = useGetEmployee()
  const dispatch = useAppDispatch()
  const succesCallBack = (data: { message: string }) => {
    setShowDeleteModal(false)
    refetch()
    dispatch(setMessageNotification(data.message))
  }

  const errorCallBack = (error: any) => {
    dispatch(setErrorNotification(`Te rog încearcă mai târziu! ${error.response.data.message}`))
    setShowDeleteModal(false)
  }
  const { mutate, isLoading: isLoadingDelete } = useDeleteEmployees(
    employeeUuid as string,
    succesCallBack,
    errorCallBack,
  )
  const onDeleteEmployee = () => {
    mutate()
    dispatch(resetCurrentPage())
  }
  return (
    <>
      <Center>
        <Menu
          transitionProps={{ transition: 'scale-y' }}
          position="bottom-end"
          width={220}
          withinPortal
        >
          <Menu.Target>
            <Button
              variant={'light'}
              size="xs"
              radius="xl"
              rightIcon={<IconChevronDown size="1.05rem" stroke={1.5} />}
              pr={12}
            >
              Acțiuni
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<IconEdit size="1rem" stroke={1.5} />}
              onClick={() => {
                setOpenModalEditEmployee(true)
              }}
            >
              <Text c="#3C4048" fw={500}>
                Editare
              </Text>
            </Menu.Item>
            <Menu.Item
              onClick={() => setShowDeleteModal(true)}
              icon={<IconHttpDelete size="1rem" stroke={1.5} />}
            >
              <Text c="#3C4048" fw={500}>
                Ștergere
              </Text>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Center>
      <GenericModal
        setOpenModal={setShowDeleteModal}
        mainActionText={'Ștergere angajat'}
        title={'Urmează să ștergi un angajat'}
        subTitle={'Ești sigur(ă) că vrei să ștergi angajatul selectat?'}
        isOpenModal={showDeleteModal}
        onHandle={onDeleteEmployee}
        isLoading={isLoadingDelete}
      />
      <EditAddEmployee
        isOpenModal={openModalEditEmployee}
        setOpenModal={setOpenModalEditEmployee}
        isEditMode={true}
        employeeDetails={employeeDetails}
      />
    </>
  )
}
