import {
  Flex,
  Paper,
  Image,
  Title,
  Stack,
  Button,
  PasswordInput,
  Box,
  TextInput,
  Notification,
  Text,
  Anchor,
  Container,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import { IconX } from "@tabler/icons-react"
import React, { FunctionComponent, useState } from "react"
import { useNavigate } from "react-router-dom"
import { IAuthorization, usePostLogin } from "../../api/login/use-post-login"
import {
  maxWidthMobile,
  maxWidthTablet,
  RoutesOptions,
} from "../../utils/constants"
import {
  formatterDate,
  freeDays,
} from "../account-details/utils-account-details"
import { ErrorSuccesNotification } from "../notification/notification"
import { useStyles } from "./login.styles"

const Login: FunctionComponent = () => {
  const { classes, theme } = useStyles()
  const [backendError, setBackendError] = useState("")
  const navigate = useNavigate()
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)

  const succesCallBack = (data: IAuthorization) => {
    const { subscriptionExpireDate, userUuid, authToken } = data
    sessionStorage.setItem("uuid", userUuid)
    sessionStorage.setItem("authToken", authToken)
    sessionStorage.setItem("subscriptionExpireDate", subscriptionExpireDate)

    const isTrialPeriod =
      new Date(formatterDate(subscriptionExpireDate as string)).getTime() <
      new Date().getTime()

    if (isTrialPeriod) {
      navigate(RoutesOptions.TRIAL_OVER)
    } else {
      navigate(RoutesOptions.ROOT)
    }
  }

  const errorCallBack = (error: any) => {
    if (error.code === 400) {
      setBackendError(`Te rog încearcă mai târziu! ${error.message}`)
    } else {
      setBackendError(` ${error.message}`)
    }
  }
  const loginForm = useForm({
    initialValues: { email: "", password: "" },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Adresa de e-mail invalidă",
      password: (value) => (value.length > 5 ? null : "Parolă invalidă"),
    },
  })
  const { mutate, isLoading, isError } = usePostLogin(
    succesCallBack,
    errorCallBack
  )
  const onPressLogin = () => {
    if (loginForm.isValid()) {
      mutate({
        email: loginForm.values.email,
        password: loginForm.values.password,
      })
    }
  }

  return (
    <Container h="100vh" m={0} maw="100vw">
      <Flex
        direction={"column"}
        align="center"
        h={"100%"}
        justify={"space-between"}
      >
        <Image
          src={"/Logo_Topo_CRM.png"}
          alt="Logo Topo"
          height="auto"
          width="auto"
        />

        <Paper radius={"xl"} maw={isMobile ? "90%" : "85%"} bg={"#ffffffc4"}>
          <Flex align="center" gap={0} h="100%" w={isTablet ? "80vw" : "auto"}>
            <Box w="100%" display={isTablet ? "none" : "block"}>
              <Image
                pl="10%"
                alt="Login image"
                width="95%"
                src="pictureLogin.png"
                fit="contain"
              />
            </Box>
            <Flex
              py={isTablet ? "xl" : "0"}
              direction={"column"}
              align={"center"}
              gap={30}
              w="100%"
            >
              <Stack spacing={0} align={"center"}>
                <Title order={2} c={theme.colors.brand[9]}>
                  Bine ai venit!
                </Title>
                <Title order={2} c={theme.colors.brand[9]}>
                  Autentifică-te
                </Title>
              </Stack>
              <form
                onSubmit={loginForm.onSubmit(onPressLogin)}
                className={classes.form}
              >
                <Stack align={"center"} w={"100%"}>
                  <TextInput
                    placeholder="E-mail"
                    radius="xl"
                    w={isMobile ? "80%" : "60%"}
                    size={isMobile ? "sm" : "md"}
                    classNames={{
                      input: classes.input,
                      error: classes.error,
                    }}
                    {...loginForm.getInputProps("email")}
                  />
                  <Stack w={isMobile ? "80%" : "60%"} spacing={5}>
                    <PasswordInput
                      placeholder="Parolă"
                      size={isMobile ? "sm" : "md"}
                      radius="xl"
                      w={"100%"}
                      classNames={{
                        input: classes.input,
                        visibilityToggle: classes.iconInputPssword,
                        error: classes.error,
                      }}
                      {...loginForm.getInputProps("password")}
                    />

                    <Anchor
                      color={"#3C4048"}
                      size={"sm"}
                      w="95%"
                      align="end"
                      component="button"
                      onClick={() => navigate(RoutesOptions.FORGET_PASSWORD)}
                    >
                      Ai uitat parola?
                    </Anchor>
                  </Stack>

                  <Button
                    variant="light"
                    radius={"xl"}
                    w={isMobile ? "auto" : "40%"}
                    mb={"sm"}
                    type="submit"
                    loading={isLoading ? true : false}
                  >
                    Autentificare
                  </Button>

                  <Text color={"dimmed"} size={isMobile ? "xs" : "sm"}>
                    Nu ai cont?{" "}
                    <Anchor
                      color={"#3C4048"}
                      component="button"
                      onClick={() => navigate(RoutesOptions.REGISTER)}
                    >
                      Creează cont.
                    </Anchor>
                  </Text>
                </Stack>
              </form>
              {isError ? (
                <Notification
                  radius={"lg"}
                  color={"red"}
                  mx={"md"}
                  title="A aparut o eroare"
                  withCloseButton={false}
                  icon={<IconX size="1.1rem" />}
                >
                  {backendError}
                </Notification>
              ) : null}
            </Flex>
          </Flex>
        </Paper>

        <Title order={3} c={theme.colors.brand[8]}>
          © evosa
        </Title>
      </Flex>
      <ErrorSuccesNotification />
    </Container>
  )
}
export default Login
