import { Select, Stack, TextInput } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { UseFormReturnType } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"

import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { useGetEmployee } from "../../../../api/employee/use-get-employee"
import { IStatusWorkitem } from "../../../../types/customize-types"
import { IUser } from "../../../../types/user-types"
import { counties } from "../../../../utils/city"
import { maxWidthMobile } from "../../../../utils/constants"
import { CustomizeElements } from "../../../../utils/enum"
import { reverseFormaterDiacritics } from "../../../../utils/strings"
import { AddCustomizeElements } from "../../../customize-page/customize-page-modals/modal-customize"
import { EditAddEmployee } from "../../../employees-page/employee-edit-add"
import { GenericModal } from "../../../generic-modal/generic-modal"
import {
  noStatus,
  noTypeWorkitem,
} from "../../utils-edit-add-work-item/utils-edit-work-item"

export const LeftSectionAddWorkItem: FunctionComponent<{
  formAddWorkItem: UseFormReturnType<any, (values: any) => any>
  setOpenModalAddClient: React.Dispatch<React.SetStateAction<boolean>>
  selectedClient: string
  setSelectedClient: React.Dispatch<React.SetStateAction<string>>
  clientsSelect: any[]
  dataUserResponse?: IUser
}> = (props) => {
  const {
    formAddWorkItem,
    setOpenModalAddClient,
    selectedClient,
    setSelectedClient,
    clientsSelect,
    dataUserResponse,
  } = props

  const isMobile = useMediaQuery(maxWidthMobile)
  const { calcFormula, amount } = formAddWorkItem.values
  const [isOpenModalResponsable, setOpenModalResponsable] = useState(false)
  const { data: dataGetEmployee, refetch: refetchEmployee } = useGetEmployee()
  const deadlineRef = useRef<any>(null)
  const [responsableSelect, setResponsableSelect]: any[] = useState([])
  const [dataTypeWorkitems, setDataTypeWorkitems] = useState<string[] | null>(
    []
  )
  const [dataStatus, setDataStatus] = useState([])
  const [isOpenModal, setOpenModal] = useState(false)
  const [customElement, setCustomElement] = useState("")
  const [isOpenWaringModal, setOpenWarningModal] = useState(false)
  const [isAgreeWithChanges, setAgreeWithChanges] = useState(false)
  const [workItemTypeState, setWorkItemTypeState] = useState("")

  useEffect(() => {
    refetchEmployee()
  }, [])

  const onChangeWorkItemType = (e: string) => {
    if ((calcFormula && amount) || calcFormula) {
      if (!isAgreeWithChanges) {
        setOpenWarningModal(true)
      } else setWorkItemTypeState(e as string)
    } else {
      setWorkItemTypeState(e as string)
    }
  }

  useEffect(() => {
    const employeeArray: any[] = []
    setResponsableSelect([])
    dataGetEmployee &&
      dataGetEmployee.data.response.forEach(
        (employee: { email: string; name: string }) => {
          employeeArray.push({
            value: employee.email,
            label: reverseFormaterDiacritics(employee.name),
          })
        }
      )
    setResponsableSelect(employeeArray)
  }, [dataGetEmployee])

  useEffect(() => {
    if (dataUserResponse) {
      const { workItemsTypes, workItemsStatuses } = dataUserResponse
      setDataTypeWorkitems(workItemsTypes && workItemsTypes.split(","))
      const statusesArray =
        workItemsStatuses &&
        JSON.parse(workItemsStatuses).map((status: IStatusWorkitem) => ({
          label: reverseFormaterDiacritics(status.label.toLocaleUpperCase()),
          value: JSON.stringify({
            label: status.label.toLocaleUpperCase(),
            color: status.color,
          }),
        }))
      setDataStatus(statusesArray)
    }
  }, [dataUserResponse])

  useEffect(() => {
    formAddWorkItem.setFieldValue(
      "workItemType",
      workItemTypeState.toLocaleUpperCase()
    )
  }, [workItemTypeState])

  return (
    <>
      <Stack w={isMobile ? "100%" : "49%"} spacing={"md"}>
        <Select
          label="Client:"
          placeholder="Alegeți/Adăugați numele clientului"
          required
          searchable
          creatable
          value={selectedClient}
          data={clientsSelect}
          onChange={(value: string) => {
            setSelectedClient(value)
            formAddWorkItem.setFieldValue("name", value)
          }}
          hoverOnSearchChange
          getCreateLabel={(query) =>
            `+ Creează un nou client cu numele ${query}`
          }
          onCreate={(query) => {
            const item = { value: query, label: query }
            setOpenModalAddClient(true)

            return item
          }}
          error={formAddWorkItem.errors.name}
        />

        <Select
          data={counties}
          w={"100%"}
          required
          label="Județ:"
          searchable
          clearable
          placeholder="Introduceți județul "
          {...formAddWorkItem.getInputProps("county")}
        />

        <TextInput
          label="Adresa:"
          placeholder="Introduceți adresa:"
          w="100%"
          {...formAddWorkItem.getInputProps("address")}
        />
        <Stack spacing={5}>
          <Select
            required
            data={dataTypeWorkitems ? dataTypeWorkitems : []}
            placeholder="Alegeți/Adăugați tipul lucrării"
            label="Tip lucrare:"
            maxDropdownHeight={280}
            creatable
            searchable
            getCreateLabel={(query) =>
              `+ Adaugă un nou tip de lucrare ${query}`
            }
            onCreate={(query) => {
              setOpenModal(true)
              setCustomElement(CustomizeElements.WORK_ITEM_TYPE)
              setWorkItemTypeState(query.toLocaleUpperCase())
              return query.toLocaleUpperCase()
            }}
            value={workItemTypeState}
            onChange={(e) => onChangeWorkItemType(e as string)}
          />
          {noTypeWorkitem(dataTypeWorkitems, setOpenModal, setCustomElement)}
        </Stack>

        <Stack spacing={5}>
          <Select
            data={dataStatus ? dataStatus : []}
            maxDropdownHeight={280}
            placeholder="Alegeți/Adăugați statusul lucrării"
            label="Status:"
            creatable
            searchable
            getCreateLabel={(query) => `+ Adaugă un nou status ${query}`}
            onCreate={(query) => {
              setOpenModal(true)
              setCustomElement(CustomizeElements.STATUS)
              return query.toLocaleUpperCase()
            }}
            {...formAddWorkItem.getInputProps("status")}
          />
          {noStatus(dataStatus, setOpenModal, setCustomElement)}
        </Stack>
        <DatePickerInput
          clearable
          required
          valueFormat="DD.MM.YYYY"
          label="Termen limită:"
          id="deadline"
          minDate={new Date()}
          placeholder="Alegeți termenul limită"
          {...formAddWorkItem.getInputProps("deadline")}
          ref={deadlineRef}
        />
        <Stack spacing={5}>
          <Select
            required
            data={responsableSelect}
            label="Responsabil:"
            creatable
            searchable
            placeholder="Alegeți /Adaugăți responsabil pentru lucrare"
            getCreateLabel={(query) => `+ Adaugă un nou responsabil ${query}`}
            onCreate={(query) => {
              setOpenModalResponsable(true)
              return query
            }}
            {...formAddWorkItem.getInputProps("responsable")}
          />
        </Stack>
        <EditAddEmployee
          isOpenModal={isOpenModalResponsable}
          setOpenModal={setOpenModalResponsable}
          isEditMode={false}
          precompleteField={formAddWorkItem.values.responsable}
        />
        <AddCustomizeElements
          dataUserResponse={dataUserResponse}
          isOpenModal={isOpenModal}
          setOpenModal={setOpenModal}
          customizeElement={customElement}
          precompleteField={
            customElement === CustomizeElements.STATUS
              ? formAddWorkItem.values.status
              : workItemTypeState
          }
        />
        <GenericModal
          isOpenModal={isOpenWaringModal}
          setOpenModal={setOpenWarningModal}
          onHandle={() => {
            setAgreeWithChanges(true)
            setOpenWarningModal(false)
          }}
          title={
            "Urmează să schimbați tipul lucrării, modificarea aceasta poate afecta câmpurile pentru formule și implicit prețul total."
          }
          subTitle={"Ești sigur(ă) că vrei să schimbi tipul lucrării?"}
          mainActionText={"Schimbare tip lucrare"}
          isLoading={false}
        />
      </Stack>
    </>
  )
}
