import { Flex, Group, Image, Table, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React from "react"
import {
  maxWidthMobile,
  maxWidthMobileMedium,
  maxWidthMobileSmall,
} from "../../../utils/constants"
import { ActionsButtonEmployee } from "../actions-button-employee"
import {
  EmployeesFields,
  EmployeesTableColumns,
} from "../employee-utils/employee.utils"
import { useAppSelector } from "../../../redux/hooks"
import { RootState } from "../../../redux/store"

export const EmployeesTableMobile = () => {
  const isMobileM = useMediaQuery(maxWidthMobileMedium)
  const { employees } = useAppSelector((state: RootState) => state.employees)
  const renderRightControlBasedOnEditMode = (field: string) => {
    let valueField = field

    return <span>{valueField}</span>
  }
  const contentCurrentRow = (
    fieldValue: string,
    field: keyof typeof EmployeesTableColumns
  ) => {
    return (
      <Flex
        justify="space-between"
        gap={0}
        direction={isMobileM ? "column" : "row"}
        mb={5}
      >
        <Text c="#3C4048" fw={500}>
          {EmployeesTableColumns[field]}:
        </Text>
        <Text> {renderRightControlBasedOnEditMode(fieldValue)}</Text>
      </Flex>
    )
  }
  const rows =
    employees &&
    employees.map((employee: any) => (
      <tr key={employee.employeeUuid}>
        <td>
          <Flex direction={"column"} w="100%" h="100%">
            <Group position="apart" mb={10}>
              <Group spacing={5}>
                <Text component="span" size={"md"} fw={700}>
                  {employee.name}
                </Text>
              </Group>

              <ActionsButtonEmployee employeeUuid={employee.employeeUuid} />
            </Group>
            {contentCurrentRow(employee.email, EmployeesFields.EMAIL)}
            {contentCurrentRow(employee.role, EmployeesFields.ROLE)}
          </Flex>
        </td>
      </tr>
    ))
  return employees && employees.length ? (
    <Table sx={{ tableLayout: "fixed" }} w={"100%"}>
      <thead>
        <tr>
          <th>
            <Text size={"lg"}>Angajat</Text>
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  ) : (
    <>
      <Text align={"center"} pt="md">
        Nu aveți nici un angajat adăugat!
      </Text>

      <Image
        maw={450}
        mx="auto"
        radius="lg"
        alt="No found employees"
        src="/add_employee.svg"
      />
    </>
  )
}
