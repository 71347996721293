import React, { FunctionComponent } from "react"
import { Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { maxWidthLaptopSmall, maxWidthMobile } from "../../../utils/constants"

export const ComponentNoItems: FunctionComponent<{ text: string }> = ({
  text,
}) => {
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isMobile = useMediaQuery(maxWidthMobile)
  const labelAreaWidth = isMobile ? "100%" : isLaptopS ? "50%" : "70%"
  return (
    <Text align="center" w={labelAreaWidth}>
      {text}
    </Text>
  )
}
