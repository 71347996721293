import React, { FunctionComponent } from "react"
import { IconBellRinging, IconExclamationMark } from "@tabler/icons-react"
import { ActionIcon, Popover, Text } from "@mantine/core"
export const WarningButton: FunctionComponent<{
  text: string
  isWarning: boolean
}> = ({ isWarning, text }) => {
  return (
    <>
      <Popover width={250} position="bottom-end" withArrow shadow="md">
        <Popover.Target>
          <ActionIcon
            radius="xl"
            size="md"
            variant="filled"
            color={isWarning ? "orange" : "yellow"}
          >
            {isWarning ? (
              <IconExclamationMark size="1.2rem" />
            ) : (
              <IconBellRinging size="1.2rem" />
            )}
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="xs">{text}</Text>
        </Popover.Dropdown>
      </Popover>
    </>
  )
}
