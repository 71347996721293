import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { ILogin } from "../../types/login-types"
import { loginUrl } from "../../utils/constants"

export type IAuthorization = {
  userUuid: string
  authToken: string
  firstName: string
  subscriptionExpireDate: string
  isTrial: boolean
}

export const usePostLogin = (
  successCallBack: (data: IAuthorization) => void,
  errorCallBack: (data: any) => void
) => {
  return useMutation(
    ["login-key"],
    (credentialLogin: ILogin | BodyInit) =>
      axios.post(loginUrl, credentialLogin),
    {
      onSuccess: (data) => {
        successCallBack(data.data.response)
      },
      onError: (error: any) => {
        errorCallBack(error.response.data)
      },
    }
  )
}
