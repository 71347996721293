import { createStyles } from "@mantine/core"

export const useStyles = createStyles((theme) => ({
  input: {
    borderColor: theme.colors.brand[9],
  },
  iconInputPssword: { color: theme.colors.brand[6] },
  form: { width: "100%" },
  error: { display: "flex", justifyContent: "center" },
}))
