import { createSlice } from "@reduxjs/toolkit";
import { IWorkItem } from "../../types/work-item-types";
import type { RootState } from "../store";

const initialState: {
  workItemsEntriesData: IWorkItem[];
  currentPageWorkItems: number;
  totalCount: number;
} = {
  workItemsEntriesData: [],
  currentPageWorkItems: 1,
  totalCount: 0,
};

export const workItemsSlice = createSlice({
  name: "workItems_slice",
  initialState: initialState,
  reducers: {
    setWorkItems: (state, action) => {
      state.workItemsEntriesData = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setCurrentPageWorkItems: (state, action) => {
      state.currentPageWorkItems = action.payload;
    },
    resetCurrentPageWorkItems: (state) => {
      state.currentPageWorkItems = initialState.currentPageWorkItems;
    },
  },
});
export const {
  setWorkItems,
  setCurrentPageWorkItems,
  resetCurrentPageWorkItems,
  setTotalCount,
} = workItemsSlice.actions;
export const selectorClients = (state: RootState) => {
  return state.workItems;
};
export default workItemsSlice.reducer;
