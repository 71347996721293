import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setErrorNotification } from '../../redux/notification/slice'
import { conventionUrl, RoutesOptions } from '../../utils/constants'
import queryString from 'query-string'
import { getGenericErrorMessage } from '../../utils/error-messages'

export interface IConventionResponse {
  data: {
    code: number
    convention: string
  }
}

export const useGetConvention = (
  workItemUuid: string,
  clientUuid: string,
  successCallback: (data: IConventionResponse) => void,
) => {
  const authToken = sessionStorage.getItem('authToken')
  const userUuid = sessionStorage.getItem('uuid')
  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  const query = queryString.stringify({ workItemUuid, clientUuid, userUuid })
  const conventionQueryUrl: string = `${conventionUrl}?${query}`
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useQuery(
    ['get-convention-key', workItemUuid],
    () => axios.get<IConventionResponse>(conventionQueryUrl, config),
    {
      onSuccess: (data: IConventionResponse) => {
        successCallback(data)
      },
      onError: (error: any) => {
        if ([401].includes(error.response.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(
            setErrorNotification(
              getGenericErrorMessage(error.response.data.message),
            ),
          )
        }
      },
      retry: false,
      enabled: false,
    },
  )
}
