import { createStyles } from "@mantine/core"

export const useStyles = createStyles((theme) => ({
  inputPDF: {
    maxWidth: "100%",
    width: "auto",
  },
  inputMultiplePdf: {
    width: "100%",
  },
}))
