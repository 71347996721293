import {
  Button,
  Modal,
  TextInput,
  Flex,
  Select,
  FileInput,
  rem,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import { IconUpload } from "@tabler/icons-react"
import _ from "lodash"
import React, { FunctionComponent, useEffect } from "react"
import { v4 as uuid } from "uuid"
import { IFile, IValueField } from "../../types/profile-types"
import { maxWidthMobile } from "../../utils/constants"
import { formaterDiacritics, normaliseDiacritics } from "../../utils/strings"

export const AddFieldProfile: FunctionComponent<{
  isOpenModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  setArrayOfInfo: any
  arrayOfInfo: IValueField[] | []
  files: IFile[]
  setFiles: React.Dispatch<React.SetStateAction<IFile[]>>
  setAddedDocs: React.Dispatch<React.SetStateAction<[]>>
  addedDocs: []
}> = (props) => {
  const {
    isOpenModal,
    setOpenModal,
    setArrayOfInfo,
    arrayOfInfo,
    files,
    setFiles,
    setAddedDocs,
    addedDocs,
  } = props
  const isMobile = useMediaQuery(maxWidthMobile)
  const onCloseModal = () => {
    setOpenModal(false)
    formAddField.clearErrors()
    formAddField.reset()
  }

  const formAddField = useForm<IValueField>({
    initialValues: {
      nameField: "",
      idField: "",
      typeField: "TEXT",
      content: "",
    },
    validate: {
      nameField: (value: string) =>
        value.length > 0
          ? null
          : "Numele trebuie să conțină cel puțin 3 caractere.",
      content: (value: any) => (value ? null : "Câmp invalid!"),
    },
  })

  const onUploadFile = (event: File | null) => {
    let filename = null,
      content = null
    const uuidFile = uuid()
    const docToAdded: any = _.cloneDeep(addedDocs)

    if (event) {
      let updateFiles = [...files]
      updateFiles.push({ idField: uuidFile, content: event, nameFile: null })
      setFiles(updateFiles)
      filename = normaliseDiacritics(event.name)
      content = event
      docToAdded.push(normaliseDiacritics(event.name))
    }
    formAddField.setFieldValue("content", {
      filename,
      content,
    })
    setAddedDocs(docToAdded)
    formAddField.setFieldValue("idField", uuidFile)
  }

  const addField = () => {
    const array = arrayOfInfo
      ? _.cloneDeep(arrayOfInfo)
      : ([] as unknown as IValueField[])

    if (formAddField.validate().hasErrors === false) {
      array.push({
        ...formAddField.values,
        nameField: formaterDiacritics(formAddField.values.nameField),
        content:
          formAddField.values.typeField === "TEXT"
            ? formaterDiacritics(formAddField.values.content)
            : formAddField.values.content,
      })
      setArrayOfInfo(array)
      setOpenModal(false)
    }
  }

  useEffect(() => {
    formAddField.clearErrors()
    formAddField.reset()
  }, [isOpenModal])

  return (
    <Modal
      size={isMobile ? "xs" : "md"}
      opened={isOpenModal}
      onClose={() => onCloseModal()}
      title={"Adăugare câmp"}
      centered
      xOffset={0}
      zIndex={2000}
    >
      <form>
        <Flex direction={"column"} gap="md" align={"center"}>
          <TextInput
            w={"100%"}
            label="Nume câmp:"
            placeholder="Introduceți numele câmpului"
            {...formAddField.getInputProps("nameField")}
          />

          <Select
            w={"100%"}
            data={["PDF", "TEXT"]}
            defaultValue={formAddField.values.typeField}
            label="Tip câmp:"
            {...formAddField.getInputProps("typeField")}
          />
          {formAddField.values.typeField === "TEXT" ? (
            <TextInput
              w={"100%"}
              label="Conținut / valoare:"
              placeholder="Introduceți conținutul câmpului "
              {...formAddField.getInputProps("content")}
            />
          ) : (
            <FileInput
              w={"100%"}
              placeholder="Alegeți conținutul câmpului "
              label="Conținut/ valoare"
              accept="application/pdf"
              defaultValue={null}
              clearable
              onChange={(e) => onUploadFile(e)}
              icon={<IconUpload size={rem(14)} />}
            />
          )}

          <Button
            variant={"light"}
            m={"md"}
            radius="xl"
            onClick={() => {
              addField()
            }}
          >
            Adaugă câmp
          </Button>
        </Flex>
      </form>
    </Modal>
  )
}
