import { Select, Stack, TextInput, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form/lib/types"
import { useMediaQuery } from "@mantine/hooks"
import { isNull } from "lodash"
import React, {
  forwardRef,
  FunctionComponent,
  useEffect,
  useState,
} from "react"
import { useGetEmployee } from "../../../../api/employee/use-get-employee"
import { IPricesFormulas } from "../../../../types/customize-types"
import { IUser } from "../../../../types/user-types"
import { ISelectCalcFormulas } from "../../../../types/work-item-types"
import { maxWidthMobile, standardCurrency } from "../../../../utils/constants"
import { CustomizeElements, WorkItemsFields } from "../../../../utils/enum"
import { reverseFormaterDiacritics } from "../../../../utils/strings"
import { AddCustomizeElements } from "../../../customize-page/customize-page-modals/modal-customize"
import { EditAddEmployee } from "../../../employees-page/employee-edit-add"
import { contentViewWorkItems } from "../../utils-edit-add-work-item/utils-edit-work-item"

export const RightSectionWorkItem: FunctionComponent<{
  isModeView: boolean
  formWorkItem: UseFormReturnType<any, (values: any) => any>
  dataUserResponse?: IUser
}> = (props) => {
  const { isModeView, formWorkItem, dataUserResponse } = props
  const {
    amount,
    calcFormula,
    totalPayment,
    advancePayment,
    restPayment,
    taxes,
    responsable,
    currency,
    workItemType,
  } = formWorkItem.values
  const isMobile = useMediaQuery(maxWidthMobile)
  const [dataCalcFormulas, setDataCalcFormulas] = useState<any>([])
  const [measurementUnit, setMeasurementUnit] = useState("")
  const [description, setDescription] = useState("")

  const SelectItem = forwardRef<HTMLDivElement, ISelectCalcFormulas>(
    ({ value, label, description, ...others }: ISelectCalcFormulas, ref) => (
      <div ref={ref} {...others}>
        <Stack spacing={0}>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </Stack>
      </div>
    )
  )
  const { data: dataGetEmployee, refetch: refetchEmployee } = useGetEmployee()
  const [responsableSelect, setResponsableSelect]: any[] = useState([])
  const [dispayCalcFormula, setDisplayCalcFormula] = useState("")
  const [isOpenModalResponsable, setOpenModalResponsable] = useState(false)
  const [isOpenModal, setOpenModal] = useState(false)
  const [workItemTypeState, setWorkItemTypeState] = useState("")

  useEffect(() => {
    refetchEmployee()
  }, [])

  useEffect(() => {
    const employeeArray: any[] = []
    setResponsableSelect([])
    dataGetEmployee &&
      dataGetEmployee.data.response.forEach(
        (employee: { email: string; name: string }) => {
          employeeArray.push({
            value: employee.email,
            label: reverseFormaterDiacritics(employee.name),
          })
        }
      )
    setResponsableSelect(employeeArray)
  }, [dataGetEmployee])

  useEffect(() => {
    if (dataUserResponse) {
      const { workItemsPricesFormulas, workItemsTypes } =
        dataUserResponse as IUser
      setWorkItemTypeState(workItemsTypes.split(","))
      const filteredArray =
        workItemsPricesFormulas &&
        JSON.parse(workItemsPricesFormulas).filter(
          (workitem: IPricesFormulas) => workitem.workItemType === workItemType
        )

      if (!filteredArray || isNull(filteredArray)) {
        setDataCalcFormulas([])
      } else {
        const newArray = filteredArray.map(
          (item: {
            name: string
            workItemMeasurementUnit: string
            workItemType: string
            basePrice: string
          }) => ({
            label: item.name,
            value: JSON.stringify(item),
            description: item.basePrice
              .concat(` ${standardCurrency} / `)
              .concat(item.workItemMeasurementUnit),
          })
        )
        setDataCalcFormulas(newArray)
      }
    }
  }, [workItemType, dataUserResponse])

  useEffect(() => {
    if (calcFormula) {
      const { workItemMeasurementUnit, name, basePrice } =
        JSON.parse(calcFormula)

      setMeasurementUnit(workItemMeasurementUnit)
      setDisplayCalcFormula(
        name
          .toLocaleUpperCase()
          .concat(" - ")
          .concat(
            basePrice + ` ${standardCurrency} /` + workItemMeasurementUnit
          )
      )
      const formulaForDescription =
        basePrice.concat(` ${standardCurrency} / `) + workItemMeasurementUnit

      setDescription(formulaForDescription)
    } else {
      setDisplayCalcFormula("")
      setDescription("")
    }
  }, [calcFormula])

  useEffect(() => {
    if (calcFormula) {
      const basePrice = JSON.parse(calcFormula).basePrice
      formWorkItem.setFieldValue(
        "totalPayment",
        amount ? amount * basePrice : totalPayment
      )
    }
  }, [amount, calcFormula])

  return (
    <>
      <Stack w={isMobile ? "100%" : "49%"}>
        {isModeView ? (
          <>
            {contentViewWorkItems(responsable, WorkItemsFields.RESPONSABLE)}
            {contentViewWorkItems(
              dispayCalcFormula,
              WorkItemsFields.CALC_FORMULA
            )}
            {contentViewWorkItems(
              amount && String(amount) + " " + measurementUnit,
              WorkItemsFields.AMOUNT
            )}
            {contentViewWorkItems(
              String(totalPayment) + " " + currency,
              WorkItemsFields.TOTAL_PAYMENT
            )}

            {contentViewWorkItems(
              advancePayment && String(advancePayment) + " " + currency,
              WorkItemsFields.ADVANCE_PAYMENT
            )}
            {contentViewWorkItems(
              String(restPayment) + " " + currency,
              WorkItemsFields.REST_PAYMENT
            )}
            {contentViewWorkItems(
              taxes && String(taxes) + " " + currency,
              WorkItemsFields.TAXES
            )}
          </>
        ) : (
          <>
            <Select
              required
              data={responsableSelect}
              label="Responsabil:"
              creatable
              searchable
              placeholder="Alege/Adaugă responsabil pentru lucrare"
              getCreateLabel={(query) => `+ Adaugă un nou responsabil ${query}`}
              onCreate={(query) => {
                setOpenModalResponsable(true)
                return query
              }}
              {...formWorkItem.getInputProps("responsable")}
            />

            <Select
              label="Formulă de calcul:"
              disabled={workItemTypeState.includes("") ? true : false}
              description={description ? description : ""}
              placeholder="Alegeți/Adăugați formula de calcul"
              itemComponent={SelectItem}
              data={dataCalcFormulas}
              maxDropdownHeight={400}
              creatable
              searchable
              getCreateLabel={(query) =>
                `+ Adaugă un nouă formulă ${query.toLocaleUpperCase()}`
              }
              onCreate={(query: any) => {
                setOpenModal(true)
                return JSON.stringify({
                  name: query.toLocaleUpperCase(),
                  workItemMeasurementsUnit: "",
                  workItemType: workItemType,
                  basePrice: "",
                })
              }}
              {...formWorkItem.getInputProps("calcFormula")}
            />

            <TextInput
              type={"number"}
              disabled={calcFormula ? false : true}
              placeholder="Introduceți cantitatea"
              label="Cantitatea:"
              rightSection={measurementUnit}
              rightSectionWidth={"3rem"}
              {...formWorkItem.getInputProps("amount")}
            />
            <TextInput
              disabled={isModeView}
              type={"number"}
              name="totalPayment"
              required
              placeholder="Introduceți totalul de plată"
              label="Total de plată:"
              rightSection={currency}
              rightSectionWidth={"3rem"}
              {...formWorkItem.getInputProps("totalPayment")}
            />

            <TextInput
              disabled={isModeView}
              name="advancePayment"
              type={"number"}
              label="Plată în avans:"
              placeholder="Introduceți plata în avans"
              rightSection={currency}
              rightSectionWidth={"3rem"}
              {...formWorkItem.getInputProps("advancePayment")}
            />

            <TextInput
              type={"number"}
              name="restPayment"
              label="Rest de plată:"
              disabled
              rightSection={currency}
              rightSectionWidth={"3rem"}
              {...formWorkItem.getInputProps("restPayment")}
            />
            <TextInput
              disabled={isModeView}
              name="taxes"
              type={"number"}
              label="Taxe:"
              placeholder="Introduceți suma pentru taxe"
              rightSection={currency}
              rightSectionWidth={"3rem"}
              {...formWorkItem.getInputProps("taxes")}
            />
          </>
        )}
        <EditAddEmployee
          isOpenModal={isOpenModalResponsable}
          setOpenModal={setOpenModalResponsable}
          isEditMode={false}
          precompleteField={responsable}
        />
      </Stack>

      <AddCustomizeElements
        isOpenModal={isOpenModal}
        setOpenModal={setOpenModal}
        customizeElement={CustomizeElements.CALC_FORMULA}
        precompleteField={calcFormula}
      />
    </>
  )
}
