import { Select, SelectItem, Stack, TextInput } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { UseFormReturnType } from "@mantine/form/lib/types"
import { useMediaQuery } from "@mantine/hooks"
import { isNull } from "lodash"
import React, { FunctionComponent, useEffect, useState } from "react"
import { IStatusWorkitem } from "../../../../types/customize-types"
import { IUser } from "../../../../types/user-types"
import { IWorkItem } from "../../../../types/work-item-types"
import { counties } from "../../../../utils/city"
import { maxWidthMobile } from "../../../../utils/constants"
import { convertDateTOStringFormatForBacEnd } from "../../../../utils/dates"
import { CustomizeElements, WorkItemsFields } from "../../../../utils/enum"
import {
  diacriticsCorrector,
  reverseFormaterDiacritics,
} from "../../../../utils/strings"
import { AddCustomizeElements } from "../../../customize-page/customize-page-modals/modal-customize"
import { GenericModal } from "../../../generic-modal/generic-modal"
import { contentViewWorkItems } from "../../utils-edit-add-work-item/utils-edit-work-item"

export const LeftSectionWorkItem: FunctionComponent<{
  isModeView: boolean
  formWorkItem: UseFormReturnType<any, (values: any) => any>
  dataWorkItem?: IWorkItem
  dataUserResponse?: IUser
}> = (props) => {
  const { isModeView, formWorkItem, dataUserResponse, dataWorkItem } = props
  const {
    name,
    county,
    address,
    status,
    workItemType,
    deadline,
    createDate,
    lastUpdateDate,
    calcFormula,
    amount,
  } = formWorkItem.values
  const isMobile = useMediaQuery(maxWidthMobile)
  const [dataTypeWorkitems, setDataTypeWorkitems] = useState([])
  const [dataStatus, setDataStatus] = useState<
    readonly (string | SelectItem)[]
  >([{ value: "", label: "" }])
  const [isOpenWaringModal, setOpenWarningModal] = useState(false)
  const [isAgreeWithChanges, setAgreeWithChanges] = useState(false)
  const [isOpenModal, setOpenModal] = useState(false)
  const [customElement, setCustomElement] = useState("")
  const [workItemTypeState, setWorkItemTypeState] = useState("")

  const onChangeWorkItemType = (e: string) => {
    if ((calcFormula && amount) || calcFormula) {
      if (!isAgreeWithChanges) {
        setOpenWarningModal(true)
      } else {
        setWorkItemTypeState(e as string)
        formWorkItem.setValues({
          ...formWorkItem,
          calcFormula: null,
          amount: 0,
        })
      }
    } else {
      setWorkItemTypeState(e as string)
    }
  }

  useEffect(() => {
    if (dataUserResponse) {
      const { workItemsTypes, workItemsStatuses } = dataUserResponse
      setDataTypeWorkitems(workItemsTypes.split(","))
      if (isNull(workItemsStatuses)) {
        const defaultValue = {
          label: "DRAFT",
          value: JSON.stringify({
            color: "#ceddca",
            label: "DRAFT",
          }),
        }
        setDataStatus([defaultValue])
      } else {
        const statusesArray =
          workItemsStatuses &&
          JSON.parse(workItemsStatuses).map((status: IStatusWorkitem) => ({
            label: reverseFormaterDiacritics(status.label.toLocaleUpperCase()),
            value: JSON.stringify({
              label: status.label,
              color: status.color,
            }).toLocaleUpperCase(),
          }))

        setDataStatus(statusesArray)
      }
    }
  }, [status, dataUserResponse])

  useEffect(() => {
    if (dataWorkItem) {
      setWorkItemTypeState(dataWorkItem.workItemType)
    }
  }, [dataWorkItem])

  useEffect(() => {
    formWorkItem.setFieldValue("workItemType", workItemTypeState)
  }, [workItemTypeState])

  return (
    <Stack w={isMobile ? "100%" : "49%"}>
      {isModeView ? (
        status ? (
          <>
            {contentViewWorkItems(name as string, WorkItemsFields.CLIENT_NAME)}
            {contentViewWorkItems(county as string, WorkItemsFields.COUNTY)}
            {contentViewWorkItems(address as string, WorkItemsFields.ADDRESS)}
            {contentViewWorkItems(
              workItemType as string,
              WorkItemsFields.WORK_ITEM_TYPE
            )}
            {contentViewWorkItems(status as string, WorkItemsFields.STATUS)}
            {contentViewWorkItems(
              String(convertDateTOStringFormatForBacEnd(deadline)),
              WorkItemsFields.DEADLINE
            )}
            {contentViewWorkItems(
              String(diacriticsCorrector(createDate)),
              WorkItemsFields.CREATE_DATE
            )}
            {contentViewWorkItems(
              String(diacriticsCorrector(lastUpdateDate)),
              WorkItemsFields.LAST_UPDATE_DATE
            )}
          </>
        ) : null
      ) : (
        <>
          <TextInput
            disabled
            label="Client:"
            required
            placeholder="Introduceți numele clientului"
            {...formWorkItem.getInputProps("name")}
          />

          <Select
            data={counties}
            w="100%"
            required
            label="Județ:"
            searchable
            clearable
            placeholder="Introduceți județul"
            {...formWorkItem.getInputProps("county")}
          />

          <TextInput
            label="Adresă:"
            placeholder="Introduceți adresa"
            w="100%%"
            {...formWorkItem.getInputProps("address")}
          />

          <Select
            data={dataTypeWorkitems}
            disabled={isModeView}
            required
            label="Tip lucrare:"
            creatable
            searchable
            getCreateLabel={(query) =>
              `+ Adaugă un nou tip de lucrare ${query}`
            }
            onCreate={(query) => {
              setOpenModal(true)
              setCustomElement(CustomizeElements.WORK_ITEM_TYPE)
              setWorkItemTypeState(query.toLocaleUpperCase())
              return query.toLocaleUpperCase()
            }}
            placeholder="Alegeți/Adăugați tipul lucrării"
            value={workItemTypeState}
            onChange={(e) => onChangeWorkItemType(e as string)}
          />
          <Select
            data={dataStatus}
            label="Status:"
            placeholder="Alegeți/Adăugați statusul lucrării"
            creatable
            searchable
            getCreateLabel={(query) => `+ Adaugă un nou status ${query}`}
            onCreate={(query) => {
              setOpenModal(true)
              setCustomElement(CustomizeElements.STATUS)
              return query.toLocaleUpperCase()
            }}
            {...formWorkItem.getInputProps("status")}
          />
          <DatePickerInput
            disabled={isModeView}
            required
            clearable
            valueFormat="DD.MM.YYYY "
            label="Termen limită:"
            placeholder="Alegeți termenul limită"
            {...formWorkItem.getInputProps("deadline")}
          />
          <TextInput
            disabled
            name="createDate"
            label="Dată creare:"
            placeholder="Introduceți responsabilul de lucrare"
            {...formWorkItem.getInputProps("createDate")}
          />
          <TextInput
            disabled
            name="lastUpdateDate"
            label="Dată actualizare:"
            placeholder="Introduceți responsabilul de lucrare"
            {...formWorkItem.getInputProps("lastUpdateDate")}
          />
          <GenericModal
            isOpenModal={isOpenWaringModal}
            setOpenModal={setOpenWarningModal}
            onHandle={() => {
              setAgreeWithChanges(true)
              setOpenWarningModal(false)
            }}
            title={
              "Urmează să schimbați tipul lucrării, modificarea aceasta poate afecta câmpurile pentru formule și implicit prețul total."
            }
            subTitle={"Ești sigur(ă) că vrei să schimbi tipul lucrării?"}
            mainActionText={"Schimbare tip lucrare"}
            isLoading={false}
          />
        </>
      )}
      <AddCustomizeElements
        dataUserResponse={dataUserResponse}
        isOpenModal={isOpenModal}
        setOpenModal={setOpenModal}
        customizeElement={customElement}
        precompleteField={
          customElement === CustomizeElements.STATUS
            ? formWorkItem.values.status
            : workItemTypeState
        }
      />
    </Stack>
  )
}
