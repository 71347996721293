import { Table, Text } from "@mantine/core";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { RowsClientsMobile } from "../utils-clients-page/clients-tables.utils";

export const ClientsTableMobile: FunctionComponent = () => {
  const { clientsEntriesData } = useSelector(
    (state: RootState) => state.clients
  );
  return (
    <>
      <Table sx={{ tableLayout: "fixed" }} w={"100%"}>
        <thead>
          <tr>
            <th>
              <Text size={"lg"}>Client</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <RowsClientsMobile clientsEntriesData={clientsEntriesData} />
        </tbody>
      </Table>
    </>
  );
};
