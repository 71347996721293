import { Flex, Group } from "@mantine/core"
import { TableColumnsWorkItems } from "../../../utils/enum"
import {
  renderRightControl,
  renderStatusControl,
} from "../../clients-page/utils-clients-page/utils-edit-client"

export const contentCurrentRow = (
  fieldValue: string,
  field: keyof typeof TableColumnsWorkItems
) => {
  return (
    <Group position="apart" spacing={0} key={field}>
      <Flex justify={"space-between"} w="100%">
        {TableColumnsWorkItems[field]}:
        {field === "status"
          ? renderStatusControl(fieldValue.toLocaleLowerCase())
          : renderRightControl(fieldValue)}
      </Flex>
    </Group>
  )
}
