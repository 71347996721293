import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { IRegister } from "../../types/register-types"
import { registerUrl } from "../../utils/constants"

export const usePostRegister = (
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void
) => {
  return useMutation(
    ["register-key"],
    (credentialRegister: IRegister | BodyInit) =>
      axios.post(registerUrl, credentialRegister),
    {
      onSuccess: (data) => {
        successCallBack(data.data.response)
      },
      onError: (error: any) => {
        errorCallBack(error.response.data)
      },
    }
  )
}
