import { IClient } from "../../../types/client-types"
import { reverseFormaterDiacritics } from "../../../utils/strings"

export const buildFormValuesClient = (clientDetails: IClient) => {
  const {
    name,
    fiscalCode,
    phoneNumber,
    email,
    userUuid,
    address,
    city,
    county,
    idSeries,
    idSeriesNumber,
    typeClient,
    CNP,
    nrRegister,
  } = clientDetails

  return {
    name: reverseFormaterDiacritics(name),
    fiscalCode,
    email,
    phoneNumber,
    userUuid,
    address: reverseFormaterDiacritics(address),
    city: city ? city : "",
    county,
    idSeries,
    idSeriesNumber,
    typeClient,
    CNP,
    nrRegister,
  }
}
