import { Flex, Table, Text } from "@mantine/core"
import React, { FunctionComponent } from "react"
import { IDocument } from "../../types/work-item-types"
import _ from "lodash"
import { maxWidthMobile } from "../../utils/constants"
import { useMediaQuery } from "@mantine/hooks"
import { ActionButtonsSectionMultipleFile } from "./action-buttons-section"

export const ComponentPreviewMultipleFile: FunctionComponent<{
  containerOtherDocs: IDocument[]
  setContainerOtherDocs: (value: React.SetStateAction<IDocument[]>) => void
  isModeView?: boolean
  isModeAdd?: boolean
}> = (props) => {
  const { containerOtherDocs, setContainerOtherDocs, isModeAdd, isModeView } =
    props
  const isMobile = useMediaQuery(maxWidthMobile)

  const rows =
    containerOtherDocs &&
    containerOtherDocs.map((element, index) => (
      <tr key={index}>
        <td width={"90%"}>
          <Text size={16} style={{ lineBreak: "anywhere" }}>
            {element.filename.includes("***")
              ? element.filename.split("***")[1]
              : element.filename}
          </Text>
        </td>
        <td width={"10%"}>
          <ActionButtonsSectionMultipleFile
            isModeView={isModeView ? true : false}
            document={element}
            index={index}
            containerOtherDocs={containerOtherDocs}
            setContainerOtherDocs={setContainerOtherDocs}
          />
        </td>
      </tr>
    ))
  const rowsMobile =
    containerOtherDocs &&
    containerOtherDocs.map((element, index) => (
      <tr key={index}>
        <td width={"100%"}>
          <Flex align="flex-start" justify="space-between">
            <Text
              size={14}
              w="calc(100% - 5rem)"
              style={{ lineBreak: "anywhere" }}
            >
              {element.filename.includes("***")
                ? element.filename.split("***")[1]
                : element.filename}
            </Text>

            <ActionButtonsSectionMultipleFile
              isModeView={isModeView ? true : false}
              document={element}
              index={index}
              containerOtherDocs={containerOtherDocs}
              setContainerOtherDocs={setContainerOtherDocs}
            />
          </Flex>
        </td>
      </tr>
    ))

  return containerOtherDocs?.length > 0 ? (
    <>
      <Table highlightOnHover>
        {isMobile ? (
          <thead>
            <tr>
              <th>Fișiere</th>
            </tr>
          </thead>
        ) : (
          <thead>
            <tr>
              <th>Fișiere</th>
              <th>Acțiuni</th>
            </tr>
          </thead>
        )}
        <tbody>{isMobile ? rowsMobile : rows}</tbody>
      </Table>
    </>
  ) : isModeAdd ? null : isModeView ? (
    isModeView && (
      <>
        <Text>-</Text>
      </>
    )
  ) : (
    <Text size={"sm"} color="#495057" align="center">
      Nu există fișier adăugat.
    </Text>
  )
}
