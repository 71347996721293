import { Table } from "@mantine/core";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useStyles } from "../clients-table.styles";
import { rowsClientsLaptopS } from "../utils-clients-page/clients-tables.utils";

export const ClientsTableLaptopS: FunctionComponent = () => {
  const { classes } = useStyles();
  const { clientsEntriesData } = useSelector(
    (state: RootState) => state.clients
  );

  return (
    <>
      <Table w={"100%"}>
        <thead className={classes.header}>
          <tr>
            <th>Denumire client</th>
            <th>Detalii client</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{rowsClientsLaptopS(clientsEntriesData)}</tbody>
      </Table>
    </>
  );
};
