import {
  Container,
  Flex,
  LoadingOverlay,
  Modal,
  Paper,
  Title,
} from "@mantine/core"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import React, { useEffect, useState } from "react"
import { useGetUser } from "../../api/user/use-get-user"
import {
  backgroudColor,
  maxWidthMobile,
  maxWidthTablet,
  RoutesOptions,
} from "../../utils/constants"
import { Navigationbar } from "../navbar/navbar"
import { SubscriberDetails } from "./subscriber-details"
//@ts-ignore
import * as MyPOSEmbedded from "mypos-embedded-checkout"
import { usePostUserPayment } from "../../api/user/use-post-user-payment"
import { useDispatch } from "react-redux"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import { getGenericErrorMessage } from "../../utils/error-messages"
import {
  callbackParams,
  customization,
  paymentParms,
} from "../../utils/payment"
import { useNavigate } from "react-router-dom"
import { formatterDate } from "./utils-account-details"
import { SubscriptionDetails } from "./subscription-details"
import { IUser } from "../../types/user-types"
import { DividerVerticalHorizontal } from "../customize-page/shared-component/component-divider-vertical-horizontal"

export const AccountDetails = () => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const isTablet = useMediaQuery(maxWidthTablet)
  const [
    paymentModalOpened,
    { open: openPaymentModal, close: closePaymentModal },
  ] = useDisclosure(false)
  const [userDataResponse, setUserDataResponse] = useState<IUser>()
  const [affiliateCode, setAffliateCode] = useState<string>()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const succesCallBack = (data: any) => {
    if (formatterDate(data.response.subscriptionExpireDate) < new Date()) {
      navigate(RoutesOptions.TRIAL_OVER)
    }
  }
  const { data, refetch, isRefetching, isLoading } = useGetUser(succesCallBack)

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (data) {
      setUserDataResponse(data.data.response)
      setAffliateCode(data.data.response.usedAffiliateCode)
    }
  }, [data])

  const userPaymentSuccessCallback = (data: any) => {
    closePaymentModal()
    refetch()
    dispatch(setMessageNotification(data.message))
  }
  const userPaymentErrorCallback = (err: any) => {
    refetch()
    dispatch(
      setErrorNotification(
        getGenericErrorMessage(
          "Daca problema persistă contactează echipa de suport!"
        )
      )
    )
  }
  const { mutate } = usePostUserPayment(
    (data) => userPaymentSuccessCallback(data),
    (err) => userPaymentErrorCallback(err)
  )

  const triggerPayment = () => {
    openPaymentModal()
    setTimeout(() => {
      MyPOSEmbedded.createPayment(
        "embeddedCheckout",
        paymentParms(affiliateCode),
        callbackParams(userDataResponse, userPaymentErrorCallback, mutate),
        customization
      )
    }, 200)
  }

  return (
    <Container
      fluid
      m={0}
      p={0}
      bg={backgroudColor}
      h={isMobile ? "100%" : "100vh"}
    >
      <LoadingOverlay visible={isLoading || isRefetching} />
      <Flex h={"100%"} direction={isTablet ? "column" : "row"}>
        <Navigationbar />
        <Flex
          direction={"column"}
          w="calc(100% - 2rem)"
          h={isTablet ? "calc(100% - 7rem)" : "calc(100% - 2rem)"}
          justify="flex-start"
          gap="md"
          p="md"
        >
          <Title order={3} h="2rem">
            Detalii cont
          </Title>

          <Paper
            p="md"
            radius={"md"}
            w="100%"
            h={isMobile ? "auto" : "calc(100% - 8rem)"}
          >
            {userDataResponse && (
              <Flex
                h="100%"
                justify="space-between"
                direction={isMobile ? "column-reverse" : "row"}
                gap={isMobile ? "2rem" : 0}
              >
                <SubscriberDetails response={userDataResponse} />
                <DividerVerticalHorizontal />
                <SubscriptionDetails
                  triggerPayment={triggerPayment}
                  affiliateCode={userDataResponse.usedAffiliateCode}
                />
              </Flex>
            )}
          </Paper>
        </Flex>
      </Flex>
      <Modal
        xOffset={0}
        size={isMobile ? "calc(100vw - 2rem)" : "lg"}
        opened={paymentModalOpened}
        onClose={closePaymentModal}
        centered
        withCloseButton={true}
        padding="xl"
      >
        <div id="embeddedCheckout"></div>
      </Modal>
    </Container>
  )
}
