export const bcpi = [
  {
    oid: '10',
    bid: '10001',
    name: 'Alba Iulia',
  },
  {
    oid: '10',
    bid: '10002',
    name: 'Aiud',
  },
  {
    oid: '10',
    bid: '10003',
    name: 'Blaj',
  },
  {
    oid: '10',
    bid: '10004',
    name: 'Câmpeni',
  },
  {
    oid: '10',
    bid: '10005',
    name: 'Sebeș',
  },
  {
    oid: '29',
    bid: '29001',
    name: 'Arad',
  },
  {
    oid: '29',
    bid: '29002',
    name: 'Chișineu-Criș',
  },
  {
    oid: '29',
    bid: '29003',
    name: 'Gurahonț',
  },
  {
    oid: '29',
    bid: '29004',
    name: 'Ineu',
  },
  {
    oid: '29',
    bid: '29005',
    name: 'Lipova',
  },
  {
    oid: '38',
    bid: '38002',
    name: 'Pitești',
  },
  {
    oid: '38',
    bid: '38003',
    name: 'Câmpulung',
  },
  {
    oid: '38',
    bid: '38004',
    name: 'Costești',
  },
  {
    oid: '38',
    bid: '38005',
    name: 'Curtea de Argeș',
  },
  {
    oid: '38',
    bid: '38006',
    name: 'Topoloveni',
  },
  {
    oid: '47',
    bid: '47002',
    name: 'Bacău',
  },
  {
    oid: '47',
    bid: '47003',
    name: 'Buhuși',
  },
  {
    oid: '47',
    bid: '47004',
    name: 'Moinești',
  },
  {
    oid: '47',
    bid: '47005',
    name: 'Onești',
  },
  {
    oid: '56',
    bid: '56002',
    name: 'Oradea',
  },
  {
    oid: '56',
    bid: '56003',
    name: 'Aleșd',
  },
  {
    oid: '56',
    bid: '56004',
    name: 'Beiuș',
  },
  {
    oid: '56',
    bid: '56005',
    name: 'Marghita',
  },
  {
    oid: '56',
    bid: '56006',
    name: 'Salonta',
  },
  {
    oid: '65',
    bid: '65002',
    name: 'Bistrița',
  },
  {
    oid: '65',
    bid: '65003',
    name: 'Beclean',
  },
  {
    oid: '65',
    bid: '65004',
    name: 'Năsăud',
  },
  {
    oid: '74',
    bid: '74001',
    name: 'Botoșani',
  },
  {
    oid: '74',
    bid: '74002',
    name: 'Dorohoi',
  },
  {
    oid: '83',
    bid: '83002',
    name: 'Brașov',
  },
  {
    oid: '83',
    bid: '83003',
    name: 'Făgăraș',
  },
  {
    oid: '83',
    bid: '83004',
    name: 'Rupea',
  },
  {
    oid: '83',
    bid: '83005',
    name: 'Zărnești',
  },
  {
    oid: '92',
    bid: '92001',
    name: 'Brăila',
  },
  {
    oid: '92',
    bid: '92002',
    name: 'Făurei',
  },
  {
    oid: '92',
    bid: '92003',
    name: 'Însurăței',
  },
  {
    oid: '109',
    bid: '109001',
    name: 'Buzău',
  },
  {
    oid: '118',
    bid: '118002',
    name: 'Reșița',
  },
  {
    oid: '118',
    bid: '118004',
    name: 'Caransebeș',
  },
  {
    oid: '118',
    bid: '118006',
    name: 'Oravița',
  },
  {
    oid: '127',
    bid: '127002',
    name: 'Cluj-Napoca',
  },
  {
    oid: '127',
    bid: '127003',
    name: 'Dej',
  },
  {
    oid: '127',
    bid: '127004',
    name: 'Gherla',
  },
  {
    oid: '127',
    bid: '127005',
    name: 'Huedin',
  },
  {
    oid: '127',
    bid: '127006',
    name: 'Turda',
  },
  {
    oid: '136',
    bid: '136002',
    name: 'Constanța',
  },
  {
    oid: '136',
    bid: '136004',
    name: 'Mangalia',
  },
  {
    oid: '136',
    bid: '136005',
    name: 'Medgidia',
  },
  {
    oid: '145',
    bid: '145002',
    name: 'Sfântu Gheorghe',
  },
  {
    oid: '145',
    bid: '145003',
    name: 'Întorsura Buzăului',
  },
  {
    oid: '145',
    bid: '145004',
    name: 'Târgu Secuiesc',
  },
  {
    oid: '154',
    bid: '154001',
    name: 'Târgoviște',
  },
  {
    oid: '154',
    bid: '154002',
    name: 'Moreni',
  },
  {
    oid: '163',
    bid: '163001',
    name: 'Craiova',
  },
  {
    oid: '172',
    bid: '172002',
    name: 'Galați',
  },
  {
    oid: '172',
    bid: '172005',
    name: 'Tecuci',
  },
  {
    oid: '172',
    bid: '172006',
    name: 'Liești',
  },
  {
    oid: '181',
    bid: '181001',
    name: 'Târgu Jiu',
  },
  {
    oid: '190',
    bid: '190002',
    name: 'Miercurea-Ciuc',
  },
  {
    oid: '190',
    bid: '190003',
    name: 'Gheorgheni',
  },
  {
    oid: '190',
    bid: '190004',
    name: 'Odorheiu Secuiesc',
  },
  {
    oid: '190',
    bid: '190005',
    name: 'Toplița',
  },
  {
    oid: '207',
    bid: '207002',
    name: 'Deva',
  },
  {
    oid: '207',
    bid: '207003',
    name: 'Brad',
  },
  {
    oid: '207',
    bid: '207004',
    name: 'Hațeg',
  },
  {
    oid: '207',
    bid: '207005',
    name: 'Hunedoara',
  },
  {
    oid: '207',
    bid: '207006',
    name: 'Orăștie',
  },
  {
    oid: '207',
    bid: '207007',
    name: 'Petroșani',
  },
  {
    oid: '216',
    bid: '216001',
    name: 'Slobozia',
  },
  {
    oid: '225',
    bid: '225002',
    name: 'Iași',
  },
  {
    oid: '225',
    bid: '225003',
    name: 'Hârlău',
  },
  {
    oid: '225',
    bid: '225004',
    name: 'Pașcani',
  },
  {
    oid: '225',
    bid: '225005',
    name: 'Răducăneni',
  },
  {
    oid: '234',
    bid: '234001',
    name: 'Buftea',
  },
  {
    oid: '243',
    bid: '243002',
    name: 'Baia Mare',
  },
  {
    oid: '243',
    bid: '243003',
    name: 'Dragomirești',
  },
  {
    oid: '243',
    bid: '243004',
    name: 'Sighetu Marmației',
  },
  {
    oid: '243',
    bid: '243005',
    name: 'Șomcuța Mare',
  },
  {
    oid: '243',
    bid: '243006',
    name: 'Târgu Lăpuș',
  },
  {
    oid: '243',
    bid: '243007',
    name: 'Vișeu de Sus',
  },
  {
    oid: '252',
    bid: '252002',
    name: 'Drobeta-Turnu Severin',
  },
  {
    oid: '252',
    bid: '252003',
    name: 'Baia De Aramă',
  },
  {
    oid: '252',
    bid: '252004',
    name: 'Orșova',
  },
  {
    oid: '252',
    bid: '252005',
    name: 'Strehaia',
  },
  {
    oid: '252',
    bid: '252006',
    name: 'Vânju Mare',
  },
  {
    oid: '261',
    bid: '261002',
    name: 'Târgu Mures',
  },
  {
    oid: '261',
    bid: '261003',
    name: 'Luduș',
  },
  {
    oid: '261',
    bid: '261004',
    name: 'Reghin',
  },
  {
    oid: '261',
    bid: '261005',
    name: 'Sighișoara',
  },
  {
    oid: '261',
    bid: '261006',
    name: 'Târnăveni',
  },
  {
    oid: '270',
    bid: '270002',
    name: 'Piatra-Neamț',
  },
  {
    oid: '270',
    bid: '270003',
    name: 'Bicaz',
  },
  {
    oid: '270',
    bid: '270004',
    name: 'Roman',
  },
  {
    oid: '270',
    bid: '270005',
    name: 'Târgu-Neamț',
  },
  {
    oid: '289',
    bid: '289002',
    name: 'Slatina',
  },
  {
    oid: '298',
    bid: '298002',
    name: 'Ploiești',
  },
  {
    oid: '298',
    bid: '298003',
    name: 'Câmpina',
  },
  {
    oid: '298',
    bid: '298004',
    name: 'Valeni de Munte',
  },
  {
    oid: '305',
    bid: '305001',
    name: 'Satu Mare',
  },
  {
    oid: '305',
    bid: '305002',
    name: 'Carei',
  },
  {
    oid: '305',
    bid: '305003',
    name: 'Negrești-Oaș',
  },
  {
    oid: '314',
    bid: '314002',
    name: 'Zalău',
  },
  {
    oid: '314',
    bid: '314003',
    name: 'Șimleu Silvaniei',
  },
  {
    oid: '323',
    bid: '323002',
    name: 'Sibiu',
  },
  {
    oid: '323',
    bid: '323003',
    name: 'Agnita',
  },
  {
    oid: '323',
    bid: '323004',
    name: 'Avrig',
  },
  {
    oid: '323',
    bid: '323005',
    name: 'Mediaș',
  },
  {
    oid: '323',
    bid: '323006',
    name: 'Săliște',
  },
  {
    oid: '332',
    bid: '332001',
    name: 'Suceava',
  },
  {
    oid: '332',
    bid: '332002',
    name: 'Câmpulung Moldovenesc',
  },
  {
    oid: '332',
    bid: '332003',
    name: 'Fălticeni',
  },
  {
    oid: '332',
    bid: '332004',
    name: 'Gura Humorului',
  },
  {
    oid: '332',
    bid: '332005',
    name: 'Rădăuți',
  },
  {
    oid: '332',
    bid: '332006',
    name: 'Vatra Dornei',
  },
  {
    oid: '341',
    bid: '341002',
    name: 'Alexandria',
  },
  {
    oid: '341',
    bid: '341005',
    name: 'Videle',
  },
  {
    oid: '341',
    bid: '341006',
    name: 'Zimnicea',
  },
  {
    oid: '350',
    bid: '350002',
    name: 'Timișoara',
  },
  {
    oid: '350',
    bid: '350003',
    name: 'Deta',
  },
  {
    oid: '350',
    bid: '350004',
    name: 'Făget',
  },
  {
    oid: '350',
    bid: '350005',
    name: 'Lugoj',
  },
  {
    oid: '350',
    bid: '350006',
    name: 'Sânnicolau Mare',
  },
  {
    oid: '369',
    bid: '369001',
    name: 'Tulcea',
  },
  {
    oid: '378',
    bid: '378001',
    name: 'Vaslui',
  },
  {
    oid: '378',
    bid: '378002',
    name: 'Bârlad',
  },
  {
    oid: '387',
    bid: '387001',
    name: 'Râmnicu Vâlcea',
  },
  {
    oid: '396',
    bid: '396001',
    name: 'Focșani',
  },
  {
    oid: '396',
    bid: '396002',
    name: 'Adjud',
  },
  {
    oid: '396',
    bid: '396003',
    name: 'Panciu',
  },
  {
    oid: '403',
    bid: '403001',
    name: 'Bucuresți Sectorul 1',
  },
  {
    oid: '403',
    bid: '403002',
    name: 'Bucuresți Sectorul 2',
  },
  {
    oid: '403',
    bid: '403003',
    name: 'Bucuresți Sectorul 3',
  },
  {
    oid: '403',
    bid: '403004',
    name: 'Bucuresți Sectorul 4',
  },
  {
    oid: '403',
    bid: '403005',
    name: 'Bucuresți Sectorul 5',
  },
  {
    oid: '403',
    bid: '403006',
    name: 'Bucuresți Sectorul 6',
  },
  {
    oid: '519',
    bid: '519001',
    name: 'Călărași',
  },
  {
    oid: '519',
    bid: '519003',
    name: 'Oltenița',
  },
  {
    oid: '528',
    bid: '528001',
    name: 'Giurgiu',
  },
]
