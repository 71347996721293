import { useMutation } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setErrorNotification } from '../../redux/notification/slice'
import { profileUrlUpdate, RoutesOptions } from '../../utils/constants'
import { IResponse } from '../../types/api.types'

export const usePutFieldsProfile = (
  successCallBack: (data: IResponse) => void,
  errorCallBack: (data: Error) => void,
  profileUuid?: string,
) => {
  const authToken = sessionStorage.getItem('authToken')

  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useMutation(
    ['update-fields-profile-key'],
    (fieldsData: BodyInit | any) =>
      axios.put(profileUrlUpdate.concat(`/${profileUuid}`), fieldsData, config),
    {
      onSuccess: (data: AxiosResponse<IResponse, IResponse>) => {
        successCallBack(data.data)
      },
      onError: (error: any) => {
        if ([401].includes(error.response.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(
            setErrorNotification(`Te rog încearcă mai târziu! ${error.response.data.message}.`),
          )
        }
        errorCallBack(error)
      },
    },
  )
}
