import { useMutation } from "@tanstack/react-query"
import axios, { AxiosResponse } from "axios"
import { setErrorNotification } from "../../redux/notification/slice"
import { IResponse } from "../../types/api.types"
import { IUserPaymentBody } from "../../types/user-types"
import { RoutesOptions, userPaymentUrl } from "../../utils/constants"
import { getGenericErrorMessage } from "../../utils/error-messages"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../redux/hooks"

export const usePostUserPayment = (
    successCallBack: (data: any) => void,
    errorCallBack: (data: Error) => void
  ) => {
    const authToken = sessionStorage.getItem("authToken")  
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    return useMutation(
      ["post-user-payment-key"],
      (userData: BodyInit | IUserPaymentBody) =>
        axios.post(userPaymentUrl, userData, config),
      {
        onSuccess: (data: AxiosResponse<IResponse, IResponse>) =>
          successCallBack(data.data),
        onError: (error: any) => {
          if ([401].includes(error.response.status)) {
            navigate(RoutesOptions.LOGIN)
          } else {
            dispatch(
              setErrorNotification(
                getGenericErrorMessage(error.response.data.message)
              )
            )
          }
          errorCallBack(error)
        },
      }
    )
  }