import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setErrorNotification } from '../../redux/notification/slice'
import {
  IWorkItemsResponse,
  IWorkItemsResponseDetails,
} from '../../types/work-item-types'
import {
  RoutesOptions,
  workItemsUrl,
  workItemsUrlDetails,
} from '../../utils/constants'
import { getGenericErrorMessage } from '../../utils/error-messages'

export const useGetWorkItems = (
  page?: number,
  limit?: number,
  search?: string,
  workItemUuid?: string,
) => {
  const authToken = sessionStorage.getItem('authToken')
  const userUuid = sessionStorage.getItem('uuid')
  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  const dispatch = useDispatch()
  const query = search
    ? queryString.stringify({ page, limit, userUuid, workItemUuid, search })
    : queryString.stringify({ page, limit, userUuid, workItemUuid })
  const worksItemsLimitPages: string = `${workItemsUrl}?${query}`
  return useQuery(
    ['read-workItems-key'],
    () => axios.get<IWorkItemsResponse>(worksItemsLimitPages, config),
    {
      retry: false,
      enabled: false,
      onSuccess: (data: IWorkItemsResponse) => {
        return data
      },
      onError: (error: any) => {
        if (error.code === 'ERR_NETWORK') {
          dispatch(
            setErrorNotification(
              `Te rog încearcă mai târziu! Eroare de rețea.`,
            ),
          )
        } else {
          dispatch(
            setErrorNotification(
              getGenericErrorMessage(error.response.data.message),
            ),
          )
        }
      },
    },
  )
}

export const useGetWorkItemsDetails = (
  page?: number,
  limit?: number,
  workItemUuid?: string,
) => {
  const authToken = sessionStorage.getItem('authToken')
  const userUuid = sessionStorage.getItem('uuid')
  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  const query = queryString.stringify({ page, limit, userUuid, workItemUuid })
  const worksItemsLimitPages: string = `${workItemsUrlDetails}?${query}`
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useQuery(
    ['read-details-workItems-key'],
    () => axios.get<IWorkItemsResponseDetails>(worksItemsLimitPages, config),
    {
      onSuccess: (data: IWorkItemsResponseDetails) => {
        return data
      },
      onError: (error: any) => {
        if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE') {
          dispatch(
            setErrorNotification(
              `Te rog încearcă mai târziu! Eroare de rețea.`,
            ),
          )
        } else {
          if ([401].includes(error.response.status)) {
            navigate(RoutesOptions.LOGIN)
          } else {
            dispatch(
              setErrorNotification(
                `Te rog încearcă mai târziu! ${error.response.data.message}.`,
              ),
            )
          }
        }
      },
      retry: false,
      enabled: false,
    },
  )
}
