import {
  Flex,
  Stack,
  TextInput,
  ColorPicker,
  Button,
  SimpleGrid,
  Badge,
  Text,
  ActionIcon,
  rem,
  ScrollArea,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconX } from "@tabler/icons-react"
import _ from "lodash"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useGetUser } from "../../api/user/use-get-user"
import { usePutUser } from "../../api/user/use-put-user"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import { IStatusWorkitem } from "../../types/customize-types"

import {
  colorSwatches,
  colorWhite,
  maxWidthLaptopSmall,
  maxWidthMobile,
} from "../../utils/constants"
import { getGenericErrorMessage } from "../../utils/error-messages"
import { formaterDiacritics } from "../../utils/strings"

import { useStyles } from "./customize-page.styles"
import {
  duplicateLabel,
  luminationCalcul,
  panelWidth,
} from "./customize-page.utils"
import { DividerVerticalHorizontal } from "./shared-component/component-divider-vertical-horizontal"
import { ComponentNoItems } from "./shared-component/component-no-items"

export const StatusPanel: FunctionComponent<{
  workitemStatus: IStatusWorkitem[]
  setWorkitemStatus: React.Dispatch<React.SetStateAction<IStatusWorkitem[]>>
  isModal: boolean
  setActiveButtons?: React.Dispatch<React.SetStateAction<boolean>>
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  workitemStatus,
  setWorkitemStatus,
  setActiveButtons,
  isModal,
  setOpenModal,
}) => {
  const { classes } = useStyles()
  const [valueHex, setValueHex] = useState(colorWhite)
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isMobile = useMediaQuery(maxWidthMobile)
  const [duplicateMessage, setDuplicateMessage] = useState("")
  const [valueStatus, setValueStatus] = useState<string>("")
  const dispatch = useDispatch()
  const { data, refetch } = useGetUser()
  const userDataResponse = data ? data.data.response : undefined

  const labelAreaWidth = isMobile ? "100%" : isLaptopS ? "45%" : "60%"
  const removeButton = (name: string, hex?: string) => (
    <ActionIcon
      size="xs"
      radius="xl"
      variant="transparent"
      onClick={() => handleDeleteStatusBadge(name)}
    >
      <IconX size={rem(10)} color={hex ? luminationCalcul(hex) : "#4a6643"} />
    </ActionIcon>
  )

  const hasNoItems =
    (!isModal && !workitemStatus) || (!isModal && !workitemStatus.length)
      ? true
      : false

  const handleDeleteStatusBadge = (labelBadge: string) => {
    let newStatus = workitemStatus.filter((a) => a.label !== labelBadge)

    setWorkitemStatus(newStatus)
    setActiveButtons && setActiveButtons(true)
  }
  const succesCallBackPut = (data: { message: string }) => {
    dispatch(setMessageNotification(data.message))
    refetch()
    setOpenModal && setOpenModal(false)
  }

  const errorCallBackPut = (error: any) => {
    setErrorNotification(getGenericErrorMessage(error.response.data.message))
  }

  const { mutate: mutateUpdate, isLoading: isPutLoading } = usePutUser(
    succesCallBackPut,
    errorCallBackPut
  )
  const handleAddStatus = () => {
    if (isModal && userDataResponse) {
      const dataWorkItemsStatus = userDataResponse.workItemsStatuses
        ? JSON.parse(userDataResponse.workItemsStatuses)
        : []
      if (duplicateLabel(valueStatus, dataWorkItemsStatus, "status"))
        setDuplicateMessage("Există deja acest tip de status.")
      else {
        let newStatus = [
          ...dataWorkItemsStatus,
          {
            label: formaterDiacritics(valueStatus),
            color: valueHex,
          },
        ]

        mutateUpdate({
          ...userDataResponse,
          workItemsStatuses:
            newStatus.length === 0 ? null : JSON.stringify(newStatus),
        })
      }
    } else {
      if (duplicateLabel(valueStatus, workitemStatus, "status"))
        setDuplicateMessage("Există deja acest tip de status.")
      else {
        let newStatus = workitemStatus ? _.cloneDeep(workitemStatus) : []
        newStatus.push({
          label: formaterDiacritics(valueStatus),
          color: valueHex,
        })
        setWorkitemStatus(newStatus)
        setValueStatus("")
        setValueHex("#ffffff")
        setActiveButtons && setActiveButtons(true)
      }
    }
  }
  useEffect(() => {
    workitemStatus && setValueStatus(workitemStatus.toString())
  }, [isModal])

  useEffect(() => {
    setDuplicateMessage("")
  }, [valueStatus === ""])

  useEffect(() => {
    if (window.location.href.includes("add")) setValueStatus("")
  }, [])

  return (
    <Flex w="100%" direction="column" mt="xs">
      <Text pl="1rem">Personalizare status lucrări</Text>
      <Flex
        p="1rem"
        gap="2rem"
        align={hasNoItems ? "center" : "flex-start"}
        direction={isMobile ? "column" : "row"}
      >
        <Stack align="left" spacing={10} w={isModal ? "100%" : panelWidth()}>
          <TextInput
            label="Statusul lucrării"
            placeholder="Introduceți denumirea noului status"
            value={valueStatus}
            onChange={(e) =>
              setValueStatus(e.currentTarget.value.toLocaleUpperCase())
            }
            styles={{
              input: {
                background: duplicateMessage ? "#fffff" : valueHex,
                color: luminationCalcul(valueHex),
              },
            }}
            error={duplicateMessage ? duplicateMessage : null}
          />

          <ColorPicker
            w="100%"
            format="hex"
            swatches={colorSwatches}
            value={valueHex}
            onChange={setValueHex}
          />
          <Button
            size="xs"
            radius="xl"
            onClick={handleAddStatus}
            disabled={valueStatus.length > 0 ? false : true}
            loading={isPutLoading}
          >
            Adaugă
          </Button>
        </Stack>
        {isModal ? null : (
          <>
            <DividerVerticalHorizontal />
            {hasNoItems ? (
              <ComponentNoItems
                text={"Nu există statusuri de lucrări personalizate."}
              />
            ) : (
              <ScrollArea
                h={"340px"}
                type="scroll"
                offsetScrollbars
                scrollbarSize={6}
                styles={{ root: { width: labelAreaWidth } }}
              >
                <SimpleGrid cols={isLaptopS ? 2 : 4} w={"100%"}>
                  {workitemStatus.map((itemStatus: IStatusWorkitem, index) => {
                    const { color, label } = itemStatus
                    return (
                      <Badge
                        key={index}
                        w="100%"
                        pr={3}
                        h="2rem"
                        rightSection={removeButton(label, color)}
                        bg={color}
                        className={classes.badge}
                        style={{
                          border:
                            color === "#ffffff" ? "1px solid #e7e7e7" : "none",
                          color: luminationCalcul(color),
                        }}
                      >
                        {itemStatus.label}
                      </Badge>
                    )
                  })}
                </SimpleGrid>
              </ScrollArea>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}
