const getBrowserName = () => {
  const userAgent = navigator.userAgent
  let browserName

  if (userAgent.includes("Chrome")) {
    browserName = "Chrome"
  } else if (userAgent.includes("Firefox")) {
    browserName = "Firefox"
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    browserName = "Safari"
  } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
    browserName = "Internet Explorer"
  } else {
    browserName = "Unknown"
  }

  return browserName
}

export default getBrowserName
