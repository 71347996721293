import { Flex, Group, Text } from '@mantine/core'
import { ComponentItemsInventory, ItemsInventoryFields } from '../../../utils/enum'
import { renderRightControl } from '../../clients-page/utils-clients-page/utils-edit-client'

import { ActionsButtonItemsIventory } from '../utils-inventory/actions-button-items-inventory'
export const contentCurrentRow = (
  fieldValue: string | number,
  field: keyof typeof ComponentItemsInventory,
  isCurrency?: boolean,
  currency?: string,
  readMore?: boolean,
) => {
  return (
    <Flex direction={'column'} justify={'space-between'} mb={5}>
      <Text c="#3C4048" fw={500} w="13rem">
        {ComponentItemsInventory[field]}:
      </Text>
      {field === ItemsInventoryFields.CHARACTERISTICS ? (
        <Text lineClamp={readMore ? 3 : 0}>{renderRightControl(fieldValue)}</Text>
      ) : (
        <Group>
          {renderRightControl(fieldValue)}
          {isCurrency ? currency : null}
        </Group>
      )}
    </Flex>
  )
}
export const rowsTableInventoryObjectMobile = (itemInventoryObjects: any) => {
  return (
    <tr key={itemInventoryObjects.itemUuid}>
      <td>
        <Flex justify={'space-between'} mb="xs" align={'center'}>
          <Text size={'md'}>{itemInventoryObjects.name}</Text>
          <ActionsButtonItemsIventory
            key={itemInventoryObjects.itemUuid}
            itemUuid={itemInventoryObjects.itemUuid}
            dataInventoryItem={itemInventoryObjects}
          />
        </Flex>

        <Group spacing={0}>
          {contentCurrentRow(
            itemInventoryObjects.value,
            ItemsInventoryFields.VALUE,
            true,
            itemInventoryObjects.currency,
          )}
        </Group>
        <Group spacing={0}>
          {contentCurrentRow(
            itemInventoryObjects.value * itemInventoryObjects.total,
            ItemsInventoryFields.TOTAL_PRICE,
            true,
            itemInventoryObjects.currency,
          )}
        </Group>

        {contentCurrentRow(itemInventoryObjects.contaNr, ItemsInventoryFields.CONTA_NUMBER)}
        {contentCurrentRow(itemInventoryObjects.total, ItemsInventoryFields.TOTAL_NUMBER)}
      </td>
    </tr>
  )
}

export const rowsTableInventoryObjects = (itemInventoryObjects: any) => {
  return (
    <tr key={itemInventoryObjects.itemUuid}>
      <td>{renderRightControl(itemInventoryObjects.name)}</td>
      <td>
        {renderRightControl(itemInventoryObjects.value)} {itemInventoryObjects.currency}
      </td>
      <td>
        {renderRightControl(itemInventoryObjects.value * itemInventoryObjects.total)}{' '}
        {itemInventoryObjects.currency}
      </td>

      <td>{renderRightControl(itemInventoryObjects.contaNr)} </td>
      <td>{renderRightControl(itemInventoryObjects.total)}</td>
      <td>
        <ActionsButtonItemsIventory
          key={itemInventoryObjects.itemUuid}
          itemUuid={itemInventoryObjects.itemUuid}
          dataInventoryItem={itemInventoryObjects}
        />
      </td>
    </tr>
  )
}
