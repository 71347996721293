import {
  Container,
  Flex,
  LoadingOverlay,
  Paper,
  ScrollArea,
  Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetClients } from '../../api/clients/use-get-clients';
import { usePostWorkItem } from '../../api/work-items/use-post-work-item';
import { IDocument } from '../../types/work-item-types';
import {
  backgroudColor,
  maxWidthMobile,
  maxWidthTablet,
  Mode,
  RoutesOptions,
} from '../../utils/constants';
import { Navigationbar } from '../navbar/navbar';
import {
  initialValuesAddWorkItem,
  validateFormWorkItem,
} from './utils-edit-add-work-item/utils-form-work-item';
import {
  setErrorNotification,
  setMessageNotification,
} from '../../redux/notification/slice';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorSuccesNotification } from '../notification/notification';
import { ClientAddEdit } from '../clients-page/client-add-edit';
import { GenericModal } from '../generic-modal/generic-modal';
import _ from 'lodash';
import {
  buildMultipleFilesData,
  uploadChunk,
} from './utils-edit-add-work-item/upload-files.util';
import { buildPayloadForCreateWorkItem } from './utils-edit-add-work-item/process-data.util';
import { HeaderAddEditWorkItem } from './components-work-item/header-add-edit-work-item';
import { LeftSectionAddWorkItem } from './components-work-item/components-add-work-item/left-section-add-work-item';
import { RightSectionAddWorkItem } from './components-work-item/components-add-work-item/right-section-add-work-item';
import { SectionInputMultipleFileWorkItem } from './components-work-item/section-input-multiple-file-work-item';
import { RootState } from '../../redux/store';
import { useGetUser } from '../../api/user/use-get-user';
import { formatterDate } from '../account-details/utils-account-details';
import { IUserResponse } from '../../types/user-types';
import { reverseFormaterDiacritics } from '../../utils/strings';
import { usePostAssignResponsable } from '../../api/assign-responsable/use-post-assign-responsable';

export const AddWorkItem = () => {
  const query = window.location.href;
  const queryClientUuid = query.slice(query.indexOf('?') + 1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isTablet = useMediaQuery(maxWidthTablet);
  const isMobile = useMediaQuery(maxWidthMobile);

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [openModalAddClient, setOpenModalAddClient] = useState(false);
  const [containerOtherDocs, setContainerOtherDocs] = useState<IDocument[]>([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [selectedClient, setSelectedClient] = useState(queryClientUuid);
  const [openModalConfimationCancel, setOpenModalConfirmationCancel] =
    useState(false);
  const clientsSelect: any[] = [];
  const { fileDeleted } = useSelector((state: RootState) => state.deleteFiles);
  const [workItemUuid, setWorkIemUuid] = useState('');
  const pathViewMode = RoutesOptions.WORKITEMS.concat(
    '/',
    workItemUuid as string,
    '/',
    Mode.VIEW
  );

  const formAddWorkItem = useForm<any>({
    initialValues: initialValuesAddWorkItem,
    validate: validateFormWorkItem,
  });
  const { totalPayment, advancePayment } = formAddWorkItem.values;

  const succesCallBack = (data: IUserResponse['data']) => {
    if (formatterDate(data.response.subscriptionExpireDate) < new Date()) {
      navigate(RoutesOptions.TRIAL_OVER);
    }
  };
  const { data: dataUser, refetch } = useGetUser(succesCallBack);

  useEffect(() => {
    formAddWorkItem.setValues({
      restPayment: totalPayment - advancePayment,
    });
  }, [totalPayment, advancePayment]);

  useEffect(() => {
    if (fileDeleted) {
      const newFilesArray = _.cloneDeep(files);
      newFilesArray.forEach((item, index) => {
        if (item?.name === fileDeleted.fileName) {
          newFilesArray.splice(index, 1);
        }
      });
      setFiles(newFilesArray);
    }
  }, [fileDeleted.fileName]);

  const { data: dataClients, refetch: refetchClients } = useGetClients();
  dataClients &&
    dataClients.data.response.forEach((client) => {
      clientsSelect.push({
        value: client.clientUuid,
        label: reverseFormaterDiacritics(client.name),
      });
    });

  useEffect(() => {
    refetchClients();
    refetch();
  }, []);

  const errorCallBack = (error: any) => {
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu! ${error.response.data.message}.`
      )
    );
  };

  const assignSuccessCallback = (data: any) => {
    if (data.workItemUuid) {
      navigate(
        RoutesOptions.WORKITEMS.concat('/', data.workItemUuid, '/', Mode.VIEW)
      );
    }
  };

  const { mutate: mutateAssignResponsable } = usePostAssignResponsable(
    assignSuccessCallback,
    () => null
  );
  const successCallBack = async (data: any) => {
    dispatch(setMessageNotification(data.message));
    setWorkIemUuid(data.response.workItemUuid);
    if (files.length > 0) {
      setIsUploadingFiles(true);
      await uploadChunk(
        files,
        data.response.workItemUuid,
        currentFileIndex,
        setCurrentFileIndex
      );
    }
    if (data.response.responsable.length) {
      const userUuid = sessionStorage.getItem('uuid');
      mutateAssignResponsable({
        responsable: data.response.responsable,
        userUuid: userUuid,
        workItemUuid: data.response.workItemUuid,
      });
    }
  };

  useEffect(() => {
    if (currentFileIndex > 0 && currentFileIndex < files.length) {
      uploadChunk(files, workItemUuid, currentFileIndex, setCurrentFileIndex);
    } else if (currentFileIndex > 0 && currentFileIndex === files.length) {
      navigate(pathViewMode);
      setIsUploadingFiles(false);
    }
  }, [currentFileIndex]);

  const { mutate, isLoading: isLodingPost } = usePostWorkItem(
    successCallBack,
    errorCallBack
  );

  const onCancel = () => {
    formAddWorkItem.reset();
    formAddWorkItem.clearErrors();
    navigate(RoutesOptions.WORKITEMS);
    setOpenModalConfirmationCancel(false);
  };

  const onAddWorkItem = () => {
    if (formAddWorkItem.isValid()) {
      const multiplePdf: IDocument[] =
        buildMultipleFilesData(containerOtherDocs);
      const selectedClientInfos = clientsSelect.find(
        (elm) => elm.value === selectedClient
      );
      const paylodCreate = buildPayloadForCreateWorkItem(
        selectedClientInfos,
        formAddWorkItem,
        multiplePdf
      );

      mutate(paylodCreate);
    }
  };

  return (
    <Container fluid m={0} p={0} bg={backgroudColor} h={'100vh'}>
      <Flex h={'100%'} direction={isTablet ? 'column' : 'row'}>
        <Navigationbar />
        <Flex direction={'column'} w={'100%'} h={'100%'}>
          <form
            onSubmit={formAddWorkItem.onSubmit(onAddWorkItem, (errors) => {
              setValidationErrors([]);
              if (errors! == null || errors) {
                Object.keys(errors).map((error) =>
                  setValidationErrors((old) => [...old, error])
                );
              }
            })}
          >
            <HeaderAddEditWorkItem
              isModeView={false}
              isModeAdd={true}
              setOpenModalConfirmationCancel={setOpenModalConfirmationCancel}
            />
            <Paper
              mx="md"
              mb="xs"
              p="xl"
              pb={isMobile ? 0 : 'xs'}
              radius={'md'}
              h={isMobile ? '100%' : 'calc(100vh - 4rem)'}
            >
              <ScrollArea h="100%" type="never">
                <Stack spacing={'md'} w={'100%'}>
                  <Flex
                    direction={isMobile ? 'column' : 'row'}
                    gap={isMobile ? 'md' : isTablet ? 'xs' : 'md'}
                    w="100%"
                    justify="space-around"
                  >
                    <LeftSectionAddWorkItem
                      formAddWorkItem={formAddWorkItem}
                      setOpenModalAddClient={setOpenModalAddClient}
                      selectedClient={selectedClient}
                      setSelectedClient={setSelectedClient}
                      clientsSelect={clientsSelect}
                      dataUserResponse={dataUser && dataUser?.data.response}
                    />
                    <RightSectionAddWorkItem
                      validationErrors={validationErrors}
                      formAddWorkItem={formAddWorkItem}
                      dataUserResponse={dataUser && dataUser?.data.response}
                    />
                  </Flex>

                  <SectionInputMultipleFileWorkItem
                    isModeView={false}
                    isModeAdd={true}
                    setContainerOtherDocs={setContainerOtherDocs}
                    containerOtherDocs={containerOtherDocs}
                    setFiles={setFiles}
                    files={files}
                  />
                </Stack>
              </ScrollArea>
            </Paper>
            <LoadingOverlay visible={isLodingPost || isUploadingFiles} />
          </form>
        </Flex>
      </Flex>
      <GenericModal
        setOpenModal={setOpenModalConfirmationCancel}
        mainActionText={'Redirecționează'}
        title={'Urmează să pierzi schimbările facute pe acestă pagină.'}
        subTitle={'Ești sigur(ă) că vrei să fii redirecționat?'}
        isOpenModal={openModalConfimationCancel}
        onHandle={() => onCancel()}
        isLoading={false}
      />
      <ClientAddEdit
        isOpenModal={openModalAddClient}
        setOpenModal={setOpenModalAddClient}
        isEditMode={false}
        precompleteField={formAddWorkItem.values.name}
      />

      <ErrorSuccesNotification />
    </Container>
  );
};
