import { Box, Breadcrumbs } from "@mantine/core";
import { Link } from "react-router-dom";
import { RoutesOptions } from "../../utils/constants";
import { useStyles } from "./breadcrumbs.styles";

const BreadcrumbsComponent = (props: { currentPage: string }) => {
  const { currentPage } = props;
  const { classes } = useStyles();
  const items = [
    { title: "Lucrări", href: `${RoutesOptions.WORKITEMS}` },
    { title: `${currentPage}`, href: window.location.pathname },
  ].map((item, index) => (
    <Link to={item.href} key={index}>
      {item.title}
    </Link>
  ));

  return (
    <Box>
      <Breadcrumbs
        separator="→"
        classNames={{
          root: classes.breadcrumbs,
          separator: classes.separator,
          breadcrumb: classes.breadcrumbsLink,
        }}
        py={"md"}
        mx={"md"}
        w={"fit-content"}
      >
        {items}
      </Breadcrumbs>
    </Box>
  );
};
export default BreadcrumbsComponent;
