import {
  Flex,
  Paper,
  Title,
  Stack,
  TextInput,
  PasswordInput,
  Button,
  Image,
  Anchor,
  Container,
  Checkbox,
  SimpleGrid,
  ActionIcon,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { usePostRegister } from "../../api/register/use-post-register"
import {
  maxWidthMobile,
  maxWidthTablet,
  minWidthLaptop,
  RoutesOptions,
} from "../../utils/constants"
import { useStyles } from "./register.styles"
import {
  initialValuesFormRegister,
  validateRegister,
} from "./register-form.utils"
import { registerNotification } from "./register.utils"
import { handleDataCompany } from "../clients-page/utils-clients-page/utils-form"
import { IconSearch } from "@tabler/icons-react"

export const Register = () => {
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const isLaptopXl = useMediaQuery(minWidthLaptop)
  const [backendError, setBackendError] = useState("")
  const [haveAffiliateCode, setHaveAffiliateCode] = useState(false)
  const [checkEmailConfirmation, setCheckEmailConfirmation] = useState("")
  const navigate = useNavigate()
  const [loadingGetCompanyInfo, setLoadingGetCompanyInfo] = useState(false)
  const [errorFiscalCode, setErrorFiscalCode] = useState<string | null>(null)
  const { classes, theme } = useStyles()

  const succesCallBack = () => {
    setCheckEmailConfirmation(
      `Vă vom trimite un e-mail de confirmare către adresa ${registerForm.values.email}. Accesați link-ul primit pentru a finaliza înregistrarea.`
    )
    registerForm.clearErrors()
    registerForm.reset()
    registerForm.setFieldValue("acceptTerms", false)
    setHaveAffiliateCode(false)
  }
  const errorCallBack = (error: any) => {
    if (error.code === 400) {
      setBackendError(`Te rog încearcă mai târziu! ${error.message}`)
    } else {
      setBackendError(` ${error.message}`)
    }
  }
  const registerForm = useForm({
    initialValues: initialValuesFormRegister,
    validate: validateRegister,
  })

  const { mutate, isLoading } = usePostRegister(succesCallBack, errorCallBack)

  const onPressRegister = () => {
    const {
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      acceptTerms,
      CUI,
      companyName,
      usedAffiliateCode,
      nrRegister,
      address,
    } = registerForm.values
    if (registerForm.isValid()) {
      mutate({
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        companyInfo: JSON.stringify({
          companyName: companyName,
          CUI: CUI,
          nrRegister: nrRegister,
          address: address,
        }),
        acceptTerms,
        usedAffiliateCode: haveAffiliateCode ? usedAffiliateCode : "",
      })
    }
  }
  useEffect(() => {
    if (backendError) setBackendError("")
  }, [registerForm.values.email])

  return (
    <Container m={0} maw="100vw" h={isMobile ? "100%" : "100vh"}>
      <Flex
        direction={"column"}
        align="center"
        h={"100%"}
        justify={"space-between"}
      >
        <Image
          src={"/Logo_Topo_CRM.png"}
          alt="Logo-Topo"
          height="auto"
          width="auto"
          className={classes.hoverImage}
          onClick={() => {
            navigate(RoutesOptions.LOGIN)
          }}
        />

        <Paper
          radius={"xl"}
          maw={isLaptopXl ? "50%" : isMobile ? "95%" : "85%"}
          w="100%"
          mah="90%"
          h="auto"
          bg={"#ffffffc4"}
          my={isMobile ? "2rem" : 0}
          py={"xl"}
        >
          <Flex
            direction={"column"}
            align="center"
            justify="center"
            gap={25}
            w="100%"
            h="100%"
          >
            <Title
              order={isMobile ? 3 : 2}
              c={theme.colors.brand[9]}
              align="center"
              mt="xl"
              mx={isMobile ? "xl" : 0}
            >
              Creează contul tău topo-crm.
            </Title>

            <form
              onSubmit={registerForm.onSubmit(onPressRegister)}
              className={classes.form}
            >
              <Flex
                direction={isMobile ? "column" : "row"}
                justify="center"
                align={isMobile ? "center" : "flex-start"}
                gap={isMobile ? "xs" : "2rem"}
                w={isMobile ? "100%" : "80%"}
              >
                <Stack w={isMobile ? "80%" : "45%"}>
                  <TextInput
                    required
                    placeholder="Introduceți adresa de e-mail."
                    label="E-mail"
                    radius="xl"
                    size={isTablet ? "sm" : "md"}
                    w="100%"
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                    }}
                    {...registerForm.getInputProps("email")}
                  />
                  <PasswordInput
                    required
                    label="Parolă"
                    placeholder="Introduceți o parolă."
                    size={isTablet ? "sm" : "md"}
                    radius="xl"
                    w="100%"
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                    }}
                    {...registerForm.getInputProps("password")}
                  />
                  <PasswordInput
                    required
                    label="Confirmă parola"
                    placeholder="Introduceți parola aleasă."
                    size={isTablet ? "sm" : "md"}
                    radius="xl"
                    w="100%"
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                    }}
                    {...registerForm.getInputProps("confirmPassword")}
                  />
                  <TextInput
                    required
                    label="Prenume"
                    placeholder="Introduceți prenumele."
                    radius="xl"
                    size={isTablet ? "sm" : "md"}
                    w="100%"
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                    }}
                    {...registerForm.getInputProps("firstName")}
                  />
                  <TextInput
                    label="Nume"
                    required
                    placeholder="Introduceți numele."
                    radius="xl"
                    size={isTablet ? "sm" : "md"}
                    w="100%"
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                    }}
                    {...registerForm.getInputProps("lastName")}
                  />
                </Stack>
                <Stack align={"center"} w={isMobile ? "80%" : "45%"}>
                  <TextInput
                    required
                    label="Număr de telefon"
                    placeholder="Introduceți numărul de telefon."
                    radius="xl"
                    size={isTablet ? "sm" : "md"}
                    w="100%"
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                    }}
                    {...registerForm.getInputProps("phoneNumber")}
                  />
                  <TextInput
                    label="CUI"
                    placeholder="Introduceți CUI-ul companiei."
                    radius="xl"
                    size={isTablet ? "sm" : "md"}
                    w="100%"
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                    }}
                    rightSection={
                      <ActionIcon
                        variant="filled"
                        color="brand.6"
                        radius="lg"
                        loading={loadingGetCompanyInfo}
                        onClick={() =>
                          handleDataCompany(
                            setLoadingGetCompanyInfo,
                            setErrorFiscalCode,
                            registerForm.values.CUI,
                            registerForm,
                            "User"
                          )
                        }
                      >
                        <IconSearch color="white" size="1rem" />
                      </ActionIcon>
                    }
                    error={
                      errorFiscalCode &&
                      "Codul fiscal trebuie să conțină cel puțin 6 caractere (ex: 123456)."
                    }
                    {...registerForm.getInputProps("CUI")}
                  />
                  <TextInput
                    label="Nume companie"
                    placeholder="Introduceți numele companiei."
                    radius="xl"
                    size={isTablet ? "sm" : "md"}
                    w="100%"
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                    }}
                    {...registerForm.getInputProps("companyName")}
                  />
                  <TextInput
                    label="Nr. înmatriculare"
                    placeholder="Introduceți numerul de înmatriculare."
                    radius="xl"
                    size={isTablet ? "sm" : "md"}
                    w="100%"
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                    }}
                    {...registerForm.getInputProps("nrRegister")}
                  />
                  <TextInput
                    label="Adresa"
                    placeholder="Introduceți adresa companiei."
                    radius="xl"
                    size={isTablet ? "sm" : "md"}
                    w="100%"
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                    }}
                    {...registerForm.getInputProps("address")}
                  />
                </Stack>
              </Flex>

              <Flex w="73%" mt="md" direction="column" gap={10}>
                <SimpleGrid cols={2} className={classes.grid}>
                  <Checkbox
                    width="100%"
                    radius="md"
                    checked={haveAffiliateCode}
                    classNames={{
                      input: classes.checkbox,
                      label: classes.checkbox,
                    }}
                    onChange={(event) =>
                      setHaveAffiliateCode(event.currentTarget.checked)
                    }
                    label={"Am cod de reducere."}
                  />
                  {haveAffiliateCode ? (
                    <TextInput
                      label={isMobile ? null : "Cod de reducere"}
                      placeholder="Introduceți codul de reducere."
                      radius="xl"
                      size={isTablet ? "sm" : "md"}
                      classNames={{
                        input: classes.inputAffiliateCode,
                        label: classes.label,
                        error: classes.error,
                      }}
                      {...registerForm.getInputProps("usedAffiliateCode")}
                    />
                  ) : null}
                </SimpleGrid>

                <Checkbox
                  width="100%"
                  radius="md"
                  classNames={{
                    input: registerForm.errors.acceptTerms
                      ? classes.errorCheckbox
                      : classes.checkbox,
                    label: registerForm.errors.acceptTerms
                      ? classes.errorCheckbox
                      : classes.checkbox,
                  }}
                  label={
                    <>
                      Trebuie să acepți{" "}
                      <Anchor
                        href="https://topo-crm.ro/termeniSiConditii.pdf"
                        target="_blank"
                        color="#8aa187"
                      >
                        termenii și condițiile.
                      </Anchor>
                    </>
                  }
                  {...registerForm.getInputProps("acceptTerms")}
                  error={registerForm.errors.acceptTerms && null}
                />
              </Flex>

              {backendError
                ? registerNotification(backendError, "error", isMobile)
                : null}
              {checkEmailConfirmation.length > 0
                ? registerNotification(
                    checkEmailConfirmation,
                    "checkEmail",
                    isMobile
                  )
                : null}

              <Button
                variant="light"
                radius={"xl"}
                mt={"2rem"}
                w={isMobile ? "fit-content" : "25vw"}
                type="submit"
                loading={isLoading ? true : false}
              >
                Creează cont
              </Button>
              <Anchor
                component="button"
                color={"dimmed"}
                size={isMobile ? "xs" : "sm"}
                my="xs"
                w={isMobile ? "70%" : isTablet ? "50%" : "30%"}
                onClick={() => navigate(RoutesOptions.LOGIN)}
                align="center"
              >
                Nu e ceea ce vrei? Atunci revino în pagina de autentificare.
              </Anchor>
            </form>
          </Flex>
        </Paper>

        <Title order={3} c={theme.colors.brand[8]}>
          © evosa
        </Title>
      </Flex>
    </Container>
  )
}
