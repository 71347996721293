import {
  Stack,
  Text,
  Flex,
  Button,
  ThemeIcon,
  rem,
  Divider,
  List,
  Paper,
  Chip,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconCheck } from "@tabler/icons-react"
import { FunctionComponent, useEffect, useState } from "react"
import { INewPrice } from "../../../types/paymnet-types"
import {
  maxWidthLaptopSmall,
  maxWidthMobile,
  maxWidthMobileMedium,
  maxWidthTablet,
  standardCurrency,
} from "../../../utils/constants"
import { paymentParms, standardPrice } from "../../../utils/payment"
import { useStyles } from "../account-details.styles"
import { priceAfterDiscount, freeDays } from "../utils-account-details"

export const SubscriptionCardComponent: FunctionComponent<{
  triggerPayment: () => void
  affiliateCode?: string
}> = (props) => {
  const { triggerPayment, affiliateCode } = props
  const { classes } = useStyles()
  const haveAffiliateCode = affiliateCode ? true : false
  const isMobile = useMediaQuery(maxWidthMobile)
  const isMobileMedium = useMediaQuery(maxWidthMobileMedium)
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isTablet = useMediaQuery(maxWidthTablet)
  const { discount, newPrice } = affiliateCode
    ? priceAfterDiscount(affiliateCode)
    : standardPrice
  const subscriptionExpireDate = sessionStorage.getItem(
    "subscriptionExpireDate"
  )

  return (
    <Paper
      w={isTablet ? "90%" : isLaptopS ? "320px" : "400px"}
      bg="#e6ede4"
      p="xl"
      radius="lg"
      mb={isMobile ? "xl" : 0}
      mt="xs"
      style={{
        transform: "scale(1.04)",
        boxShadow: "2px 2px 6px 1px #c1bfbf",
      }}
    >
      <Stack spacing="xs" align={isMobileMedium ? "center" : "left"} w="100%">
        <Flex
          direction={isMobileMedium ? "column" : "row"}
          align="center"
          justify="space-between"
        >
          <Text
            size={23}
            color={"brand.5"}
            align="center"
            transform="uppercase"
          >
            Abonament
          </Text>
          {haveAffiliateCode ? (
            <Chip size="xs" classNames={{ label: classes.chipDiscount }}>
              {discount}% Reducere
            </Chip>
          ) : null}
        </Flex>

        <Stack align={isMobileMedium ? "center" : "flex-start"} spacing={0}>
          <Flex gap={5} align="flex-end">
            <Text c={"#4b5663"} size={25} align="center">
              {haveAffiliateCode && affiliateCode
                ? `${standardCurrency} ${newPrice}`
                : `${standardCurrency} ${standardPrice.newPrice}`}
            </Text>
            <Text c="#4b5663" size={14} align="center">
              /lună
            </Text>
          </Flex>

          {haveAffiliateCode ? (
            <Text size={18} align="center" className={classes.price}>
              {standardCurrency} {standardPrice.newPrice}
            </Text>
          ) : null}

          <Text c="#4b5663" size={12} align="center">
            *TVA inclus
          </Text>
        </Stack>
        <Divider color="#ceddca" w="100%" />
        <List
          spacing="xs"
          size="sm"
          mt="xs"
          center
          icon={
            <ThemeIcon color={"brand.5"} size={20} radius="xl">
              <IconCheck
                style={{ width: rem(12), height: rem(12) }}
                color={"white"}
              />
            </ThemeIcon>
          }
        >
          <List.Item>Nelimitat clienți</List.Item>
          <List.Item>Nelimitat lucrări</List.Item>
          <List.Item>Nelimitat angajați</List.Item>
          <List.Item>Nelimitat documente</List.Item>
          <List.Item style={{ lineHeight: "17px" }}>
            Nelimitat obiecte inventar
          </List.Item>
          {haveAffiliateCode ? (
            <List.Item style={{ lineHeight: "17px" }}>
              Reducere {discount}% - cod: {affiliateCode}
            </List.Item>
          ) : null}
        </List>

        <Button
          name="paySubscription"
          radius="xl"
          mt="1rem"
          w="100%"
          onClick={triggerPayment}
        >
          Plătește
        </Button>

        <Flex gap={2} justify="center" align="flex-end" w="100%">
          <Text c="#4b5663" size={12}>
            {freeDays(subscriptionExpireDate as string)}
          </Text>
          <Text c="#4b5663" size={10}>
            / 30 de zile până la plată
          </Text>
        </Flex>
      </Stack>
    </Paper>
  )
}
