import { createStyles } from "@mantine/core"

export const useStyles = createStyles(() => ({
  notification: {
    position: "fixed",
    bottom: 5,
    right: 8,
    zIndex: 100,
  },
}))
