import { Badge } from "@mantine/core"

import {
  diacriticsCorrector,
  reverseFormaterDiacritics,
} from "../../../utils/strings"
import { luminationCalcul } from "../../customize-page/customize-page.utils"

export const renderRightControl = (field: string | number) => {
  return (
    <span style={{ wordBreak: "break-word" }}>
      {field
        ? typeof field === "string"
          ? diacriticsCorrector(field)
          : field
        : "-"}
    </span>
  )
}

export const renderStatusControl = (field: string) => {
  const { color, label } = JSON.parse(field)
  return (
    <span style={{ wordBreak: "break-word" }}>
      <Badge
        style={{
          background: color,
          color: color && luminationCalcul(color),
        }}
      >
        {reverseFormaterDiacritics(label)}
      </Badge>
    </span>
  )
}
