import { rem, Select } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { IItemInventory } from '../../../types/items-inventory'

export const calcTotalValue = (
  formItemInventory: UseFormReturnType<IItemInventory, (values: IItemInventory) => IItemInventory>,
  setTotalPrice: (value: React.SetStateAction<number>) => void,
) => {
  const { value, total } = formItemInventory.values
  return value && total && /^\d+$/.test(value) && /^\d+$/.test(String(total))
    ? setTotalPrice(parseInt(value) * total)
    : setTotalPrice(0)
}
export const selectCurrency = (
  currencySelected: string,
  setCurrencySelected: (value: React.SetStateAction<string>) => void,
) => {
  return (
    <Select
      data={[
        { value: 'euro', label: '🇪🇺 EUR' },
        { value: 'ron', label: '🇷🇴 RON' },
      ]}
      styles={{
        input: {
          fontWeight: 500,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          width: rem(105),
        },
      }}
      value={currencySelected}
      onChange={(value) => {
        value && setCurrencySelected(value)
      }}
    />
  )
}
