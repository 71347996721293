import {
  Container,
  Flex,
  Paper,
  Stack,
  ScrollArea,
  LoadingOverlay,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import { FunctionComponent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { RootState } from "../../redux/store"
import {
  backgroudColor,
  limitPage,
  maxWidthMobile,
  maxWidthTablet,
  Mode,
  RoutesOptions,
} from "../../utils/constants"
import { Navigationbar } from "../navbar/navbar"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"
import "./utils-edit-add-work-item/pdf.css"
import { IDocument, IWorkItem } from "../../types/work-item-types"
import { setValueForm } from "./utils-edit-add-work-item/utils-edit-work-item"
import { useGetWorkItemsDetails } from "../../api/work-items/use-get-work-items"
import {
  reinitialValuesWorkItem,
  validateFormWorkItem,
} from "./utils-edit-add-work-item/utils-form-work-item"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import { ErrorSuccesNotification } from "../notification/notification"
import { usePutWorkItems } from "../../api/work-items/use-put-work-items"
import _ from "lodash"
import { cities } from "../../utils/city"
import { GenericModal } from "../generic-modal/generic-modal"
import {
  buildMultipleFilesData,
  uploadChunk,
} from "./utils-edit-add-work-item/upload-files.util"
import { buildPayloadForUpdateWorkItem } from "./utils-edit-add-work-item/process-data.util"
import { LeftSectionWorkItem } from "./components-work-item/components-edit-view-work-item/left-section-work-item"
import { RightSectionWorkItem } from "./components-work-item/components-edit-view-work-item/right-section-work-item"
import { HeaderAddEditWorkItem } from "./components-work-item/header-add-edit-work-item"
import { SectionInputMultipleFileWorkItem } from "./components-work-item/section-input-multiple-file-work-item"
import { setResetFileDeleted } from "../../redux/delete-files/deleteFilesSilce"
import { formatterDate } from "../account-details/utils-account-details"
import { useGetUser } from "../../api/user/use-get-user"
import { IUserResponse } from "../../types/user-types"

export const ViewEditAddWorkItemPage: FunctionComponent<{ mode: string }> = ({
  mode,
}) => {
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const { workItemUuid } = useParams()
  const [citiesArray, setCitiesArray] = useState(cities)
  const [isModeView, setIsModeView] = useState(mode === "view" ? true : false)
  const { currentPage } = useSelector((state: RootState) => state.pagination)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openModalConfimationCancel, setOpenModalConfirmationCancel] =
    useState(false)
  const [containerOtherDocs, setContainerOtherDocs] = useState<IDocument[]>([])
  const [currentFileIndex, setCurrentFileIndex] = useState(0)
  const [isUploadingFiles, setIsUploadingFiles] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const { fileDeleted } = useSelector((state: RootState) => state.deleteFiles)
  const [deletedDocs, setDeletedDocs] = useState<any[]>([])
  const [allDocs, setAllDocs] = useState<any[]>([])
  const { data, isLoading, isRefetching, refetch } = useGetWorkItemsDetails(
    currentPage,
    limitPage,
    workItemUuid
  )
  const responseDetailsWorkItems = data && data.data.response

  const pathViewMode = RoutesOptions.WORKITEMS.concat(
    "/",
    workItemUuid as string,
    "/",
    Mode.VIEW
  )
  const formWorkItem = useForm<any>({
    initialValues:
      responseDetailsWorkItems &&
      reinitialValuesWorkItem(responseDetailsWorkItems as IWorkItem),
    validate: validateFormWorkItem,
  })

  const succesCallBack = (data: IUserResponse["data"]) => {
    if (formatterDate(data.response.subscriptionExpireDate) < new Date()) {
      navigate(RoutesOptions.TRIAL_OVER)
    }
  }
  const { data: dataUser, refetch: refetchUser } = useGetUser(succesCallBack)

  const onEditCancel = () => {
    setIsModeView(true)
    formWorkItem.resetDirty()
    setValueForm(formWorkItem, data)
    workItemUuid && navigate(pathViewMode)
    setOpenModalConfirmationCancel(false)
    setIsUploadingFiles(false)
    setDeletedDocs([])
    setFiles([])
    setContainerOtherDocs(formWorkItem.values.otherDocs)
  }

  const succesCallbackUpdate = (data: any) => {
    dispatch(setMessageNotification(data.message))
    formWorkItem.resetDirty()
    if (files.length > 0) {
      setIsUploadingFiles(true)
      uploadChunk(files, workItemUuid, currentFileIndex, setCurrentFileIndex)
    } else {
      refetch()
    }
    setIsModeView(true)
  }
  const errorCallbackUpdate = (error: any) => {
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu! ${error.response.data.message}.`
      )
    )
    formWorkItem.resetDirty()
  }

  const { mutate, isLoading: isLoadingUpdate } = usePutWorkItems(
    succesCallbackUpdate,
    errorCallbackUpdate,
    workItemUuid
  )

  const onUpdateWorkItem = () => {
    if (formWorkItem.isValid()) {
      const multiplePdf: IDocument[] =
        buildMultipleFilesData(containerOtherDocs)

      const paylodUpdate = buildPayloadForUpdateWorkItem(
        formWorkItem,
        multiplePdf,
        formWorkItem.values.workItemUuid,
        deletedDocs
      )
      formWorkItem.isDirty() ? mutate(paylodUpdate) : onEditCancel()
      dispatch(setResetFileDeleted())
      setDeletedDocs([])
    }
  }

  useEffect(() => {
    refetch()
    refetchUser()
  }, [])

  useEffect(() => {
    formWorkItem.setValues({
      ...formWorkItem,
      amount: responseDetailsWorkItems?.amount,
      calcFormula: responseDetailsWorkItems?.calcFormula,
    })
  }, [responseDetailsWorkItems])

  useEffect(() => {
    const deletedOtherDocs = _.cloneDeep(deletedDocs)

    if (fileDeleted && fileDeleted.fileName !== "" && fileDeleted.type !== "") {
      const newFilesArray = _.cloneDeep(files)
      newFilesArray.forEach((item, index) => {
        if (item?.name === fileDeleted.fileName) {
          newFilesArray.splice(index, 1)
        }
      })

      setFiles(newFilesArray)
      if (
        fileDeleted.fileName.includes(
          `${responseDetailsWorkItems?.workItemUuid}***`
        )
      ) {
        deletedOtherDocs.push(fileDeleted)
      }
      deletedOtherDocs.map(
        (item, index) => item.fileName === "" && deletedOtherDocs.slice(index)
      )
    }

    setDeletedDocs(deletedOtherDocs)
  }, [fileDeleted])

  const { totalPayment, advancePayment, city } = formWorkItem.values

  useEffect(() => {
    formWorkItem.setValues({
      restPayment: totalPayment - advancePayment,
    })
  }, [totalPayment, advancePayment])

  useEffect(() => {
    setCitiesArray(cities)
  }, [city])

  useEffect(() => {
    if (data) {
      const { city } = data.data.response
      const foundedCity = citiesArray.find((element) => element === city)
      if (!foundedCity && city !== "") {
        const newArrayCities = [...cities]
        newArrayCities.push(city)
        setCitiesArray(newArrayCities)
        formWorkItem.setFieldValue("city", city)
      }
      formWorkItem.resetDirty()
    }
  }, [!isModeView, data])

  useEffect(() => {
    formWorkItem.setFieldValue(
      "otherDocs",
      buildMultipleFilesData(containerOtherDocs)
    )
  }, [containerOtherDocs])

  useEffect(() => {
    if (currentFileIndex > 0 && currentFileIndex < files.length) {
      uploadChunk(
        files,
        data?.data.response.workItemUuid,
        currentFileIndex,
        setCurrentFileIndex
      )
    } else if (currentFileIndex > 0 && currentFileIndex === files.length) {
      setIsUploadingFiles(false)
      navigate(pathViewMode)
      setFiles([])
      setCurrentFileIndex(0)
    }
  }, [currentFileIndex])

  useEffect(() => {
    formWorkItem.resetDirty()
    setValueForm(formWorkItem, data)
  }, [data?.data.response, isLoading, isRefetching])

  useEffect(() => {
    if (data) {
      setContainerOtherDocs(responseDetailsWorkItems?.otherDocs as IDocument[])
    }
  }, [data])

  return (
    <Container fluid m={0} p={0} bg={backgroudColor} h={"100vh"}>
      <Flex h={"100%"} direction={isTablet ? "column" : "row"}>
        <Navigationbar />
        <Flex
          direction={"column"}
          w="100%"
          h={isMobile ? "auto" : isTablet ? "calc(100vh - 2rem)" : "100%"}
          justify="space-around"
        >
          <form onSubmit={formWorkItem.onSubmit(onUpdateWorkItem)}>
            <HeaderAddEditWorkItem
              isModeView={isModeView}
              isModeAdd={false}
              response={responseDetailsWorkItems}
              setIsModeView={setIsModeView}
              setOpenModalConfirmationCancel={setOpenModalConfirmationCancel}
            />
            <LoadingOverlay
              visible={
                isLoading || isRefetching || isLoadingUpdate || isUploadingFiles
              }
            />
            <Paper
              mx="md"
              p="1.2rem"
              radius={"md"}
              h={
                isMobile
                  ? "100%"
                  : isTablet
                  ? "calc(100vh - 9rem)"
                  : "calc(100vh - 5rem)"
              }
            >
              <ScrollArea h="100%" type="never" offsetScrollbars>
                <Stack spacing={"xs"}>
                  <Flex
                    direction={isMobile ? "column" : "row"}
                    gap="md"
                    w={"100%"}
                    justify="space-between"
                  >
                    <LeftSectionWorkItem
                      isModeView={isModeView}
                      formWorkItem={formWorkItem}
                      dataUserResponse={dataUser && dataUser?.data.response}
                      dataWorkItem={responseDetailsWorkItems}
                    />
                    <RightSectionWorkItem
                      isModeView={isModeView}
                      formWorkItem={formWorkItem}
                      dataUserResponse={dataUser && dataUser?.data.response}
                    />
                  </Flex>

                  <SectionInputMultipleFileWorkItem
                    isModeView={isModeView}
                    setContainerOtherDocs={setContainerOtherDocs}
                    containerOtherDocs={containerOtherDocs}
                    setFiles={setFiles}
                    files={files}
                    isModeAdd={false}
                    allDocs={allDocs}
                  />
                </Stack>
              </ScrollArea>
            </Paper>
          </form>
        </Flex>
      </Flex>
      <GenericModal
        setOpenModal={setOpenModalConfirmationCancel}
        mainActionText={"Redirecționează"}
        title={"Urmează să pierzi schimbările facute pe acestă pagină."}
        subTitle={"Ești sigur(ă) că vrei să fii redirecționat?"}
        isOpenModal={openModalConfimationCancel}
        onHandle={() => onEditCancel()}
        isLoading={false}
      />
      {
        //TODO: Detecting user leaving page
        /* <WarningModal
        showModal={openModalConfimationCancel}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      /> */
      }
      <ErrorSuccesNotification />
    </Container>
  )
}
