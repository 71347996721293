import axios, { AxiosResponse } from "axios"
import { useMutation } from "@tanstack/react-query"
import { resetPasswordUrl, RoutesOptions } from "../../utils/constants"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { IResponse } from "../../types/api.types"

export const usePostResetPassword = (
  successCallBack: (data: IResponse) => void,
  errorCallBack: (data: any) => void
) => {
  const navigate = useNavigate()
  return useMutation(
    ["reset-password-key"],
    (bodyResetPassword: BodyInit | { email: string }) =>
      axios.post(resetPasswordUrl, bodyResetPassword),
    {
      onSuccess: (data: AxiosResponse<IResponse, IResponse>) => {
        successCallBack(data.data)
      },
      onError: (error: any) => {
        errorCallBack(error.response.data)
      },
    }
  )
}
