import {
  Stack,
  Title,
  Button,
  Group,
  LoadingOverlay,
  TextInput,
  ActionIcon,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconAt,
  IconUser,
  IconPhone,
  IconBuilding,
  Icon123,
  IconMapPin,
  IconFileCertificate,
  IconSearch,
} from "@tabler/icons-react"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useGetUser } from "../../api/user/use-get-user"
import { usePutUser } from "../../api/user/use-put-user"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import { IUser } from "../../types/user-types"
import {
  maxWidthMobile,
  maxWidthTablet,
  minWidthLaptop,
} from "../../utils/constants"
import {
  formaterDiacritics,
  reverseFormaterDiacritics,
} from "../../utils/strings"
import { handleDataCompany } from "../clients-page/utils-clients-page/utils-form"
import { ErrorSuccesNotification } from "../notification/notification"
import {
  fieldFormAccount,
  fieldFormAccountCompanyInfo,
  setValueFormUser,
  validateForm,
} from "./utils-account-details"

export const SubscriberDetails: FunctionComponent<{ response: IUser }> = (
  data
) => {
  const [disableEditMode, setDisableEditMode] = useState(true)
  const { refetch } = useGetUser()
  const dispatch = useDispatch()
  const [loadingGetCompanyInfo, setLoadingGetCompanyInfo] = useState(false)
  const [errorFiscalCode, setErrorFiscalCode] = useState<string | null>(null)

  const {
    firstName,
    lastName,
    email,
    isTrial,
    subscriptionExpireDate,
    userUuid,
    phoneNumber,
    companyInfo,
  } = data.response
  const { companyName, CUI, address, nrRegister } =
    companyInfo === null ? "" : JSON.parse(companyInfo)

  const [CUIState, setCUIState] = useState(CUI)
  const [companyNameState, setCompanyNameState] = useState(companyName)
  const [addressState, setAddressState] = useState(address)
  const [nrRegisterState, setNrRegisterState] = useState(nrRegister)
  const isMobile = useMediaQuery(maxWidthMobile)
  const isTablet = useMediaQuery(maxWidthTablet)
  const isLaptopXL = useMediaQuery(minWidthLaptop)

  const formUserData = useForm<any>({
    initialValues: {
      userUuid,
      firstName: reverseFormaterDiacritics(firstName),
      email,
      isTrial,
      lastName: reverseFormaterDiacritics(lastName),
      subscriptionExpireDate,
      phoneNumber,
      companyInfo: JSON.stringify({
        companyName: reverseFormaterDiacritics(companyName),
        CUI,
        nrRegister,
        address,
      }),
    },
    validate: validateForm,
  })

  const checkIsFormDirty =
    formUserData.isDirty("firstName") ||
    formUserData.isDirty("lastName") ||
    formUserData.isDirty("email") ||
    formUserData.isDirty("phoneNumber") ||
    formUserData.isDirty("companyInfo")

  const succesCallBack = (data: { message: string }) => {
    setDisableEditMode(true)
    dispatch(setMessageNotification(data.message))
    formUserData.resetDirty()
    refetch()
  }

  const errorCallBack = (error: any) => {
    formUserData.clearErrors()
    formUserData.reset()
    setDisableEditMode(true)
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu! ${error.response.data.message}.`
      )
    )
  }
  const { mutate: mutateUpdate, isLoading: isPutLoading } = usePutUser(
    succesCallBack,
    errorCallBack
  )

  const onSave = () => {
    const { firstName, lastName, email, phoneNumber } = formUserData.values
    if (formUserData.validate().hasErrors === false) {
      if (checkIsFormDirty) {
        mutateUpdate({
          firstName: formaterDiacritics(firstName),
          lastName: formaterDiacritics(lastName),
          email,
          phoneNumber,
          companyInfo: JSON.stringify({
            companyName: formaterDiacritics(companyNameState),
            CUI: CUIState,
            address: addressState,
            nrRegister: nrRegisterState,
          }),
        } as Partial<IUser>)
      } else {
        setDisableEditMode(true)
        formUserData.clearErrors()
      }
    }
  }

  const onCloseModal = () => {
    setValueFormUser(data, formUserData)
    formUserData.clearErrors()
    setDisableEditMode(true)
  }

  useEffect(() => {
    setValueFormUser(data, formUserData)
    if (formUserData) {
      const { CUI, companyName, address, nrRegister } = JSON.parse(
        formUserData.values.companyInfo
      )
      setCUIState(CUI)
      setCompanyNameState(companyName)
      setAddressState(address)
      setNrRegisterState(nrRegister)
    }
  }, [data.response])

  useEffect(() => {
    const newCompanyInfo = JSON.stringify({
      companyName: formaterDiacritics(companyNameState),
      CUI: CUIState,
      address: addressState,
      nrRegister: nrRegisterState,
    })
    formUserData.setFieldValue("companyInfo", newCompanyInfo)
  }, [CUIState, companyNameState, nrRegisterState, addressState])

  return (
    <Stack
      w="100%"
      h="100%"
      align="center"
      spacing={isMobile ? "1rem" : "3rem"}
    >
      <Title order={4} mt="xl">
        Detalii abonat
      </Title>
      <form
        onSubmit={formUserData.onSubmit(onSave)}
        style={{
          height: "calc(100% - 6rem)",
          width: isTablet ? "95%" : isLaptopXL ? "50%" : "75%",
          display: "flex",
          gap: "xs",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack
          spacing="md"
          align="center"
          w="100%"
          style={{ overflowX: "auto" }}
        >
          {fieldFormAccount(
            "Nume",
            "Numele tău",
            "lastName",
            IconUser,
            disableEditMode,
            formUserData
          )}
          {fieldFormAccount(
            "Prenume",
            "Prenumele tău",
            "firstName",
            IconUser,
            disableEditMode,
            formUserData
          )}
          {fieldFormAccount(
            "Adresa de e-mail",
            "Adresa ta de e-mail",
            "email",
            IconAt,
            disableEditMode,
            formUserData
          )}
          {fieldFormAccount(
            "Număr de telefon",
            "Numărul tău de telefon",
            "phoneNumber",
            IconPhone,
            disableEditMode,
            formUserData
          )}

          <TextInput
            label={"CUI"}
            required
            w="100%"
            icon={<IconFileCertificate />}
            value={CUIState}
            rightSection={
              <ActionIcon
                variant="filled"
                color="brand.0"
                disabled={disableEditMode ? true : false}
                loading={loadingGetCompanyInfo}
                onClick={() =>
                  handleDataCompany(
                    setLoadingGetCompanyInfo,
                    setErrorFiscalCode,
                    CUIState,
                    formUserData,
                    "User",
                    setCompanyNameState,
                    setAddressState,
                    setNrRegisterState
                  )
                }
              >
                <IconSearch color="#4A6642" size="1rem" />
              </ActionIcon>
            }
            variant="filled"
            placeholder={"Introduceți CUI-ul companiei"}
            error={
              errorFiscalCode &&
              "Codul fiscal trebuie să conțină cel puțin 6 caractere (ex: 123456)."
            }
            disabled={disableEditMode ? true : false}
            onChange={(e) => setCUIState(e.currentTarget.value)}
          />
          {fieldFormAccountCompanyInfo(
            "Nume companie",
            "Numele companiei",
            IconBuilding,
            disableEditMode,
            companyNameState,
            setCompanyNameState
          )}
          {fieldFormAccountCompanyInfo(
            "Nr. de înmatriculare",
            "Numărul de înmatriculare",
            Icon123,
            disableEditMode,
            nrRegisterState,
            setNrRegisterState
          )}
          {fieldFormAccountCompanyInfo(
            "Adresa",
            "Introduceți adresa",
            IconMapPin,
            disableEditMode,
            addressState,
            setAddressState
          )}
        </Stack>
        {!disableEditMode ? (
          <Group mt="md" align="center">
            <Button radius="xl" w="fit-content" type="submit">
              Salvează
            </Button>
            <Button
              radius="xl"
              variant="light"
              w="fit-content"
              onClick={() => {
                onCloseModal()
              }}
            >
              Anulează
            </Button>
          </Group>
        ) : (
          <Button
            mt="md"
            radius="xl"
            variant="light"
            w="fit-content"
            onClick={() => setDisableEditMode(!disableEditMode)}
          >
            Editează
          </Button>
        )}
        <LoadingOverlay visible={isPutLoading} />
      </form>

      <ErrorSuccesNotification />
    </Stack>
  )
}
