import axios, { AxiosResponse } from "axios"
import { useMutation } from "@tanstack/react-query"
import { RoutesOptions, workItemsUrlCreate } from "../../utils/constants"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setErrorNotification } from "../../redux/notification/slice"
import { IResponse } from "../../types/api.types"
import { getGenericErrorMessage } from "../../utils/error-messages"

export const usePostWorkItem = (
  successCallBack: (data: IResponse) => void,
  errorCallBack: (data: Error) => void
) => {
  const authToken = sessionStorage.getItem("authToken")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  return useMutation(
    ["create-workItem-key"],
    (workItemData: BodyInit | any) =>
      axios.post(workItemsUrlCreate, workItemData, config),
    {
      onSuccess: (data: AxiosResponse<IResponse, IResponse>) => {
        successCallBack(data.data)
      },
      onError: (error: any) => {
        if ([401].includes(error.response.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(
            setErrorNotification(
              getGenericErrorMessage(error.response.data.message)
            )
          )
        }
        errorCallBack(error)
      },
    }
  )
}
