import { UseFormReturnType } from "@mantine/form"
import { IItemInventory } from "../../../types/items-inventory"
import { InventoryTypes } from "../../../utils/enum"
import { formaterDiacritics, normaliseDiacritics } from "../../../utils/strings"

export const initialValuesItemInventory = (
  itemInventoryDetails: IItemInventory
) => {
  const {
    userUuid,
    itemUuid,
    type,
    name,
    value,
    contaNr,
    currency,
    photo,
    characteristics,
    serialNumber,
    total,
  } = itemInventoryDetails
  const valuesItemInventory = {
    userUuid: userUuid,
    itemUuid,
    type,
    name,
    value,
    contaNr,
    currency,
    photo,
    characteristics,
    serialNumber,
    total,
  }
  return valuesItemInventory
}

export const validateFormItemInventory = {
  name: (value: string) =>
    value.length < 3
      ? "Denumirea trebuie să conțină cel puțin 3 caractere. "
      : null,
  value: (value: string) =>
    /^\d+$/.test(value) && value.length > 0 && value !== "0"
      ? null
      : "Valoarea trebuie să fie validă și mai mare decât 0. ",
  contaNr: (value: string) =>
    value.length > 0 ? null : "Numărul de contabilitate trebuie să fie valid. ",
  total: (value: number) =>
    /^\d+$/.test(String(value)) && value > 0
      ? null
      : "Numărul de bucăți trebuie să fie valid. ",
}
export const buildPayloadForUpdateItemInventory = (
  formItemInventory: UseFormReturnType<
    IItemInventory,
    (values: IItemInventory) => IItemInventory
  >,
  deletedPhoto?: string,
  addedPhoto?: string
) => {
  const {
    value,
    type,
    total,
    serialNumber,
    name,
    currency,
    contaNr,
    characteristics,
    photoName,
    itemUuid,
  } = formItemInventory.values

  return {
    value: value,
    type: type,
    total: total,
    serialNumber: type === InventoryTypes.INVENTORY_OBJECTS ? "" : serialNumber,
    name: formaterDiacritics(name),
    currency: currency,
    contaNr: contaNr,
    characteristics:
      type === InventoryTypes.INVENTORY_OBJECTS
        ? ""
        : characteristics && formaterDiacritics(characteristics),
    photo:
      addedPhoto && addedPhoto !== photoName
        ? itemUuid &&
          itemUuid.concat("***").concat(normaliseDiacritics(addedPhoto))
        : deletedPhoto || (addedPhoto && photoName)
        ? ""
        : photoName,
    addedPhoto: addedPhoto,
    deletedPhoto: addedPhoto && photoName ? photoName : deletedPhoto,
  }
}

export const resetValuesItemInventory = (
  formItemInventory: UseFormReturnType<
    IItemInventory,
    (values: IItemInventory) => IItemInventory
  >,
  itemInventoryDetails: IItemInventory
) => {
  const {
    userUuid,
    itemUuid,
    type,
    name,
    value,
    contaNr,
    currency,
    total,
    photoName,
    characteristics,
    photo,
    serialNumber,
  } = itemInventoryDetails
  return formItemInventory.setValues({
    userUuid,
    itemUuid,
    type,
    name,
    value,
    contaNr,
    currency,
    total,
    photoName: photo && photoName,
    characteristics,
    photo: type === InventoryTypes.FIXED_ASSETS && photo,
    serialNumber,
  })
}
