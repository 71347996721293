import { Button, Flex, Stack, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import {
  IWorkItem,
  IWorkItemsResponseDetails,
} from "../../../types/work-item-types"
import { convertStringFormatForBackendToDate } from "../../../utils/dates"
import { ComponentViewWorkItems, CustomizeElements } from "../../../utils/enum"
import {
  diacriticsCorrector,
  reverseFormaterDiacritics,
} from "../../../utils/strings"
import {
  renderRightControl,
  renderStatusControl,
} from "../../clients-page/utils-clients-page/utils-edit-client"

export const reformatDate = (date: string | null) => {
  if (date && date != null)
    return new Date(date.substring(0, 10).split("-").reverse().join("."))
}
export const reverseReformatDate = (date: string | null | undefined) => {
  if (date && date != null) return date.split("-").reverse().join(".")
}

export const convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}
export const contentViewWorkItems = (
  fieldValue: string,
  field: keyof typeof ComponentViewWorkItems
) => {
  return (
    <Stack spacing={0} w="fit-content">
      <Text c="#979797" fw={500}>
        {ComponentViewWorkItems[field]}:
      </Text>

      {field === "status"
        ? renderStatusControl(fieldValue.toLocaleLowerCase())
        : renderRightControl(fieldValue)}
    </Stack>
  )
}
export const setValueForm = (
  formWorkItem: UseFormReturnType<any, (values: any) => any>,
  data: IWorkItemsResponseDetails | undefined
) => {
  const response = data ? data.data.response : []
  const {
    workItemUuid,
    name,
    clientUuid,
    createDate,
    lastUpdateDate,
    workItemType,
    responsable,
    county,
    city,
    status,
    deadline,
    otherDocs,
    currency,
    advancePayment,
    restPayment,
    totalPayment,
    taxes,
    userUuid,
    address,
  } = response as IWorkItem

  formWorkItem.setValues({
    workItemUuid,
    name: reverseFormaterDiacritics(name),
    clientUuid,
    createDate: diacriticsCorrector(createDate),
    lastUpdateDate: diacriticsCorrector(lastUpdateDate),
    workItemType:
      workItemType &&
      reverseFormaterDiacritics(workItemType.toLocaleUpperCase()),
    responsable: reverseFormaterDiacritics(responsable),
    county,
    city,
    status: status && reverseFormaterDiacritics(status.toLocaleUpperCase()),
    deadline: convertStringFormatForBackendToDate(response ? deadline : null),
    otherDocs,
    currency,
    advancePayment,
    restPayment,
    totalPayment,
    taxes,
    userUuid,
    address: reverseFormaterDiacritics(address),
  })
}
export const noStatus = (
  dataStatus: any,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  setCustomElement: (value: React.SetStateAction<string>) => void
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return !dataStatus ? (
    <Flex w="100%" justify="space-between">
      <Text size="xs" color="dimmed">
        Nu există nici un status.
      </Text>
      <Button
        size="xs"
        variant="light"
        radius="lg"
        compact
        onClick={() => {
          setOpenModal(true)
          setCustomElement(CustomizeElements.STATUS)
        }}
      >
        Adaugă status
      </Button>
    </Flex>
  ) : null
}
export const noTypeWorkitem = (
  dataTypeWorkitems: any,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  setCustomElement: (value: React.SetStateAction<string>) => void
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return !dataTypeWorkitems ? (
    <Flex w="100%" justify="space-between">
      <Text size="xs" color="dimmed">
        Nu există nici un tip de lucrare.{" "}
      </Text>{" "}
      <Button
        size="xs"
        variant="light"
        radius="lg"
        compact
        onClick={() => {
          setOpenModal(true)
          setCustomElement(CustomizeElements.WORK_ITEM_TYPE)
        }}
      >
        Adaugă tip lucrare
      </Button>
    </Flex>
  ) : null
}
