import { useMutation } from '@tanstack/react-query'
import { assignResponsableUrl, RoutesOptions } from '../../utils/constants'
import axios, { AxiosResponse } from 'axios'
import { IAssignResponsableResponse } from '../../types/assign-responsable-types'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setErrorNotification } from '../../redux/notification/slice'
import { getGenericErrorMessage } from '../../utils/error-messages'

export const usePostAssignResponsable = (
  successCallBack: (data: IAssignResponsableResponse) => void,
  errorCallBack: (data: Error) => void,
) => {
  const authToken = sessionStorage.getItem('authToken')
  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useMutation(
    ['assign-responsable-key'],
    (responsableData: BodyInit | any) =>
      axios.post(assignResponsableUrl, responsableData, config),
    {
      onSuccess: (
        data: AxiosResponse<
          IAssignResponsableResponse,
          IAssignResponsableResponse
        >,
      ) => {
        successCallBack(data.data)
      },
      onError: (error: any) => {
        if ([401].includes(error.response.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(
            setErrorNotification(
              getGenericErrorMessage(error.response.data.message),
            ),
          )
        }
        errorCallBack(error)
      },
    },
  )
}
