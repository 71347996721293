import { ActionIcon, Button, Flex, Group, Text, Tooltip } from "@mantine/core"
import { IconPhotoCheck } from "@tabler/icons-react"
import { ItemsInventoryFields } from "../../../utils/enum"
import { renderRightControl } from "../../clients-page/utils-clients-page/utils-edit-client"
import { ActionsButtonItemsIventory } from "../utils-inventory/actions-button-items-inventory"
import { contentCurrentRow } from "./row-items-invetory-objects"

export const rowsTableFixedAssetsMobile = (
  itemInventoryObjects: any,
  setDisplayPhoto: React.Dispatch<React.SetStateAction<undefined>>,
  setShowImage: React.Dispatch<React.SetStateAction<boolean>>,
  readMore: boolean,
  setReadMore: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const {
    itemUuid,
    name,
    value,
    currency,
    characteristics,
    contaNr,
    serialNumber,
    total,
    photo,
  } = itemInventoryObjects

  return (
    <tr key={itemUuid}>
      <td>
        <Flex justify={"space-between"} mb="xs" align={"center"}>
          <Text size={"md"}>{name}</Text>
          <Flex gap={"xs"}>
            <Tooltip
              withArrow
              position="bottom"
              transitionProps={{ duration: 200 }}
              label="Vizualizează imaginea adăugată."
            >
              <ActionIcon
                disabled={photo === "" ? true : false}
                radius="lg"
                variant="light"
                color={"#ceddca"}
                onClick={() => {
                  setDisplayPhoto(photo)
                  setShowImage(true)
                }}
              >
                <IconPhotoCheck size="1.125rem" color="#4A6642" />
              </ActionIcon>
            </Tooltip>
            <ActionsButtonItemsIventory
              key={itemUuid}
              itemUuid={itemUuid}
              dataInventoryItem={itemInventoryObjects}
            />
          </Flex>
        </Flex>
        <Group spacing={0}>
          {contentCurrentRow(value, ItemsInventoryFields.VALUE, true, currency)}
        </Group>
        <Group spacing={0}>
          {contentCurrentRow(
            value * total,
            ItemsInventoryFields.TOTAL_PRICE,
            true,
            currency
          )}
        </Group>
        {contentCurrentRow(serialNumber, ItemsInventoryFields.SERIAL_NUMBER)}

        {characteristics && characteristics.split(" ").length > 25 ? (
          <Flex direction={"column"} w={"100%"} mb={"md"}>
            {contentCurrentRow(
              characteristics,
              ItemsInventoryFields.CHARACTERISTICS,
              false,
              currency,
              readMore
            )}

            <Button
              w={"fit-content"}
              compact
              radius={"xl"}
              size={"xs"}
              onClick={() => {
                setReadMore(() => !readMore)
              }}
            >
              {readMore ? "Vezi mai mult" : "Vezi mai puțin"}
            </Button>
          </Flex>
        ) : (
          contentCurrentRow(
            characteristics,
            ItemsInventoryFields.CHARACTERISTICS
          )
        )}

        {contentCurrentRow(contaNr, ItemsInventoryFields.CONTA_NUMBER)}
        {contentCurrentRow(total, ItemsInventoryFields.TOTAL_NUMBER)}
      </td>
    </tr>
  )
}
export const rowsTableFixedAssets = (
  itemInventoryObjects: any,
  setDisplayPhoto: React.Dispatch<React.SetStateAction<undefined>>,
  setShowImage: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const {
    itemUuid,
    name,
    value,
    currency,
    characteristics,
    contaNr,
    serialNumber,
    total,
    photo,
  } = itemInventoryObjects

  return (
    <tr key={itemUuid}>
      <td>{renderRightControl(name)}</td>
      <td>
        {renderRightControl(value)} {currency}
      </td>
      <td>
        {renderRightControl(value * total)} {currency}
      </td>

      <td>{renderRightControl(serialNumber)}</td>
      <td
        style={{
          width: "12vw",
        }}
      >
        {characteristics ? (
          <Tooltip
            multiline
            width={220}
            position="bottom"
            withArrow
            transitionProps={{ duration: 200 }}
            label={`${characteristics}`}
          >
            <Text lineClamp={1}>{characteristics}</Text>
          </Tooltip>
        ) : (
          renderRightControl(characteristics)
        )}
      </td>
      <td>{renderRightControl(contaNr)} </td>
      <td>{renderRightControl(total)}</td>
      <td>
        <Flex gap={5}>
          <Tooltip
            withArrow
            position="bottom"
            transitionProps={{ duration: 200 }}
            label="Vizualizează imaginea adăugată."
          >
            <ActionIcon
              disabled={photo === "" ? true : false}
              radius="lg"
              variant="light"
              color={"#ceddca"}
              onClick={() => {
                setDisplayPhoto(photo)
                setShowImage(true)
              }}
            >
              <IconPhotoCheck size="1.125rem" color="#4A6642" />
            </ActionIcon>
          </Tooltip>
          <ActionsButtonItemsIventory
            key={itemUuid}
            itemUuid={itemUuid}
            dataInventoryItem={itemInventoryObjects}
          />
        </Flex>
      </td>
    </tr>
  )
}
