import { Image, Table, Text } from "@mantine/core"
import { FunctionComponent } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { IWorkItem } from "../../../types/work-item-types"
import { reverseFormaterDiacritics } from "../../../utils/strings"

import {
  renderRightControl,
  renderStatusControl,
} from "../../clients-page/utils-clients-page/utils-edit-client"
import { ActionsButtonWorkItem } from "../utils-work-items/actions-button-work-items"
import { useStyles } from "../utils-work-items/table-work.styles"

export const TableWorkItems: FunctionComponent = () => {
  const { classes } = useStyles()
  const { workItemsEntriesData } = useSelector(
    (state: RootState) => state.workItems
  )

  const rows =
    workItemsEntriesData !== undefined &&
    workItemsEntriesData.map((workItem: IWorkItem) => {
      const cellsTable = [
        workItem.name,
        workItem.workItemType,
        workItem.county,
        workItem.status,
        workItem.deadline,
        workItem.responsable,
        workItem.createDate,
        workItem.lastUpdateDate,
      ]

      return (
        <tr key={workItem.workItemUuid}>
          {cellsTable.map((field: any, index: number) => (
            <td key={index}>
              {index === 3
                ? renderStatusControl(field.toLocaleLowerCase())
                : renderRightControl(field)}
            </td>
          ))}
          <td>
            <ActionsButtonWorkItem
              key={workItem.workItemUuid}
              workItemUuid={workItem.workItemUuid}
              clientUuid={workItem.clientUuid || ""}
              statusWorkItem={workItem.status}
            />
          </td>
        </tr>
      )
    })

  return (
    <>
      {workItemsEntriesData && workItemsEntriesData.length ? (
        <Table w={"100%"} highlightOnHover>
          <thead className={classes.header}>
            <tr>
              <th>Client</th>
              <th>Tip lucrare</th>
              <th>Județ</th>
              <th>Status</th>
              <th>Termen limită</th>
              <th>Responsabil</th>
              <th>Data creare</th>
              <th>Data actualizare</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      ) : (
        <>
          <Text align={"center"} pt="md">
            Nu aveți nici o lucrare adăugată!
          </Text>

          <Image
            maw={650}
            mx="auto"
            radius="lg"
            src="/add_workitem.svg"
            alt="Add workitem"
          />
        </>
      )}
    </>
  )
}
