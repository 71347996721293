import { useMediaQuery } from '@mantine/hooks'
import _ from 'lodash'
import React, { FunctionComponent, useState } from 'react'
import { maxWidthMobile, maxWidthTablet } from '../../utils/constants'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import { Document, Page, pdfjs } from 'react-pdf'
import { Modal } from '@mantine/core'
import { IContentPdfView } from '../../types/preview-files-types'
import { options } from '../../utils/files'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

export const ContentPdfView: FunctionComponent<IContentPdfView> = (props) => {
  const { isOpenPDF, setIsOpenPdf, fileContent } = props
  const [numPages, setNumPages] = useState(1)

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy) => {
    setNumPages(nextNumPages)
  }
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  return (
    <Modal
      opened={isOpenPDF}
      onClose={() => setIsOpenPdf(false)}
      xOffset={0}
      size={isMobile ? '95vw' : isTablet ? '65vw' : '45vw'}
      centered
      zIndex={2000}
    >
      <Document file={fileContent} onLoadSuccess={onDocumentLoadSuccess} options={options}>
        {Array.from(new Array(numPages), (_el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </Modal>
  )
}
