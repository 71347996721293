import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'

const initialState: {
  fileDeleted: { type: string; fileName: string }
} = {
  fileDeleted: { type: '', fileName: '' },
}

export const deleteFilesSlice = createSlice({
  name: 'delete_files_slice',
  initialState: initialState,
  reducers: {
    setFileDeleted: (state, action) => {
      state.fileDeleted.type = action.payload.type
      state.fileDeleted.fileName = action.payload.fileName
    },
    setResetFileDeleted: (state) => {
      state.fileDeleted.type = initialState.fileDeleted.type
      state.fileDeleted.fileName = initialState.fileDeleted.fileName
    },
  },
})
export const { setFileDeleted, setResetFileDeleted } = deleteFilesSlice.actions
export const selectorClients = (state: RootState) => {
  return state.deleteFiles
}
export default deleteFilesSlice.reducer
