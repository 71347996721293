import { createStyles, getStylesRef, rem } from "@mantine/core"

export const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${theme.colors.gray[2]}`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${theme.colors.gray[2]}`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: theme.colors.brand[6],
    padding: `5px ${theme.spacing.sm}`,
    marginBottom: "0.5rem",
    borderRadius: 13,
    fontWeight: 500,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.colors.gray[0],
      color: theme.black,

      [`& .${getStylesRef("icon")}`]: {
        color: theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color: theme.colors.brand[6],
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: "#E7EDE4",
      color: "#62685F",
      [`& .${getStylesRef("icon")}`]: {
        color: "#62685F",
      },
    },
  },
  burger: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },
  description: {
    color: "#3C4048",
    fontSize: 12,
    textAlign: "start",
  },
}))
