import { Text, Flex } from "@mantine/core"
import { IconMail, IconPhone, IconSun } from "@tabler/icons-react"
import React from "react"

const SupportContent = () => {
  return (
    <Flex w="100%" justify="center" gap="xl" direction="column">
      <Text size={15} color="black" align="center">
        Ai nevoie de ajutor? <br />
        Nu ezita să ne contactezi și te vom ajuta cât mai repede.
      </Text>
      <Flex w="100%" justify="center" gap="xs" direction="column">
        <Flex justify="center" gap="xs">
          <IconSun size={25} color="#4A6642" />
          <Text size={15} color="brand.2" align="center">
            Luni - Vineri: 09:00 - 17:00
          </Text>
        </Flex>
        <Flex justify="center" gap="xs">
          <IconMail size={25} color="#4A6642" />
          <Text
            component="a"
            href="mailto:support@topo-crm.ro"
            size={15}
            color="brand.2"
            align="center"
          >
            support@topo-crm.ro
          </Text>
        </Flex>
{/*         <Flex justify="center" gap="xs">
          <IconPhone size={25} color="#4A6642" />
          <Text
            component="a"
            href="tel:+40 750 471 820"
            size={15}
            color="brand.2"
            align="center"
          >
            +40 750 471 820
          </Text>
        </Flex> */}
      </Flex>
    </Flex>
  )
}

export default SupportContent
