import {
  ActionIcon,
  Flex,
  LoadingOverlay,
  Pagination,
  Paper,
  ScrollArea,
  TextInput,
  Text,
  Image,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconSearch, IconX } from "@tabler/icons-react"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useGetClients } from "../../api/clients/use-get-clients"
import { ClientsTables } from "./clients-tables/clients-tables"
import { ClientsTableMobile } from "./clients-tables/clients-table-mobile"
import { ClientsTableLaptopS } from "./clients-tables/clients-table-laptop-s"
import {
  limitPage,
  maxWidthLaptopSmall,
  maxWidthMobile,
  regexSearch,
} from "../../utils/constants"
import { useDispatch, useSelector } from "react-redux"
import { setClients } from "../../redux/clients/clientsSlice"
import { RootState } from "../../redux/store"
import { setCurrentPageClient } from "../../redux/clients/clientsSlice"

interface IGeneralTableComponentProps {
  nrOfClients: number
  setNrOfClient: React.Dispatch<React.SetStateAction<number>>
}

export const GeneralTableComponent: FunctionComponent<
  IGeneralTableComponentProps
> = ({ nrOfClients, setNrOfClient }) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(maxWidthMobile)
  const isLaptopSmall = useMediaQuery(maxWidthLaptopSmall)
  const { currentPageClient } = useSelector((state: RootState) => state.clients)
  const [searchValue, setSearchValue] = useState("")
  const {
    data,
    isLoading,
    isRefetching,
    refetch: refetchClients,
  } = useGetClients(currentPageClient, limitPage, searchValue)

  useEffect(() => {
    refetchClients()
  }, [currentPageClient])

  useEffect(() => {
    dispatch(setClients(data?.data.response))
    setNrOfClient(data?.data.entries || 0)
    if (searchValue.length > 0) {
      dispatch(setCurrentPageClient(1))
    }
  }, [data])

  useEffect(() => {
    if (searchValue === "") {
      refetchClients()
    }
  }, [searchValue])

  useEffect(() => {
    dispatch(setCurrentPageClient(1))
  }, [nrOfClients])

  return (
    <Flex
      h="calc(100% - 3rem)"
      direction={"column"}
      justify={nrOfClients > 0 ? "space-between" : "flex-start"}
    >
      <Flex gap={0} justify="space-between" align={"center"} mx={"md"} h="4rem">
        <TextInput
          placeholder="Caută după denumirea clientului sau e-mail"
          type={"search"}
          value={searchValue}
          w="100%"
          my="xs"
          mr="xs"
          radius="md"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              refetchClients()
            }
          }}
          rightSection={
            searchValue.length > 0 ? (
              <ActionIcon
                variant={"transparent"}
                onClick={() => {
                  setSearchValue("")
                }}
              >
                <IconX size="1.2rem" color="#adb5bd" />
              </ActionIcon>
            ) : null
          }
          onChange={(e) =>
            setSearchValue(e.target.value.replace(regexSearch, ""))
          }
        />
        <ActionIcon
          variant={"light"}
          radius={"md"}
          color="#CEDDCA"
          onClick={() => {
            refetchClients()
          }}
          size={"2rem"}
        >
          <IconSearch size="1.2rem" color="#4A6642" />
        </ActionIcon>
      </Flex>

      <Flex h="calc(100% - 8rem)" direction={"column"} justify="space-between">
        <Paper
          mx="md"
          p="md"
          radius={"md"}
          w={"auto"}
          h={isMobile ? "auto" : "100%"}
        >
          <LoadingOverlay visible={isLoading || isRefetching} />
          <ScrollArea h={"100%"} type="auto" scrollbarSize={6}>
            {nrOfClients > 0 ? (
              isMobile ? (
                <ClientsTableMobile />
              ) : isLaptopSmall ? (
                <ClientsTableLaptopS />
              ) : (
                <ClientsTables />
              )
            ) : searchValue.length > 0 && nrOfClients === 0 ? (
              <>
                <Text align={"center"} pt="lg">
                  Clientul {searchValue} nu a fost găsit!
                </Text>

                <Image
                  maw={450}
                  mx="auto"
                  alt="No found client"
                  radius="lg"
                  src="/No_data.png"
                />
              </>
            ) : (
              <>
                <Text align={"center"} pt="lg">
                  Nu aveți nici un client adăugat!
                </Text>

                <Image
                  maw={550}
                  mx="auto"
                  alt="No found clients"
                  radius="lg"
                  src="/noEntries.svg"
                />
              </>
            )}
          </ScrollArea>
        </Paper>
      </Flex>
      {nrOfClients === 0 && searchValue.length === 0 ? null : (
        <Pagination
          h="4rem"
          total={data?.data.pages || 1}
          value={currentPageClient}
          onChange={(e: number) => dispatch(setCurrentPageClient(e))}
          position="center"
          radius="xl"
          withEdges
        />
      )}
    </Flex>
  )
}
