import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MantineProvider } from '@mantine/core'
import { Provider } from 'react-redux'
import { store } from './redux/store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <MantineProvider
      theme={{
        colors: {
          brand: [
            '#CEDDCA',
            '#96B58C',
            '#79A16D',
            '#618556',
            '#B2C9AB',
            '#4A6642',
            '#34472E',
            '#1D281A',
            '#8aa187',
            '#3C4048',
          ],
        },
        fontFamily: 'Poppins',
        primaryColor: 'brand',
        primaryShade: 5,
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </MantineProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
