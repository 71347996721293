import {
  Flex,
  Paper,
  Image,
  Title,
  Stack,
  Box,
  Container,
  Text,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { usePostConfirmationEmail } from "../../api/confirmation-email/use-post-confirmation-email"
import { setMessageNotification } from "../../redux/notification/slice"
import {
  maxWidthMobile,
  maxWidthTablet,
  minWidthLaptop,
  RoutesOptions,
} from "../../utils/constants"
import { useStyles } from "../change-password/change-password.styles"
import { ErrorSuccesNotification } from "../notification/notification"

export const EmailConfirmationPage = () => {
  const { classes, theme } = useStyles()
  const navigate = useNavigate()
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const isLaptopXl = useMediaQuery(minWidthLaptop)
  const dispatch = useDispatch()
  const userUuid = window.location.pathname.split("/")[1]

  const succesCallBack = (data: any) => {
    dispatch(setMessageNotification(data.message))
    navigate(RoutesOptions.LOGIN)
  }

  const { mutate } = usePostConfirmationEmail(succesCallBack)

  useEffect(() => {
    mutate({ userUuid: userUuid })
  }, [])

  return (
    <Container h="100vh" m={0} maw="100vw">
      <Flex
        direction={"column"}
        align="center"
        h={"100%"}
        justify={"space-between"}
      >
        <Image
          src={"/Logo_Topo_CRM.png"}
          height="auto"
          width="auto"
          alt="Logo Topo"
          className={classes.hoverImage}
          onClick={() => {
            navigate(RoutesOptions.LOGIN)
          }}
        />

        <Paper
          radius={"xl"}
          w={isLaptopXl ? "55%" : isMobile ? "95%" : "75%"}
          bg={"#ffffffc4"}
        >
          <Flex align="center" gap={0} h="100%" w={"100%"}>
            <Box
              w={isLaptopXl ? "70%" : "80%"}
              display={isTablet ? "none" : "block"}
            >
              <Image
                pl="10%"
                alt="E-mail confirmation image"
                width={isLaptopXl ? "500px" : "400px"}
                src="/email-confirmation.svg"
                fit="contain"
              />
            </Box>
            <Flex
              py={isTablet ? "4rem" : "0"}
              px={isTablet ? "1.5rem" : "3rem"}
              direction={"column"}
              align={"center"}
              justify="center"
              gap={20}
              w="100%"
            >
              <Stack spacing="md" align="center">
                {isTablet ? (
                  <Image
                    src="/check-mail.png"
                    alt="Check mail image"
                    width={"6rem"}
                    height={"6rem"}
                  />
                ) : null}
                <Title
                  order={isMobile ? 3 : 2}
                  c={theme.colors.brand[9]}
                  align="center"
                >
                  Confirmarea adresei de e-mail
                </Title>
              </Stack>
              <Text align="center">
                Felicitări pentru confirmarea adresei tale de e-mail. Ai activat
                cu succes contul tău și în câteva secunde vei fi redirecționat
                pe pagina de autentificare.
              </Text>
            </Flex>
          </Flex>
        </Paper>
        <ErrorSuccesNotification />
        <Title order={3} c={theme.colors.brand[8]}>
          © evosa
        </Title>
      </Flex>
    </Container>
  )
}
