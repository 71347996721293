import { createStyles } from "@mantine/core"

export const useStyles = createStyles(() => ({
  generalContainer: {
    height: "462px",
    border: "1px solid #f6f8f9",
    borderRadius: 10,
    width: "fit-content",
  },
  pdfHeight: {
    height: "462px",
  },
  propsPdf: {
    borderRadius: 10,

    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f6f8f9",
    },
  },

  nameDoc: {
    wordBreak: "break-word",
  },
}))
