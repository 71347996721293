import {
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  TextInput,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import React, { FunctionComponent, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useGetEmployee } from "../../api/employee/use-get-employee"
import { usePostEmployee } from "../../api/employee/use-post-employee"
import { usePutEmployee } from "../../api/employee/use-put-employee"
import {
  resetErrorNotification,
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import { RootState } from "../../redux/store"
import { IEmployee } from "../../types/employee-types"
import { limitPage, maxWidthMobile } from "../../utils/constants"
import { formaterDiacritics } from "../../utils/strings"
import {
  initialValuesEmployeeData,
  validateEmployeeForm,
} from "./employee-utils/employee.utils"

export const EditAddEmployee: FunctionComponent<{
  isOpenModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  employeeDetails?: IEmployee
  isEditMode: boolean
  precompleteField?: string
}> = ({
  isOpenModal,
  setOpenModal,
  isEditMode,
  employeeDetails,
  precompleteField,
}) => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const dispatch = useDispatch()
  const { currentPageEmployee } = useSelector(
    (state: RootState) => state.employees
  )
  const formEmployeeData = useForm<IEmployee>({
    initialValues:
      employeeDetails &&
      initialValuesEmployeeData(employeeDetails as IEmployee),
    validate: validateEmployeeForm,
  })

  const onCloseModal = () => {
    setOpenModal(false)
    formEmployeeData.clearErrors()
    formEmployeeData.reset()
    employeeDetails &&
      formEmployeeData.setValues({
        name: employeeDetails.name,
        email: employeeDetails.email,
        role: employeeDetails.role,
        managerUuid: employeeDetails.managerUuid,
      })
    formEmployeeData.resetDirty()
    dispatch(resetErrorNotification())
  }

  const { refetch } = useGetEmployee(currentPageEmployee, limitPage)

  const succesUpdateCallBack = (data: { message: string }) => {
    dispatch(setMessageNotification(data.message))
    setOpenModal(false)
    formEmployeeData.resetDirty()
    refetch()
  }
  const errorUpdateCallBack = (error: any) => {
    formEmployeeData.resetDirty()
    formEmployeeData.reset()
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu! ${error.response.data.message}.`
      )
    )
  }
  const { mutate: mutateUpdate, isLoading: isLoadingPut } = usePutEmployee(
    succesUpdateCallBack,
    errorUpdateCallBack,
    employeeDetails?.employeeUuid
  )
  const onEditEmployee = () => {
    if (!formEmployeeData.validate().hasErrors) {
      if (formEmployeeData.isDirty()) {
        mutateUpdate({
          ...formEmployeeData.values,
          name: formaterDiacritics(formEmployeeData.values.name),
          role: formaterDiacritics(formEmployeeData.values.role),
        })
      } else onCloseModal()
    }
  }

  const succesCallBackPost = (data: any) => {
    dispatch(setMessageNotification(data.message))
    onCloseModal()
    refetch()
  }

  const errorCallBackPost = (error: any) => {
    formEmployeeData.reset()
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu! ${error.response.data.message}.`
      )
    )
  }
  const { mutate, isLoading: isLoadingPost } = usePostEmployee(
    succesCallBackPost,
    errorCallBackPost
  )
  const onCreateEmployee = () => {
    if (!formEmployeeData.validate().hasErrors) {
      const userUuid = sessionStorage.getItem("uuid")
      mutate({
        ...formEmployeeData.values,
        managerUuid: userUuid,
        name: formaterDiacritics(formEmployeeData.values.name),
        email: formEmployeeData.values.email,
        role: formaterDiacritics(formEmployeeData.values.role),
      })
    }
  }
  useEffect(() => {
    if (precompleteField) {
      formEmployeeData.setValues({ name: precompleteField })
    }
  }, [isOpenModal])
  return (
    <Modal
      size={isMobile ? "xs" : "md"}
      opened={isOpenModal}
      onClose={() => onCloseModal()}
      title={isEditMode ? "Editare client" : "Adăugare angajat"}
      centered
      xOffset={0}
      zIndex={2000}
    >
      <Flex direction={"column"} gap="md">
        <TextInput
          required
          label="Numele angajatului:"
          placeholder="Introduceți numele complet al angajatului"
          {...formEmployeeData.getInputProps("name")}
        />

        <TextInput
          required
          type={"email"}
          label="E-mail:"
          placeholder="Introduceți e-mailul "
          {...formEmployeeData.getInputProps("email")}
        />
        <TextInput
          required
          label="Post:"
          placeholder="Introduceți postul "
          {...formEmployeeData.getInputProps("role")}
        />
      </Flex>
      <Group position="center" mt="md">
        {isEditMode ? (
          <Group>
            <Button radius="xl" onClick={onEditEmployee}>
              Salvează
            </Button>
            <Button variant={"light"} radius="xl" onClick={onCloseModal}>
              Anulează
            </Button>
          </Group>
        ) : (
          <Button variant={"light"} radius="xl" onClick={onCreateEmployee}>
            Adaugă angajat
          </Button>
        )}
      </Group>

      <LoadingOverlay visible={isLoadingPost || isLoadingPut} />
    </Modal>
  )
}
