import {
  Flex,
  Paper,
  Image,
  Title,
  Stack,
  Button,
  Box,
  PasswordInput,
  Container,
  LoadingOverlay,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import { IconLock, IconLockCheck } from "@tabler/icons-react"
import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { usePostChangePassword } from "../../api/change-password/use-post-change-password"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import {
  maxWidthMobile,
  maxWidthTablet,
  minWidthLaptop,
  RoutesOptions,
} from "../../utils/constants"
import { useStyles } from "./change-password.styles"

export const ChangePasswordPage = () => {
  const { classes, theme } = useStyles()
  const navigate = useNavigate()
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const isLaptopXl = useMediaQuery(minWidthLaptop)
  const userUuid = window.location.href.split("/")[4]
  const authToken = window.location.href.split("/")[5]
  const dispatch = useDispatch()

  const changePasswordForm = useForm({
    initialValues: { password: "", confirmPassword: "" },
    validate: {
      password: (value) => (value.length > 5 ? null : "Parolă invalidă."),
      confirmPassword: (value: string, values: any) =>
        value !== values.password ? "Parolele nu se potrivesc." : null,
    },
  })
  const succesCallBack = (data: any) => {
    dispatch(setMessageNotification(data))
    navigate(RoutesOptions.LOGIN)
  }
  const errorCallBack = (error: any) => {
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu! ${error.response.data.message}`
      )
    )
  }
  const { mutate, isLoading } = usePostChangePassword(
    authToken,
    succesCallBack,
    errorCallBack
  )
  const onChangePassword = () => {
    if (!changePasswordForm.validate().hasErrors) {
      mutate({
        userUuid: userUuid,
        newPassword: changePasswordForm.values.password,
      })
    }
  }
  return (
    <Container h="100vh" m={0} maw="100vw">
      <Flex
        direction={"column"}
        align="center"
        h={"100%"}
        justify={"space-between"}
      >
        <Image
          src={"/Logo_Topo_CRM.png"}
          alt="Logo Topo"
          height="auto"
          width="auto"
          className={classes.hoverImage}
          onClick={() => {
            navigate(RoutesOptions.LOGIN)
          }}
        />

        <Paper
          radius={"xl"}
          w={isLaptopXl ? "50%" : isMobile ? "95%" : "75%"}
          bg={"#ffffffc4"}
        >
          <Flex align="center" gap={0} h="100%" w="auto">
            <Box
              w={isLaptopXl ? "70%" : "100%"}
              display={isTablet ? "none" : "block"}
            >
              <Image
                pl="10%"
                alt="Reset password image"
                width={isLaptopXl ? "500px" : "100%"}
                src="/reset-password.png"
                fit="contain"
              />
            </Box>
            <Flex
              py={isTablet ? "4rem" : "0"}
              direction={"column"}
              align={"center"}
              justify="center"
              gap={20}
              w="100%"
            >
              <Stack spacing={5} align="center">
                {isTablet ? (
                  <Image
                    alt="Change password image"
                    src="/change-password.png"
                    width={"6rem"}
                    height={"6rem"}
                  />
                ) : null}
                <Title order={isMobile ? 3 : 2} c={theme.colors.brand[9]}>
                  Schimbă parola
                </Title>
              </Stack>
              <form
                onSubmit={changePasswordForm.onSubmit(onChangePassword)}
                className={classes.form}
              >
                <Stack align={"center"} w={"100%"}>
                  <PasswordInput
                    icon={<IconLock size="1rem" />}
                    label="Parolă"
                    placeholder="Introduceți noua parolă."
                    size={isMobile ? "sm" : "md"}
                    radius="xl"
                    w={isTablet ? "100%" : "95%"}
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                      visibilityToggle: classes.iconInputPssword,
                    }}
                    {...changePasswordForm.getInputProps("password")}
                  />
                  <PasswordInput
                    icon={<IconLockCheck size="1rem" />}
                    label="Confirmă parola"
                    placeholder="Introduceți parola introdusă anterior."
                    size={isMobile ? "sm" : "md"}
                    radius="xl"
                    w={isTablet ? "100%" : "95%"}
                    classNames={{
                      input: classes.input,
                      label: classes.label,
                      error: classes.error,
                      visibilityToggle: classes.iconInputPssword,
                    }}
                    {...changePasswordForm.getInputProps("confirmPassword")}
                  />

                  <Button
                    variant="light"
                    radius={"xl"}
                    w={isTablet ? "100%" : "95%"}
                    my={"sm"}
                    type="submit"
                  >
                    Schimbă parola
                  </Button>
                </Stack>
              </form>
            </Flex>
          </Flex>
          <LoadingOverlay visible={isLoading} />
        </Paper>

        <Title order={3} c={theme.colors.brand[8]}>
          © evosa
        </Title>
      </Flex>
    </Container>
  )
}
