import { useMutation } from "@tanstack/react-query"
import { RoutesOptions, userUrl } from "../../utils/constants"
import axios, { AxiosResponse } from "axios"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setErrorNotification } from "../../redux/notification/slice"
import { IResponse } from "../../types/api.types"
import { getGenericErrorMessage } from "../../utils/error-messages"
import { IUser } from "../../types/user-types"

export const usePutUser = (
  successCallBack: (data: IResponse) => void,
  errorCallBack: (data: Error) => void
) => {
  const authToken = sessionStorage.getItem("authToken")
  const userUuidd = sessionStorage.getItem("uuid")

  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useMutation(
    ["update-user-key"],
    (userData: BodyInit | Partial<IUser>) =>
      axios.put(userUrl.concat(`/${userUuidd}`), userData, config),
    {
      onSuccess: (data: AxiosResponse<IResponse, IResponse>) =>
        successCallBack(data.data),
      onError: (error: any) => {
        if ([401].includes(error.response.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(
            setErrorNotification(
              getGenericErrorMessage(error.response.data.message)
            )
          )
        }
        errorCallBack(error)
      },
    }
  )
}
