import {
  Button,
  Group,
  Modal,
  TextInput,
  Flex,
  LoadingOverlay,
  SimpleGrid,
  Select,
  ActionIcon,
  Autocomplete,
  Text,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useGetClients } from "../../api/clients/use-get-clients"
import { usePutClients } from "../../api/clients/use-put-clients"
import { IClient, IClientForm } from "../../types/client-types"
import {
  limitPage,
  maxWidthLaptopSmall,
  maxWidthMobile,
} from "../../utils/constants"
import {
  handleDataCompany,
  initialValuesClientData,
  messageDuplicateCNP,
  messageDuplicateCUI,
  validateForm,
} from "./utils-clients-page/utils-form"
import { usePostClients } from "../../api/clients/use-post-clients"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import {
  resetErrorNotification,
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import { resetCurrentPage } from "../../redux/pagination/slice"
import { cities, counties } from "../../utils/city"
import { buildFormValuesClient } from "./utils-clients-page/build-form-values"
import { formaterDiacritics } from "../../utils/strings"
import { IconSearch } from "@tabler/icons-react"
import { ClientTypes } from "../../utils/enum"

export const ClientAddEdit: FunctionComponent<{
  isOpenModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  clientDetails?: IClient
  isEditMode: boolean
  precompleteField?: string
}> = ({
  isOpenModal,
  setOpenModal,
  isEditMode,
  clientDetails,
  precompleteField,
}) => {
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isMobile = useMediaQuery(maxWidthMobile)
  const dispatch = useDispatch()
  const [selectTypeClient, setSelectTypeClient] = useState<string | null>(
    clientDetails?.typeClient as string
  )
  const { currentPage } = useSelector((state: RootState) => state.pagination)

  const [loadingGetCompanyInfo, setLoadingGetCompanyInfo] = useState(false)
  const [errorCUI, setErrorCUI] = useState<string | null>(null)
  const { clientsEntriesData } = useSelector(
    (state: RootState) => state.clients
  )

  const legalClient =
    selectTypeClient === ClientTypes.LEGAL_CLIENT ? true : false

  const formClientData = useForm<IClientForm>({
    initialValues:
      clientDetails && initialValuesClientData(clientDetails as IClient),
    validate: validateForm,
  })

  useEffect(() => {
    if (isEditMode && clientDetails) {
      formClientData.setValues(buildFormValuesClient(clientDetails))
      setSelectTypeClient(formClientData.values.typeClient as string)
    }
    formClientData.resetDirty()
  }, [clientDetails, isOpenModal])

  useEffect(() => {
    if (selectTypeClient === ClientTypes.INDIVIDUAL_CLIENT) {
      formClientData.setFieldValue("fiscalCode", "")
    } else {
      formClientData.setFieldValue("CNP", "")
    }
  }, [selectTypeClient])

  const onCloseModal = () => {
    setOpenModal(false)
    formClientData.resetDirty()
    formClientData.clearErrors()
    isEditMode ? dispatch(resetErrorNotification()) : formClientData.reset()
  }

  const { refetch } = useGetClients(currentPage, limitPage)

  const succesCallBack = (data: { message: string }) => {
    dispatch(setMessageNotification(data.message))
    onCloseModal()
    dispatch(resetCurrentPage())
    refetch()
  }

  const errorCallBack = (error: any) => {
    formClientData.resetDirty()
    dispatch(setErrorNotification(error))
  }

  const { mutate: mutatePost, isLoading } = usePostClients(
    succesCallBack,
    errorCallBack
  )
  const clientDuplicate = (
    typeClient: string,
    identityId: string,
    isEditMode: boolean,
    isDirtyField?: boolean
  ) => {
    if (isEditMode) {
      const filterClients = clientsEntriesData.filter((client) =>
        typeClient === ClientTypes.INDIVIDUAL_CLIENT
          ? client.CNP === identityId
          : client.fiscalCode === identityId
      )

      if (filterClients.length === 1 && !isDirtyField) {
        return false
      } else return true
    } else {
      const isDuplicate = clientsEntriesData.some((client) =>
        typeClient === ClientTypes.INDIVIDUAL_CLIENT
          ? client.CNP === identityId
          : client.fiscalCode === identityId
      )
      return isDuplicate
    }
  }

  const onCreateClient = () => {
    if (!formClientData.validate().hasErrors) {
      if (
        clientDuplicate(
          selectTypeClient as string,
          selectTypeClient === ClientTypes.INDIVIDUAL_CLIENT
            ? formClientData.values.CNP
            : formClientData.values.fiscalCode,
          isEditMode
        )
      ) {
        if (selectTypeClient === ClientTypes.INDIVIDUAL_CLIENT)
          formClientData.setFieldError("CNP", messageDuplicateCNP)
        else formClientData.setFieldError("fiscalCode", messageDuplicateCUI)
      } else {
        const userUuid = sessionStorage.getItem("uuid")
        const { name, address } = formClientData.values
        mutatePost({
          ...formClientData.values,
          name: formaterDiacritics(name),
          address: formaterDiacritics(address),
          userUuid: userUuid == null ? "" : userUuid,
          typeClient: selectTypeClient as string,
        })
      }
    }
  }

  const { mutate: mutateUpdate, isLoading: isPutLoading } = usePutClients(
    succesCallBack,
    errorCallBack,
    clientDetails ? clientDetails.clientUuid : ""
  )

  const onEditClient = () => {
    if (!formClientData.validate().hasErrors) {
      if (
        clientDuplicate(
          selectTypeClient as string,
          selectTypeClient === ClientTypes.INDIVIDUAL_CLIENT
            ? formClientData.values.CNP
            : formClientData.values.fiscalCode,
          isEditMode,
          selectTypeClient === ClientTypes.INDIVIDUAL_CLIENT
            ? formClientData.isDirty("CNP")
            : formClientData.isDirty("fiscalCode")
        )
      ) {
        if (selectTypeClient === ClientTypes.INDIVIDUAL_CLIENT)
          formClientData.setFieldError("CNP", messageDuplicateCNP)
        else formClientData.setFieldError("fiscalCode", messageDuplicateCUI)
      } else {
        const userUuid = sessionStorage.getItem("uuid")

        if (formClientData.isDirty()) {
          const { name, address } = formClientData.values
          mutateUpdate({
            ...formClientData.values,
            name: formaterDiacritics(name),
            address: formaterDiacritics(address),
            userUuid: !userUuid ? "" : userUuid,
            typeClient: selectTypeClient as string,
          })
        } else onCloseModal()
      }
    }
  }

  useEffect(() => {
    if (precompleteField) {
      formClientData.setValues({ name: precompleteField })
    }
    formClientData.resetDirty()
  }, [isOpenModal])

  useEffect(() => {
    setErrorCUI(null)
  }, [formClientData.values.fiscalCode])

  useEffect(() => {
    if (errorCUI?.includes("is undefined")) {
      setErrorCUI("Ne pare rău, dar CUI-ul introdus nu a fost găsit.")
    }
  }, [errorCUI])

  return (
    <>
      <Modal
        size={isMobile ? "lg" : isLaptopS ? "65%" : "45%"}
        h={isMobile && isOpenModal ? "80svh" : "fit-content"}
        opened={isOpenModal}
        onClose={() => onCloseModal()}
        title={isEditMode ? "Editare client" : "Adăugare client"}
        centered
        xOffset={0}
        zIndex={2000}
      >
        <Flex direction={"column"} gap="md">
          <Select
            data={[ClientTypes.INDIVIDUAL_CLIENT, ClientTypes.LEGAL_CLIENT]}
            value={selectTypeClient}
            onChange={setSelectTypeClient}
            label="Tipul clientului:"
            placeholder="Alegeți tipul clientului"
          />
          {legalClient && (
            <Flex align="flex-start" direction="column" c="red" fz="xs">
              <TextInput
                w={"100%"}
                rightSection={
                  <ActionIcon
                    variant="filled"
                    color="brand.0"
                    loading={loadingGetCompanyInfo}
                    onClick={() =>
                      handleDataCompany(
                        setLoadingGetCompanyInfo,
                        setErrorCUI,
                        formClientData.values.fiscalCode,
                        formClientData,
                        "Client"
                      )
                    }
                  >
                    <IconSearch color="#4A6642" size="1rem" />
                  </ActionIcon>
                }
                styles={{
                  input: {
                    border: (errorCUI as string)
                      ? "1px solid red"
                      : "1px solid #ced4da",
                  },
                }}
                required
                type="text"
                label="CUI:"
                placeholder={"Introduceți codul CUI-ul firmei"}
                {...formClientData.getInputProps("fiscalCode")}
              />
              <Text>{errorCUI}</Text>
            </Flex>
          )}
          <TextInput
            required
            label={
              legalClient ? "Numele firmei:" : "Numele complet al clientului:"
            }
            placeholder={
              legalClient
                ? "Introduceți numele firmei"
                : "Introduceți numele complet al clientului"
            }
            {...formClientData.getInputProps("name")}
          />
          {!legalClient && (
            <TextInput
              required
              type="text"
              label="CNP:"
              placeholder="Introduceți CNP-ul"
              {...formClientData.getInputProps("CNP")}
            />
          )}

          {legalClient ? (
            <TextInput
              label="Nr. înmatriculare:"
              placeholder="Introduceți nr. înmatriculare"
              w="100%"
              {...formClientData.getInputProps("nrRegister")}
            />
          ) : (
            <Flex gap={"sm"} w="100%">
              <TextInput
                label="Serie:"
                placeholder="AX"
                w="25%"
                {...formClientData.getInputProps("idSeries")}
              />
              <TextInput
                type={"number"}
                label="Număr serie:"
                placeholder="Introduceți numărului seriei "
                w="75%"
                {...formClientData.getInputProps("idSeriesNumber")}
              />
            </Flex>
          )}
          {isMobile ? (
            <>
              <Flex gap={"sm"} w="100%" direction={"column"}>
                <Select
                  data={counties}
                  label="Județ:"
                  searchable
                  clearable
                  placeholder="Introduceți județul"
                  {...formClientData.getInputProps("county")}
                />
                <Autocomplete
                  data={cities}
                  label="Localitate:"
                  placeholder="Introduceți localitatea"
                  {...formClientData.getInputProps("city")}
                />

                <TextInput
                  label="Stradă:"
                  placeholder="Introduceți strada"
                  w="100%"
                  {...formClientData.getInputProps("address")}
                />
              </Flex>
            </>
          ) : (
            <>
              <SimpleGrid cols={2}>
                <Select
                  data={counties}
                  label="Județ:"
                  searchable
                  clearable
                  placeholder="Introduceți județul"
                  {...formClientData.getInputProps("county")}
                />
                <Autocomplete
                  data={cities}
                  label="Localitate:"
                  placeholder="Introduceți localitatea"
                  {...formClientData.getInputProps("city")}
                />
              </SimpleGrid>

              <TextInput
                label="Stradă:"
                placeholder="Introduceți strada"
                {...formClientData.getInputProps("address")}
              />
            </>
          )}

          <TextInput
            required
            type={"number"}
            label="Numar telefon:"
            placeholder="Introduceți numarul de telefon"
            {...formClientData.getInputProps("phoneNumber")}
          />
          <TextInput
            type={"email"}
            label="E-mail:"
            placeholder="Introduceți e-mailul "
            {...formClientData.getInputProps("email")}
          />
        </Flex>
        <Group position="center" mt="md">
          {isEditMode ? (
            <Group>
              <Button radius="xl" onClick={onEditClient}>
                Salvează
              </Button>
              <Button variant={"light"} radius="xl" onClick={onCloseModal}>
                Anulează
              </Button>
            </Group>
          ) : (
            <Button variant={"light"} radius="xl" onClick={onCreateClient}>
              Adaugă client
            </Button>
          )}
        </Group>
        <LoadingOverlay visible={isLoading || isPutLoading} />
      </Modal>
    </>
  )
}
