import { Divider } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React from "react"
import { maxWidthMobile } from "../../../utils/constants"

export const DividerVerticalHorizontal = () => {
  const isMobile = useMediaQuery(maxWidthMobile)
  return (
    <Divider
      orientation={isMobile ? "horizontal" : "vertical"}
      w={isMobile ? "100%" : "auto"}
    />
  )
}
