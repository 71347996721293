import { IconCheck, IconX } from "@tabler/icons-react"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  resetErrorNotification,
  resetMessageNotification,
} from "../../redux/notification/slice"
import { RootState } from "../../redux/store"
import { Notification } from "@mantine/core"
import { useStyles } from "./notification.styles"

export const ErrorSuccesNotification = () => {
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const { messageNotification, errorNotification } = useSelector(
    (state: RootState) => state.notification
  )

  useEffect(() => {
    if (messageNotification.length > 0 || errorNotification.length > 0) {
      const timer = setTimeout(() => {
        dispatch(resetErrorNotification())
        dispatch(resetMessageNotification())
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [messageNotification, errorNotification])

  return messageNotification.length > 0 || errorNotification.length > 0 ? (
    <Notification
      radius="lg"
      color={errorNotification !== "" ? "red" : "teal"}
      withCloseButton={true}
      onClose={() => {
        dispatch(resetErrorNotification())
        dispatch(resetMessageNotification())
      }}
      icon={
        errorNotification !== "" ? (
          <IconX size="1.1rem" />
        ) : (
          <IconCheck size="1.1rem" />
        )
      }
      className={classes.notification}
    >
      {errorNotification !== "" ? errorNotification : messageNotification}
    </Notification>
  ) : null
}
