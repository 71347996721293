import React, { FunctionComponent, useState } from "react"
import type { PDFDocumentProxy } from "pdfjs-dist"
import { Document, Page, pdfjs } from "react-pdf"
import { IDocument } from "../../../types/work-item-types"
import { ActionIcon, Container, Flex, Modal, Stack, Text } from "@mantine/core"
import {
  maxWidthMobile,
  maxWidthTablet,
  pdfNeincarcat,
} from "../../../utils/constants"
import { useMediaQuery } from "@mantine/hooks"
import _ from "lodash"
import { IconDownload } from "@tabler/icons-react"
import { downloadDoc } from "../../../utils/files"
import { useStyles } from "./preview-pdf-profile.styles"

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js"

const options = {
  cMapUrl: "cmaps/",
  standardFontDataUrl: "standard_fonts/",
}

export const ContentPDFViewProfile: FunctionComponent<{
  fieldValue: IDocument
  isModeEdit: boolean
}> = ({ fieldValue, isModeEdit }) => {
  const { classes } = useStyles()
  const [numPages, setNumPages] = useState(1)
  const [isOpenPdf, setIsOpenPdf] = useState(false)
  const [fileContentForView, setFileContentForView] = useState("")

  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)

  const onDocumentLoadSuccess = ({
    numPages: nextNumPages,
  }: PDFDocumentProxy) => {
    setNumPages(nextNumPages)
  }
  const openModalPdf = (fileContent: string) => {
    setFileContentForView(fileContent)
    setIsOpenPdf(true)
  }

  return (
    <>
      <Stack spacing={0}>
        {fieldValue?.content ? (
          <Stack align="center" spacing={0}>
            {isModeEdit ? null : (
              <Flex w="100%" direction={"row"} justify="end">
                <ActionIcon
                  size={"sm"}
                  radius={"sm"}
                  mt={5}
                  variant="filled"
                  title="Descarca"
                  color={"#4A6642"}
                  onClick={() => downloadDoc(fieldValue)}
                >
                  <IconDownload size="1rem" />
                </ActionIcon>
              </Flex>
            )}

            <Container
              p={"md"}
              className={classes.propsPdf}
              onClick={() =>
                isMobile
                  ? window.open(
                      "/pdf/profil/".concat(`${fieldValue.filename}`),
                      "_blank"
                    )
                  : openModalPdf(fieldValue.content)
              }
            >
              <div>
                <Text fw={500} align="center" className={classes.nameDoc}>
                  {fieldValue.filename}
                </Text>
              </div>
              <Document
                file={fieldValue.content}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page className={classes.pdfHeight} pageNumber={1} />
              </Document>
            </Container>
          </Stack>
        ) : (
          <Container className={classes.generalContainer}>
            <Document
              file={pdfNeincarcat}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page className={classes.pdfHeight} pageNumber={1} />
            </Document>
          </Container>
        )}
      </Stack>
      <Modal
        opened={isOpenPdf}
        onClose={() => {
          setIsOpenPdf(false)
          setFileContentForView("")
        }}
        xOffset={0}
        size={isMobile ? "95vw" : isTablet ? "65vw" : "45vw"}
        centered
        zIndex={2000}
      >
        <Document
          file={fileContentForView}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </Modal>
    </>
  )
}
