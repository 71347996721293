import { createSlice } from '@reduxjs/toolkit'
import { IEmployee } from '../../types/employee-types'
import type { RootState } from '../store'

const initialState: {
  employees: IEmployee[]
  currentPageEmployee: number
  countEmployees: number
} = {
  employees: [],
  currentPageEmployee: 1,
  countEmployees: 0,
}

export const employeeSlice = createSlice({
  name: 'employee_slice',
  initialState: initialState,
  reducers: {
    setEmployee: (state, action) => {
      state.employees = action.payload
    },
    setCountEmployee: (state, action) => {
      state.countEmployees = action.payload
    },
    setCurrentPageEmployee: (state, action) => {
      state.currentPageEmployee = action.payload
    },
    resetCurrentPage: (state) => {
      state.currentPageEmployee = initialState.currentPageEmployee
    },
  },
})
export const { setEmployee, setCurrentPageEmployee, resetCurrentPage, setCountEmployee } =
  employeeSlice.actions
export const selectorEmployee = (state: RootState) => {
  return state.employees
}
export default employeeSlice.reducer
