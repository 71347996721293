export const counties = [
  "Alba",
  "Arad",
  "Arges",
  "Bacău",
  "Bihor",
  "Bistrita-Năsăud",
  "Botosani",
  "Brasov",
  "Brăila",
  "Buzău",
  "Caras-Severin",
  "Călăras",
  "Cluj",
  "Constanta",
  "Covasna",
  "Dâmbovita",
  "Dolj",
  "Galati",
  "Giurgiu",
  "Gorj",
  "Haragita",
  "Hunedoara",
  "Ialomita",
  "Iasi",
  "Ilfov",
  "Maramures",
  "Mehedinti",
  "Mures",
  "Municipiul Bucuresti",
  "Neamt",
  "Olt",
  "Prahova",
  "Satu Mare",
  "Sălaj",
  "Sibiu",
  "Suceava",
  "Teleorman",
  "Timis",
  "Tulcea",
  "Vâlcea",
  "Vaslui",
  "Vrancea",
]
export const cities = [
  "Bucuresti",
  "Cluj-Napoca",
  "Timisoara",
  "Iasi",
  "Constanta",
  "Craiova",
  "Brasov",
  "Galati",
  "Ploiesti",
  "Oradea",
  "Braila",
  "Arad",
  "Pitesti",
  "Sibiu",
  "Bacau",
  "Targu Mures",
  "Baia Mare",
  "Buzau",
  "Botosani",
  "Satu Mare",
  "Ramnicu Valcea",
  "Drobeta-Turnu Severin",
  "Suceava",
  "Piatra Neamt",
  "Targu Jiu",
  "Targoviste",
  "Focsani",
  "Bistrita",
  "Tulcea",
  "Resita",
  "Slatina",
  "Calarasi",
  "Alba Iulia",
  "Giurgiu",
  "Deva",
  "Hunedoara",
  "Zalau",
  "Sfantu Gheorghe",
  "Barlad",
  "Vaslui",
  "Roman",
  "Turda",
  "Medias",
  "Slobozia",
  "Alexandria",
  "Voluntari",
  "Lugoj",
  "Medgidia",
  "Onesti",
  "Miercurea Ciuc",
  "Sighetu Marmatiei",
  "Petrosani",
  "Mangalia",
  "Tecuci",
  "Odorheiu Secuiesc",
  "Ramnicu Sarat",
  "Pascani",
  "Dej",
  "Reghin",
  "Navodari",
  "Campina",
  "Mioveni",
  "Campulung",
  "Caracal",
  "Sacele",
  "Fagaras",
  "Fetesti",
  "Sighisoara",
  "Borsa",
  "Rosiorii de Vede",
  "Curtea de Arges",
  "Sebes",
  "Husi",
  "Falticeni",
  "Pantelimon",
  "Oltenita",
  "Turnu Magurele",
  "Caransebeș",
  "Dorohoi",
  "Vulcan",
  "Radauti",
  "Zarnesti",
  "Lupeni",
  "Aiud",
  "Petrila",
  "Campia Turzii",
  "Buftea",
  "Tarnaveni",
  "Popesti-Leordeni",
  "Moinesti",
  "Codlea",
  "Cugir",
  "Carei",
  "Gherla",
  "Blaj",
  "Comanesti",
  "Motru",
  "Targu Neamt",
  "Moreni",
  "Targu Secuiesc",
  "Gheorgheni",
  "Orastie",
  "Bals",
  "Baicoi",
  "Dragasani",
  "Salonta",
  "Bailesti",
  "Calafat",
  "Cernavoda",
  "Filiasi",
  "Campulung Moldovenesc",
  "Corabia",
  "Adjud",
  "Breaza",
  "Bocsa",
  "Marghita",
  "Baia Sprie",
  "Bragadiru",
  "Ludus",
  "Urziceni",
  "Viseu de Sus",
  "Rasnov",
  "Buhusi",
  "Brad",
  "Simleu Silvaniei",
  "Vatra Dornei",
  "Mizil",
  "Cisnadie",
  "Pucioasa",
  "Chitila",
  "Zimnicea",
  "Toplita",
  "Otopeni",
  "Ovidiu",
  "Gura Humorului",
  "Gaesti",
  "Vicovu de Sus",
  "Tandarei",
  "Ocna Mures",
  "Bolintin-Vale",
  "Avrig",
  "Pecica",
  "Simeria",
  "Moldova Noua",
  "Sannicolau Mare",
  "Valenii de Munte",
  "Darmanesti",
  "Dabuleni",
  "Comarnic",
  "Negresti-Oas",
  "Rovinari",
  "Scornicesti",
  "Targu Lapus",
  "Sacueni",
  "Videle",
  "Santana",
  "Oravita",
  "Targu Ocna",
  "Calan",
  "Boldesti-Scaeni",
  "Magurele",
  "Harlau",
  "Draganesti-Olt",
  "Jimbolia",
  "Marasesti",
  "Beius",
  "Beclean",
  "Urlati",
  "Otelu Rosu",
  "Strehaia",
  "Targu Frumos",
  "Orsova",
  "Sinaia",
  "Jibou",
  "Sovata",
  "Costesti",
  "Ianca",
  "Lipova",
  "Dolhasca",
  "Topoloveni",
  "Murfatlar",
  "Nehoiu",
  "Flamanzi",
  "Covasna",
  "Alesd",
  "Valea lui Mihai",
  "Darabani",
  "Hateg",
  "Sangeorz-Bai",
  "Titu",
  "Cristuru Secuiesc",
  "Harsova",
  "Liteni",
  "Nasaud",
  "Podu Iloaiei",
  "Eforie",
  "Odobesti",
  "Huedin",
  "Ineu",
  "Salcea",
  "Seini",
  "Uricani",
  "Babadag",
  "Bumbesti-Jiu",
  "Busteni",
  "Agnita",
  "Iernut",
  "Baraolt",
  "Tasnad",
  "Roznov",
  "Babeni",
  "Negresti",
  "Recas",
  "Macin",
  "Targu Carbunesti",
  "Chisineu-Cris",
  "Siret",
  "Mihailesti",
  "Budesti",
  "Plopeni",
  "Panciu",
  "Calimanesti",
  "Fieni",
  "Somcuta Mare",
  "Intorsura Buzaului",
  "Zlatna",
  "Anina",
  "Curtici",
  "Nadlac",
  "Dumbraveni",
  "Victoria",
  "Amara",
  "Patarlagele",
  "Techirghiol",
  "Pogoanele",
  "Ulmeni",
  "Turceni",
  "Campeni",
  "Cehu Silvaniei",
  "Tautii-Magheraus",
  "Murgeni",
  "Tismana",
  "Buzias",
  "Segarcea",
  "Saveni",
  "Pancota",
  "Ungheni",
  "Sarmasu",
  "Racari",
  "Talmaciu",
  "Cajvana",
  "Vlahita",
  "Fundulea",
  "Livada",
  "Faget",
  "Teius",
  "Bicaz",
  "Stei",
  "Insuratei",
  "Lehliu Gara",
  "Targu Bujor",
  "Piatra-Olt",
  "Horezu",
  "Deta",
  "Ardud",
  "Balan",
  "Slanic",
  "Brezoi",
  "Sebis",
  "Frasin",
  "Gataia",
  "Potcoava",
  "Miercurea Nirajului",
  "Brosteni",
  "Novaci",
  "Saliste",
  "Copsa Mica",
  "Baneasa",
  "Baia de Arama",
  "Ciacova",
  "Stefanesti",
  "Vanju Mare",
  "Geoagiu",
  "Rupea",
  "Sangeorgiu de Padure",
  "Negru Voda",
  "Abrud",
  "Isaccea",
  "Baile Herculane",
  "Milisauti",
  "Cavnic",
  "Fierbinti-Targ",
  "Salistea de Sus",
  "Balcesti",
  "Berbesti",
  "Predeal",
  "Ghimbav",
  "Azuga",
  "Ticleni",
  "Aninoasa",
  "Bucecea",
  "Slanic Moldova",
  "Baile Olanesti",
  "Miercurea Sibiului",
  "Sulina",
  "Bechet",
  "Faurei",
  "Ocna Sibiului",
  "Baia de Aries",
  "Ocnele Mari",
  "Cazanesti",
  "Dragomiresti",
  "Beresti",
  "Borsec",
  "Baile Govora",
  "Vascau",
  "Solca",
  "Nucet",
  "Baile Tusnad",
  "Sectorul 1",
  "Sectorul 2",
  "Sectorul 3",
  "Sectorul 4",
  "Sectorul 5",
]
