import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import queryString from 'query-string'
import { ancpiUrl, checkRGIUrl } from '../../utils/constants'
import { IResponse } from '../../types/api.types'
export const useGetDataBCPI = () => {
  return useQuery(
    ['read-bcpi-key'],
    () => axios.get<any>(ancpiUrl.concat('bcpi.json')),
    {
      onSuccess: (data: any) => {
        return data
      },
      onError: (error: any) => {
        return error
      },
      retry: false,
      enabled: false,
    },
  )
}

export const useGetDataOCPI = () => {
  return useQuery(
    ['read-ocpi-key'],
    () => axios.get<any>(ancpiUrl.concat('ocpi.json')),
    {
      onSuccess: (data: any) => {
        return data
      },
      onError: (error: any) => {
        return error
      },
      retry: false,
      enabled: false,
    },
  )
}

export const usePostRGI = (
  successCallBack: (data: IResponse) => void,
  errorCallBack: (data: Error) => void,
  y: string,
  b: string,
  a: string,
) => {
  const query = queryString.stringify({ y, b, a })
  const checkRGI: string = `${checkRGIUrl}?${query}`
  return useMutation(['create-ancpi-key'], () => axios.post(checkRGI), {
    onSuccess: (data: any) => {
      successCallBack(data.data)
    },
    onError: (error: any) => {
      errorCallBack(error)
    },
  })
}
