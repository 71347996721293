import {
  ActionIcon,
  Flex,
  rem,
  Textarea,
  TextInput,
  Tooltip,
  Image,
  FileInput,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconUpload, IconX } from "@tabler/icons-react"
import React, { FunctionComponent, useState } from "react"
import { useDispatch } from "react-redux"
import { setErrorNotification } from "../../redux/notification/slice"
import { IItemInventory } from "../../types/items-inventory"
import { normaliseDiacritics } from "../../utils/strings"
import { convertBase64 } from "../view-edit-add-work-item-page/utils-edit-add-work-item/utils-edit-work-item"

export const ModalSectionFixedAssets: FunctionComponent<{
  isEditMode: boolean
  formItemInventory: UseFormReturnType<
    IItemInventory,
    (values: IItemInventory) => IItemInventory
  >
  itemInventoryDetails: IItemInventory | undefined
  setFiles: (value: React.SetStateAction<File[]>) => void
  files: File[]
  setValidateFixedAssests: React.Dispatch<React.SetStateAction<boolean>>
  setDeletedPhoto: React.Dispatch<React.SetStateAction<string>>
  setAddedPhoto: React.Dispatch<React.SetStateAction<string>>
}> = (props) => {
  const {
    formItemInventory,
    setFiles,
    files,
    isEditMode,
    itemInventoryDetails,
    setValidateFixedAssests,
    setDeletedPhoto,
    setAddedPhoto,
  } = props
  const { photo, photoName } = formItemInventory.values
  const [errorLimitSize, setErrorLimitSize] = useState("")
  const dispatch = useDispatch()
  const [newPhoto, setNewPhoto] = useState<string | undefined>(photo)

  const onUploadPhoto = (event: File | null) => {
    let filename = null

    if (event && event.size < 3.5 * 1024 * 1024) {
      setFiles([...files, event])
      convertBase64(event)
        .then((e: any) => {
          setNewPhoto(e)
          setAddedPhoto(normaliseDiacritics(event.name))
        })
        .catch((err) =>
          dispatch(setErrorNotification(`Eroare incarcare fisier ${err}`))
        )
      if (isEditMode) {
        filename =
          itemInventoryDetails?.itemUuid &&
          itemInventoryDetails.itemUuid
            .concat("***")
            .concat(normaliseDiacritics(event.name))
      } else {
        filename = normaliseDiacritics(event.name)
      }
    }

    formItemInventory.setFieldValue("photo", filename)
  }

  const deletePhoto = () => {
    setFiles([])
    setDeletedPhoto(photoName ? photoName : "")
    formItemInventory.setFieldValue("photo", "")
    setNewPhoto("")
  }

  return (
    <>
      <TextInput
        label="S/N:"
        placeholder="Introduceți valoarea "
        {...formItemInventory.getInputProps("serialNumber")}
      />
      <Textarea
        label="Caracteristici tehnice:"
        placeholder="Introduceți câteva informații despre obiectul de inventar ..."
        {...formItemInventory.getInputProps("characteristics")}
      />
      <FileInput
        clearable
        label="Imagine cu obiectul de inventar:"
        placeholder="Atașează imagine..."
        accept="image/png, image/jpeg"
        icon={<IconUpload size={rem(14)} />}
        onChange={(e: any) => {
          if (e && e.size > 3.5 * 1024 * 1024) {
            setErrorLimitSize("Imaginea este mai mare de 3.5MB.")
            setValidateFixedAssests(false)
          } else {
            setErrorLimitSize("")
            onUploadPhoto(e)
            setValidateFixedAssests(true)
          }
        }}
        error={errorLimitSize.length > 0 ? errorLimitSize : null}
      />
      {newPhoto === "" || !newPhoto ? null : (
        <Flex direction={"column"} align="end">
          <Tooltip withArrow transitionProps={{ duration: 200 }} label="Șterge">
            <ActionIcon
              radius="lg"
              variant="light"
              color="#ceddca"
              onClick={() => deletePhoto()}
            >
              <IconX size={"1rem"} />
            </ActionIcon>
          </Tooltip>
          <Image src={newPhoto} alt="New photo" height="80%" width={"80%"} />
        </Flex>
      )}
    </>
  )
}
