import axios, { AxiosResponse } from "axios"
import { useMutation } from "@tanstack/react-query"
import {
  clientsUrlCreate,
  confirmEmailUrl,
  RoutesOptions,
} from "../../utils/constants"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setErrorNotification } from "../../redux/notification/slice"
import { IResponse } from "../../types/api.types"
import { getGenericErrorMessage } from "../../utils/error-messages"

export const usePostConfirmationEmail = (
  successCallBack: (data: IResponse) => void
) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useMutation(
    ["confirmation-email-key"],
    (bodyConfirmationEmail: BodyInit | { userUuid: string }) =>
      axios.post(confirmEmailUrl, bodyConfirmationEmail),
    {
      onSuccess: (data: AxiosResponse<IResponse, IResponse>) => {
        successCallBack(data.data)
      },
      onError: (error: any) => {
        if ([401].includes(error.response.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(
            setErrorNotification(
              getGenericErrorMessage(error.response.data.message)
            )
          )
        }
      },
    }
  )
}
