import { Stack, TextInput } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IUser } from "../../types/user-types"
import { paymentParms, standardPrice } from "../../utils/payment"
import { reverseFormaterDiacritics } from "../../utils/strings"
export const formatterDate = (subscriptionExpireDate: string) => {
  const formattedDateArray = subscriptionExpireDate.split(".")
  const formattedDate = `${formattedDateArray[2]}-${formattedDateArray[1]}-${formattedDateArray[0]}`
  const expireDateObject = new Date(formattedDate)
  return expireDateObject
}
export const freeDays = (subscriptionExpireDate: string) => {
  return Math.ceil(
    Math.abs(
      new Date().getTime() -
        new Date(formatterDate(subscriptionExpireDate)).getTime()
    ) /
      (1000 * 3600 * 24)
  )
}

export const fieldFormAccount = (
  fieldName: string,
  placeholder: string,
  formField: string,
  Icon: React.FC<any>,
  disable: boolean,
  formUserData: UseFormReturnType<any, (values: any) => any>
) => {
  return (
    <Stack spacing={5} w="100%">
      <TextInput
        label={fieldName}
        required={true}
        w="100%"
        icon={<Icon />}
        variant="filled"
        placeholder={placeholder}
        disabled={disable}
        {...formUserData.getInputProps(formField)}
      />
    </Stack>
  )
}
export const fieldFormAccountCompanyInfo = (
  fieldName: string,
  placeholder: string,
  Icon: React.FC<any>,
  disable: boolean,
  fieldValue: string,
  setFieldValue: React.Dispatch<any>
) => {
  return (
    <Stack spacing={5} w="100%">
      <TextInput
        label={fieldName}
        value={fieldValue}
        w="100%"
        icon={<Icon />}
        variant="filled"
        placeholder={placeholder}
        disabled={disable}
        onChange={(e) => setFieldValue(e.currentTarget.value)}
      />
    </Stack>
  )
}
export const setValueFormUser = (
  data: {
    response: IUser
  },
  formUserData: UseFormReturnType<any, (values: any) => any>
) => {
  const {
    firstName,
    lastName,
    email,
    subscriptionExpireDate,
    userUuid,
    phoneNumber,
    companyInfo,
  } = data.response
  const { companyName, CUI, nrRegister, address } =
    companyInfo === null ? "" : JSON.parse(companyInfo)

  const newCompanyInfo = JSON.stringify({
    companyName: reverseFormaterDiacritics(companyName),
    CUI: CUI ? CUI : "",
    nrRegister,
    address,
  })

  data &&
    formUserData.setValues({
      firstName: reverseFormaterDiacritics(firstName),
      lastName: reverseFormaterDiacritics(lastName),
      email,
      subscriptionExpireDate,
      userUuid,
      phoneNumber,
      companyInfo: newCompanyInfo,
    })
}
export const validateForm = {
  firstName: (value: string) =>
    /^[a-zA-ZÎîȚțȘșăâ ]{3,}$/.test(value)
      ? null
      : "Prenumele trebuie să conțină doar litere și cel puțin 3 caractere.",

  lastName: (value: string) =>
    /^[a-zA-ZÎîȚțȘșăâ ]{3,}$/.test(value)
      ? null
      : "Numele trebuie să conțină doar litere și cel puțin 3 caractere.",
  email: (value: string) => {
    if (value) {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return null
      } else {
        return "Emailul este invalid."
      }
    } else {
      return null
    }
  },
  phoneNumber: (value: string) =>
    /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s\.|-)?([0-9]{3}(\s|\.|-|)){2}$/.test(
      value
    ) && value.length > 0
      ? null
      : "Numărul de telefon este invalid.",
  companyName: (value: string) => {
    if (value) {
      if (value.length === 0 || /^[a-zA-Z0-9\s\-&*@]{3,}$/.test(value))
        return null
      else return "Numele companiei trebuie să conțină cel puțin 3 caractere."
    }
  },
  CUI: (value: string) => {
    if (value)
      if (value.length === 0 || /^[1-9]\d{0,8}$/.test(value)) return null
      else return "Formatului CUI-ului nu este corect."
  },
}
export const priceAfterDiscount = (affiliateCode: string) => {
  const discount = affiliateCode.match(/\d+/g)
  const price: any = paymentParms().amount
  if (discount) {
    const newPrice = (
      price -
      price * (parseInt(discount[0], 10) / 100)
    ).toPrecision(4)
    return { newPrice: newPrice, discount: discount[0] }
  }
  return standardPrice
}
