import { Button, Center, Menu, Text } from "@mantine/core"
import {
  IconChevronDown,
  IconEdit,
  IconFilePlus,
  IconHttpDelete,
} from "@tabler/icons-react"
import { FunctionComponent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDeleteClients } from "../../../api/clients/use-delete-clients"
import { useGetClients } from "../../../api/clients/use-get-clients"
import { limitPage, Mode, RoutesOptions } from "../../../utils/constants"
import { ClientAddEdit } from "../client-add-edit"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { IClient } from "../../../types/client-types"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../../redux/notification/slice"
import { DeleteClientModal } from "../../generic-modal/delete-client-modal"

export const ActionsButton: FunctionComponent<{ clientUuid?: string }> = (
  props
) => {
  const navigate = useNavigate()
  const { clientUuid } = props
  const [openModalEditClient, setOpenModalEditClient] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const [currentClient, setCurrentClient] = useState<IClient>()
  const { currentPage } = useSelector((state: RootState) => state.pagination)
  const { refetch } = useGetClients(currentPage, limitPage)
  const { clientsEntriesData } = useSelector(
    (state: RootState) => state.clients
  )

  const succesCallBack = (data: { message: string }) => {
    setShowDeleteModal(false)
    refetch()
    dispatch(setMessageNotification(data.message))
  }

  const errorCallBack = (error: any) => {
    dispatch(setErrorNotification(error))
  }
  const { mutate, isLoading: isLoadingDelete } = useDeleteClients(
    clientUuid as string,
    succesCallBack,
    errorCallBack
  )
  const onDeleteClient = () => {
    mutate()
  }
  useEffect(() => {
    const currClient = clientsEntriesData.find(
      (client) => client.clientUuid === clientUuid
    )
    setCurrentClient(currClient)
  }, [clientUuid, clientsEntriesData])
  return (
    <>
      <Center>
        <Menu
          transitionProps={{ transition: "scale-y" }}
          position="bottom-end"
          width={220}
          withinPortal
        >
          <Menu.Target>
            <Button
              variant={"light"}
              size="xs"
              radius="xl"
              rightIcon={<IconChevronDown size="1.05rem" stroke={1.5} />}
              pr={12}
            >
              Acțiuni
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<IconEdit size="1rem" stroke={1.5} />}
              onClick={() => {
                setOpenModalEditClient(true)
              }}
            >
              <Text c="#3C4048" fw={500}>
                Editare
              </Text>
            </Menu.Item>
            <Menu.Item
              icon={<IconHttpDelete size="1rem" stroke={1.5} />}
              onClick={() => {
                setShowDeleteModal(true)
              }}
            >
              <Text c="#3C4048" fw={500}>
                Ștergere
              </Text>
            </Menu.Item>
            <Menu.Item
              icon={<IconFilePlus size="1rem" stroke={1.5} />}
              onClick={() =>
                clientUuid &&
                navigate(
                  RoutesOptions.WORKITEMS.concat("/")
                    .concat(Mode.ADD)
                    .concat("/?")
                    .concat(clientUuid)
                )
              }
            >
              <Text c="#3C4048" fw={500}>
                Adăugare lucrare
              </Text>
            </Menu.Item>
            {/* TODO: currently unavailable */}
            {/* <Menu.Item icon={<IconEye size="1rem" stroke={1.5} />} disabled>
              Vizualizare lucrări
            </Menu.Item> */}
          </Menu.Dropdown>
        </Menu>
      </Center>
      <ClientAddEdit
        isOpenModal={openModalEditClient}
        setOpenModal={setOpenModalEditClient}
        clientDetails={currentClient}
        isEditMode={true}
      />
      <DeleteClientModal
        setOpenModal={setShowDeleteModal}
        mainActionText={"Ștergere client"}
        title={"Urmează să ștergi un client"}
        subTitle={"Ești sigur(ă) că vrei să ștergi clientul selectat?"}
        isOpenModal={showDeleteModal}
        onHandle={onDeleteClient}
        clientName={currentClient?.name}
      />
    </>
  )
}
