import {
  Stack,
  Text,
  Button,
  TextInput,
  Select,
  Flex,
  Table,
  ActionIcon,
  ScrollArea,
  LoadingOverlay,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import { IconX } from "@tabler/icons-react"
import _ from "lodash"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useGetUser } from "../../api/user/use-get-user"
import { usePutUser } from "../../api/user/use-put-user"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import { IPricesFormulas } from "../../types/customize-types"
import {
  maxWidthLaptopSmall,
  maxWidthMobile,
  maxWidthTablet,
} from "../../utils/constants"
import { getGenericErrorMessage } from "../../utils/error-messages"

import {
  duplicateLabel,
  initialValuesPriceFormula,
  panelWidth,
  validateFormPriceFormula,
} from "./customize-page.utils"
import { DividerVerticalHorizontal } from "./shared-component/component-divider-vertical-horizontal"
import { ComponentNoItems } from "./shared-component/component-no-items"

export const PriceFormulasPanel: FunctionComponent<{
  workItemsFormulas: IPricesFormulas[]
  measurementUnits: string[]
  workItemsTypes: string[]
  setWorkItemsFormulas: React.Dispatch<React.SetStateAction<IPricesFormulas[]>>
  isModal: boolean
  setActiveButtons?: React.Dispatch<React.SetStateAction<boolean>>
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  workItemsFormulas,
  setWorkItemsFormulas,
  setActiveButtons,
  workItemsTypes,
  measurementUnits,
  isModal,
  setOpenModal,
}) => {
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const [isActiveAddButton, setActiveAddButton] = useState(false)
  const [isAddedUnitMeasurement, setAddedUnitMeasurement] = useState(false)
  const [valueCalcFormulaName, setValueCalcFormulaName] = useState<string>("")
  const [duplicateMessage, setDuplicateMessage] = useState("")
  const messageDuplicateCalcFormula =
    "Există deja o formulă de calcul cu acest nume."
  const dispatch = useDispatch()
  const formPricesFormulas = useForm<IPricesFormulas>({
    initialValues: initialValuesPriceFormula(),
    validate: validateFormPriceFormula,
  })
  const { data, refetch } = useGetUser()
  const userDataResponse = data ? data.data.response : undefined
  const succesCallBackPut = (data: { message: string }) => {
    dispatch(setMessageNotification(data.message))
    refetch()

    if (!isAddedUnitMeasurement) {
      setOpenModal && setOpenModal(false)
      formPricesFormulas.reset()
    }
    setAddedUnitMeasurement(false)
  }

  const errorCallBackPut = (error: any) => {
    setErrorNotification(getGenericErrorMessage(error.response.data.message))
  }

  const { mutate: mutateUpdate, isLoading: isPutLoading } = usePutUser(
    succesCallBackPut,
    errorCallBackPut
  )

  const handleAddFormula = () => {
    if (formPricesFormulas.validate().hasErrors === false) {
      if (userDataResponse) {
        let newFormulas = []
        if (isModal) {
          if (userDataResponse.workItemsPricesFormulas)
            newFormulas = _.cloneDeep(
              JSON.parse(userDataResponse.workItemsPricesFormulas)
            )
        } else {
          if (workItemsFormulas) newFormulas = _.cloneDeep(workItemsFormulas)
        }

        if (
          !duplicateLabel(
            formPricesFormulas.values.name.toLocaleUpperCase(),
            isModal
              ? JSON.parse(userDataResponse.workItemsPricesFormulas)
              : workItemsFormulas,
            "calcFormula"
          )
        ) {
          newFormulas && newFormulas.push(formPricesFormulas.values)
          if (isModal)
            mutateUpdate({
              ...userDataResponse,
              workItemsPricesFormulas:
                newFormulas && JSON.stringify(newFormulas),
            })
          else {
            setWorkItemsFormulas(newFormulas)
            setActiveButtons && setActiveButtons(true)
            formPricesFormulas.reset()
          }
        } else {
          setDuplicateMessage(messageDuplicateCalcFormula)
        }
      }
    } else formPricesFormulas.validate()
  }

  const handleAddMeasurementUnits = (newMeasurementUnit: string) => {
    if (measurementUnits && measurementUnits[0] !== "") {
      let newArrayMeasurementUnits = _.cloneDeep(measurementUnits)
      newArrayMeasurementUnits.push(newMeasurementUnit)

      mutateUpdate({
        workItemsMeasurementUnits: newArrayMeasurementUnits,
      })
    } else {
      mutateUpdate({
        workItemsMeasurementUnits: newMeasurementUnit,
      })
    }
  }

  const handleDeleteFormula = (itemDelete: string) => {
    if (workItemsFormulas) {
      let newFormulas = workItemsFormulas.filter(
        (workitemFormula) => workitemFormula.name !== itemDelete
      )
      setWorkItemsFormulas(newFormulas)
      setActiveButtons && setActiveButtons(true)
    }
  }
  useEffect(() => {
    const { name, basePrice, workItemMeasurementUnit, workItemType } =
      formPricesFormulas.values
    if (
      name.length > 0 &&
      basePrice &&
      workItemType &&
      workItemMeasurementUnit
    ) {
      setActiveAddButton(true)
    } else setActiveAddButton(false)
  }, [formPricesFormulas])

  useEffect(() => {
    if (isModal) {
      if (formPricesFormulas) {
        const { name, workItemType } = workItemsFormulas[0]
        formPricesFormulas.setValues({
          name: name.toLocaleUpperCase(),
          workItemType: workItemType,
        })
        setValueCalcFormulaName(name.toLocaleUpperCase())
      }
    }
  }, [isModal && isModal])

  useEffect(() => {
    formPricesFormulas.setFieldValue("name", valueCalcFormulaName)
  }, [valueCalcFormulaName])

  useEffect(() => {
    setDuplicateMessage("")
  }, [formPricesFormulas.values.name === ""])

  useEffect(() => {
    formPricesFormulas.setFieldValue(
      "workItemMeasurementUnit",
      formPricesFormulas.values.workItemMeasurementUnit.toLocaleUpperCase()
    )
  }, [formPricesFormulas.values.workItemMeasurementUnit])

  const labelAreaWidth = isMobile ? "100%" : isLaptopS ? "50%" : "70%"
  const hasNoItems =
    (!isModal && !workItemsFormulas) || (!isModal && !workItemsFormulas.length)
  const tabletRows =
    workItemsFormulas &&
    workItemsFormulas.map((element) => (
      <tr key={element.name}>
        <td>
          <Stack spacing={5}>
            <Flex justify="space-between" align="center">
              <Text size={"md"}>{element.name}</Text>
              <ActionIcon
                size={"xs"}
                variant="filled"
                color={"brand.0"}
                radius={"xl"}
                onClick={() => handleDeleteFormula(element.name)}
              >
                <IconX color="#34472e" size={"10px"} />
              </ActionIcon>{" "}
            </Flex>

            <Stack spacing={0}>
              <Text color="#3C4048">Preț:</Text>
              <Text>
                {`${element.basePrice} / ${element.workItemMeasurementUnit}`}
              </Text>
            </Stack>
            <Stack spacing={0}>
              <Text color="#3C4048">Tip lucrare:</Text>
              <Text>{element.workItemType}</Text>
            </Stack>
          </Stack>
        </td>
      </tr>
    ))
  const rows =
    workItemsFormulas &&
    workItemsFormulas.map((element) => (
      <tr key={element.name + Math.random()}>
        <td>
          <Text>{element.name}</Text>
        </td>
        <td> {`${element.basePrice}/${element.workItemMeasurementUnit}`}</td>
        <td>
          <Text>{element.workItemType}</Text>
        </td>

        <td>
          <ActionIcon
            size={"xs"}
            variant="filled"
            color={"brand.0"}
            radius={"xl"}
            onClick={() => handleDeleteFormula(element.name)}
          >
            <IconX color="#34472e" size={"10px"} />
          </ActionIcon>
        </td>
      </tr>
    ))

  return (
    <Stack spacing="0.5" mb="xl" mt="md">
      <Text pl="md">Personalizare formulă de calcul</Text>
      <Text pl="md" fz="xs" color="dimmed">
        *Toate câmpurile trebuie completate pentru a putea adăuga o nouă formulă
        de calcul.
      </Text>
      <Stack>
        <Flex
          p="1rem"
          gap="2rem"
          align={hasNoItems ? "center" : "flex-start"}
          direction={isMobile ? "column" : "row"}
        >
          <form style={{ width: isModal ? "100%" : panelWidth() }}>
            <Stack align="left" spacing={10}>
              <TextInput
                label="Nume formulă"
                placeholder="Introduceți denumirea formulei de calcul"
                value={valueCalcFormulaName}
                onChange={(e) =>
                  setValueCalcFormulaName(e.target.value.toLocaleUpperCase())
                }
                error={
                  formPricesFormulas.errors.name
                    ? formPricesFormulas.errors.name
                    : duplicateMessage
                    ? duplicateMessage
                    : null
                }
              />
              <Select
                data={workItemsTypes}
                label="Tip lucrare"
                placeholder="Alegeți tipul lucrării"
                {...formPricesFormulas.getInputProps("workItemType")}
              />

              <Flex
                align={isTablet ? "flex-end" : "flex-start"}
                justify="space-between"
                gap="xs"
              >
                <TextInput
                  type={"number"}
                  w="50%"
                  label="Preț de baza"
                  placeholder="Introduceți prețul"
                  {...formPricesFormulas.getInputProps("basePrice")}
                  error={formPricesFormulas.errors.basePrice && " "}
                />
                <Text color="#ced4da" mt={"2rem"}>
                  /
                </Text>
                <Select
                  data={measurementUnits}
                  w="50%"
                  label={isMobile ? "Unit. de măsură" : "Unitatea de măsură"}
                  placeholder={
                    isModal
                      ? "Alegeți/Adăugați o unitatea de măsură"
                      : "Alegeți unitatea de măsură"
                  }
                  creatable
                  searchable
                  getCreateLabel={(query) =>
                    `+ Adaugă o unitate de măsură ${query}`
                  }
                  onCreate={(query: any) => {
                    handleAddMeasurementUnits(query.toLocaleUpperCase())
                    setAddedUnitMeasurement(true)
                    return query
                  }}
                  mb={
                    isMobile && formPricesFormulas.errors.basePrice ? "5px" : 0
                  }
                  {...formPricesFormulas.getInputProps(
                    "workItemMeasurementUnit"
                  )}
                ></Select>
              </Flex>
              {formPricesFormulas.errors.basePrice ? (
                <Text color={"red"} size="xs">
                  {formPricesFormulas.errors.basePrice}
                </Text>
              ) : null}

              <Button
                radius="xl"
                mt="xs"
                disabled={isActiveAddButton ? false : true}
                onClick={() => handleAddFormula()}
                loading={isPutLoading}
              >
                Adaugă
              </Button>
            </Stack>
          </form>
          {isModal ? null : (
            <>
              <DividerVerticalHorizontal />
              {hasNoItems ? (
                <ComponentNoItems
                  text={" Nu a fost adăugată nici o formulă de preț."}
                />
              ) : (
                <ScrollArea
                  h={isTablet ? "230px" : "260px"}
                  type="scroll"
                  offsetScrollbars
                  scrollbarSize={6}
                  styles={{ root: { width: labelAreaWidth } }}
                >
                  <Table w={"100%"}>
                    <thead>
                      {isTablet ? (
                        <tr>
                          <th>Formule de calcul</th>
                        </tr>
                      ) : (
                        <tr>
                          <th>Nume formulă</th>
                          <th>Formulă calcul</th>
                          <th>Tipul lucrării</th>
                          <th></th>
                        </tr>
                      )}
                    </thead>

                    <tbody>{isTablet ? tabletRows : rows}</tbody>
                  </Table>
                </ScrollArea>
              )}
            </>
          )}
        </Flex>
      </Stack>
      {isModal && <LoadingOverlay visible={isPutLoading} />}
    </Stack>
  )
}
