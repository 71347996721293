import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { changePasswordUrl } from "../../utils/constants"

export const usePostChangePassword = (
  authToken: string,
  successCallBack: (data: any) => void,
  errorCallBack: (data: any) => void
) => {
  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  return useMutation(
    ["change-password-key"],
    (
      bodyChangePassword: BodyInit | { userUuid: string; newPassword: string }
    ) => axios.post(changePasswordUrl, bodyChangePassword, config),
    {
      onSuccess: (data) => {
        successCallBack(data.data.response)
      },
      onError: (error: any) => {
        errorCallBack(error.response.data)
      },
    }
  )
}
