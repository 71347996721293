import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Paper,
  Text,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { FunctionComponent } from "react"
import { IGenericModal } from "../../types/generic-modal-types"
import { maxWidthMobile } from "../../utils/constants"

export const GenericModal: FunctionComponent<IGenericModal> = ({
  isOpenModal,
  setOpenModal,
  onHandle,
  title,
  subTitle,
  mainActionText,
  isLoading,
}) => {
  const isMobile = useMediaQuery(maxWidthMobile)

  return (
    <Modal
      opened={isOpenModal}
      onClose={() => setOpenModal(false)}
      title={mainActionText}
      centered
      size={isMobile ? "calc(100vw - 2rem)" : "md"}
      xOffset={0}
      zIndex={2000}
    >
      <Paper p="xs">
        <Text>{title}</Text>
        <Text>{subTitle}</Text>
      </Paper>
      <Group position="center" mt="md" spacing={5} noWrap>
        <Button radius="xl" type="submit" onClick={() => onHandle()} size="sm">
          {mainActionText}
        </Button>
        <Button
          variant={"light"}
          size="sm"
          radius="xl"
          type="submit"
          onClick={() => {
            setOpenModal(false)
          }}
        >
          Anulează
        </Button>
      </Group>
      <LoadingOverlay visible={isLoading} />
    </Modal>
  )
}
