import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setErrorNotification } from '../../redux/notification/slice'
import { IProfileResponse } from '../../types/profile-types'
import { profileUrl, RoutesOptions } from '../../utils/constants'
import { getGenericErrorMessage } from '../../utils/error-messages'

export const useGetProfile = () => {
  const authToken = sessionStorage.getItem('authToken')
  const userUuid = sessionStorage.getItem('uuid')
  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useQuery(
    ['read-profile-key'],
    () => axios.get<IProfileResponse>(profileUrl.concat(`/${userUuid}`), config),
    {
      onSuccess: (data: any) => {
        return data.data.response
      },
      onError: (error: any) => {
        if ([401].includes(error.response?.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(setErrorNotification(getGenericErrorMessage(error.response?.data.message)))
        }
      },
      retry: false,
      enabled: false,
    },
  )
}
