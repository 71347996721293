import {
  Container,
  Flex,
  Paper,
  Tabs,
  Title,
  Divider,
  LoadingOverlay,
  Button,
  Group,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { useEffect, useState } from "react"
import {
  backgroudColor,
  maxWidthMobile,
  maxWidthTablet,
  RoutesOptions,
} from "../../utils/constants"
import { Navigationbar } from "../navbar/navbar"
import { ErrorSuccesNotification } from "../notification/notification"
import { CustomizeTypes } from "../../utils/enum"
import { StatusPanel } from "./customize-page-status-panel"
import { TypeWorkitemPanel } from "./customize-page-type-workitem-panel"
import { MeasurementUnitsPanel } from "./customize-page-measurement-units-panel"
import { useGetUser } from "../../api/user/use-get-user"
import { formatterDate } from "../account-details/utils-account-details"
import { useNavigate } from "react-router-dom"
import { IUserResponse } from "../../types/user-types"
import { PriceFormulasPanel } from "./customize-page-price-formulas-panel"
import { getGenericErrorMessage } from "../../utils/error-messages"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import { usePutUser } from "../../api/user/use-put-user"
import { useDispatch } from "react-redux"
import { IPricesFormulas, IStatusWorkitem } from "../../types/customize-types"
import { CustomizePageModal } from "../generic-modal/warning-customize-page-modal"

export const CustomizePage = () => {
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const navigate = useNavigate()
  const [isActiveButtons, setActiveButtons] = useState(false)
  const [isOpenCancelModal, setOpenCancelModal] = useState(false)
  const [workitemsTypes, setWorkitemsTypes] = useState<string[]>([])
  const [workitemStatus, setWorkitemStatus] = useState<IStatusWorkitem[]>([])
  const [workItemsFormulas, setWorkItemsFormulas] = useState<IPricesFormulas[]>(
    []
  )

  const [measurementUnits, setMeasurementUnits] = useState<string[]>([])
  const [tabInventary, setTabInventary] = useState<string>(
    CustomizeTypes.STATUS_CUSTOMIZE
  )
  const dispatch = useDispatch()

  const succesCallBack = (data: IUserResponse["data"]) => {
    if (formatterDate(data.response.subscriptionExpireDate) < new Date()) {
      navigate(RoutesOptions.TRIAL_OVER)
    }
  }

  const { data, refetch, isRefetching, isLoading } = useGetUser(succesCallBack)

  useEffect(() => {
    refetch()
  }, [])

  const userDataResponse = data && data.data.response

  useEffect(() => {
    if (userDataResponse) {
      setWorkitemsTypes(
        userDataResponse.workItemsTypes === "" ||
          !userDataResponse.workItemsTypes
          ? []
          : userDataResponse.workItemsTypes.split(",")
      )

      setWorkitemStatus(
        userDataResponse.workItemsStatuses &&
          JSON.parse(userDataResponse.workItemsStatuses)
      )
      setMeasurementUnits(
        userDataResponse.workItemsMeasurementUnits === "" ||
          !userDataResponse.workItemsMeasurementUnits
          ? []
          : userDataResponse.workItemsMeasurementUnits.split(",")
      )
      setWorkItemsFormulas(JSON.parse(userDataResponse.workItemsPricesFormulas))
    }
  }, [userDataResponse])

  const succesCallBackPut = (data: { message: string }) => {
    dispatch(setMessageNotification(data.message))
    refetch()
  }

  const errorCallBackPut = (error: any) => {
    setErrorNotification(getGenericErrorMessage(error.response.data.message))
  }

  const { mutate: mutateUpdate, isLoading: isPutLoading } = usePutUser(
    succesCallBackPut,
    errorCallBackPut
  )
  const onSave = () => {
    if (tabInventary === CustomizeTypes.STATUS_CUSTOMIZE) {
      mutateUpdate({
        workItemsTypes: workitemsTypes ? workitemsTypes : null,
        workItemsStatuses: workitemStatus
          ? JSON.stringify(workitemStatus)
          : null,
      })
      setActiveButtons(false)
    } else {
      mutateUpdate({
        workItemsMeasurementUnits: measurementUnits && measurementUnits,
        workItemsPricesFormulas:
          workItemsFormulas && JSON.stringify(workItemsFormulas),
      })
    }
    setOpenCancelModal(false)
    setActiveButtons(false)
  }
  const onCancel = () => {
    if (tabInventary === CustomizeTypes.STATUS_CUSTOMIZE) {
      if (userDataResponse) {
        setWorkitemsTypes(userDataResponse.workItemsTypes.split(","))
        setWorkitemStatus(
          userDataResponse.workItemsStatuses &&
            JSON.parse(userDataResponse.workItemsStatuses)
        )
        setActiveButtons(false)
      }
    } else {
      if (userDataResponse) {
        setWorkItemsFormulas(
          JSON.parse(userDataResponse.workItemsPricesFormulas)
        )
        setMeasurementUnits(
          userDataResponse.workItemsMeasurementUnits.split(",")
        )
        setActiveButtons(false)
      }
    }
    setOpenCancelModal(false)
  }

  return (
    <>
      <Container
        fluid
        m={0}
        p={0}
        bg={backgroudColor}
        h={isMobile ? "100%" : "100vh"}
      >
        <Flex h={"100%"} direction={isTablet ? "column" : "row"}>
          <Navigationbar />
          <Flex
            direction={"column"}
            w="100%"
            h={
              isTablet
                ? "calc( 100% - 65px)"
                : isMobile
                ? "calc(100% - 4rem)"
                : "100%"
            }
            gap="md"
          >
            <Flex
              justify={"space-between"}
              align="flex-end"
              h="3rem"
              wrap="nowrap"
            >
              <Title order={4} ml="md">
                Personalizare
              </Title>

              <Group spacing={0}>
                <Button
                  disabled={isActiveButtons ? false : true}
                  mt="md"
                  radius="xl"
                  size="xs"
                  onClick={() => onSave()}
                >
                  Salvează
                </Button>
                <Button
                  disabled={isActiveButtons ? false : true}
                  variant="light"
                  mt="md"
                  mx={isMobile ? "2px" : "xs"}
                  radius="xl"
                  size="xs"
                  onClick={() => onCancel()}
                >
                  Anulează
                </Button>
              </Group>
            </Flex>
            <Paper
              mx="md"
              radius="md"
              p="xs"
              h={isTablet ? "auto" : "calc(100% - 8rem)"}
            >
              <Tabs
                value={tabInventary}
                onTabChange={(tab: string) => {
                  if (isActiveButtons) {
                    setOpenCancelModal(true)
                  } else setTabInventary(tab)
                }}
              >
                <Tabs.List grow>
                  <Tabs.Tab value={CustomizeTypes.STATUS_CUSTOMIZE}>
                    Status & Tipul lucrării
                  </Tabs.Tab>

                  <Tabs.Tab value={CustomizeTypes.PRICE_CUSTOMIZE}>
                    Preț
                  </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value={CustomizeTypes.STATUS_CUSTOMIZE} pt="xs">
                  <TypeWorkitemPanel
                    workitemsTypesState={workitemsTypes}
                    setWorkitemsTypes={setWorkitemsTypes}
                    setActiveButtons={setActiveButtons}
                    isModal={false}
                  />
                  <Divider />
                  <StatusPanel
                    workitemStatus={workitemStatus}
                    setWorkitemStatus={setWorkitemStatus}
                    setActiveButtons={setActiveButtons}
                    isModal={false}
                  />
                </Tabs.Panel>

                <Tabs.Panel value={CustomizeTypes.PRICE_CUSTOMIZE} pt="xs">
                  <MeasurementUnitsPanel
                    measurementUnits={measurementUnits}
                    setMeasurementUnits={setMeasurementUnits}
                    setActiveButtons={setActiveButtons}
                  />
                  <Divider />
                  <PriceFormulasPanel
                    workItemsFormulas={workItemsFormulas}
                    measurementUnits={measurementUnits}
                    workItemsTypes={workitemsTypes}
                    setWorkItemsFormulas={setWorkItemsFormulas}
                    setActiveButtons={setActiveButtons}
                    isModal={false}
                  />
                </Tabs.Panel>
              </Tabs>
            </Paper>
          </Flex>
        </Flex>
        <LoadingOverlay visible={isRefetching || isLoading || isPutLoading} />
        <ErrorSuccesNotification />
      </Container>
      <CustomizePageModal
        isOpenCancelModal={isOpenCancelModal}
        onSave={onSave}
        onCancel={onCancel}
      />
    </>
  )
}
