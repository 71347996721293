import { createStyles } from "@mantine/core"

export const useStyles = createStyles((theme) => ({
  buttonDecoration: {
    textDecoration: "underline",
    padding: 0,
  },
  list: { textAlign: "left", color: theme.colors.brand[9] },
  price: {
    color: "#c8d0d3",
    textDecorationLine: "line-through",
    textDecorationColor: "#c8d0d3",
  },
  chipDiscount: {
    padding: 10,
    color: theme.colors.brand[5],
    backgroundColor: theme.colors.brand[4],
  },
}))
