import {
  Flex,
  Paper,
  Image,
  Title,
  Stack,
  Button,
  Box,
  TextInput,
  Text,
  Container,
  Anchor,
  LoadingOverlay,
  Notification,
} from "@mantine/core"

import { useMediaQuery } from "@mantine/hooks"
import { IconMail, IconX } from "@tabler/icons-react"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { usePostResetPassword } from "../../api/reset-password/use-post-reset-password"
import {
  maxWidthMobile,
  maxWidthTablet,
  minWidthLaptop,
  RoutesOptions,
} from "../../utils/constants"
import { useStyles } from "./forget-password.styles"

export const ForgetPasswordPage = () => {
  const { classes, theme } = useStyles()
  const navigate = useNavigate()
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const isLaptopXl = useMediaQuery(minWidthLaptop)
  const [email, setEmail] = useState("")
  const [backendMessage, setBackendMessage] = useState({
    type: "",
    message: "",
  })
  const [errorEmail, setErrorEmail] = useState("")
  const succesCallBack = (data: any) => {
    setBackendMessage({ type: "notification", message: data.message })
    setEmail("")
  }
  const errorCallBack = (error: any) => {
    if (error.code === 400) {
      setBackendMessage({
        type: "error",
        message: `Te rog încearcă mai târziu! ${error.message}`,
      })
    } else {
      setBackendMessage({ type: "error", message: `${error.message}` })
    }
  }
  const { mutate, isLoading } = usePostResetPassword(
    succesCallBack,
    errorCallBack
  )
  const onResetPassword = () => {
    if (/^\S+@\S+$/.test(email) && email.length > 0) {
      mutate({ email })
    } else {
      setErrorEmail("Adresa de e-mail invalidă.")
    }
  }

  return (
    <Container h="100vh" m={0} maw="100vw">
      <Flex
        direction={"column"}
        align="center"
        h={"100%"}
        justify={"space-between"}
      >
        <Image
          src={"/Logo_Topo_CRM.png"}
          height="auto"
          alt="Logo Topo"
          width="auto"
          className={classes.hoverImage}
          onClick={() => {
            navigate(RoutesOptions.LOGIN)
          }}
        />

        <Paper
          radius={"xl"}
          maw={isLaptopXl ? "50%" : isMobile ? "95%" : "85%"}
          bg={"#ffffffc4"}
        >
          <Flex align="center" gap={0} h="100%" w={isTablet ? "80vw" : "auto"}>
            <Box
              w={isLaptopXl ? "80%" : "100%"}
              display={isTablet ? "none" : "block"}
            >
              <Image
                alt="Forgot password"
                pl="10%"
                width={isLaptopXl ? "500px" : "100%"}
                src="forgot-password.svg"
                fit="contain"
              />
            </Box>
            <Flex
              py={isTablet ? "4rem" : "0"}
              direction={"column"}
              align={"center"}
              gap={20}
              w="100%"
            >
              <Stack spacing={10} align="center">
                {isTablet ? (
                  <Image
                    alt="Secure email"
                    src="/secure-email.png"
                    width={"6rem"}
                    height={"6rem"}
                  />
                ) : null}
                <Title order={isMobile ? 3 : 2} c={theme.colors.brand[9]}>
                  Resetează parola
                </Title>
              </Stack>
              <Container w={"85%"}>
                <Text
                  w={"100%"}
                  size={isMobile ? "xs" : "sm"}
                  color={"dimmed"}
                  mb={"2rem"}
                  align="center"
                >
                  Introduceți adresa de e-mail asociată contului și vă vom
                  trimite un e-mail cu instrucțiunile necesare pentru resetare a
                  parolei.
                </Text>
                <Stack align={"center"} w={"100%"} spacing="xs">
                  <TextInput
                    icon={<IconMail size="1.2rem" />}
                    placeholder="E-mail"
                    radius="xl"
                    w={isTablet ? "100%" : "95%"}
                    size={isMobile ? "sm" : "md"}
                    value={email}
                    onChange={(e: any) => {
                      if (e.target.value.length > 0) {
                        setErrorEmail("")
                        setBackendMessage({ type: "", message: "" })
                      }
                      setEmail(e.target.value)
                    }}
                    classNames={{
                      input: classes.input,
                      error: classes.error,
                    }}
                    error={errorEmail}
                  />
                  {backendMessage.message ? (
                    <Notification
                      radius="lg"
                      color={
                        backendMessage.type === "notification"
                          ? "yellow"
                          : "red"
                      }
                      mt="md"
                      title=""
                      withCloseButton={false}
                      icon={
                        backendMessage.type === "notification" ? null : (
                          <IconX size="1.1rem" />
                        )
                      }
                      w={isMobile ? "auto" : "fit-content"}
                    >
                      {backendMessage.message}
                    </Notification>
                  ) : null}

                  <Button
                    variant="light"
                    radius={"xl"}
                    w={isTablet ? "100%" : "95%"}
                    mt={"sm"}
                    onClick={onResetPassword}
                  >
                    Trimite e-mail
                  </Button>
                  <Anchor
                    component="button"
                    color={"dimmed"}
                    size={isMobile ? "xs" : "sm"}
                    w={"80%"}
                    onClick={() => navigate(RoutesOptions.LOGIN)}
                    align="center"
                  >
                    Nu e ceea ce vrei? Atunci revino în pagina de autetificare.
                  </Anchor>
                </Stack>
              </Container>
            </Flex>
          </Flex>
          <LoadingOverlay visible={isLoading} />
        </Paper>

        <Title order={3} c={theme.colors.brand[8]}>
          © evosa
        </Title>
      </Flex>
    </Container>
  )
}
