import {
  Button,
  Container,
  Flex,
  LoadingOverlay,
  Pagination,
  Paper,
  Tabs,
  Title,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useGetInventory } from "../../api/inventory/use-get-inventory"
import {
  resetCurrentPageInventory,
  setCurrentPageFixedAssets,
  setCurrentPageInventoryObjects,
} from "../../redux/item-inventory/item-inventory-slice"
import { RootState } from "../../redux/store"
import {
  backgroudColor,
  limitPage,
  maxWidthMobile,
  maxWidthTablet,
  RoutesOptions,
} from "../../utils/constants"
import { Navigationbar } from "../navbar/navbar"
import { ErrorSuccesNotification } from "../notification/notification"
import { TabelItemInventory } from "./table-item-invetory/tabels-item-inventory"
import { ItemInventoryAddEdit } from "./item-inventory-add-edit"
import { InventoryTypes } from "../../utils/enum"
import { useGetUser } from "../../api/user/use-get-user"
import { useNavigate } from "react-router-dom"
import { formatterDate } from "../account-details/utils-account-details"
import { IUserResponse } from "../../types/user-types"
import { reverseFormaterDiacritics } from "../../utils/strings"

export const InventoryPage = () => {
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const [showModalAddItemInventory, setShowModalAddItemInventory] =
    useState(false)
  const [tabInventary, setTabInventary] = useState<string>(
    InventoryTypes.INVENTORY_OBJECTS
  )
  const [dataInventory, setDataInvetory] = useState<any>()
  const [currentPageInventory, setCurrentPageInventory] = useState(1)
  const { currentPageFixedAssets, currentPageInventoryObjects } = useSelector(
    (state: RootState) => state.itemsInventory
  )
  const dispatch = useDispatch()
  const {
    data: dataFixedAssets,
    refetch: refetchFixedAssets,
    isRefetching: isRefetchingFixedAssets,
  } = useGetInventory(
    currentPageInventory,
    limitPage,
    InventoryTypes.FIXED_ASSETS
  )

  const {
    data: dataInventoryObjects,
    refetch: refetchInventoryObjects,
    isRefetching: isRefetchinginvetoryObjects,
    isLoading,
  } = useGetInventory(
    currentPageInventory,
    limitPage,
    InventoryTypes.INVENTORY_OBJECTS
  )
  const noEntriesInventoryObjects =
    dataInventoryObjects && dataInventoryObjects.data.entries === 0
  const noEntriesForFixedAssets =
    dataFixedAssets && dataFixedAssets.data.entries === 0

  const navigate = useNavigate()

  const succesCallBack = (data: IUserResponse["data"]) => {
    if (formatterDate(data.response.subscriptionExpireDate) < new Date()) {
      navigate(RoutesOptions.TRIAL_OVER)
    }
  }
  const { refetch } = useGetUser(succesCallBack)

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (tabInventary === InventoryTypes.FIXED_ASSETS) {
      refetchFixedAssets()
    } else if (tabInventary === InventoryTypes.INVENTORY_OBJECTS) {
      refetchInventoryObjects()
    }
  }, [])

  useEffect(() => {
    if (tabInventary === InventoryTypes.FIXED_ASSETS) {
      refetchFixedAssets()
      dispatch(resetCurrentPageInventory)
      setCurrentPageInventory(currentPageInventoryObjects)
    } else if (tabInventary === InventoryTypes.INVENTORY_OBJECTS) {
      refetchInventoryObjects()
      dispatch(resetCurrentPageInventory)
      setCurrentPageInventory(currentPageInventoryObjects)
    }
  }, [tabInventary, currentPageInventory])

  useEffect(() => {
    if (tabInventary === InventoryTypes.FIXED_ASSETS) {
      if (dataFixedAssets) {
        const newDataResponse = dataFixedAssets.data.response.map((item) => {
          return {
            ...item,
            name: reverseFormaterDiacritics(item.name),
            characteristics:
              item.characteristics &&
              reverseFormaterDiacritics(item.characteristics),
            photoName:
              item.photoName && reverseFormaterDiacritics(item.photoName),
          }
        })

        setDataInvetory({ ...dataFixedAssets.data, response: newDataResponse })
      }
    } else {
      if (dataInventoryObjects) {
        const newDataResponse = dataInventoryObjects.data.response.map(
          (item) => {
            return {
              ...item,
              name: reverseFormaterDiacritics(item.name),
            }
          }
        )
        setDataInvetory({
          ...dataInventoryObjects.data,
          response: newDataResponse,
        })
      }
    }
  }, [dataFixedAssets, dataInventoryObjects])

  useEffect(() => {
    setCurrentPageInventory(1)
    if (dataFixedAssets && dataFixedAssets.data.entries % limitPage === 0) {
      setCurrentPageInventory(currentPageFixedAssets - 1)
    }
    if (
      dataInventoryObjects &&
      dataInventoryObjects.data.entries % limitPage === 0
    ) {
      setCurrentPageInventory(currentPageInventoryObjects - 1)
    }
  }, [
    dataFixedAssets && dataFixedAssets.data.entries,
    dataInventoryObjects && dataInventoryObjects.data.entries,
  ])

  return (
    <>
      <Container
        fluid
        m={0}
        p={0}
        bg={backgroudColor}
        h={isMobile ? "100%" : "100vh"}
      >
        <Flex h={"100%"} direction={isTablet ? "column" : "row"}>
          <Navigationbar />
          <Flex
            direction={"column"}
            w="100%"
            h={isTablet ? "calc(100% - 4rem)" : "100%"}
            justify={
              noEntriesForFixedAssets || noEntriesInventoryObjects
                ? "flex-start"
                : "space-between"
            }
            gap="md"
          >
            <Flex justify={"space-between"} align="flex-end" h="3rem">
              <Title order={4} ml="md">
                Inventar(
                {tabInventary === InventoryTypes.INVENTORY_OBJECTS
                  ? dataInventoryObjects?.data.entries
                  : dataFixedAssets?.data.entries}
                )
              </Title>

              <Button
                variant="light"
                w={isMobile ? "auto" : "10rem"}
                mx="xs"
                radius="xl"
                size="xs"
                onClick={() => {
                  setShowModalAddItemInventory(true)
                }}
              >
                Adaugă în inventar
              </Button>
            </Flex>
            <Paper
              mx="md"
              radius="md"
              p="xs"
              h={isMobile ? "auto" : "calc(100% - 8rem)"}
            >
              <Tabs
                value={tabInventary}
                onTabChange={(tab: string) => {
                  setTabInventary(tab)
                  dispatch(resetCurrentPageInventory())
                }}
              >
                <Tabs.List grow>
                  <Tabs.Tab
                    style={{ width: "40%" }}
                    value={InventoryTypes.INVENTORY_OBJECTS}
                  >
                    Obiecte de inventar
                  </Tabs.Tab>
                  <Tabs.Tab
                    style={{ width: "40%" }}
                    value={InventoryTypes.FIXED_ASSETS}
                  >
                    Mijloace fixe
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value={tabInventary} pt="xs" h="100%">
                  <TabelItemInventory
                    dataInventory={dataInventory}
                    tabInventary={tabInventary}
                  />
                </Tabs.Panel>
              </Tabs>
            </Paper>
            <Pagination
              h="4rem"
              total={dataInventory && dataInventory.pages}
              value={
                tabInventary === InventoryTypes.FIXED_ASSETS
                  ? currentPageFixedAssets
                  : currentPageInventoryObjects
              }
              onChange={(e: number) => {
                setCurrentPageInventory(e)
                tabInventary === InventoryTypes.FIXED_ASSETS
                  ? dispatch(setCurrentPageFixedAssets(e))
                  : dispatch(setCurrentPageInventoryObjects(e))
              }}
              position="center"
              radius="xl"
              withEdges
            />
          </Flex>
        </Flex>

        <LoadingOverlay
          visible={
            isRefetchingFixedAssets || isRefetchinginvetoryObjects || isLoading
          }
        />
        <ErrorSuccesNotification />
      </Container>
      <ItemInventoryAddEdit
        isEditMode={false}
        isOpenModal={showModalAddItemInventory}
        setOpenModal={setShowModalAddItemInventory}
      />
    </>
  )
}
