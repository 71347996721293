import {
  Flex,
  LoadingOverlay,
  Pagination,
  Paper,
  ScrollArea,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { useEffect, useState } from "react"
import { limitPage, maxWidthMobile } from "../../utils/constants"
import { EmployeesTable } from "./employee-table/employees-tables"
import { EmployeesTableMobile } from "./employee-table/employees-table-mobile"
import { useGetEmployee } from "../../api/employee/use-get-employee"
import { useAppDispatch } from "../../redux/hooks"
import {
  setCountEmployee,
  setEmployee,
  setCurrentPageEmployee,
} from "../../redux/employee/employee-slice"
import { RootState } from "../../redux/store"
import { useSelector } from "react-redux"
import { reverseFormaterDiacritics } from "../../utils/strings"

export const GeneralTableComponentEmployees = () => {
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery(maxWidthMobile)
  const { currentPageEmployee } = useSelector(
    (state: RootState) => state.employees
  )
  const { data, isLoading, isRefetching, refetch } = useGetEmployee(
    currentPageEmployee,
    limitPage
  )
  const [nrOfEmployee, setNrOfEmployee] = useState(0)

  useEffect(() => {
    refetch()
  }, [currentPageEmployee])

  useEffect(() => {
    const newDataResponse = data?.data.response.map((item) => ({
      ...item,
      name: reverseFormaterDiacritics(item.name),
      role: reverseFormaterDiacritics(item.role),
    }))

    dispatch(setEmployee(newDataResponse))
    dispatch(setCountEmployee(data?.data.entries || 0))
    setNrOfEmployee(data?.data.entries || 0)
  }, [data])

  useEffect(() => {
    dispatch(setCurrentPageEmployee(1))
  }, [nrOfEmployee])

  return (
    <Flex h="calc(100% - 4rem)" direction={"column"} justify="space-between">
      {/*TODO: to be activated when will be the logic made */}
      {/*<TextInput*/}
      {/*  placeholder="Caută după denumirea angajatului"*/}
      {/*  type={"search"}*/}
      {/*  m="xs"*/}
      {/*  radius="md"*/}
      {/*  icon={<IconSearch size="0.9rem" stroke={1.5} />}*/}
      {/*/>*/}
      <Flex h="calc(100% - 5rem)" direction={"column"} justify="space-between">
        <Paper
          mx="md"
          p="md"
          mt={isMobile ? "xl" : 0}
          radius={"md"}
          w={"auto"}
          h={isMobile ? "auto" : "100%"}
        >
          <LoadingOverlay visible={isLoading || isRefetching} />
          <ScrollArea h={"100%"} type="auto" scrollbarSize={6}>
            {isMobile ? <EmployeesTableMobile /> : <EmployeesTable />}
          </ScrollArea>
        </Paper>
      </Flex>
      {nrOfEmployee === 0 ? null : (
        <Pagination
          h="4rem"
          total={data?.data.pages || 1}
          value={currentPageEmployee}
          onChange={(e: number) => dispatch(setCurrentPageEmployee(e))}
          position="center"
          radius="xl"
          withEdges
        />
      )}
    </Flex>
  )
}
