import {
  ActionIcon,
  Flex,
  LoadingOverlay,
  Pagination,
  Paper,
  ScrollArea,
  TextInput,
  Text,
  Image,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { useEffect, useState } from "react"
import {
  limitPage,
  maxWidthLaptopSmall,
  maxWidthMobile,
  regexSearch,
} from "../../utils/constants"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { TableWorkItemsMobile } from "./tables-work-items/table-work-items-mobile"
import { TableWorkItemsLaptopS } from "./tables-work-items/table-work-items-laptopS"
import { TableWorkItems } from "./tables-work-items/table-work-items"
import {
  setCurrentPageWorkItems,
  setTotalCount,
  setWorkItems,
} from "../../redux/workItems/worksItemsSlice"
import { useGetWorkItems } from "../../api/work-items/use-get-work-items"
import { IconSearch, IconX } from "@tabler/icons-react"
import { useGetEmployee } from "../../api/employee/use-get-employee"
import { setEmployee } from "../../redux/employee/employee-slice"
import { reverseFormaterDiacritics } from "../../utils/strings"

export const GeneralTableComponentWorkItem = () => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(maxWidthMobile)
  const isLaptopSmall = useMediaQuery(maxWidthLaptopSmall)
  const { currentPageWorkItems } = useSelector(
    (state: RootState) => state.workItems
  )
  const { totalCount } = useSelector((state: RootState) => state.workItems)
  const [searchValue, setSearchValue] = useState("")
  const { data: dataGetEmployee, refetch: refetchEmployee } = useGetEmployee()

  const {
    data,
    isLoading,
    isRefetching,
    refetch: refetchWorkItems,
  } = useGetWorkItems(currentPageWorkItems, limitPage, searchValue)

  useEffect(() => {
    const newDataResponse = data?.data.response.map((item) => ({
      ...item,
      name: reverseFormaterDiacritics(item.name),
      workItemType: reverseFormaterDiacritics(item.workItemType),
    }))

    dispatch(setWorkItems(newDataResponse))
    dispatch(setTotalCount(data?.data.entries || 0))
    if (searchValue.length > 0) {
      dispatch(setCurrentPageWorkItems(1))
    }
  }, [data])

  useEffect(() => {
    refetchWorkItems().then(() => refetchEmployee())
  }, [currentPageWorkItems])

  useEffect(() => {
    dispatch(setEmployee(dataGetEmployee?.data.response))
  }, [dataGetEmployee])

  useEffect(() => {
    if (searchValue === "") {
      refetchWorkItems()
    }
  }, [searchValue])

  useEffect(() => {
    dispatch(setCurrentPageWorkItems(1))
  }, [data?.data.entries])
  return (
    <Flex
      h="calc(100% - 3rem)"
      direction="column"
      justify={data && data.data.entries > 0 ? "space-between" : "flex-start"}
    >
      <Flex gap={0} justify="space-between" align={"center"} mx={"md"} h="4rem">
        <TextInput
          placeholder="Caută după client sau tipul lucrării"
          type="search"
          value={searchValue}
          w="100%"
          my="xs"
          mr="xs"
          radius="md"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              refetchWorkItems()
            }
          }}
          rightSection={
            searchValue.length > 0 ? (
              <ActionIcon
                variant={"transparent"}
                onClick={() => {
                  setSearchValue("")
                }}
              >
                <IconX size="1.2rem" color="#adb5bd" />
              </ActionIcon>
            ) : null
          }
          onChange={(e) =>
            setSearchValue(e.target.value.replace(regexSearch, ""))
          }
        />
        <ActionIcon
          variant={"light"}
          radius={"md"}
          color="#CEDDCA"
          onClick={() => {
            refetchWorkItems()
          }}
          size={"2rem"}
        >
          <IconSearch size="1.2rem" color="#4A6642" />
        </ActionIcon>
      </Flex>

      <Flex h="calc(100% - 8rem)" direction={"column"} justify="flex-start">
        <Paper
          mx="md"
          p="md"
          radius={"md"}
          w={"auto"}
          h={isMobile ? "auto" : "100%"}
        >
          <LoadingOverlay visible={isLoading || isRefetching} />
          <ScrollArea h="100%" type="auto" scrollbarSize={6}>
            {totalCount > 0 ? (
              isMobile ? (
                <TableWorkItemsMobile />
              ) : isLaptopSmall ? (
                <TableWorkItemsLaptopS />
              ) : (
                <TableWorkItems />
              )
            ) : searchValue.length > 0 && totalCount === 0 ? (
              <>
                <Text align={"center"} pt="lg">
                  Lucrarea/clientul {searchValue} nu a fost găsit!
                </Text>

                <Image
                  maw={450}
                  mx="auto"
                  radius="lg"
                  src="/No_data.png"
                  alt="No found client/workitem"
                />
              </>
            ) : (
              <>
                <Text align={"center"} pt="lg">
                  Nu aveți nici o lucrare adăugată!
                </Text>

                <Image
                  maw={550}
                  mx="auto"
                  radius="lg"
                  src="/noEntries.svg"
                  alt="No found workitems"
                />
              </>
            )}
          </ScrollArea>
        </Paper>
      </Flex>
      {totalCount === 0 && searchValue.length === 0 ? null : (
        <Pagination
          h="4rem"
          total={data?.data.pages || 1}
          value={currentPageWorkItems}
          onChange={(e: number) => dispatch(setCurrentPageWorkItems(e))}
          position="center"
          radius="xl"
          withEdges
        />
      )}
    </Flex>
  )
}
