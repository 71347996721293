export const initialValuesFormRegister = {
  email: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  companyName: "",
  CUI: "",
  usedAffiliateCode: "",
  acceptTerms: false,
  address: "",
  nrRegister: "",
}

export const validateRegister = {
  email: (value: string) =>
    /^\S+@\S+$/.test(value) ? null : "Adresa de e-mail invalidă.",
  password: (value: string) =>
    value.length > 5
      ? null
      : "Parola trebuie să conțină cel puțin 5 caractere.",
  firstName: (value: string) =>
    /^[a-zA-ZÎîȚțȘșăâ ]{3,}$/.test(value)
      ? null
      : "Numele trebuie să conțină doar litere și cel puțin 3 caractere.",
  lastName: (value: string) =>
    /^[a-zA-ZÎîȚțȘșăâ ]{3,}$/.test(value)
      ? null
      : "Prenumele trebuie să conțină doar litere și cel puțin 3 caractere.",
  confirmPassword: (value: string, values: any) =>
    value !== values.password ? "Parolele nu se potrivesc." : null,
  phoneNumber: (value: string) =>
    /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s\.|-)?([0-9]{3}(\s|\.|-|)){2}$/.test(
      value
    ) && value.length > 0
      ? null
      : "Numărul de telefon este invalid.",
  acceptTerms: (value: boolean) =>
    value ? null : "Trebuie să acepți termenii si condițiile.",
  companyName: (value: string) => {
    if (value) {
      if (/^[a-zA-Z0-9ÎîȚțȘșăâ\s\-&*@]{3,}$/.test(value)) return null
      else return "Numele companiei trebuie să conțină cel puțin 3 caractere."
    }
  },
  CUI: (value: string) =>
    value.length === 0 || /^[1-9]\d{0,8}$/.test(value)
      ? null
      : "Formatului CUI-ului nu este corect.",
  usedAffiliateCode: (value: string) => {
    if (value) {
      if (value.length > 2) return null
      else return "Formatului codului nu este corect."
    }
  },
}
