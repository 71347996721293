export const ocpi = [
  {
    oid: '10',
    name: 'Alba',
  },
  {
    oid: '29',
    name: 'Arad',
  },
  {
    oid: '38',
    name: 'Argeș',
  },
  {
    oid: '47',
    name: 'Bacău',
  },
  {
    oid: '56',
    name: 'Bihor',
  },
  {
    oid: '65',
    name: 'Bistrița-Năsăud',
  },
  {
    oid: '74',
    name: 'Botoșani',
  },
  {
    oid: '83',
    name: 'Brașov',
  },
  {
    oid: '92',
    name: 'Brăila',
  },
  {
    oid: '403',
    name: 'București',
  },
  {
    oid: '109',
    name: 'Buzău',
  },
  {
    oid: '118',
    name: 'Caraș-Severin',
  },
  {
    oid: '519',
    name: 'Călărași',
  },
  {
    oid: '127',
    name: 'Cluj',
  },
  {
    oid: '136',
    name: 'Constanța',
  },
  {
    oid: '145',
    name: 'Covasna',
  },
  {
    oid: '154',
    name: 'Dâmbovița',
  },
  {
    oid: '163',
    name: 'Dolj',
  },
  {
    oid: '172',
    name: 'Galați',
  },
  {
    oid: '528',
    name: 'Giurgiu',
  },
  {
    oid: '181',
    name: 'Gorj',
  },
  {
    oid: '190',
    name: 'Harghita',
  },
  {
    oid: '207',
    name: 'Hunedoara',
  },
  {
    oid: '216',
    name: 'Ialomița',
  },
  {
    oid: '225',
    name: 'Iași',
  },
  {
    oid: '234',
    name: 'Ilfov',
  },
  {
    oid: '243',
    name: 'Maramureș',
  },
  {
    oid: '252',
    name: 'Mehedinți',
  },
  {
    oid: '261',
    name: 'Mureș',
  },
  {
    oid: '270',
    name: 'Neamț',
  },
  {
    oid: '289',
    name: 'Olt',
  },
  {
    oid: '298',
    name: 'Prahova',
  },
  {
    oid: '305',
    name: 'Satu Mare',
  },
  {
    oid: '314',
    name: 'Sălaj',
  },
  {
    oid: '323',
    name: 'Sibiu',
  },
  {
    oid: '332',
    name: 'Suceava',
  },
  {
    oid: '341',
    name: 'Teleorman',
  },
  {
    oid: '350',
    name: 'Timiș',
  },
  {
    oid: '369',
    name: 'Tulcea',
  },
  {
    oid: '378',
    name: 'Vaslui',
  },
  {
    oid: '387',
    name: 'Vâlcea',
  },
  {
    oid: '396',
    name: 'Vrancea',
  },
]
