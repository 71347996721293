import { Button, Center, Loader, Menu, Text } from "@mantine/core"
import {
  IconChevronDown,
  IconEye,
  IconHttpDelete,
  IconCheck,
  IconEdit,
  IconFileCertificate,
  IconFileCheck,
  IconCircleCheck,
} from "@tabler/icons-react"
import { FunctionComponent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  limitPage,
  maxWidthTablet,
  Mode,
  RoutesOptions,
} from "../../../utils/constants"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../../redux/notification/slice"
import { GenericModal } from "../../generic-modal/generic-modal"
import { useDeleteWorkItems } from "../../../api/work-items/use-delete-work-items"
import { useGetWorkItems } from "../../../api/work-items/use-get-work-items"
import { ModalAsignResponsable } from "./modal-asign-responsable"
import { useAppSelector } from "../../../redux/hooks"
import {
  IConventionResponse,
  useGetConvention,
} from "../../../api/generate-convention/use-get-convention"
import { ModalCheckRGI } from "../../check-RGI/check-RGI"
import { downloadDoc } from "../../../utils/files"
import { useMediaQuery } from "@mantine/hooks"
import { usePutWorkItems } from "../../../api/work-items/use-put-work-items"
import { WorkItemStatuses } from "../../../utils/enum"

export const ActionsButtonWorkItem: FunctionComponent<{
  workItemUuid: string
  clientUuid: string
  statusWorkItem: string
}> = ({ workItemUuid, clientUuid, statusWorkItem }) => {
  const navigate = useNavigate()
  const isTablet = useMediaQuery(maxWidthTablet)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showModalConfimation, setShowModalConfirmation] = useState(false)
  const [showModalCheckRGI, setShowModalCheckRGI] = useState(false)
  const [showAttachedEmployeeModal, setShowAttacheEmployeeModal] =
    useState(false)
  const dispatch = useDispatch()
  const { currentPage } = useSelector((state: RootState) => state.pagination)
  const { employees } = useAppSelector((state: RootState) => state.employees)

  const { refetch } = useGetWorkItems(currentPage, limitPage)
  const [employeeEntries, setEmployeeEntries] = useState(employees)

  const succesCallBack = (data: { message: string }) => {
    setShowDeleteModal(false)
    refetch()
    dispatch(setMessageNotification(data.message))
  }

  useEffect(() => {
    setEmployeeEntries(employees)
  }, [employees])
  const errorCallBack = (error: any) => {
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu! ${error.response.data.message}`
      )
    )
  }
  const { mutate, isLoading: isLoadingDelete } = useDeleteWorkItems(
    workItemUuid as string,
    succesCallBack,
    errorCallBack
  )

  const onDeleteWorkItem = () => {
    mutate()
  }
  const succesCallBackUpdateStatus = (data: { message: string }) => {
    setShowModalConfirmation(false)
    refetch()
    dispatch(setMessageNotification(data.message))
  }

  const errorCallBackUpdateStatus = (error: any) => {
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu!${error.response.data.message}`
      )
    )
  }
  const { mutate: mutateUpdate } = usePutWorkItems(
    succesCallBackUpdateStatus,
    errorCallBackUpdateStatus,
    workItemUuid as string
  )
  const onUpdateStatusWorkItem = () => {
    mutateUpdate({ status: WorkItemStatuses.FINALIZAT })
  }

  const successConventionCallback = (response: IConventionResponse) => {
    let pdfResult = response.data.convention
    let pdfWindow = window.open("", "_blank")

    // @ts-ignore
    pdfWindow.document.write(` <html>
      <head>
        <title>CONVENȚIE</title>
        <style>
          body {
            margin: 0;
            overflow: hidden;
          }
          iframe {
            width: 100vw;
            height: 100vh;
            border: none;
          }
        </style>
      </head>
      <body>
        <iframe src="${pdfResult}"></iframe>
      </body>
    </html>`)
    if (isTablet) {
      downloadDoc({ filename: "conveție", content: pdfResult })
    }
  }

  const {
    data,
    refetch: refetchConvention,
    isFetching: conventionIsLoding,
  } = useGetConvention(workItemUuid, clientUuid, successConventionCallback)
  const onGenerateConvention = () => {
    refetchConvention()
  }

  return (
    <>
      {conventionIsLoding ? (
        <Loader />
      ) : (
        <Center>
          <Menu
            transitionProps={{ transition: "scale-y" }}
            position="bottom-end"
            width={220}
            withinPortal
          >
            <Menu.Target>
              <Button
                variant={"light"}
                size="xs"
                radius="xl"
                rightIcon={<IconChevronDown size="1.05rem" stroke={1.5} />}
                pr={12}
              >
                Acțiuni
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                icon={<IconEye size="1rem" stroke={1.5} />}
                onClick={() => {
                  workItemUuid &&
                    navigate(
                      RoutesOptions.WORKITEMS.concat("/").concat(
                        workItemUuid,
                        "/",
                        Mode.VIEW
                      )
                    )
                }}
              >
                <Text c="#3C4048" fw={500}>
                  Detalii lucrare
                </Text>
              </Menu.Item>
              <Menu.Item
                disabled={
                  statusWorkItem === WorkItemStatuses.FINALIZAT ? true : false
                }
                icon={<IconEdit size="1rem" stroke={1.5} />}
                onClick={() => {
                  workItemUuid &&
                    navigate(
                      RoutesOptions.WORKITEMS.concat("/").concat(
                        workItemUuid,
                        "/",
                        Mode.EDIT
                      )
                    )
                }}
              >
                <Text
                  c={
                    statusWorkItem === WorkItemStatuses.FINALIZAT
                      ? "#d1d6da"
                      : "#3C4048"
                  }
                  fw={500}
                >
                  Editare lucrare
                </Text>
              </Menu.Item>
              <Menu.Item
                disabled={
                  statusWorkItem === WorkItemStatuses.FINALIZAT ||
                  employeeEntries?.length === 0
                }
                icon={<IconCheck size="1rem" stroke={1.5} />}
                onClick={() => setShowAttacheEmployeeModal(true)}
              >
                <Text
                  c={
                    statusWorkItem === WorkItemStatuses.FINALIZAT ||
                    employeeEntries?.length === 0
                      ? "#d1d6da"
                      : "#3C4048"
                  }
                  fw={500}
                >
                  Atribuie responsabil
                </Text>
                {employeeEntries?.length === 0 ? (
                  <Text c={"#d1d6da"} size={10}>
                    Adaugă un angajat pentru a putea sa atribui responsabil.
                  </Text>
                ) : null}
              </Menu.Item>
              {/* TODO: will be visible (after fix the cors problems)  */}
              {/* <Menu.Item
                c="#3C4048"
                icon={<IconFileCertificate size="1rem" stroke={1.5} />}
                onClick={() => onGenerateConvention()}
                disabled
              >
                 <Text c={"#d1d6da"} fw={500}>
                 Generare conveție
                </Text>
                <Text c={"#d1d6da"} size={10}>
                  Funcționalitatea va fi prezentă curând.  
                </Text>
              </Menu.Item> */}
              {/* TODO: will be visible (after fix the cors problems)  */}
              {/* <Menu.Item
                icon={<IconFileCheck size="1rem" stroke={1.5} />}
                onClick={() => {
                  setShowModalCheckRGI(true)
                }}
              >
                <Text c="#3C4048" fw={500}>
                  Verificare RGI
                </Text>
              </Menu.Item> */}
              {/* TODO: rethink if we still need it */}
              {/* <Menu.Item
                disabled={
                  statusWorkItem === WorkItemStatuses.DRAFT ||
                  statusWorkItem === WorkItemStatuses.FINALIZAT
                    ? true
                    : false
                }
                icon={<IconCircleCheck size="1rem" stroke={1.5} />}
                onClick={() => {
                  setShowModalConfirmation(true)
                }}
              >
                <Text
                  c={
                    statusWorkItem === WorkItemStatuses.DRAFT ||
                    statusWorkItem === WorkItemStatuses.FINALIZAT
                      ? "#d1d6da"
                      : "#3C4048"
                  }
                  fw={500}
                >
                  Finalizare lucrare
                </Text>
              </Menu.Item> */}
              <Menu.Item
                icon={<IconHttpDelete size="1rem" stroke={1.5} />}
                onClick={() => setShowDeleteModal(true)}
              >
                <Text c="#3C4048" fw={500}>
                  Ștergere
                </Text>
              </Menu.Item>
            </Menu.Dropdown>

            <GenericModal
              setOpenModal={setShowDeleteModal}
              mainActionText={"Ștergere lucrare"}
              title={"Urmează să ștergi o lucrare"}
              subTitle={"Ești sigur(ă) că vrei să ștergi lucrarea selectată?"}
              isOpenModal={showDeleteModal}
              onHandle={onDeleteWorkItem}
              isLoading={isLoadingDelete}
            />
            {/* TODO: rethink if we still need it */}
            {/* <GenericModal
              setOpenModal={setShowModalConfirmation}
              mainActionText={"Finalizare lucrare"}
              title={"Urmează să finalizez o lucrare"}
              subTitle={
                "Ești sigur(ă) că vrei să finalizezi lucrarea selectată?"
              }
              isOpenModal={showModalConfimation}
              onHandle={onUpdateStatusWorkItem}
              isLoading={isLoadingUpdateSatus}
            /> */}
            <ModalAsignResponsable
              isOpen={showAttachedEmployeeModal}
              setIsOpen={setShowAttacheEmployeeModal}
              workItemUuid={workItemUuid}
            />
            <ModalCheckRGI
              isOpenModal={showModalCheckRGI}
              setOpenModal={setShowModalCheckRGI}
              workItemUuid={workItemUuid}
            />
          </Menu>
        </Center>
      )}
    </>
  )
}
