export const convertDateTOStringFormatForBacEnd = (date: string) => {
  return new Date(date).toLocaleString("ro-RO", {
    timeZone: "Europe/Bucharest",
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}

export const convertStringFormatForBackendToDate = (dateStr: string | null) => {
  if (dateStr) {
    const monthMap: { [key: string]: string } = {
      ianuarie: "01",
      februarie: "02",
      martie: "03",
      aprilie: "04",
      mai: "05",
      iunie: "06",
      iulie: "07",
      august: "08",
      septembrie: "09",
      octombrie: "10",
      noiembrie: "11",
      decembrie: "12",
    }

    const [dayOfWeek, day, monthStr, year] = dateStr && dateStr.split(" ")

    const date = new Date(`${year}-${monthMap[monthStr]}-${day}`)
    return date
  } else return null
}

export const replaceDiacritics = (text: string) => {
  return text
    .replace(/[ȘŞ]/g, "S")
    .replace(/[șş]/g, "s")
    .replace(/[ȚŢ]/g, "T")
    .replace(/[țţ]/g, "t")
    .replace(/[ĂÂÃ]/g, "A")
    .replace(/[ăâã]/g, "a")
    .replace(/[Î]/g, "I")
    .replace(/[î]/g, "i")
}
