import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
  breadcrumbs: {
    width: '100%',
  },
  separator: {
    fontWeight: 800,
    color: theme.colors.brand[6],
  },

  breadcrumbsLink: {
    fontWeight: 800,
    fontSize: 18,
    textDecoration: 'none',
    color: theme.colors.brand[6],
  },
}))
