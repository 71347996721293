import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import queryString from "query-string"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setErrorNotification } from "../../redux/notification/slice"
import { IClientsResponse } from "../../types/client-types"
import { clientsUrl, RoutesOptions } from "../../utils/constants"
import { getGenericErrorMessage } from "../../utils/error-messages"

export const useGetClients = (
  page?: number,
  limit?: number,
  search?: string
) => {
  const authToken = sessionStorage.getItem("authToken")
  const userUuid = sessionStorage.getItem("uuid")
  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }

  const query = search
    ? queryString.stringify({ page, limit, userUuid, search })
    : queryString.stringify({ page, limit, userUuid })
  const clientsLimitPages: string = `${clientsUrl}?${query}`
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useQuery(
    ["read-clients-key"],
    () => axios.get<IClientsResponse>(clientsLimitPages, config),
    {
      onSuccess: (data: IClientsResponse) => {
        return data
      },
      onError: (error: any) => {
        if ([401].includes(error.response.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(
            setErrorNotification(
              getGenericErrorMessage(error.response.data.message)
            )
          )
        }
      },
      retry: false,
      enabled: false,
    }
  )
}
