import { Image, Table, Text } from "@mantine/core"
import React, { FunctionComponent } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { IWorkItem } from "../../../types/work-item-types"
import { TableWorkItemsFields } from "../../../utils/enum"
import { renderRightControl } from "../../clients-page/utils-clients-page/utils-edit-client"
import { ActionsButtonWorkItem } from "../utils-work-items/actions-button-work-items"
import { useStyles } from "../utils-work-items/table-work.styles"
import { contentCurrentRow } from "../utils-work-items/utils-table-work-items"

export const TableWorkItemsLaptopS: FunctionComponent = () => {
  const { classes } = useStyles()
  const { workItemsEntriesData } = useSelector(
    (state: RootState) => state.workItems
  )
  const rows =
    workItemsEntriesData !== undefined &&
    workItemsEntriesData.map((workItem: IWorkItem) => {
      const cellsTable = [
        [workItem.workItemType, TableWorkItemsFields.WORK_ITEM_TYPE],
        [workItem.county, TableWorkItemsFields.COUNTY],
        [workItem.status, TableWorkItemsFields.STATUS],
        [workItem.deadline, TableWorkItemsFields.DEADLINE],
        [workItem.responsable, TableWorkItemsFields.RESPONSABLE],
        [workItem.createDate, TableWorkItemsFields.CREATED_DATE],
        [workItem.lastUpdateDate, TableWorkItemsFields.LAST_UPDATED_DATE],
      ]

      return (
        <tr key={workItem.workItemUuid}>
          <td style={{ width: "10rem" }}>
            {renderRightControl(workItem.name)}
          </td>
          <td>
            {cellsTable.map((field: any) =>
              contentCurrentRow(field[0], field[1])
            )}
          </td>
          <td>
            <ActionsButtonWorkItem
              workItemUuid={workItem.workItemUuid}
              clientUuid={workItem.clientUuid || ""}
              statusWorkItem={workItem.status}
            />
          </td>
        </tr>
      )
    })
  return (
    <>
      {workItemsEntriesData && workItemsEntriesData.length ? (
        <Table w={"100%"}>
          <thead className={classes.header}>
            <tr>
              <th>Client</th>
              <th>Detalii lucrare</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      ) : (
        <>
          <Text align={"center"} pt="md">
            Nu aveți nici o lucrare adăugată!
          </Text>

          <Image
            maw={550}
            mx="auto"
            radius="lg"
            src="/add_workitem.svg"
            alt="Add workitem"
          />
        </>
      )}
    </>
  )
}
