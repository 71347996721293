import { IWorkItem } from "../../../types/work-item-types"
import { standardCurrency } from "../../../utils/constants"
import { convertStringFormatForBackendToDate } from "../../../utils/dates"
import {
  diacriticsCorrector,
  reverseFormaterDiacritics,
} from "../../../utils/strings"

export const reinitialValuesWorkItem = (response: IWorkItem) => {
  const {
    workItemUuid,
    name,
    clientUuid,
    createDate,
    lastUpdateDate,
    workItemType,
    responsable,
    county,
    city,
    status,
    deadline,
    otherDocs,
    currency,
    advancePayment,
    restPayment,
    totalPayment,
    taxes,
    userUuid,
    address,
    amount,
    calcFormula,
  } = response

  const valuesWorkItem = {
    workItemUuid,
    name: reverseFormaterDiacritics(name),
    clientUuid,
    createDate: diacriticsCorrector(createDate),
    lastUpdateDate: diacriticsCorrector(lastUpdateDate),
    workItemType: reverseFormaterDiacritics(workItemType),
    responsable: reverseFormaterDiacritics(responsable),
    county,
    city,
    status: reverseFormaterDiacritics(status),
    deadline: convertStringFormatForBackendToDate(deadline ? deadline : null),
    otherDocs,
    currency,
    advancePayment,
    restPayment,
    totalPayment,
    taxes,
    userUuid,
    address: reverseFormaterDiacritics(address),
    calcFormula: calcFormula,
    amount: amount,
  }

  return valuesWorkItem
}
export const initialValuesAddWorkItem: IWorkItem = {
  workItemUuid: "",
  name: "",
  clientUuid: "",
  createDate: "",
  lastUpdateDate: "",
  workItemType: "",
  responsable: "-",
  county: "",
  city: "",
  status: '{"label":"DRAFT","color":"#ceddca"}',
  deadline: null,
  otherDocs: [
    {
      filename: "",
      content: "",
    },
  ],
  currency: standardCurrency,
  advancePayment: 0,
  restPayment: 0,
  totalPayment: 0,
  taxes: 0,
  userUuid: "",
  address: "",
  calcFormula: "",
  amount: "",
}

export const validateFormWorkItem = {
  workItemType: (value: string) =>
    value ? null : "Nu uita să adaugi tipul lucrării.",

  county: (value: string) =>
    value && value.length > 2
      ? null
      : "Județul trebuie să conțină cel puțin 3 caractere. ",
  totalPayment: (value: number) =>
    value > 0 ? null : "Totalul de plată nu poate fi negativ sau 0. ",
  restPayment: (value: number) =>
    value >= 0 ? null : "Restul de plată nu poate fi negativ. ",
  deadline: (value: string) =>
    value === null ? "Trebuie să alegi un termen limită!" : null,
}
