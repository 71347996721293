import {
  Badge,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  TextInput,
  Text,
  ActionIcon,
  rem,
  ScrollArea,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconX } from "@tabler/icons-react"
import _ from "lodash"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useGetUser } from "../../api/user/use-get-user"
import { usePutUser } from "../../api/user/use-put-user"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"

import { maxWidthLaptopSmall, maxWidthMobile } from "../../utils/constants"
import { getGenericErrorMessage } from "../../utils/error-messages"
import { useStyles } from "./customize-page.styles"
import { duplicateLabel, panelWidth } from "./customize-page.utils"
import { DividerVerticalHorizontal } from "./shared-component/component-divider-vertical-horizontal"
import { ComponentNoItems } from "./shared-component/component-no-items"

export const TypeWorkitemPanel: FunctionComponent<{
  workitemsTypesState: string[]
  setWorkitemsTypes: React.Dispatch<React.SetStateAction<string[]>>
  isModal: boolean
  setActiveButtons?: React.Dispatch<React.SetStateAction<boolean>>
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  workitemsTypesState,
  setWorkitemsTypes,
  setActiveButtons,
  isModal,
  setOpenModal,
}) => {
  const { classes } = useStyles()
  const [valueWorkitemType, setValueWorkitemType] = useState("")
  const [duplicateMessage, setDuplicateMessage] = useState("")
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isMobile = useMediaQuery(maxWidthMobile)
  const dispatch = useDispatch()
  const { data, refetch } = useGetUser()
  const userDataResponse = data && data.data.response
  const hasNoItems = !isModal && !workitemsTypesState.length
  const labelAreaWidth = isMobile ? "100%" : isLaptopS ? "45%" : "60%"

  const succesCallBackPut = (data: { message: string }) => {
    dispatch(setMessageNotification(data.message))
    refetch()
    setOpenModal && setOpenModal(false)
  }

  const errorCallBackPut = (error: any) => {
    setErrorNotification(getGenericErrorMessage(error.response.data.message))
  }

  const { mutate: mutateUpdate, isLoading: isPutLoading } = usePutUser(
    succesCallBackPut,
    errorCallBackPut
  )

  const handleAddTypeWorkitem = () => {
    if (userDataResponse) {
      const workitemsTypesArray = userDataResponse.workItemsTypes
        ? userDataResponse.workItemsTypes.split(",")
        : []
      if (isModal) {
        if (
          duplicateLabel(valueWorkitemType, workitemsTypesArray, "typeWorkitem")
        ) {
          setDuplicateMessage("Există deja acest tip de lucrare.")
        } else {
          const newWorkitemTypes = workitemsTypesArray
            ? _.cloneDeep(workitemsTypesArray)
            : []
          newWorkitemTypes.push(valueWorkitemType)
          mutateUpdate({
            ...userDataResponse,
            workItemsTypes: newWorkitemTypes,
          })
        }
      } else {
        if (
          duplicateLabel(valueWorkitemType, workitemsTypesState, "typeWorkitem")
        ) {
          setDuplicateMessage("Există deja acest tip de lucrare.")
        } else {
          const newWorkitemTypes = [...workitemsTypesState, valueWorkitemType]
          setWorkitemsTypes(newWorkitemTypes)
          setValueWorkitemType("")
          setActiveButtons && setActiveButtons(true)
        }
      }
    }
  }
  useEffect(() => {
    workitemsTypesState && setValueWorkitemType(workitemsTypesState.toString())
  }, [isModal])

  const removeButton = (labelBadge: string) => (
    <ActionIcon
      size="xs"
      radius="xl"
      color="brand.5"
      variant="transparent"
      onClick={() => handleDeleteWorkitemBadge(labelBadge)}
    >
      <IconX size={rem(10)} />
    </ActionIcon>
  )
  const handleDeleteWorkitemBadge = (labelBadge: string) => {
    const filterWorkintemType = workitemsTypesState.filter(
      (a) => a !== labelBadge
    )
    setWorkitemsTypes(filterWorkintemType)
    setActiveButtons && setActiveButtons(true)
  }
  useEffect(() => {
    setDuplicateMessage("")
  }, [valueWorkitemType === ""])

  return (
    <Flex w="100%" direction="column">
      <Text pl="1rem">Personalizare tipuri de lucrări</Text>
      <Flex
        p="1rem"
        mb="1rem"
        gap="2rem"
        direction={isMobile ? "column" : "row"}
        align={hasNoItems ? "center" : "flex-start"}
      >
        <Stack align="left" spacing={10} w={isModal ? "100%" : panelWidth()}>
          <TextInput
            label="Tipul lucrării"
            placeholder="Introduceți denumirea noului tip de lucrare"
            value={valueWorkitemType}
            onChange={(e) =>
              setValueWorkitemType(e.currentTarget.value.toLocaleUpperCase())
            }
            error={duplicateMessage ? duplicateMessage : null}
          />

          <Button
            size="xs"
            radius="xl"
            onClick={handleAddTypeWorkitem}
            disabled={valueWorkitemType.length > 0 ? false : true}
            loading={isPutLoading}
          >
            Adaugă
          </Button>
        </Stack>

        {isModal ? null : (
          <>
            <DividerVerticalHorizontal />
            {hasNoItems ? (
              <ComponentNoItems
                text={"Nu există tipuri de lucrări personalizate."}
              />
            ) : (
              <ScrollArea
                h={"100px"}
                type="scroll"
                offsetScrollbars
                scrollbarSize={6}
                styles={{ root: { width: labelAreaWidth } }}
              >
                <SimpleGrid cols={isLaptopS ? 2 : 4} w={"100%"}>
                  {workitemsTypesState.map(
                    (workitemType: string, index: number) => (
                      <Badge
                        key={index}
                        variant="light"
                        w="100%"
                        pr={3}
                        h="2rem"
                        rightSection={removeButton(workitemType)}
                        className={classes.badge}
                      >
                        {workitemType}
                      </Badge>
                    )
                  )}
                </SimpleGrid>
              </ScrollArea>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}
