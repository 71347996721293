import React, { useEffect, useState } from "react"
import type { PDFDocumentProxy } from "pdfjs-dist"
import { Document, Page, pdfjs } from "react-pdf"
import { IUserResponse } from "../../types/user-types"
import { formatterDate } from "../account-details/utils-account-details"
import { useNavigate } from "react-router-dom"
import { limitPage, RoutesOptions } from "../../utils/constants"
import { useGetUser } from "../../api/user/use-get-user"
import { useGetProfile } from "../../api/profile/use-get-profile"
import { LoadingOverlay } from "@mantine/core"
import { useGetWorkItemsDetails } from "../../api/work-items/use-get-work-items"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js"

const options = {
  cMapUrl: "cmaps/",
  standardFontDataUrl: "standard_fonts/",
}
export const DisplayPdfPage = () => {
  const navigate = useNavigate()
  const [numPages, setNumPages] = useState(1)
  const [fileContentForView, setFileContentForView] = useState("")
  const namePDF = window.location.href.split("/")[5].replaceAll("%20", " ")
  const pathPDF = window.location.href.split("/")[4]
  const workitemUuid = namePDF.split("***")[0]
  const { currentPage } = useSelector((state: RootState) => state.pagination)

  const succesCallBack = (data: IUserResponse["data"]) => {
    if (formatterDate(data.response.subscriptionExpireDate) < new Date()) {
      navigate(RoutesOptions.TRIAL_OVER)
    }
  }
  const { data: dataProfile, refetch: refetchProfile } = useGetProfile()
  const { data: dataWorkItemsDetails, refetch: refetchWorkItemsDetails } =
    useGetWorkItemsDetails(currentPage, limitPage, workitemUuid)
  const { refetch: refetchUser } = useGetUser(succesCallBack)

  useEffect(() => {
    refetchUser()
    if (pathPDF === "profil") refetchProfile()
    else refetchWorkItemsDetails()
  }, [])

  useEffect(() => {
    if (pathPDF === "workitem") {
      const arrayWorkiItems =
        dataWorkItemsDetails &&
        (dataWorkItemsDetails.data.response.otherDocs as [])

      arrayWorkiItems &&
        arrayWorkiItems.forEach((e: any) => {
          if (e.filename === namePDF) setFileContentForView(e.content)
        })
    } else {
      const pdf =
        dataProfile &&
        dataProfile.data.response.value.find(
          (e: any) => e.content.filename === namePDF
        )
      setFileContentForView(pdf ? pdf.content.content : "")
    }
  }, [dataProfile, dataWorkItemsDetails])

  const onDocumentLoadSuccess = ({
    numPages: nextNumPages,
  }: PDFDocumentProxy) => {
    setNumPages(nextNumPages)
  }
  return (
    <>
      <Document
        file={fileContentForView}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        {Array.from(new Array(numPages), (_el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
      <LoadingOverlay visible={fileContentForView ? false : true} />
    </>
  )
}
