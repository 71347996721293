import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'

const initialState: {
  currentPage: number
} = {
  currentPage: 1,
}

export const paginationSlice = createSlice({
  name: 'pagination_slice',
  initialState: initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    resetCurrentPage: (state) => {
      state.currentPage = initialState.currentPage
    },
  },
})
export const { setCurrentPage, resetCurrentPage } = paginationSlice.actions
export const selectorActivePage = (state: RootState) => {
  return state.pagination
}
export default paginationSlice.reducer
