import { Button, Center, Menu, Text } from "@mantine/core"
import { IconChevronDown, IconHttpDelete, IconEdit } from "@tabler/icons-react"
import { FunctionComponent, useState } from "react"
import { limitPage } from "../../../utils/constants"
import { useDispatch, useSelector } from "react-redux"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../../redux/notification/slice"
import { GenericModal } from "../../generic-modal/generic-modal"
import { ItemInventoryAddEdit } from "../item-inventory-add-edit"
import { useDeleteInventory } from "../../../api/inventory/use-delete-item-invetory"
import { useGetInventory } from "../../../api/inventory/use-get-inventory"
import { RootState } from "../../../redux/store"
import { resetCurrentPageInventory } from "../../../redux/item-inventory/item-inventory-slice"
import { IItemInventory } from "../../../types/items-inventory"
import { InventoryTypes } from "../../../utils/enum"

export const ActionsButtonItemsIventory: FunctionComponent<{
  itemUuid: string
  dataInventoryItem: IItemInventory
}> = ({ itemUuid, dataInventoryItem }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showModalEditItemInventory, setShowModalEditItemInventory] =
    useState(false)
  const { currentPageFixedAssets, currentPageInventoryObjects } = useSelector(
    (state: RootState) => state.itemsInventory
  )
  const dispatch = useDispatch()
  const { refetch: refetchFixedAssets } = useGetInventory(
    currentPageFixedAssets,
    limitPage,
    InventoryTypes.FIXED_ASSETS
  )
  const { refetch: refetchInventoryObjects } = useGetInventory(
    currentPageInventoryObjects,
    limitPage,
    InventoryTypes.INVENTORY_OBJECTS
  )
  const succesCallBack = (data: { message: string }) => {
    dispatch(setMessageNotification(data.message))
    dispatch(resetCurrentPageInventory())
    refetchInventoryObjects()
    refetchFixedAssets()
    setShowDeleteModal(false)
  }

  const errorCallBack = (error: any) => {
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu! ${error.response.data.message}`
      )
    )
  }
  const { mutate, isLoading: isLoadingDelete } = useDeleteInventory(
    itemUuid as string,
    dataInventoryItem.type,
    succesCallBack,
    errorCallBack
  )
  const onDeletItemInventory = () => {
    mutate()
  }

  return (
    <>
      <Center>
        <Menu
          transitionProps={{ transition: "scale-y" }}
          position="bottom-end"
          width={220}
          withinPortal
        >
          <Menu.Target>
            <Button
              variant={"light"}
              size="xs"
              radius="xl"
              rightIcon={<IconChevronDown size="1.05rem" stroke={1.5} />}
              pr={12}
            >
              Acțiuni
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<IconEdit size="1rem" stroke={1.5} />}
              onClick={() => {
                setShowModalEditItemInventory(true)
              }}
            >
              <Text c="#3C4048" fw={500}>
                Editare
              </Text>
            </Menu.Item>

            <Menu.Item
              icon={<IconHttpDelete size="1rem" stroke={1.5} />}
              onClick={() => setShowDeleteModal(true)}
            >
              <Text c="#3C4048" fw={500}>
                Ștergere
              </Text>
            </Menu.Item>
          </Menu.Dropdown>

          <GenericModal
            setOpenModal={setShowDeleteModal}
            mainActionText={`Șterge`}
            title={`Urmează să ștergi un obiectul de inventar ${dataInventoryItem.name}.`}
            subTitle={`Ești sigur(ă) că vrei să ștergi obiectul de inventar ${dataInventoryItem.name} ?`}
            isOpenModal={showDeleteModal}
            onHandle={onDeletItemInventory}
            isLoading={isLoadingDelete}
          />
          <ItemInventoryAddEdit
            isEditMode={true}
            isOpenModal={showModalEditItemInventory}
            setOpenModal={setShowModalEditItemInventory}
            itemInventoryDetails={dataInventoryItem}
          />
        </Menu>
      </Center>
    </>
  )
}
