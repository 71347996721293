import { Flex, Group, Text } from "@mantine/core"
import { ActionsButton } from "./actions-button"
import { renderRightControl } from "./utils-edit-client"
import { IClient } from "../../../types/client-types"
import { ClientsFields, ClientTypes, TableColumns } from "../../../utils/enum"
import {
  maxWidthMobile,
  maxWidthMobileMedium,
  maxWidthMobileSmall,
} from "../../../utils/constants"
import { useMediaQuery } from "@mantine/hooks"
import { FunctionComponent } from "react"
import { reverseFormaterDiacritics } from "../../../utils/strings"

export const rowsClientsDesktop = (clientsEntriesData: IClient[]) =>
  clientsEntriesData &&
  clientsEntriesData.map((client: IClient) => {
    const cellsTable = [
      client.typeClient === ClientTypes.LEGAL_CLIENT
        ? client.fiscalCode
        : client.CNP,
      client.phoneNumber,
      client.email,
      client.createDate,
      client.lastUpdateDate,
    ]
    return (
      <tr key={client.clientUuid}>
        <td>
          <Text fw={300}>{reverseFormaterDiacritics(client.name)}</Text>
        </td>
        {cellsTable.map((field: string | number, index: number) => (
          <td key={index}>
            {renderRightControl( field)}
          </td>
        ))}
        <td>
          <ActionsButton clientUuid={client.clientUuid} />
        </td>
      </tr>
    )
  })
//================== CLIENTS LAPTOP S UTILS ================================
const contentCurrentRowClientsLaptopS = (
  fieldValue: string,
  field: keyof typeof TableColumns
) => {
  return (
    <Group position="apart" spacing={0} key={field}>
      <Text c="#3C4048" fw={500} w="9rem">
        {TableColumns[field]}:
      </Text>
      {renderRightControl(fieldValue)}
    </Group>
  )
}
export const rowsClientsLaptopS = (clientsEntriesData: IClient[]) =>
  clientsEntriesData &&
  clientsEntriesData.map((client: IClient) => {
    const cellsTable = [
      client.typeClient === ClientTypes.LEGAL_CLIENT
        ? [client.fiscalCode, ClientsFields.CUI]
        : [client.CNP, ClientsFields.CNP],
      [client.phoneNumber, ClientsFields.PHONE],
      [client.email, ClientsFields.EMAIL],
      [client.createDate, ClientsFields.CREATED_DATE],
      [client.lastUpdateDate, ClientsFields.LAST_UPDATED_DATE],
    ]

    return (
      <tr key={client.clientUuid}>
        <td>
          <Text w={"10rem"}>{reverseFormaterDiacritics(client.name)}</Text>
        </td>

        <td>
          <Flex direction={"column"}>
            {cellsTable.map((field: any) =>
              contentCurrentRowClientsLaptopS(
                 field[0],
                field[1]
              )
            )}

            {/*  TODO: Add workItemsNumber
            {contentCurrentRow(
              String(client.workItemsNumber),
              ClientsFields.WORK_ITEMS
            )} */}
          </Flex>
        </td>
        <td>
          <ActionsButton clientUuid={client.clientUuid} />
        </td>
      </tr>
    )
  })
//================== END CLIENTS LAPTOP S UTILS ================================

//================== CLIENTS MOBILE UTILS ================================
export const ContentCurrentClientRowMobile: FunctionComponent<{
  fieldValue: string
  field: keyof typeof TableColumns
}> = ({ fieldValue, field }) => {
  const isMobileM = useMediaQuery(maxWidthMobileMedium)
  return (
    <Flex
      justify="space-between"
      gap={0}
      direction={isMobileM ? "column" : "row"}
      mb={5}
      key={field + "-id"}
    >
      <Text c="#3C4048" fw={500}>
        {TableColumns[field]}:
      </Text>
      <Text> {renderRightControl(fieldValue)}</Text>
    </Flex>
  )
}
export const RowsClientsMobile: FunctionComponent<{
  clientsEntriesData: IClient[]
}> = ({ clientsEntriesData }) => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const isMobileS = useMediaQuery(maxWidthMobileSmall)
  return (
    <>
      {clientsEntriesData &&
        clientsEntriesData.map((client: IClient) => {
          const cellsTable = [
            client.typeClient === ClientTypes.LEGAL_CLIENT
              ? [client.fiscalCode, ClientsFields.CUI]
              : [client.CNP, ClientsFields.CNP],
            [client.phoneNumber, ClientsFields.PHONE],
            [client.email, ClientsFields.EMAIL],
            [client.createDate, ClientsFields.CREATED_DATE],
            [client.lastUpdateDate, ClientsFields.LAST_UPDATED_DATE],
          ]
          return (
            <tr key={client.clientUuid}>
              <td>
                <Flex direction={"column"} w="100%" h="100%">
                  <Group position="apart" mb={10}>
                    <Text
                      component="span"
                      w={isMobileS ? "7.5rem" : isMobile ? "10rem" : "auto"}
                      size={"md"}
                    >
                      {reverseFormaterDiacritics(client.name)}
                    </Text>

                    <ActionsButton clientUuid={client.clientUuid} />
                  </Group>
                  {cellsTable.map((field: any, index: number) => (
                    <ContentCurrentClientRowMobile
                      key={index}
                      fieldValue={ field[0]}
                      field={field[1]}
                    />
                  ))}
                </Flex>
              </td>
            </tr>
          )
        })}
    </>
  )
}

//==================END  CLIENTS MOBILE UTILS ================================
