// IN PROD we use only official BE link, not localhost
export const baseUrl: string = process.env.REACT_APP_BACKEND_URL as string
export const loginUrl = baseUrl.concat("/login")
export const registerUrl = baseUrl.concat("/register")
export const clientsUrl = baseUrl.concat("/clients")
export const userUrl = baseUrl.concat("/user")
export const userPaymentUrl = baseUrl.concat("/user/payment")
export const paymentIpcNotifyUrl = baseUrl.concat("/client/ipcNotify")
export const clientsUrlCreate = baseUrl.concat("/clients/new")
export const clientsUrlUpdate = baseUrl.concat("/clients/update")
export const clientsUrlDelete = baseUrl.concat("/clients/delete")

export const workItemsUrl = baseUrl.concat("/workitems")
export const workItemsUrlDetails = baseUrl.concat("/workitems/details")
export const workItemsUrlCreate = baseUrl.concat("/workitems/new")
export const workItemsUrlUpdate = baseUrl.concat("/workitems/update")
export const workItemsUrlDelete = baseUrl.concat("/workitems/delete")

export const employeesUrl = baseUrl.concat("/employees")
export const employeesUrlCreate = baseUrl.concat("/employees/new")
export const employeesUrlUpdate = baseUrl.concat("/employees/update")
export const employeesUrlDelete = baseUrl.concat("/employees/delete")

export const assignResponsableUrl = baseUrl.concat("/workitems/assign")
export const conventionUrl = baseUrl.concat("/workitems/convention")
export const uploadFilesUrl = baseUrl.concat("/upload?")

export const inventoryUrl = baseUrl.concat("/inventory")
export const inventoryUrlCreate = baseUrl.concat("/inventory/new")
export const inventoryUrlUpdate = baseUrl.concat("/inventory/update")
export const inventoryUrlDelete = baseUrl.concat("/inventory/delete")

export const confirmEmailUrl = baseUrl.concat("/confirmemail")
export const changePasswordUrl = baseUrl.concat("/changepassword")
export const resetPasswordUrl = baseUrl.concat("/resetpassword")

export const ancpiUrl = "https://www.ancpi.ro/aplicatii/urmarireCerereRGI/data/"
export const checkRGIUrl =
  "https://www.ancpi.ro/aplicatii/urmarireCerereRGI/apptrack.php"
export const profileUrlCreate = baseUrl.concat("/profile/new")
export const profileUrlUpdate = baseUrl.concat("/profile/update")
export const profileUrl = baseUrl.concat("/profile")

export enum RoutesOptions {
  LOGIN = "/login",
  ROOT = "/",
  CLIENTS = "/clients",
  WORKITEMS = "/workitem",
  WORKITEMS_DETAILS = "/workitem/:workItemUuid/",
  EMPLOYEES = "/employees",
  INVENTORY = "/inventory",
  PROFILE = "/profil",
  REGISTER = "/register",
  FORGET_PASSWORD = "/resetpassword",
  CHANGE_PASSWORD = "/reset/:userUuid/:authToken",
  PAYMENT = "/payment",
  LOGOUT = "/logout",
  TRIAL_OVER = "/subscribe",
  ACCOUNT_DETAILS = "/account-details",
  EMAIL_CONFIRMATION = "/:userUuid/confirmation",
  SUPPORT = "/support",
  CUSTOMIZE = "/customize",
  DISPLAY_PDF = "/pdf/:path/:namePDF",
}
export enum Mode {
  VIEW = "view",
  EDIT = "edit",
  ADD = "add",
}

export const maxWidthTablet = "(max-width: 62em)"
export const maxWidthLaptopSmall = "(max-width: 80em)"
export const maxWidthMobile = "(max-width: 39em)"
export const maxWidthMobileSmall = "(max-width: 23em)"
export const maxWidthMobileMedium = "(max-width: 26em)"
export const minWidthLaptop = "(min-width: 100em)"
export const regexSearch = /[^\w ]/g

export const StatusDocumentsEnum = {
  MISSING: "Lipsă",
  RECEIVED: "Primit",
}

export const limitPage = 5
export const convertEurToRon = 4.95
export const standardCurrency = "RON"
export const pdfNeincarcat = `${window.document.location.origin}/fisierNeincarcat.pdf`
export const yearsRGI = [
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
]
export const colorSwatches = [
  "#25262b",
  "#868e96",
  "#fa5252",
  "#e64980",
  "#be4bdb",
  "#7950f2",
  "#4c6ef5",
  "#228be6",
  "#8ab1de",
  "#15aabf",
  "#4a6642",
  "#8aa188",
  "#12b886",
  "#40c057",
  "#82c91e",
  "#ceddca",
  "#f4f3ee",
  "#fcc419",
  "#fab005",
  "#fd7e14",
]

export const backgroudColor = "#f4f3ee"
export const colorBlack = "#000000"
export const colorWhite = "#fff"

export const GRAPHQL_API = "https://targetare.ro/api/graphql"
export const GET_COMPANY_INFO_QUERY = `
query AutocompleteQuery($text: String) {
  autocomplete(text: $text) {
    companyId
    companyName
    locality
    county
    streetName
    streetNr
    block
    VAT
    staircase
    apartment
    taxId
    county
    status
    __typename
  }
}
`
