import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  TextInput,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import _ from "lodash"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { usePostRGI } from "../../api/utils-api/use-ancpi"
import { setErrorNotification } from "../../redux/notification/slice"
import { RootState } from "../../redux/store"
import { IBCPI, ICheckRGI, IOCPI } from "../../types/check-rgi-types"
import { bcpi } from "../../utils/bcpi"
import { maxWidthMobile, yearsRGI } from "../../utils/constants"
import { ocpi } from "../../utils/ocpi"
import "./customize-response-RGI.css"
import { getGenericErrorMessage } from "../../utils/error-messages"

type RGIIType = { value: string; label: string; oid: string }
export const ModalCheckRGI: FunctionComponent<{
  isOpenModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  workItemUuid: string
}> = ({ setOpenModal, isOpenModal, workItemUuid }) => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const { workItemsEntriesData } = useSelector(
    (state: RootState) => state.workItems
  )
  const [arrayBCPI, setArrayBCPI] = useState<RGIIType[]>([])
  const [updatedArrayBCPI, setUpdatedArrayBCPI] = useState<IBCPI[]>([])
  const [arrayOCPI, setArrayOCPI] = useState<IOCPI[]>([])
  const [selectedBCPI, setSelectedBCPI] = useState("")
  const [selectedOCPI, setSelectedOCPI] = useState("")
  const [checkRGIResponse, setCheckRGIResponse] = useState<any>()
  const dispatch = useDispatch()

  const currentWorkItem = workItemsEntriesData.find(
    (workItem) => workItem.workItemUuid === workItemUuid
  )

  useEffect(() => {
    if (ocpi) {
      const initialOCPI: React.SetStateAction<IOCPI[]> = []
      ocpi.forEach((item: { oid: string; name: string }) => {
        initialOCPI.push({
          value: item.oid,
          label: item.name,
        })
        //TODO: current method until we will fix diacritics in DB
        if (
          item.name.normalize("NFD").replace(/\p{Diacritic}/gu, "") ===
          currentWorkItem?.county
        ) {
          setSelectedOCPI(item.oid)
        }
      })
      setArrayOCPI(initialOCPI)
    }
  }, [isOpenModal])

  useEffect(() => {
    if (bcpi) {
      const initialBCPI: React.SetStateAction<IBCPI[]> = []
      bcpi.forEach((item: { oid: string; bid: string; name: string }) => {
        initialBCPI.push({
          oid: item.oid,
          value: item.bid,
          label: item.name,
        })
        //TODO: current method until we will fix diacritics in DB
        if (
          item.name.normalize("NFD").replace(/\p{Diacritic}/gu, "") ===
          currentWorkItem?.city
        ) {
          setSelectedBCPI(item.bid)
        }
      })
      setArrayBCPI(initialBCPI)
    }
  }, [isOpenModal])

  useEffect(() => {
    if (selectedOCPI.length) {
      const updatedBCPI = _.cloneDeep(arrayBCPI)
      setUpdatedArrayBCPI(_.filter(updatedBCPI, ["oid", selectedOCPI]))
    }
  }, [selectedOCPI])

  const initialValuesRGI: ICheckRGI = {
    year: new Date().getFullYear().toString(),
    numberRGI: "",
    codeBCPI: selectedBCPI,
    codeOCPI: selectedOCPI,
  }

  const validateFormRGI = {
    year: (value: string) => (value ? null : "Trebuie să selectați anul."),
    numberRGI: (value: string) =>
      value.length > 0 ? null : "Trebuie să introduceți numărul.",
    codeBCPI: (value: string) => (value ? null : "Trebuie să selectați BCPI."),
  }

  const formCheckRGI = useForm<ICheckRGI>({
    initialValues: initialValuesRGI,
    validate: validateFormRGI,
  })

  const succesCallBack = (data: any) => {
    setCheckRGIResponse(data)
  }
  const errorCallBack = (error: any) => {
    dispatch(
      setErrorNotification(getGenericErrorMessage(error.response.data.message))
    )
  }

  const { mutate, isLoading } = usePostRGI(
    succesCallBack,
    errorCallBack,
    formCheckRGI.values.year,
    formCheckRGI.values.codeBCPI,
    formCheckRGI.values.numberRGI
  )

  useEffect(() => {
    formCheckRGI.setValues({
      codeOCPI: selectedOCPI,
      codeBCPI: selectedBCPI,
    })
  }, [selectedBCPI, selectedOCPI, isOpenModal])

  const onCheckRGI = () => {
    if (formCheckRGI.isValid()) {
      mutate()
    }
  }

  const ResponseCheckRGI = () => (
    <>
      <Divider my="xl" label="Rezultatul căutării" labelPosition="center" />
      <div
        id="responseRGI"
        dangerouslySetInnerHTML={{ __html: checkRGIResponse }}
      />
    </>
  )

  const closeModal = () => {
    setOpenModal(false)
    formCheckRGI.reset()
    setCheckRGIResponse("")
  }

  return (
    <Modal
      opened={isOpenModal}
      onClose={() => {
        closeModal()
      }}
      centered
      size={isMobile ? "calc(100vw - 3rem)" : "md"}
      xOffset={0}
      zIndex={2000}
      title={"Verificare RGI"}
    >
      <Stack>
        <form onSubmit={formCheckRGI.onSubmit(onCheckRGI)}>
          <Select
            data={arrayOCPI}
            searchable
            placeholder={"Alege..."}
            label="OCPI"
            value={selectedOCPI}
            my="xs"
            styles={{
              dropdown: { height: "auto" },
              itemsWrapper: { height: 150 },
            }}
            onChange={(value: string) => {
              setSelectedBCPI("")
              setSelectedOCPI(value)
              formCheckRGI.setFieldValue("codeOCPI", value)
            }}
            error={formCheckRGI.errors.codeOCPI}
          />
          <Select
            data={updatedArrayBCPI}
            searchable
            placeholder={"Alege..."}
            label="BCPI"
            value={selectedBCPI}
            my="xs"
            styles={{
              dropdown: { height: "auto" },
              itemsWrapper: { height: 150 },
            }}
            onChange={(value: string) => {
              setSelectedBCPI(value)
              formCheckRGI.setFieldValue("codeBCPI", value)
            }}
            error={formCheckRGI.errors.codeBCPI}
          />
          <Select
            placeholder={"Alegeți anul..."}
            dropdownPosition="bottom"
            data={yearsRGI}
            styles={{
              dropdown: { height: "auto" },
              itemsWrapper: { height: 110 },
            }}
            label="Anul"
            value={formCheckRGI.values.year}
            my="xs"
            onChange={(value: string) => {
              formCheckRGI.setFieldValue("year", value)
            }}
            error={formCheckRGI.errors.year}
          />
          <TextInput
            label="Număr"
            placeholder={"Introduceți numărul căutat ..."}
            my="xs"
            {...formCheckRGI.getInputProps("numberRGI")}
          />

          <Flex w={"100%"} justify={isMobile ? "center" : "flex-end"}>
            <Button type="submit" radius={"xl"} m={"md"}>
              Verificare
            </Button>
          </Flex>
          <LoadingOverlay visible={isLoading} />
          {checkRGIResponse ? <ResponseCheckRGI /> : null}
        </form>
      </Stack>
    </Modal>
  )
}
