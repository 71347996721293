import { FileInput, Text, rem, Stack } from "@mantine/core"
import { IconUpload } from "@tabler/icons-react"
import _ from "lodash"
import React, { FunctionComponent, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setErrorNotification } from "../../../redux/notification/slice"
import { RootState } from "../../../redux/store"
import { IDocument, IWorkItem } from "../../../types/work-item-types"
import { ComponentPreviewMultipleFile } from "../../component-preview-file/component-preview-file"
import { useStyles } from "../utils-edit-add-work-item/add-edit-work-item-classes"
import { convertBase64 } from "../utils-edit-add-work-item/utils-edit-work-item"

export const SectionInputMultipleFileWorkItem: FunctionComponent<{
  isModeView: boolean
  isModeAdd: boolean
  setContainerOtherDocs: (value: React.SetStateAction<IDocument[]>) => void
  containerOtherDocs: IDocument[]
  setFiles: (value: React.SetStateAction<File[]>) => void
  files: File[]
  response?: IWorkItem
  allDocs?: IDocument[]
}> = (props) => {
  const {
    isModeView,
    setContainerOtherDocs,
    containerOtherDocs,
    setFiles,
    files,
    isModeAdd,
    allDocs,
  } = props

  const { fileDeleted } = useSelector((state: RootState) => state.deleteFiles)
  const dispatch = useDispatch()
  const { classes } = useStyles()

  const onUploadMultipleFile = async (event: File[] | null) => {
    let newDocs: any = []
    let duplicate: boolean = false

    if (event) {
      event.forEach((currentFile) => {
        const found = files.find(
          (storedFile) => currentFile.name === storedFile.name
        )
        const foundItemBackend =
          allDocs &&
          allDocs.find(
            (storedFile: any) => currentFile.name === storedFile.split("***")[1]
          )

        if (found || foundItemBackend) {
          duplicate = true
        } else setFiles([...files, ...event])
      })

      if (duplicate) {
        dispatch(setErrorNotification("Există deja un fișier cu același nume."))
      } else {
        Promise.all(
          event.map(async (doc) => {
            return convertBase64(doc)
              .then((e: any) => {
                const newDoc: IDocument = {
                  filename: doc.name,
                  content: e,
                }
                return newDoc
              })
              .catch((err) =>
                dispatch(setErrorNotification(`Eroare incarcare fisier ${err}`))
              )
          })
        )
          .then((result) => {
            result.forEach((element) => {
              newDocs.push(element as IDocument)
            })
            return newDocs
          })
          .then((newDocArray) => {
            setContainerOtherDocs([...newDocArray, ...containerOtherDocs])
          })
      }
    }
  }

  useEffect(() => {
    if (fileDeleted) {
      const newFilesArray = _.cloneDeep(files)
      newFilesArray.forEach((item, index) => {
        if (item?.name === fileDeleted.fileName) {
          newFilesArray.splice(index, 1)
        }
      })
      setFiles(newFilesArray)
    }
  }, [fileDeleted.fileName])

  return (
    <Stack>
      {isModeView ? (
        <>
          <Text c="#979797">Documente:</Text>
          <ComponentPreviewMultipleFile
            containerOtherDocs={containerOtherDocs}
            setContainerOtherDocs={setContainerOtherDocs}
            isModeView={isModeView}
            isModeAdd={isModeAdd}
          />
        </>
      ) : (
        <>
          <FileInput
            disabled={isModeView}
            label="Documente:"
            placeholder="Încărcați documentele clientului"
            defaultValue={undefined}
            multiple
            clearable
            icon={<IconUpload size={rem(14)} />}
            onChange={(e) => onUploadMultipleFile(e)}
            className={classes.inputMultiplePdf}
          />
          <ComponentPreviewMultipleFile
            containerOtherDocs={containerOtherDocs}
            setContainerOtherDocs={setContainerOtherDocs}
            isModeView={isModeView}
            isModeAdd={isModeAdd}
          />
        </>
      )}
    </Stack>
  )
}
