import React from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { ClientsPage } from "./components/clients-page/clients-page"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ProtectedRoute } from "./protected-route"
import { Mode, RoutesOptions } from "./utils/constants"
import { WorkItemsPage } from "./components/work-items-page/work-items-page"
import { ViewEditAddWorkItemPage } from "./components/view-edit-add-work-item-page/view-edit-work-item-page"
import Login from "./components/login/login"
import { AddWorkItem } from "./components/view-edit-add-work-item-page/add-work-item"
import { EmployeesPage } from "./components/employees-page/employee-page"
import { InventoryPage } from "./components/inventory/inventory-page"
import { ProfilePage } from "./components/profile/profile-page"
import { Register } from "./components/register/register"
import { ForgetPasswordPage } from "./components/forget-password/forget-password"
import { ChangePasswordPage } from "./components/change-password/change-password"
import { TrialOverPage } from "./components/trial-over-page/trial-over-page"
import { AccountDetails } from "./components/account-details/account-details"
import { EmailConfirmationPage } from "./components/email-confirmation-page/email-confirmation-page"
import { CustomizePage } from "./components/customize-page/customize-page"
import { DisplayPdfPage } from "./components/profile/display-pdf"

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path={RoutesOptions.LOGIN} element={<Login />} />
            <Route
              path={RoutesOptions.ROOT}
              element={<Navigate to={RoutesOptions.CLIENTS} replace />}
            />
            <Route
              path={RoutesOptions.CLIENTS}
              element={
                <ProtectedRoute>
                  <ClientsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesOptions.WORKITEMS}
              element={
                <ProtectedRoute>
                  <WorkItemsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesOptions.WORKITEMS_DETAILS.concat(Mode.VIEW)}
              element={
                <ProtectedRoute>
                  <ViewEditAddWorkItemPage mode={"view"} />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesOptions.WORKITEMS_DETAILS.concat(Mode.EDIT)}
              element={
                <ProtectedRoute>
                  <ViewEditAddWorkItemPage mode={"edit"} />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesOptions.WORKITEMS.concat("/").concat(Mode.ADD)}
              element={
                <ProtectedRoute>
                  <AddWorkItem />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesOptions.EMPLOYEES}
              element={
                <ProtectedRoute>
                  <EmployeesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesOptions.INVENTORY}
              element={
                <ProtectedRoute>
                  <InventoryPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesOptions.CUSTOMIZE}
              element={
                <ProtectedRoute>
                  <CustomizePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesOptions.PROFILE}
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesOptions.DISPLAY_PDF}
              element={
                <ProtectedRoute>
                  <DisplayPdfPage />
                </ProtectedRoute>
              }
            />
            <Route path={RoutesOptions.REGISTER} element={<Register />} />
            <Route
              path={RoutesOptions.FORGET_PASSWORD}
              element={<ForgetPasswordPage />}
            />
            <Route
              path={RoutesOptions.CHANGE_PASSWORD}
              element={<ChangePasswordPage />}
            />
            <Route
              path={RoutesOptions.TRIAL_OVER}
              element={<TrialOverPage />}
            />
            <Route
              path={RoutesOptions.ACCOUNT_DETAILS}
              element={<AccountDetails />}
            />
            <Route
              path={RoutesOptions.EMAIL_CONFIRMATION}
              element={<EmailConfirmationPage />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  )
}

export default App
