import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setErrorNotification } from '../../redux/notification/slice'
import { IItemIventoryResponse } from '../../types/items-inventory'
import { inventoryUrl, RoutesOptions } from '../../utils/constants'
import { getGenericErrorMessage } from '../../utils/error-messages'

export const useGetInventory = (
  page?: number,
  limit?: number,
  type?: string,
) => {
  const authToken = sessionStorage.getItem('authToken')
  const userUuid = sessionStorage.getItem('uuid')
  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  const query = type
    ? queryString.stringify({ page, limit, userUuid, type })
    : queryString.stringify({ page, limit, userUuid })
  const inventoryLimitPages: string = `${inventoryUrl}/${userUuid}?${query}`
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useQuery(
    [`read-inventory-${type}-key`],
    () => axios.get<IItemIventoryResponse>(inventoryLimitPages, config),
    {
      onSuccess: (data: IItemIventoryResponse) => {
        return data.data
      },
      onError: (error: any) => {
        if ([401].includes(error.response?.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(
            setErrorNotification(
              getGenericErrorMessage(error.response.data.message),
            ),
          )
        }
      },
      retry: false,
      enabled: false,
    },
  )
}
