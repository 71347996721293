import { Flex, Tooltip, ActionIcon, Modal, Image } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconDownload, IconEyeCheck, IconX } from "@tabler/icons-react"
import _ from "lodash"
import { FunctionComponent, useState } from "react"
import { maxWidthMobile, maxWidthTablet } from "../../utils/constants"
import { ISectionActionMultiple } from "../../types/preview-files-types"
import { ContentPdfView } from "./utils-preview-file"
import { downloadDoc } from "../../utils/files"
import { useDispatch } from "react-redux"
import { setFileDeleted } from "../../redux/delete-files/deleteFilesSilce"
import { IDocument } from "../../types/work-item-types"

export const ActionButtonsSectionMultipleFile: FunctionComponent<
  ISectionActionMultiple
> = (props) => {
  const {
    isModeView,
    document,
    index,
    containerOtherDocs,
    setContainerOtherDocs,
  } = props
  const [isOpenPdf, setIsOpenPdf] = useState(false)
  const [fileContentForView, setFileContentForView] = useState("")
  const checkExtension =
    document.filename.includes(".pdf") ||
    document.filename.includes(".jpeg") ||
    document.filename.includes(".svg") ||
    document.filename.includes(".jpg") ||
    document.filename.includes(".png")
  const [showImage, setShowImage] = useState(false)
  const [displayPhoto, setDisplayPhoto] = useState<IDocument>()
  const isMobile = useMediaQuery(maxWidthMobile)
  const isTablet = useMediaQuery(maxWidthTablet)
  const dispatch = useDispatch()

  const openModalPdf = (fileContent: string) => {
    setFileContentForView(fileContent)
    setIsOpenPdf(true)
  }
  const deleteFile = (index: number) => {
    dispatch(
      setFileDeleted({
        type: "otherDocs",
        fileName: containerOtherDocs[index].filename,
      })
    )
    const otherDocsCopy = _.cloneDeep(containerOtherDocs)
    if (otherDocsCopy) {
      otherDocsCopy.splice(index, 1)
      setContainerOtherDocs(otherDocsCopy)
    }
  }

  return (
    <>
      <Flex gap={5} justify={isMobile ? "flex-end" : "start"}>
        <Tooltip
          withArrow
          transitionProps={{ duration: 200 }}
          label="Vizualizează"
        >
          <ActionIcon
            disabled={checkExtension ? false : true}
            radius="lg"
            variant="light"
            color="#ceddca"
            onClick={() => {
              if (document.filename.includes(".pdf")) {
                if (isMobile) {
                  window.open(
                    "/pdf/workitem/".concat(`${document.filename}`),
                    "_blank"
                  )
                } else openModalPdf(document.content)
              } else {
                setShowImage(true)
                setDisplayPhoto(document)
              }
            }}
          >
            <IconEyeCheck size="1rem" />
          </ActionIcon>
        </Tooltip>
        {!isModeView ? null : (
          <>
            <Tooltip
              withArrow
              transitionProps={{ duration: 200 }}
              label="Descarcă"
            >
              <ActionIcon
                radius="lg"
                variant="light"
                color="#ceddca"
                onClick={() => downloadDoc(document)}
              >
                <IconDownload size="1rem" />
              </ActionIcon>
            </Tooltip>
          </>
        )}

        {isModeView ? null : (
          <Tooltip withArrow transitionProps={{ duration: 200 }} label="Șterge">
            <ActionIcon
              radius="lg"
              variant="light"
              color="#ceddca"
              onClick={() => deleteFile(index)}
            >
              <IconX size="1rem" />
            </ActionIcon>
          </Tooltip>
        )}

        <ContentPdfView
          isOpenPDF={isOpenPdf}
          setIsOpenPdf={setIsOpenPdf}
          fileContent={fileContentForView}
        />
        <Modal
          opened={showImage}
          onClose={() => {
            setShowImage(false)
          }}
          centered
          size={isMobile ? "calc(100vw - 3rem)" : "md"}
          xOffset={0}
          zIndex={2000}
        >
          <Image src={displayPhoto?.content} alt="Display photo" />
        </Modal>
      </Flex>
    </>
  )
}
