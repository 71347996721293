import { Select, Stack, TextInput, Text, LoadingOverlay } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import React, {
  forwardRef,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react"
import { IPricesFormulas } from "../../../../types/customize-types"
import { IUser } from "../../../../types/user-types"
import { ISelectCalcFormulas } from "../../../../types/work-item-types"
import { maxWidthMobile, standardCurrency } from "../../../../utils/constants"
import { CustomizeElements } from "../../../../utils/enum"
import { AddCustomizeElements } from "../../../customize-page/customize-page-modals/modal-customize"

export const RightSectionAddWorkItem: FunctionComponent<{
  validationErrors: string[]
  formAddWorkItem: UseFormReturnType<any, (values: any) => any>
  dataUserResponse?: IUser
}> = (props) => {
  const { formAddWorkItem, validationErrors, dataUserResponse } = props
  const { workItemType, calcFormula, amount } = formAddWorkItem.values
  const { basePrice, workItemMeasurementUnit } = calcFormula
    ? JSON.parse(calcFormula)
    : ""

  const totalPaymentRef = useRef<any>(null)
  const isMobile = useMediaQuery(maxWidthMobile)
  const [dataCalcFormulas, setDataCalcFormulas] = useState<any>()
  const [measurementUnit, setMeasurementUnit] = useState("")
  const [description, setDescription] = useState("")
  const [isOpenModal, setOpenModal] = useState(false)

  useEffect(() => {
    validationErrors.forEach((e) => {
      if (e === "totalPayment") {
        if (totalPaymentRef && totalPaymentRef.current) {
          totalPaymentRef.current.scrollIntoView({
            behavior: "smooth",
          })
        }
      }
    })
  }, [validationErrors])
  const SelectItem = forwardRef<HTMLDivElement, ISelectCalcFormulas>(
    ({ value, label, description, ...others }: ISelectCalcFormulas, ref) => (
      <div ref={ref} {...others}>
        <Stack spacing={0}>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </Stack>
      </div>
    )
  )

  useEffect(() => {
    if (dataUserResponse) {
      const { workItemsPricesFormulas } = dataUserResponse as IUser

      const filteredArray =
        workItemsPricesFormulas &&
        JSON.parse(workItemsPricesFormulas).filter(
          (workitem: IPricesFormulas) => workitem.workItemType === workItemType
        )

      const newArray = filteredArray
        ? filteredArray.map(
            (item: {
              name: string
              workItemMeasurementUnit: string
              workItemType: string
              basePrice: string
            }) => ({
              label: item.name.toLocaleUpperCase(),
              value: JSON.stringify(item),
              description: item.basePrice
                .concat(` ${standardCurrency}/ `)
                .concat(item.workItemMeasurementUnit),
            })
          )
        : []

      setDataCalcFormulas(newArray)
    }
    formAddWorkItem.setValues({
      calcFormula: "",
      amount: "",
    })
  }, [workItemType, dataUserResponse, isOpenModal])

  useEffect(() => {
    if (calcFormula) {
      if (calcFormula.length > 10) {
        setMeasurementUnit(workItemMeasurementUnit)
      } else setMeasurementUnit("")
    }
    setDescription("")
  }, [calcFormula])

  useEffect(() => {
    if (calcFormula) {
      const formulaForDescription = basePrice
        .concat(` ${standardCurrency} / `)
        .concat(workItemMeasurementUnit)

      setDescription(formulaForDescription)
    }
  }, [calcFormula])

  useEffect(() => {
    if (calcFormula) {
      formAddWorkItem.setValues({
        totalPayment: amount * basePrice,
      })
    }
  }, [amount, calcFormula])

  return (
    <>
      {dataCalcFormulas ? (
        <Stack w={isMobile ? "100%" : "49%"} spacing="md">
          <Select
            label="Formulă de calcul:"
            disabled={workItemType ? false : true}
            description={description}
            placeholder="Alegeți/Adăugați formula de calcul"
            itemComponent={SelectItem}
            data={dataCalcFormulas}
            maxDropdownHeight={400}
            creatable
            searchable
            getCreateLabel={(query) => `+ Adaugă o nouă formulă ${query}`}
            onCreate={(query: any) => {
              setOpenModal(true)
              return JSON.stringify({
                name: query.toLocaleUpperCase(),
                workItemMeasurementsUnit: "",
                workItemType: workItemType,
                basePrice: "",
              })
            }}
            {...formAddWorkItem.getInputProps("calcFormula")}
          />
          <TextInput
            type={"number"}
            disabled={calcFormula ? false : true}
            placeholder="Introduceți cantitatea"
            label="Cantitatea:"
            rightSection={measurementUnit}
            rightSectionWidth={"3rem"}
            {...formAddWorkItem.getInputProps("amount")}
          />
          <TextInput
            type={"number"}
            required
            placeholder="Introduceți totalul de plată"
            label="Total de plată:"
            rightSection={standardCurrency}
            rightSectionWidth={"3rem"}
            {...formAddWorkItem.getInputProps("totalPayment")}
            ref={totalPaymentRef}
          />
          <TextInput
            type={"number"}
            label="Plată în avans:"
            placeholder="Introduceți plata în avans"
            rightSection={standardCurrency}
            rightSectionWidth={"3rem"}
            {...formAddWorkItem.getInputProps("advancePayment")}
          />
          <TextInput
            type={"number"}
            label="Rest de plată:"
            disabled
            rightSection={standardCurrency}
            rightSectionWidth={"3rem"}
            {...formAddWorkItem.getInputProps("restPayment")}
          />
          <TextInput
            type={"number"}
            label="Taxe:"
            placeholder="Introduceți suma pentru taxe"
            rightSection={standardCurrency}
            rightSectionWidth={"3rem"}
            {...formAddWorkItem.getInputProps("taxes")}
          />
        </Stack>
      ) : null}
      <AddCustomizeElements
        isOpenModal={isOpenModal}
        setOpenModal={setOpenModal}
        customizeElement={CustomizeElements.CALC_FORMULA}
        precompleteField={calcFormula}
      />
      <LoadingOverlay visible={dataCalcFormulas ? false : true} />
    </>
  )
}
