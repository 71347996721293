import axios from "axios"
import { IClientForm } from "../../../types/client-types"
import { cities } from "../../../utils/city"
import { GET_COMPANY_INFO_QUERY, GRAPHQL_API } from "../../../utils/constants"
import { reverseFormaterDiacritics } from "../../../utils/strings"

export const initialValuesClientData = (clientsDetails: IClientForm) => {
  const {
    name,
    fiscalCode,
    email,
    phoneNumber,
    userUuid,
    address,
    city,
    county,
    streetNr,
    idSeries,
    idSeriesNumber,
    building,
    buildingNr,
    apartment,
    floor,
    CNP,
    nrRegister,
    typeClient,
  } = clientsDetails
  const initalValue = {
    name: reverseFormaterDiacritics(name),
    fiscalCode,
    email,
    phoneNumber,
    userUuid,
    address: reverseFormaterDiacritics(address),
    city: city ? city : "",
    county,
    streetNr,
    idSeries,
    idSeriesNumber,
    building,
    buildingNr,
    apartment,
    floor,
    nrRegister,
    CNP,
    typeClient,
  }
  return initalValue
}

export const validateForm = {
  name: (value: string) => {
    if (/^[a-zA-Z0-9ȘșȚțÎîăâ &%#@*-]{3,}$/.test(value) && value) return null
    else return "Numele trebuie să conțină cel puțin 3 caractere valide."
  },
  fiscalCode: (value: string, values: any) => {
    if (values.CNP && values.CNP.length > 1) {
      return null
    }
    if (/^\d{1,9}\d$/.test(value)) return null
    else
      return "Codul fiscal trebuie să conțină cel puțin 6 cifre (ex: 123456). "
  },
  CNP: (value: string, values: any) => {
    if (values.fiscalCode && values.fiscalCode.length > 1) {
      return null
    }
    if (/^\d{13}$/.test(value)) return null
    else return "CNP-ul trebuie să conțină 13 cifre."
  },
  idSeries: (value: string) => {
    if (value) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (
        /^(AX|TR|AR|ZR|XC|ZC|MM|XM|XB|XT|BV|ZV|XR|DP|DR|DT|DX|RD|RR|RT|RX|RK|RZ|IF|XZ|ZB|KL|KX|CJ|KT|KZ|DX|DZ|HD|XD|VN|GL|ZL|GG|MX|MZ|IZ|MH|HR|XH|ZH|NT|NZ|AS|AZ|PH|PX|KS|VX|SM|KV|SB|SR|OT|SZ|SV|XV|TM|TZ|DD|ZD|GZ|ZS|MS|TC|VS|XS|SX)$/.test(
          value
        )
      )
        return null
      else return "Seria trebuie să conțină 2 litere majuscule valide."
    }
  },
  idSeriesNumber: (value: string) => {
    if (value) {
      if (value.length === 6) return null
      else return "Numărul de seria trebuie să conțină 6 litere cifre."
    }
  },
  email: (value: string) => {
    if (value) {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return null
      } else {
        return "Emailul este invalid."
      }
    } else {
      return null
    }
  },
  phoneNumber: (value: string) =>
    value &&
    /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s\.|-)?([0-9]{3}(\s|\.|-|)){2}$/.test(
      value
    ) &&
    value.length > 0
      ? null
      : "Numărul de telefon este invalid.",
}
export const messageDuplicateCNP = "Există deja un client cu acest CNP."
export const messageDuplicateCUI = "Există deja un client cu acest CUI."

export const checkedCity = (locality: string) => {
  const existingCity =
    cities &&
    cities.find((city) => city && city.toLowerCase() === locality.toLowerCase())
  if (!existingCity) {
    cities.push(locality)
  }
  return locality
}
export const createFullAddress = (
  streetName: string,
  streetNr: string,
  staircase: string,
  apartment: string
) => {
  const newStaircase = staircase ? ", Sc. " + staircase : ""
  const newApartment = apartment ? ", Ap. " + apartment : ""
  const newStreet = streetName ? streetName : "Str. - "
  const newStreetNr = streetNr ? ", Nr. " + streetNr : ""
  const address = newStreet + newStreetNr + newStaircase + newApartment
  return address
}
export const handleDataCompany = async (
  setLoadingGetCompanyInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorCUI: React.Dispatch<React.SetStateAction<string | null>>,
  CUI: string,
  form: any,
  typeForm: "User" | "Client",
  setCompanyName?: React.Dispatch<any>,
  setAddress?: React.Dispatch<any>,
  setNrRegister?: React.Dispatch<any>
) => {
  setLoadingGetCompanyInfo(true)
  setErrorCUI(null)

  try {
    const response = await axios.post(GRAPHQL_API, {
      query: GET_COMPANY_INFO_QUERY,
      variables: {
        text: CUI,
      },
    })

    if (response.data.errors) {
      throw new Error(response.data.errors[0].message)
    }
    const {
      companyName,
      streetName,
      streetNr,
      apartment,
      companyId,
      staircase,
      county,
      locality,
    } = response.data.data.autocomplete[0]

    setAddress &&
      setAddress(
        createFullAddress(streetName, streetNr, staircase, apartment) +
          ", " +
          locality +
          ", jud. " +
          county
      )
    setCompanyName && setCompanyName(companyName)
    setNrRegister && setNrRegister(companyId)

    typeForm === "Client"
      ? form.setValues({
          ...form.values,
          fiscalCode: CUI,
          name: companyName,
          address: createFullAddress(
            streetName,
            streetNr,
            staircase,
            apartment
          ),
          county: county,
          city: checkedCity(locality),
          nrRegister: companyId,
        })
      : form.setValues({
          ...form.values,
          fiscalCode: CUI,
          companyName: companyName,
          address: createFullAddress(
            streetName,
            streetNr,
            staircase,
            apartment
          ),
          county: county,
          city: checkedCity(locality),
          nrRegister: companyId,
          companyInfo: JSON.stringify({
            CUI: CUI,
            companyName: companyName,
            nrRegister: companyId,
            address:
              createFullAddress(streetName, streetNr, staircase, apartment) +
              ", " +
              locality +
              ", jud. " +
              county,
          }),
        })
  } catch (error: any) {
    setErrorCUI(error.message)
  } finally {
    setLoadingGetCompanyInfo(false)
  }
}
