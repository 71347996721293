import { Table } from "@mantine/core"
import { FunctionComponent } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { useStyles } from "../clients-table.styles"
import { rowsClientsDesktop } from "../utils-clients-page/clients-tables.utils"

export const ClientsTables: FunctionComponent = () => {
  const { classes } = useStyles()
  const { clientsEntriesData } = useSelector(
    (state: RootState) => state.clients
  )

  return (
    <>
      <Table w={"100%"} highlightOnHover>
        <thead className={classes.header}>
          <tr>
            <th>Denumire client</th>
            <th>CUI / CNP</th>
            <th>Telefon</th>
            <th>E-mail</th>
            <th>Data creare</th>
            <th>Ultima actualizare</th>
            {/* TODO:Add total woork items */}
            {/* <th>Total lucrări</th> */}
          </tr>
        </thead>
        <tbody>{rowsClientsDesktop(clientsEntriesData)}</tbody>
      </Table>
    </>
  )
}
