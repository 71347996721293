import { IEmployee } from "../../../types/employee-types"
import { reverseFormaterDiacritics } from "../../../utils/strings"

export enum EmployeesFields {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
  PHONE = "phoneNumber",
  ROLE = "role",
}
export const EmployeesTableColumns: Record<EmployeesFields, string> = {
  [EmployeesFields.FIRST_NAME]: "Prenume",
  [EmployeesFields.LAST_NAME]: "Nume",
  [EmployeesFields.EMAIL]: "E-mail",
  [EmployeesFields.PHONE]: "Telefon",
  [EmployeesFields.ROLE]: "Role",
}
export const initialValuesEmployeeData = (employeeDetails: IEmployee) => {
  const { name, email, managerUuid, role } = employeeDetails
  const values = {
    name: reverseFormaterDiacritics(name),
    email,
    managerUuid,
    role: reverseFormaterDiacritics(role),
  }
  return values
}

export const validateEmployeeForm = {
  name: (value: string) => {
    if (/^[a-zA-ZȘșȚțÎîăâ ]{3,}$/.test(value) && value) return null
    else return "Numele trebuie să conțină cel puțin 3 litere."
  },
  email: (value: string) => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return null
    } else {
      return "Emailul este invalid."
    }
  },
  role: (value: string) => {
    if (/^[a-zA-ZȘșȚțÎîăâ ]{3,}$/.test(value) && value) return null
    else return "Postul trebuie să conțină cel puțin 3 litere."
  },
}
