import { UseFormReturnType } from "@mantine/form"
import { convertDateTOStringFormatForBacEnd } from "../../../utils/dates"
import { IDocument } from "../../../types/work-item-types"
import { formaterDiacritics } from "../../../utils/strings"

export const buildPayloadForCreateWorkItem = (
  selectedClientInfos: any,
  formAddWorkItem: UseFormReturnType<any, (values: any) => any>,
  multiplePdf: IDocument[]
) => {
  const { workItemType, status, deadline, address, taxes, advancePayment } =
    formAddWorkItem.values

  return {
    ...formAddWorkItem.values,
    name: formaterDiacritics(selectedClientInfos.label),
    workItemType: formaterDiacritics(workItemType.toUpperCase()),
    status: formaterDiacritics(status.toUpperCase()),
    deadline: convertDateTOStringFormatForBacEnd(deadline as string),
    otherDocs: formatMultipleDocuments(multiplePdf),
    clientUuid: selectedClientInfos.value,
    address: formaterDiacritics(address),
    taxes: taxes ? taxes : 0,
    advancePayment: advancePayment ? advancePayment : 0,
    userUuid: sessionStorage.getItem("uuid"),
  }
}
export const buildPayloadForUpdateWorkItem = (
  formWorkItem: UseFormReturnType<any, (values: any) => any>,
  multiplePdf: IDocument[],
  uuid: string,
  deletedDocs: any
) => {
  const {
    deadline,
    address,
    amount,
    calcFormula,
    county,
    city,
    advancePayment,
    restPayment,
    totalPayment,
    taxes,
    workItemType,
    responsable,
    clientUuid,
    currency,
    status,
  } = formWorkItem.values
  const addedDocs: any = []

  multiplePdf &&
    multiplePdf.forEach((pdf) => {
      if (pdf.filename && !pdf.filename.includes(`***`)) {
        addedDocs.push({
          type: "otherDocs",
          fileName: formaterDiacritics(pdf.filename),
        })
      }
    })

  return {
    workItemType: formaterDiacritics(workItemType),
    county,
    city,
    deadline: convertDateTOStringFormatForBacEnd(deadline as string),
    otherDocs:
      multiplePdf.length > 0
        ? formatMultipleDocuments(multiplePdf, uuid)
        : formatMultipleDocuments(multiplePdf, uuid),
    advancePayment: advancePayment ? advancePayment : 0,
    restPayment,
    totalPayment,
    taxes: taxes ? taxes : 0,
    responsable: formaterDiacritics(responsable),
    clientUuid,
    userUuid: sessionStorage.getItem("uuid"),
    currency,
    address: formaterDiacritics(address),
    status: formaterDiacritics(status),
    addedDocs: addedDocs,
    deletedDocs: deletedDocs,
    amount,
    calcFormula,
  }
}

export const formatDocumentFieldFileName = (
  field: { filename: string; content?: any },
  uuid?: string
) => {
  return field.filename?.length > 0
    ? uuid
      ? uuid.concat("***").concat(formaterDiacritics(field.filename))
      : formaterDiacritics(field.filename)
    : null
}

const formatMultipleDocuments = (multiplePdf: IDocument[], uuid?: string) => {
  return multiplePdf?.length > 0
    ? uuid
      ? JSON.stringify(
          multiplePdf.map((elm) =>
            !elm.filename.includes("***")
              ? formatDocumentFieldFileName(elm, uuid)
              : formatDocumentFieldFileName(elm)
          )
        )
      : JSON.stringify(
          multiplePdf.map((elm) => formaterDiacritics(elm.filename))
        )
    : null
}
