import {
  Button,
  Modal,
  Select,
  Stack,
  Group,
  Text,
  LoadingOverlay,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, {
  FunctionComponent,
  useState,
  forwardRef,
  useEffect,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { usePostAssignResponsable } from "../../../api/assign-responsable/use-post-assign-responsable"
import { useGetWorkItems } from "../../../api/work-items/use-get-work-items"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../../redux/notification/slice"
import { RootState } from "../../../redux/store"
import {
  limitPage,
  maxWidthMobile,
  RoutesOptions,
} from "../../../utils/constants"
interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  value: string
  label: string
  describe: string
}
export const ModalAsignResponsable: FunctionComponent<{
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  workItemUuid: string
}> = (props) => {
  const { isOpen, setIsOpen, workItemUuid } = props
  const isMobile = useMediaQuery(maxWidthMobile)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentPageWorkItems } = useSelector(
    (state: RootState) => state.workItems
  )
  const { employees } = useSelector((state: RootState) => state.employees)
  const { refetch } = useGetWorkItems(currentPageWorkItems, limitPage)
  const responsableSelect: any[] = []
  const [error, setError] = useState("")
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [selectedResponsable, setSelectedResponsable] = useState<string | null>(
    ""
  )
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, describe, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              {describe}
            </Text>
          </div>
        </Group>
      </div>
    )
  )
  employees &&
    employees.forEach((employee: { email: any; name: any }) => {
      responsableSelect.push({
        value: employee.email,
        label: employee.name,
        describe: employee.email,
      })
    })
  const succesCallBack = (data: any) => {
    setIsOpen(false)
    dispatch(setMessageNotification(data.message))
    refetch()
  }
  const errorCallBack = (error: any) => {
    setError("")
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu! ${error.response.data.message}.`
      )
    )
  }
  const { mutate, isLoading } = usePostAssignResponsable(
    succesCallBack,
    errorCallBack
  )
  const sendEmail = () => {
    const userUuid = sessionStorage.getItem("uuid")
    if (selectedResponsable?.length === 0) {
      setError("Te rog selectează câmpul cu date existente din listă.")
    } else {
      mutate({
        responsable: selectedResponsable,
        userUuid: userUuid,
        workItemUuid: workItemUuid,
      })
    }
  }
  useEffect(() => {
    setSelectedResponsable("")
  }, [isOpen])

  return (
    <Modal
      title={"Alegeți responsabilul pentru lucrare"}
      opened={isOpen}
      onClose={() => {
        setIsOpen(false)
        setError("")
      }}
      xOffset={0}
      size={isMobile ? "calc(100vw - 3rem)" : "md"}
      centered
      zIndex={2000}
    >
      <LoadingOverlay visible={isLoading} />
      <Stack h={isOpenDropdown ? "30vh" : "14vh"} justify="space-between">
        <Select
          label="Responsabil"
          data={responsableSelect}
          placeholder={"Alegeți responsabilul ..."}
          defaultValue={null}
          searchable
          clearable
          creatable
          onDropdownOpen={() => setIsOpenDropdown(true)}
          onDropdownClose={() => setIsOpenDropdown(false)}
          dropdownPosition="bottom"
          itemComponent={SelectItem}
          getCreateLabel={(query) => `+ Adaugă un nou angajat ${query}`}
          onCreate={(query) => {
            navigate(RoutesOptions.EMPLOYEES)
            return query
          }}
          styles={{
            dropdown: { height: "45%", overflow: "visible" },
            itemsWrapper: { height: 130 },
          }}
          onChange={(e) => {
            setSelectedResponsable(e)
            setError("")
          }}
          error={error}
        />
        <Button radius={"xl"} w={"auto"} onClick={() => sendEmail()}>
          Alege responsabil
        </Button>
      </Stack>
    </Modal>
  )
}
