import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import {
  Modal,
  Button,
  Text,
  Stack,
  Group,
  Image,
  LoadingOverlay,
} from "@mantine/core"
import { FunctionComponent, useEffect, useState } from "react"
import { maxWidthMobile, maxWidthMobileSmall } from "../../utils/constants"
//@ts-ignore
import * as MyPOSEmbedded from "mypos-embedded-checkout"
import { useDispatch } from "react-redux"
import { useGetUser } from "../../api/user/use-get-user"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import { getGenericErrorMessage } from "../../utils/error-messages"
import { usePostUserPayment } from "../../api/user/use-post-user-payment"
import {
  callbackParams,
  customization,
  paymentParms,
} from "../../utils/payment"

export const ModalSubscription: FunctionComponent<{ days: number }> = ({
  days,
}) => {
  const [opened, { close }] = useDisclosure(true)
  const isMobile = useMediaQuery(maxWidthMobile)
  const isMobileS = useMediaQuery(maxWidthMobileSmall)
  const [isSubscribed, setSubscribe] = useState(false)
  const [
    paymentModalOpened,
    { open: openPaymentModal, close: closePaymentModal },
  ] = useDisclosure(false)
  const { data, refetch, isRefetching, isLoading } = useGetUser()
  const dispatch = useDispatch()
  useEffect(() => {
    refetch()
    setSubscribe(false)
  }, [])
  const userDataResponse = data?.data.response

  const userPaymentSuccessCallback = (data: any) => {
    closePaymentModal()
    refetch()
    dispatch(setMessageNotification(data.message))
  }
  const userPaymentErrorCallback = (err: any) => {
    refetch()
    dispatch(
      setErrorNotification(
        getGenericErrorMessage(
          "Daca problema persistă contactează echipa de suport!"
        )
      )
    )
  }
  const { mutate } = usePostUserPayment(
    (data) => userPaymentSuccessCallback(data),
    (err) => userPaymentErrorCallback(err)
  )
  const triggerPayment = () => {
    openPaymentModal()

    setTimeout(() => {
      MyPOSEmbedded.createPayment(
        "embeddedCheckout",
        paymentParms(userDataResponse?.usedAffiliateCode),
        callbackParams(userDataResponse, userPaymentErrorCallback, mutate),
        customization
      )
    }, 200)
  }

  return (
    <>
      {isSubscribed ? (
        <Modal
          xOffset={0}
          size={isMobile ? "calc(100vw - 2rem)" : "lg"}
          opened={paymentModalOpened}
          onClose={closePaymentModal}
          centered
          withCloseButton={true}
          padding="xl"
        >
          <div id="embeddedCheckout"></div>
        </Modal>
      ) : (
        <Modal
          opened={opened}
          radius="md"
          onClose={() => {
            close()
            sessionStorage.setItem("activeModalSubscription", "false")
          }}
          title="Nu rata momentul!"
          zIndex={10020}
          centered
          styles={{ inner: { width: "90%" } }}
        >
          <LoadingOverlay visible={isLoading || isRefetching} />
          <Stack align="center" w="100%">
            {days === 1 ? (
              <Stack spacing={"md"} w="100%">
                <Image
                  alt="Trial"
                  src="/trial-over.svg"
                  height={isMobile ? "150px" : "280px"}
                  width={isMobile ? "150px" : "400px"}
                />
                <Text align="justify">
                  Astăzi se încheie perioada de probă, dar călătoria ta cu noi
                  abia începe.
                </Text>
                <Text align="justify">
                  Abonează-te acum pentru a te bucura de avantajele
                  exclusiviste.
                </Text>
              </Stack>
            ) : (
              <Stack spacing={"md"} w="100%">
                <Image
                  alt="Trial"
                  src="/trial-over.svg"
                  height={isMobileS ? "220px" : isMobile ? "250px" : "280px"}
                  width={isMobile ? "100%" : "400px"}
                />
                <Text align="justify">
                  Abonamentul tău expira în {days} zile, iar oportunitatea de a
                  accesa în continuare serviciile noastre este la un click
                  distanță.
                </Text>
                <Text align="justify">
                  Abonează-te acum și te asiguri că vei experimenta în
                  continuare toate avantajele oferite exclusiv abonaților
                  noștri.
                </Text>
              </Stack>
            )}
            <Group my="md" w="100%" style={{ justifyContent: "center" }}>
              <Button
                variant="filled"
                size="sm"
                w="45%"
                radius="xl"
                onClick={() => {
                  setSubscribe(true)
                  triggerPayment()
                }}
              >
                Abonează-te
              </Button>
              <Button
                variant="light"
                size="sm"
                radius="xl"
                w="45%"
                onClick={() => {
                  close()
                  sessionStorage.setItem("activeModalSubscription", "false")
                }}
              >
                Mai târziu
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  )
}
