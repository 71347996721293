import { useMediaQuery } from "@mantine/hooks"
import { Button, Container, Flex, Title } from "@mantine/core"
import { Navigationbar } from "../navbar/navbar"
import { GeneralTableComponentWorkItem } from "./general-table-component-work-item"
import { useNavigate } from "react-router-dom"
import {
  backgroudColor,
  maxWidthMobile,
  Mode,
  RoutesOptions,
} from "../../utils/constants"
import { ErrorSuccesNotification } from "../notification/notification"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { useEffect } from "react"
import { useGetUser } from "../../api/user/use-get-user"
import { formatterDate } from "../account-details/utils-account-details"
import { IUserResponse } from "../../types/user-types"

export const WorkItemsPage = () => {
  const isTablet = useMediaQuery("(max-width: 62em)")
  const isMobile = useMediaQuery(maxWidthMobile)
  const { totalCount } = useSelector((state: RootState) => state.workItems)
  const navigate = useNavigate()

  const succesCallBack = (data: IUserResponse["data"]) => {
    if (formatterDate(data.response.subscriptionExpireDate) < new Date()) {
      navigate(RoutesOptions.TRIAL_OVER)
    }
  }
  const { refetch } = useGetUser(succesCallBack)

  useEffect(() => {
    refetch()
  }, [])

  return (
    <Container
      fluid
      m={0}
      p={0}
      bg={backgroudColor}
      h={isMobile ? "100%" : "100vh"}
    >
      <Flex h="100%" direction={isTablet ? "column" : "row"}>
        <Navigationbar />
        <Flex
          direction={"column"}
          w="100%"
          h={isTablet ? "calc(100% - 3rem)" : "100%"}
          justify="space-between"
        >
          <Flex justify="space-between" align="flex-end" h="3rem">
            <Title order={4} ml="md">
              Lucrări ({totalCount})
            </Title>

            <Button
              variant="light"
              w="10rem"
              mx="xs"
              radius="xl"
              size="xs"
              onClick={() => {
                navigate(RoutesOptions.WORKITEMS.concat("/").concat(Mode.ADD))
              }}
            >
              Adaugă lucrare
            </Button>
          </Flex>
          <GeneralTableComponentWorkItem />
        </Flex>
      </Flex>
      <ErrorSuccesNotification />
    </Container>
  )
}
