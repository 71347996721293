import { Flex, Grid, Image, Table, Text } from "@mantine/core"
import React, { FunctionComponent } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import {
  maxWidthMobileMedium,
  maxWidthMobileSmall,
} from "../../../utils/constants"
import { useMediaQuery } from "@mantine/hooks"
import {
  renderRightControl,
  renderStatusControl,
} from "../../clients-page/utils-clients-page/utils-edit-client"
import { IWorkItem } from "../../../types/work-item-types"
import { ActionsButtonWorkItem } from "../utils-work-items/actions-button-work-items"
import {
  TableColumnsWorkItems,
  TableWorkItemsFields,
} from "../../../utils/enum"

export const TableWorkItemsMobile: FunctionComponent = () => {
  const { workItemsEntriesData } = useSelector(
    (state: RootState) => state.workItems
  )
  const isMobileS = useMediaQuery(maxWidthMobileSmall)
  const isMobileM = useMediaQuery(maxWidthMobileMedium)

  const contentCurrentRow = (
    fieldValue: string,
    field: keyof typeof TableColumnsWorkItems,
    key?: any
  ) => {
    return (
      <Flex
        direction={isMobileM ? "column" : "row"}
        justify={"space-between"}
        mb={5}
        key={key}
      >
        <Text c="#3C4048" fw={500} w="9rem">
          {TableColumnsWorkItems[field]}:
        </Text>
        {field === "status"
          ? renderStatusControl(fieldValue.toLocaleLowerCase())
          : renderRightControl(fieldValue)}
      </Flex>
    )
  }
  const rows =
    workItemsEntriesData !== undefined &&
    workItemsEntriesData.map((workItem: IWorkItem) => {
      const cellsTable = [
        [workItem.workItemType, TableWorkItemsFields.WORK_ITEM_TYPE],
        [workItem.county, TableWorkItemsFields.COUNTY],
        [workItem.status, TableWorkItemsFields.STATUS],
        [workItem.deadline, TableWorkItemsFields.DEADLINE],
        [workItem.responsable, TableWorkItemsFields.RESPONSABLE],
        [workItem.createDate, TableWorkItemsFields.CREATED_DATE],
        [workItem.lastUpdateDate, TableWorkItemsFields.LAST_UPDATED_DATE],
      ]

      return (
        <tr key={workItem.workItemUuid}>
          <td key={workItem.workItemUuid}>
            <Grid mb="xs" justify={"space-between"} align="center">
              <Grid.Col span={7}>
                <Text>{workItem.name}</Text>
              </Grid.Col>
              <Grid.Col
                span={isMobileS ? 5 : isMobileM ? 4 : 3}
                m={isMobileS ? "5" : "0"}
              >
                <ActionsButtonWorkItem
                  workItemUuid={workItem.workItemUuid}
                  clientUuid={workItem.clientUuid || ""}
                  statusWorkItem={workItem.status}
                />
              </Grid.Col>
            </Grid>
            {cellsTable.map((field: any, index) =>
              contentCurrentRow(field[0], field[1], index)
            )}
          </td>
        </tr>
      )
    })

  return (
    <>
      {workItemsEntriesData && workItemsEntriesData.length ? (
        <Table sx={{ tableLayout: "fixed" }} w={"100%"}>
          <thead>
            <tr>
              <th>
                <Text size={"lg"}>Lucrare</Text>
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      ) : (
        <>
          <Text align={"center"} pt="md">
            Nu aveți nici o lucrare adăugată!
          </Text>

          <Image
            maw={550}
            mx="auto"
            radius="lg"
            src="/add_workitem.svg"
            alt="Add workitem"
          />
        </>
      )}
    </>
  )
}
