import { Button, Container, Flex, Title } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGetUser } from "../../api/user/use-get-user"
import { IUserResponse } from "../../types/user-types"
import {
  backgroudColor,
  maxWidthMobile,
  maxWidthTablet,
  RoutesOptions,
} from "../../utils/constants"
import {
  formatterDate,
  freeDays,
} from "../account-details/utils-account-details"
import { ModalSubscription } from "../modal-subscription/modal-subscription"
import { Navigationbar } from "../navbar/navbar"
import { ErrorSuccesNotification } from "../notification/notification"
import { ClientAddEdit } from "./client-add-edit"
import { GeneralTableComponent } from "./general-table-component"

export const ClientsPage = () => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const isTablet = useMediaQuery(maxWidthTablet)
  const [openModalAddClient, setOpenModalAddClient] = useState(false)
  const [nrOfClients, setNrOfClient] = useState(0)
  const isTrial = sessionStorage.getItem("isTrial") === "true" ? true : false
  const subscriptionExpireDate = sessionStorage.getItem(
    "subscriptionExpireDate"
  )
  const navigate = useNavigate()
  const [activeModalSubscription, setActiveModalSubscription] = useState(false)

  const succesCallBack = (data: IUserResponse["data"]) => {
    if (formatterDate(data.response.subscriptionExpireDate) < new Date()) {
      navigate(RoutesOptions.TRIAL_OVER)
    }
  }
  const { refetch } = useGetUser(succesCallBack)

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    setActiveModalSubscription(
      sessionStorage.getItem("activeModalSubscription") === "true"
        ? true
        : false
    )
  }, [activeModalSubscription])

  return (
    <Container
      fluid
      m={0}
      p={0}
      bg={backgroudColor}
      h={isMobile ? "100%" : "100vh"}
    >
      {freeDays(subscriptionExpireDate as string) <= 5 &&
      isTrial &&
      activeModalSubscription ? (
        <ModalSubscription days={freeDays(subscriptionExpireDate as string)} />
      ) : null}
      <Flex h={"100%"} direction={isTablet ? "column" : "row"}>
        <Navigationbar />
        <Flex
          direction={"column"}
          w={"100%"}
          h={isTablet ? "calc(100% - 3rem)" : "100%"}
          justify="space-between"
        >
          <Flex justify="space-between" align="flex-end" h="3rem">
            <Title order={4} ml="md">
              Clienți ({nrOfClients})
            </Title>
            <Button
              variant="light"
              w="10rem"
              mx="md"
              radius="xl"
              size="xs"
              onClick={() => setOpenModalAddClient(true)}
            >
              Adaugă client
            </Button>
          </Flex>
          <GeneralTableComponent
            nrOfClients={nrOfClients}
            setNrOfClient={setNrOfClient}
          />
        </Flex>
      </Flex>
      <ClientAddEdit
        isOpenModal={openModalAddClient}
        setOpenModal={setOpenModalAddClient}
        isEditMode={false}
      />
      <ErrorSuccesNotification />
    </Container>
  )
}
