import axios, { AxiosResponse } from "axios"
import { useMutation } from "@tanstack/react-query"
import { inventoryUrlCreate, RoutesOptions } from "../../utils/constants"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setErrorNotification } from "../../redux/notification/slice"
import { IItemInventory } from "../../types/items-inventory"
import { IResponse } from "../../types/api.types"
import { getGenericErrorMessage } from "../../utils/error-messages"

export const usePostItemInventory = (
  successCallBack: (data: IResponse) => void,
  errorCallBack: (data: Error) => void
) => {
  const authToken = sessionStorage.getItem("authToken")

  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useMutation(
    ["create-item-inventory-key"],
    (itemInventoryData: BodyInit | IItemInventory) =>
      axios.post(inventoryUrlCreate, itemInventoryData, config),
    {
      onSuccess: (data: AxiosResponse<IResponse, IResponse>) => {
        successCallBack(data.data)
      },
      onError: (error: any) => {
        if ([401].includes(error.response.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(
            setErrorNotification(
              getGenericErrorMessage(error.response.data.message)
            )
          )
        }
        errorCallBack(error)
      },
    }
  )
}
