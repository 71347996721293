export enum ClientsFields {
  CLIENT_NAME = "name",
  CUI = "fiscalCode",
  CNP = "CNP",
  EMAIL = "email",
  PHONE = "phoneNumber",
  CREATED_DATE = "createDate",
  LAST_UPDATED_DATE = "lastUpdateDate",
  WORK_ITEMS = "workItemsNumber",
}
export const TableColumns: Record<ClientsFields, string> = {
  [ClientsFields.CUI]: "CUI",
  [ClientsFields.CNP]: "CNP",
  [ClientsFields.CLIENT_NAME]: "Denumire client",
  [ClientsFields.EMAIL]: "E-mail",
  [ClientsFields.PHONE]: "Telefon",
  [ClientsFields.CREATED_DATE]: "Data creare",
  [ClientsFields.LAST_UPDATED_DATE]: "Ultima actualizare",
  [ClientsFields.WORK_ITEMS]: "Total lucrari",
}
export enum DocumentsOptions {
  OTHER_DOCUMENTS = "otherDocs",
}

export enum TableWorkItemsFields {
  CLIENT_NAME = "name",
  COUNTY = "county",
  WORK_ITEM_TYPE = "workItemType",
  STATUS = "status",
  DEADLINE = "deadline",
  RESPONSABLE = "responsable",
  CREATED_DATE = "createDate",
  LAST_UPDATED_DATE = "lastUpdateDate",
}
export const TableColumnsWorkItems: Record<TableWorkItemsFields, string> = {
  [TableWorkItemsFields.CLIENT_NAME]: "Client",
  [TableWorkItemsFields.WORK_ITEM_TYPE]: "Tip lucrare",
  [TableWorkItemsFields.COUNTY]: "Județ",
  [TableWorkItemsFields.STATUS]: "Status",
  [TableWorkItemsFields.DEADLINE]: "Termen limită",
  [TableWorkItemsFields.RESPONSABLE]: "Responsabil",
  [TableWorkItemsFields.CREATED_DATE]: "Data creare",
  [TableWorkItemsFields.LAST_UPDATED_DATE]: "Ultima actualizare",
}
export enum WorkItemsFields {
  AMOUNT = "amount",
  CALC_FORMULA = "calcFormula",
  CLIENT_NAME = "name",
  CREATE_DATE = "createDate",
  LAST_UPDATE_DATE = "lastUpdateDate",
  WORK_ITEM_TYPE = "workItemType",
  RESPONSABLE = "responsable",
  COUNTY = "county",
  CITY = "city",
  STATUS = "status",
  DEADLINE = "deadline",
  OTHER_DOCUMENTS = "otherDocs",
  ADVANCE_PAYMENT = "advancePayment",
  REST_PAYMENT = "restPayment",
  TOTAL_PAYMENT = "totalPayment",
  TAXES = "taxes",
  ADDRESS = "address",
}

export const ComponentViewWorkItems: Record<WorkItemsFields, string> = {
  [WorkItemsFields.AMOUNT]: "Cantitate",
  [WorkItemsFields.CALC_FORMULA]: "Formula de calcul",
  [WorkItemsFields.CLIENT_NAME]: "Client",
  [WorkItemsFields.CITY]: "Localitate",
  [WorkItemsFields.COUNTY]: "Județ",
  [WorkItemsFields.WORK_ITEM_TYPE]: "Tip lucrare",
  [WorkItemsFields.STATUS]: "Status",
  [WorkItemsFields.DEADLINE]: "Termen limită",
  [WorkItemsFields.RESPONSABLE]: "Responsabil",
  [WorkItemsFields.TOTAL_PAYMENT]: "Total de plată",
  [WorkItemsFields.ADVANCE_PAYMENT]: "Plată în avans",
  [WorkItemsFields.REST_PAYMENT]: "Rest de plată",
  [WorkItemsFields.TAXES]: "Taxe",
  [WorkItemsFields.CREATE_DATE]: "Dată creare",
  [WorkItemsFields.LAST_UPDATE_DATE]: "Dată actualizare",
  [WorkItemsFields.OTHER_DOCUMENTS]: "Alte documente",
  [WorkItemsFields.ADDRESS]: "Adresă",
}
export enum ItemsInventoryFields {
  NAME = "name",
  VALUE = "value",
  CONTA_NUMBER = "contaNr",
  TOTAL_NUMBER = "total",
  TOTAL_PRICE = "pricePiece",
  SERIAL_NUMBER = "serialNumber",
  CHARACTERISTICS = "characteristics",
}
export enum InventoryTypes {
  FIXED_ASSETS = "fixedAssets",
  INVENTORY_OBJECTS = "inventoryObjects",
}
export enum CustomizeTypes {
  STATUS_CUSTOMIZE = "statusCustomize",
  WORKITEM_TYPE_CUSTOMIZE = "workitemTypeCustomize",
  PRICE_CUSTOMIZE = "priceCustomize",
}
export enum ClientTypes {
  LEGAL_CLIENT = "Persoană juridică",
  INDIVIDUAL_CLIENT = "Persoană fizică",
}
export const ComponentItemsInventory: Record<ItemsInventoryFields, string> = {
  [ItemsInventoryFields.NAME]: "Denumire",
  [ItemsInventoryFields.VALUE]: "Preț / buc",
  [ItemsInventoryFields.CONTA_NUMBER]: "Număr contabilitate",
  [ItemsInventoryFields.TOTAL_NUMBER]: "Număr bucăți",
  [ItemsInventoryFields.TOTAL_PRICE]: "Preț total",
  [ItemsInventoryFields.SERIAL_NUMBER]: "S/N",
  [ItemsInventoryFields.CHARACTERISTICS]: "Caracteristici tehnice",
}

export enum WorkItemStatuses {
  FINALIZAT = "FINALIZAT",
  DRAFT = "DRAFT",
}
export enum CustomizeElements {
  STATUS = "status",
  WORK_ITEM_TYPE = "tipul lucrării",
  CALC_FORMULA = "formula de calcul",
}
