import { IconBellRinging } from "@tabler/icons-react"
import { Notification } from "@mantine/core"
import { useStyles } from "./navbar.style"
import { renderMessage } from "../login/login.utils"
import { FunctionComponent } from "react"

export const NavbarNotification: FunctionComponent<{
  isTrial: boolean
  freeDays: number
}> = ({ isTrial, freeDays }) => {
  const { classes } = useStyles()
  return (
    <Notification
      icon={<IconBellRinging size="1.2rem" />}
      withCloseButton={false}
      color="yellow"
      radius="lg"
      classNames={{ description: classes.description }}
    >
      {renderMessage(isTrial, freeDays)}
    </Notification>
  )
}
