/* eslint-disable array-callback-return */
import {
  Button,
  Container,
  Flex,
  Paper,
  Title,
  Text,
  SimpleGrid,
  ScrollArea,
  createStyles,
  LoadingOverlay,
  Group,
  Image,
  Stack,
  ActionIcon,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconPlus, IconX } from "@tabler/icons-react"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useGetProfile } from "../../api/profile/use-get-profile"
import { usePostNewField } from "../../api/profile/use-post-new-field"
import { usePutFieldsProfile } from "../../api/profile/use-put-fields"
import { useGetUser } from "../../api/user/use-get-user"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import { IFile, IValueField } from "../../types/profile-types"
import { IUserResponse } from "../../types/user-types"
import {
  backgroudColor,
  maxWidthMobile,
  maxWidthTablet,
  RoutesOptions,
} from "../../utils/constants"
import { reverseFormaterDiacritics } from "../../utils/strings"
import { formatterDate } from "../account-details/utils-account-details"
import { renderRightControl } from "../clients-page/utils-clients-page/utils-edit-client"
import { GenericModal } from "../generic-modal/generic-modal"
import { Navigationbar } from "../navbar/navbar"
import { ErrorSuccesNotification } from "../notification/notification"
import { uploadChunkProfile } from "../view-edit-add-work-item-page/utils-edit-add-work-item/upload-files.util"
import { AddFieldProfile } from "./add-field-profile"
import { ContentPDFViewProfile } from "./utils-profile/preview-pdf-profile"
import { filterFiles } from "./utils-profile/utils"

const useStyles = createStyles(() => ({
  containerField: {
    border: "1px solid #f4f3ee",
    boxShadow: "0px 0px 4px 1px rgba(206,206,206,0.46)",
  },
}))

export const ProfilePage = () => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const userUuid = sessionStorage.getItem("uuid")
  const [showModalAddFieldProfile, setShowAddFieldProfile] = useState(false)
  const [arrayOfInfo, setArrayOfInfo] = useState<IValueField[] | []>()
  const [sortArray, setSortArray] = useState<IValueField[] | []>()
  const [editMode, setEditMode] = useState(false)
  const [openModalConfirmationCancel, setOpenModalConfirmationCancel] =
    useState(false)
  const [currentFileIndex, setCurrentFileIndex] = useState(0)
  const [isUploadingFiles, setIsUploadingFiles] = useState(false)
  const [files, setFiles] = useState<IFile[]>([])
  const [deletedDocs, setDeletedDocs] = useState<string[]>([])
  const [addedDocs, setAddedDocs] = useState<[]>([])
  const [nameFilesData, setNameFilesData] = useState<string[]>()
  const navigate = useNavigate()

  const { data, refetch, isRefetching, isLoading } = useGetProfile()

  const succesCallBack = (data: IUserResponse["data"]) => {
    if (formatterDate(data.response.subscriptionExpireDate) < new Date()) {
      navigate(RoutesOptions.TRIAL_OVER)
    }
  }
  const { refetch: refetchUser, isRefetching: isRefetchingUser } =
    useGetUser(succesCallBack)

  useEffect(() => {
    refetch()
    refetchUser()
  }, [])

  useEffect(() => {
    if (data) {
      setArrayOfInfo(data.data.response.value)
      const newFilesNameArray: string[] = []

      data.data.response.value &&
        data.data.response.value.forEach((item: IValueField) => {
          if (item.typeField === "PDF") {
            newFilesNameArray.push(item.fileNameStorage as string)
          }
        })
      setNameFilesData(newFilesNameArray)
    }
    setFiles([])
  }, [data])

  useEffect(() => {
    let sortedArray =
      arrayOfInfo &&
      arrayOfInfo.sort((a, b) => {
        if (a.typeField === "TEXT" && b.typeField !== "TEXT") {
          return -1
        }
        if (a.typeField !== "TEXT" && b.typeField === "TEXT") {
          return 1
        }
        if (a.typeField < b.typeField) {
          return -1
        }
        if (a.typeField > b.typeField) {
          return 1
        }
        return 0
      })
    setSortArray(sortedArray)
  }, [arrayOfInfo])

  const contentViewProfileItem = (
    fileNameStorage: string,
    fieldName: string,
    fieldValue: any,
    type: string,
    index: number,
    sortArray: IValueField[] | [],
    setSortArray: any,
    editMode: boolean
  ) => {
    const deleteField = (fileNameStorage: string) => {
      const newArray = _.cloneDeep(sortArray)
      const newDeletedDocs = _.cloneDeep(deletedDocs)
      const newFiles = _.cloneDeep(files)
      newDeletedDocs.push(fileNameStorage)
      setDeletedDocs(newDeletedDocs)
      newArray &&
        newArray.forEach((item, currIndex) => {
          if (currIndex === index) {
            newArray.splice(currIndex, 1)
          }
        })

      const uploadedFiles = newFiles.filter((item) =>
        newDeletedDocs.find((deletedFile) => deletedFile !== item.nameFile)
      )
      setCurrentFileIndex(0)
      setFiles(uploadedFiles)
      setSortArray(newArray)
    }

    return (
      <Stack spacing={0} w="100%">
        <Flex justify={"space-between"}>
          <Text c="#979797" fw={500}>
            {reverseFormaterDiacritics(fieldName)}:
          </Text>
          {editMode === true ? (
            <ActionIcon
              title="Sterge"
              size="sm"
              radius={"xl"}
              variant="light"
              color={"#4A6642"}
              onClick={() => {
                deleteField(fileNameStorage)
              }}
            >
              <IconX size="0.8rem" />
            </ActionIcon>
          ) : null}
        </Flex>

        {type === "PDF" ? (
          <ContentPDFViewProfile
            fieldValue={fieldValue}
            isModeEdit={editMode}
          />
        ) : (
          renderRightControl(reverseFormaterDiacritics(fieldValue))
        )}
      </Stack>
    )
  }

  const viewFields =
    sortArray &&
    sortArray.map((item, index) => {
      if (item.nameField) {
        return (
          <Paper
            key={index}
            w={"auto"}
            h={item.typeField === "TEXT" ? "fit-content" : "auto"}
            p="xs"
            m="xs"
            className={classes.containerField}
          >
            {contentViewProfileItem(
              item.fileNameStorage || "",
              item.nameField,
              item.content,
              item.typeField,
              index,
              arrayOfInfo ? arrayOfInfo : [],
              setArrayOfInfo,
              editMode
            )}
          </Paper>
        )
      }
      return null
    })

  const succesCallback = async (data: any) => {
    dispatch(setMessageNotification(data.message))
    if (files.length > 0) {
      let newFiles: any[] = []
      setIsUploadingFiles(true)
      newFiles = filterFiles(files, data)
      setFiles(newFiles)
      uploadChunkProfile(newFiles, currentFileIndex, setCurrentFileIndex)
    } else if (files.length === 0) {
      refetch()
    }
    setEditMode(false)
  }

  useEffect(() => {
    if (currentFileIndex > 0 && currentFileIndex < files.length) {
      uploadChunkProfile(files, currentFileIndex, setCurrentFileIndex)
    } else if (currentFileIndex > 0 && currentFileIndex === files.length) {
      setIsUploadingFiles(false)
      setEditMode(false)
      refetch()
    }
  }, [currentFileIndex])

  const errorCallback = (error: any) => {
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu! ${error.response.data.message}.`
      )
    )
  }
  const { mutate, isLoading: isLoadingPost } = usePostNewField(
    succesCallback,
    errorCallback
  )

  const succesCallBackUpdate = (data: any) => {
    dispatch(setMessageNotification(data.message))

    if (files.length > 0) {
      let newFiles: any[] = []
      setIsUploadingFiles(true)
      newFiles = filterFiles(files, data)
      setFiles(newFiles)
      uploadChunkProfile(newFiles, currentFileIndex, setCurrentFileIndex)
    } else if (files.length === 0) {
      refetch()
    }

    setEditMode(false)
  }

  const errorCallBackUpdate = (error: any) => {
    dispatch(
      setErrorNotification(
        `Te rog încearcă mai târziu! ${error.response.data.message}.`
      )
    )
  }

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } =
    usePutFieldsProfile(
      succesCallBackUpdate,
      errorCallBackUpdate,
      data && data.data.response.profileUuid
    )

  const onSave = () => {
    const array = arrayOfInfo
      ? _.cloneDeep(arrayOfInfo)
      : ([] as unknown as IValueField[])

    if (data?.data.response.length !== 0) {
      const intersectedDeletecDocs: string[] = _.intersectionBy(
        deletedDocs,
        nameFilesData as string[]
      )

      if (data?.data.response.value === arrayOfInfo) {
        setEditMode(false)
        return
      }
      array.forEach((elem) => {
        if (elem.typeField === "PDF") {
          elem.content = _.omit(elem.content, "content")
        }
      })

      mutateUpdate({
        userUuid: userUuid,
        value: JSON.stringify(array),
        deletedDocs: intersectedDeletecDocs,
        addedDocs: addedDocs,
      })
    } else {
      mutate({ userUuid: userUuid, value: JSON.stringify(array) })
    }
    setAddedDocs([])
    onCancel()
  }

  const onCancel = () => {
    setEditMode(false)
    setArrayOfInfo(data.data.response.value)
    setOpenModalConfirmationCancel(false)
  }

  return (
    <Container fluid m={0} p={0} bg={backgroudColor} h={"100vh"}>
      <Flex h={"100%"} direction={isTablet ? "column" : "row"}>
        <Navigationbar />
        <Flex
          direction={"column"}
          w={"100%"}
          h={isTablet ? "calc(100% - 3rem)" : "100vh"}
          justify="flex-start"
          gap="md"
        >
          <Flex justify="space-between" align="flex-end" h="3rem">
            <Title order={4} ml="md">
              Profil
            </Title>
            {editMode ? (
              <Group spacing={0}>
                <Button
                  mt="md"
                  mx="xs"
                  radius="xl"
                  size="xs"
                  onClick={() => onSave()}
                >
                  Salvează
                </Button>
                <Button
                  variant="light"
                  mt="md"
                  mx="xs"
                  radius="xl"
                  size="xs"
                  onClick={() => setOpenModalConfirmationCancel(true)}
                >
                  Anulează
                </Button>
              </Group>
            ) : (
              <Button
                variant="light"
                w="10rem"
                mt="md"
                mx="xs"
                radius="xl"
                size="xs"
                onClick={() => {
                  setEditMode(true)
                  setDeletedDocs([])
                }}
              >
                Editează profil
              </Button>
            )}
          </Flex>
          <Paper
            mx={"md"}
            mb="xl"
            radius="md"
            p={"xs"}
            h={isTablet ? "calc(100% - 5.5rem)" : "calc(100% - 8rem)"}
          >
            <ScrollArea h={"100%"} type="auto" scrollbarSize={6}>
              {sortArray && sortArray.length > 0 ? (
                <SimpleGrid
                  cols={isMobile ? 1 : 2}
                  spacing="xs"
                  verticalSpacing="xs"
                >
                  {viewFields}
                </SimpleGrid>
              ) : (
                <>
                  <Text align={"center"} pt="md">
                    <Text align="center">
                      Momentan nu a fost adăugat nici un câmp
                    </Text>
                  </Text>
                  <Image
                    maw={450}
                    mx="auto"
                    radius="md"
                    src="/profile.svg"
                    alt="Profile"
                  />
                </>
              )}
              {editMode ? (
                <Flex justify={"center"} m="md">
                  <Button
                    radius="xl"
                    size="xs"
                    leftIcon={<IconPlus size="1rem" />}
                    onClick={() => setShowAddFieldProfile(true)}
                  >
                    Adăugă câmp
                  </Button>
                </Flex>
              ) : null}
            </ScrollArea>
          </Paper>
        </Flex>
        <AddFieldProfile
          isOpenModal={showModalAddFieldProfile}
          setOpenModal={setShowAddFieldProfile}
          setArrayOfInfo={setArrayOfInfo}
          arrayOfInfo={arrayOfInfo ? arrayOfInfo : []}
          setFiles={setFiles}
          files={files}
          setAddedDocs={setAddedDocs}
          addedDocs={addedDocs}
        />
      </Flex>
      <GenericModal
        setOpenModal={setOpenModalConfirmationCancel}
        mainActionText={"Redirecționează"}
        title={"Urmează să pierzi schimbările facute pe acestă pagină."}
        subTitle={"Ești sigur(ă) că vrei să fii redirecționat?"}
        isOpenModal={openModalConfirmationCancel}
        onHandle={() => onCancel()}
        isLoading={false}
      />
      <LoadingOverlay
        visible={
          isRefetching ||
          isLoadingPost ||
          isLoadingUpdate ||
          isLoading ||
          isUploadingFiles ||
          isRefetchingUser
        }
      />
      <ErrorSuccesNotification />
    </Container>
  )
}
