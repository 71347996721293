import { MutateOptions } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import { priceAfterDiscount } from "../components/account-details/utils-account-details"
import { IResponse } from "../types/api.types"
import { IUser, IUserPaymentBody } from "../types/user-types"

export const customization = {
  inputBorderRadius: "15px",
  payButtonBorderColor: "#ceddca",
  payButtonBackgroundColor: "#ceddca",
  payButtonColor: "#4a6642",
  payButtonBackgroundColorHover: "rgba(150, 181, 140, 0.65)",
}

export const callbackParams = (
  userDataResponse: IUser | undefined,
  userPaymentErrorCallback: (err: any) => void,
  mutate: (
    variables: IUserPaymentBody | BodyInit,
    options?:
      | MutateOptions<
          AxiosResponse<IResponse, IResponse>,
          any,
          IUserPaymentBody | BodyInit,
          unknown
        >
      | undefined
  ) => void
) => {
  const params = {
    isSandbox: false,
    onSuccess: (data: any) => {
      const reqBody: IUserPaymentBody = {
        userUuid: userDataResponse?.userUuid,
        paymentId: data.OrderID,
        subscriptionExpireDate: userDataResponse?.subscriptionExpireDate,
      }
      mutate(reqBody)
    },
    onError: (err: any) => {
      userPaymentErrorCallback({ message: err })
    },
  }
  return params
}
export const paymentParms = (affiliateCode?: string) => {
  return {
    sid: process.env.REACT_APP_SID,
    ipcLanguage: "ro",
    walletNumber: process.env.REACT_APP_WALLET_NUMBER,
    amount: affiliateCode
      ? priceAfterDiscount(affiliateCode).newPrice
      : standardPrice.newPrice,
    currency: process.env.REACT_APP_CURRENCY,
    orderID:
      new Date().toJSON() + "-" + Math.random().toString(36).substr(2, 9),
    urlNotify: process.env.REACT_APP_BACKEND_URL + "/client/ipcNotify",
    keyIndex: 1,
    cartItems: [
      {
        article: process.env.REACT_APP_ARTICLE,
        quantity: 1,
        price: affiliateCode
          ? priceAfterDiscount(affiliateCode).newPrice
          : standardPrice.newPrice,
        currency: process.env.REACT_APP_CURRENCY,
      },
    ],
  }
}
export const standardPrice = {
  newPrice: process.env.REACT_APP_AMOUNT,
  discount: 0,
}
