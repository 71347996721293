import { Button, Container, Flex, Title } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { useEffect, useState } from "react"
import {
  backgroudColor,
  maxWidthMobile,
  maxWidthTablet,
  RoutesOptions,
} from "../../utils/constants"
import { Navigationbar } from "../navbar/navbar"
import { EditAddEmployee } from "../employees-page/employee-edit-add"
import { GeneralTableComponentEmployees } from "./general-table-component-employee"
import { ErrorSuccesNotification } from "../notification/notification"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { useGetUser } from "../../api/user/use-get-user"
import { useNavigate } from "react-router-dom"
import { formatterDate } from "../account-details/utils-account-details"
import { IUserResponse } from "../../types/user-types"

export const EmployeesPage = () => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const isTablet = useMediaQuery(maxWidthTablet)
  const [openModalAddEmployee, setOpenModalAddEmployee] = useState(false)

  const { countEmployees: count } = useSelector(
    (state: RootState) => state.employees
  )
  const navigate = useNavigate()

  const succesCallBack = (data: IUserResponse["data"]) => {
    if (formatterDate(data.response.subscriptionExpireDate) < new Date()) {
      navigate(RoutesOptions.TRIAL_OVER)
    }
  }
  const { refetch } = useGetUser(succesCallBack)

  useEffect(() => {
    refetch()
  }, [])

  return (
    <Container
      fluid
      m={0}
      p={0}
      bg={backgroudColor}
      h={isMobile ? "100%" : "100vh"}
    >
      <Flex h={"100%"} direction={isTablet ? "column" : "row"}>
        <Navigationbar />
        <Flex
          direction={"column"}
          w="100%"
          h={isTablet ? "calc(100% - 4rem)" : "100%"}
          justify="space-between"
        >
          <Flex justify="space-between" align="flex-end" h="3rem">
            <Title order={4} ml="md">
              Angajați({count})
            </Title>
            <Button
              variant="light"
              w="10rem"
              mx="xs"
              radius="xl"
              size="xs"
              onClick={() => setOpenModalAddEmployee(true)}
            >
              Adaugă angajat
            </Button>
          </Flex>
          <GeneralTableComponentEmployees />
          <EditAddEmployee
            isOpenModal={openModalAddEmployee}
            setOpenModal={setOpenModalAddEmployee}
            isEditMode={false}
          />
        </Flex>
      </Flex>
      <ErrorSuccesNotification />
    </Container>
  )
}
