import {
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  TextInput,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useGetInventory } from "../../api/inventory/use-get-inventory"
import { usePostItemInventory } from "../../api/inventory/use-post-item-invetory"
import { usePutItemInventory } from "../../api/inventory/use-put-item-inventory"
import { resetCurrentPageInventory } from "../../redux/item-inventory/item-inventory-slice"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import { RootState } from "../../redux/store"
import { IItemInventory } from "../../types/items-inventory"
import {
  convertEurToRon,
  limitPage,
  maxWidthMobile,
} from "../../utils/constants"
import { formaterDiacritics } from "../../utils/strings"
import { uploadChunk } from "../view-edit-add-work-item-page/utils-edit-add-work-item/upload-files.util"
import { ModalSectionFixedAssets } from "./modal-section-fixed-assets"
import {
  buildPayloadForUpdateItemInventory,
  initialValuesItemInventory,
  resetValuesItemInventory,
  validateFormItemInventory,
} from "./utils-inventory/form-item-inventory-utils"
import {
  calcTotalValue,
  selectCurrency,
} from "./utils-inventory/function-for-invetory-add-edit"

export const ItemInventoryAddEdit: FunctionComponent<{
  isEditMode: boolean
  isOpenModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  itemInventoryDetails?: IItemInventory
}> = ({ isEditMode, isOpenModal, setOpenModal, itemInventoryDetails }) => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const [currencySelected, setCurrencySelected] = useState<string>("ron")
  const [showFieldsFixedAssets, setShowFieldsFixedAssets] = useState(false)
  const [currentFileIndex, setCurrentFileIndex] = useState(0)
  const [files, setFiles] = useState<File[]>([])
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0)
  const [validateFixedAssests, setValidateFixedAssests] = useState(true)
  const [deletedPhoto, setDeletedPhoto] = useState<string>("")
  const [addedPhoto, setAddedPhoto] = useState<string>("")
  const dispatch = useDispatch()

  const { currentPageFixedAssets, currentPageInventoryObjects } = useSelector(
    (state: RootState) => state.itemsInventory
  )
  const formItemInventory = useForm<IItemInventory>({
    initialValues:
      itemInventoryDetails &&
      initialValuesItemInventory(itemInventoryDetails as IItemInventory),
    validate: validateFormItemInventory,
  })

  useEffect(() => {
    if (isEditMode && itemInventoryDetails) {
      resetValuesItemInventory(formItemInventory, itemInventoryDetails)
      setCurrencySelected(itemInventoryDetails.currency)
    }
    if (itemInventoryDetails) {
      resetValuesItemInventory(formItemInventory, itemInventoryDetails)
    }
    if (!isEditMode) {
      formItemInventory.setValues({
        currency: currencySelected,
      })
    }
    setCurrentFileIndex(0)
    setFiles([])
    formItemInventory.resetDirty()
  }, [itemInventoryDetails, isOpenModal])

  useEffect(() => {
    if (currentFileIndex > 0 && currentFileIndex < files.length) {
      uploadChunk(
        files,
        formItemInventory.values.itemUuid,
        currentFileIndex,
        setCurrentFileIndex
      )
    } else if (currentFileIndex > 0 && currentFileIndex === files.length) {
      setIsUploadingPhoto(false)
    }
    refetchFixedAssets()
  }, [currentFileIndex])

  useEffect(() => {
    formItemInventory.setValues({
      currency: currencySelected,
      value: "",
    })
    formItemInventory.resetDirty()
  }, [currencySelected])

  useEffect(() => {
    if (currencySelected === "ron") {
      if (parseFloat(formItemInventory.values.value) >= 2500) {
        formItemInventory.setValues({
          type: "fixedAssets",
        })
      } else {
        formItemInventory.setValues({
          type: "inventoryObjects",
        })
      }
    } else {
      if (
        parseFloat(formItemInventory.values.value) * convertEurToRon >=
        2500
      ) {
        formItemInventory.setValues({
          type: "fixedAssets",
        })
      } else {
        formItemInventory.setValues({
          type: "inventoryObjects",
        })
      }
    }

    if (currencySelected === "ron") {
      if (parseInt(formItemInventory.values.value) >= 2500) {
        setShowFieldsFixedAssets(true)
      } else {
        setShowFieldsFixedAssets(false)
      }
    } else if (currencySelected === "euro") {
      if (
        parseInt(formItemInventory.values.value, 10) * convertEurToRon >=
        2500
      ) {
        setShowFieldsFixedAssets(true)
      } else {
        setShowFieldsFixedAssets(false)
      }
    }
  }, [formItemInventory.values.value, currencySelected])

  useEffect(() => {
    calcTotalValue(formItemInventory, setTotalPrice)
  }, [formItemInventory.values.value, formItemInventory.values.total])

  const { refetch: refetchFixedAssets } = useGetInventory(
    currentPageFixedAssets,
    limitPage,
    "fixedAssets"
  )
  const { refetch: refetchInventoryObjects } = useGetInventory(
    currentPageInventoryObjects,
    limitPage,
    "inventoryObjects"
  )

  const succesCallBackPost = (data: any) => {
    formItemInventory.setFieldValue("itemUuid", data.response.itemUuid)
    if (formItemInventory.values.photo) {
      setIsUploadingPhoto(true)
      uploadChunk(
        files,
        data.response.itemUuid,
        currentFileIndex,
        setCurrentFileIndex
      )
    }
    formItemInventory.reset()
    dispatch(resetCurrentPageInventory())
    dispatch(setMessageNotification(data.message))
    refetchFixedAssets()
    setOpenModal(false)
    refetchInventoryObjects()
    setCurrentFileIndex(0)
    setFiles([])
    setShowFieldsFixedAssets(false)
  }

  const errorCallBackPost = (error: any) => {
    dispatch(setErrorNotification(error))
  }
  const { mutate, isLoading: isLoadingPost } = usePostItemInventory(
    succesCallBackPost,
    errorCallBackPost
  )
  const {
    value,
    type,
    total,
    serialNumber,
    name,
    currency,
    contaNr,
    characteristics,
  } = formItemInventory.values

  const onCreateItemInventory = () => {
    if (
      formItemInventory.validate().hasErrors === false &&
      validateFixedAssests
    ) {
      addedPhoto.length === 0
        ? mutate({
            userUuid: sessionStorage.getItem("uuid"),
            value,
            type,
            total,
            serialNumber,
            name: formaterDiacritics(name),
            currency,
            contaNr,
            characteristics: characteristics
              ? formaterDiacritics(characteristics)
              : characteristics,
          })
        : mutate({
            ...formItemInventory.values,
            userUuid: sessionStorage.getItem("uuid"),
            name: formaterDiacritics(name),
            characteristics: characteristics
              ? formaterDiacritics(characteristics)
              : characteristics,
          })
    }
  }

  const succesCallBackPut = (data: any) => {
    if (files && files.length > 0) {
      setIsUploadingPhoto(true)
      uploadChunk(
        files,
        itemInventoryDetails && itemInventoryDetails.itemUuid,
        currentFileIndex,
        setCurrentFileIndex
      )
    } else {
      refetchFixedAssets()
      dispatch(setMessageNotification(data.message))
    }

    refetchInventoryObjects()
    formItemInventory.reset()
    formItemInventory.resetDirty()
    setOpenModal(false)
  }

  const errorCallBackPut = (error: any) => {
    dispatch(setErrorNotification(error))
    formItemInventory.reset()
  }

  const { mutate: mutateUpdate, isLoading: isLoadingPut } = usePutItemInventory(
    succesCallBackPut,
    errorCallBackPut,
    itemInventoryDetails ? itemInventoryDetails.itemUuid : ""
  )

  const onEditItemInventory = () => {
    if (
      formItemInventory.validate().hasErrors === false &&
      validateFixedAssests
    ) {
      const paylodUpdate = buildPayloadForUpdateItemInventory(
        formItemInventory,
        deletedPhoto,
        files.length === 0 ? "" : addedPhoto
      )
      const checkIsDirty =
        formItemInventory.isDirty("characteristics") ||
        formItemInventory.isDirty("contaNr") ||
        formItemInventory.isDirty("currency") ||
        formItemInventory.isDirty("deletedPhoto") ||
        formItemInventory.isDirty("name") ||
        formItemInventory.isDirty("photo") ||
        formItemInventory.isDirty("serialNumber") ||
        formItemInventory.isDirty("total") ||
        !(formItemInventory.values.value === itemInventoryDetails?.value)

      checkIsDirty ? mutateUpdate(paylodUpdate) : onCloseModal()
      setDeletedPhoto("")
      setAddedPhoto("")
    }
  }

  useEffect(() => {
    if (currentFileIndex > 0 && currentFileIndex < files.length) {
      uploadChunk(
        files,
        formItemInventory.values.itemUuid,
        currentFileIndex,
        setCurrentFileIndex
      )
    } else if (currentFileIndex > 0 && currentFileIndex === files.length) {
      setOpenModal(false)
      formItemInventory.reset()
    }
    setIsUploadingPhoto(false)
  }, [currentFileIndex])

  const onCloseModal = () => {
    setOpenModal(false)
    formItemInventory.resetDirty()
    formItemInventory.clearErrors()
    formItemInventory.reset()
    setDeletedPhoto("")
    setAddedPhoto("")
  }

  return (
    <>
      <Modal
        size={isMobile ? (showFieldsFixedAssets ? "lg" : "xs") : "md"}
        opened={isOpenModal}
        onClose={() => onCloseModal()}
        title={isEditMode ? "Editare" : "Adăugare în inventar"}
        centered
        xOffset={0}
        styles={{ close: { height: "2rem", width: "20px" } }}
        zIndex={2000}
      >
        <form
          onSubmit={formItemInventory.onSubmit(
            isEditMode ? onEditItemInventory : onCreateItemInventory
          )}
        >
          <Flex direction={"column"} gap="md">
            <TextInput
              name="name"
              label="Denumire:"
              required
              placeholder="Introduceți numele itemului "
              {...formItemInventory.getInputProps("name")}
            />

            <TextInput
              label="Preț / buc:"
              name="value"
              required
              type={"number"}
              placeholder="Introduceți valoarea "
              {...formItemInventory.getInputProps("value")}
              rightSection={selectCurrency(
                currencySelected,
                setCurrencySelected
              )}
              rightSectionWidth={105}
            />
            <TextInput
              name="total"
              required
              label="Număr bucați:"
              placeholder="Introduceți valoarea "
              {...formItemInventory.getInputProps("total")}
            />
            <TextInput
              label="Preț total:"
              disabled
              value={totalPrice}
              onChange={() => {
                calcTotalValue(formItemInventory, setTotalPrice)
              }}
            />
            {showFieldsFixedAssets ? (
              <ModalSectionFixedAssets
                isEditMode={isEditMode}
                formItemInventory={formItemInventory}
                itemInventoryDetails={itemInventoryDetails}
                setFiles={setFiles}
                files={files}
                setValidateFixedAssests={setValidateFixedAssests}
                setDeletedPhoto={setDeletedPhoto}
                setAddedPhoto={setAddedPhoto}
              />
            ) : null}

            <TextInput
              label="Număr contabilitate:"
              name="contaNr"
              required
              placeholder="Introduceți numărul contabilitate "
              {...formItemInventory.getInputProps("contaNr")}
            />
          </Flex>
          <Group position="center" mt="md">
            {isEditMode ? (
              <Group>
                <Button radius="xl" type="submit">
                  Salvează
                </Button>
                <Button variant={"light"} radius="xl" onClick={onCloseModal}>
                  Anulează
                </Button>
              </Group>
            ) : (
              <Button variant={"light"} radius="xl" type="submit">
                Adaugă item
              </Button>
            )}
          </Group>
        </form>
        <LoadingOverlay
          h="100%"
          visible={isLoadingPost || isLoadingPut || isUploadingPhoto}
        />
      </Modal>
    </>
  )
}
