/* eslint-disable array-callback-return */
import { IFile } from "../../../types/profile-types"

export const filterFiles = (files: IFile[], data: any) =>
  files.filter(() => {
    const dataResponse = data.response
    const copyFiles = [...files]
    dataResponse.map((res: any) => {
      if (res.typeField === "PDF") {
        const foundedFile = copyFiles.find((e) => e.idField === res.idField)
        if (foundedFile !== undefined) {
          foundedFile.nameFile = res.fileNameStorage
        }
      }
    })
    return copyFiles
  })
