import { createSlice } from '@reduxjs/toolkit'
import { IClient } from '../../types/client-types'
import type { RootState } from '../store'

const initialState: {
  clientsEntriesData: IClient[]
  currentPageClient: number
} = {
  clientsEntriesData: [],
  currentPageClient: 1,
}

export const clientsSlice = createSlice({
  name: 'clients_slice',
  initialState: initialState,
  reducers: {
    setClients: (state, action) => {
      state.clientsEntriesData = action.payload
    },
    setCurrentPageClient: (state, action) => {
      state.currentPageClient = action.payload
    },
    resetCurrentPageClient: (state) => {
      state.currentPageClient = initialState.currentPageClient
    },
  },
})
export const { setClients, setCurrentPageClient, resetCurrentPageClient } = clientsSlice.actions
export const selectorClients = (state: RootState) => {
  return state.clients
}
export default clientsSlice.reducer
