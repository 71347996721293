import { useMutation } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setErrorNotification } from '../../redux/notification/slice'
import { employeesUrlDelete, RoutesOptions } from '../../utils/constants'
import { IResponse } from '../../types/api.types'
import { getGenericErrorMessage } from '../../utils/error-messages'

export const useDeleteEmployees = (
  employeeUuid: string,
  successCallBack: (data: IResponse) => void,
  errorCallBack: (data: Error) => void,
) => {
  const authToken = sessionStorage.getItem('authToken')
  let config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const employeeDelete: string = `${employeesUrlDelete}/${employeeUuid}`
  return useMutation(
    ['delete-employee-key'],
    () => axios.delete(employeeDelete, config),
    {
      onSuccess: (data: AxiosResponse<IResponse, IResponse>) => {
        successCallBack(data.data)
      },
      onError: (error: any) => {
        if ([401].includes(error.response.status)) {
          navigate(RoutesOptions.LOGIN)
        } else {
          dispatch(
            setErrorNotification(
              getGenericErrorMessage(error.response.data.message),
            ),
          )
        }
        errorCallBack(error)
      },
    },
  )
}
