import { Flex, Modal, Table, Text, Image } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { FunctionComponent, useState } from "react"
import { maxWidthMobile, maxWidthTablet } from "../../../utils/constants"
import { InventoryTypes } from "../../../utils/enum"
import {
  rowsTableFixedAssets,
  rowsTableFixedAssetsMobile,
} from "./row-items-fixed-assets"

import {
  rowsTableInventoryObjectMobile,
  rowsTableInventoryObjects,
} from "./row-items-invetory-objects"
import { useStyles } from "./tables-item-invetory-classes"

export const TabelItemInventory: FunctionComponent<{
  dataInventory: any
  tabInventary: string
}> = (props) => {
  const { dataInventory, tabInventary } = props
  const { classes } = useStyles()
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const [showImage, setShowImage] = useState(false)
  const [displayPhoto, setDisplayPhoto] = useState()
  const [readMore, setReadMore] = useState(true)

  const rowsInventoryObjects =
    dataInventory &&
    dataInventory.response.map((itemInventoryObjects: any) =>
      isMobile
        ? rowsTableInventoryObjectMobile(itemInventoryObjects)
        : rowsTableInventoryObjects(itemInventoryObjects)
    )
  
  const rowsFixedAssets =
    dataInventory &&
    dataInventory.response.map((itemInventoryObjects: any) => 
      isMobile
        ? rowsTableFixedAssetsMobile(
            itemInventoryObjects,
            setDisplayPhoto,
            setShowImage,
            readMore,
            setReadMore
          )
        : rowsTableFixedAssets(
            itemInventoryObjects,
            setDisplayPhoto,
            setShowImage
          )
    )

  return (
    <>
      <Flex direction={"column"} h={"100%"}>
        {dataInventory?.entries > 0 ? (
          isMobile ? (
            <Table sx={{ tableLayout: "fixed" }} w={"100%"}>
              {tabInventary === InventoryTypes.FIXED_ASSETS ? (
                <tbody>{rowsFixedAssets}</tbody>
              ) : (
                <tbody>{rowsInventoryObjects}</tbody>
              )}
            </Table>
          ) : (
            <Table w={"100%"} highlightOnHover>
              {tabInventary === InventoryTypes.FIXED_ASSETS ? (
                <thead className={classes.header}>
                  <tr>
                    <th>Nume</th>
                    <th>Preț / buc</th>
                    <th>Preț total</th>
                    <th>S / N</th>
                    <th>Caracteristici tehnice</th>
                    <th>Număr contabilitate</th>
                    <th>Numărul de bucăți</th>
                    <th></th>
                  </tr>
                </thead>
              ) : (
                <thead className={classes.header}>
                  <tr>
                    <th>Nume</th>
                    <th>Preț / buc</th>
                    <th>Preț total</th>
                    <th>Număr contabilitate</th>
                    <th>Numărul de bucăți</th>
                    <th></th>
                  </tr>
                </thead>
              )}

              {tabInventary === InventoryTypes.FIXED_ASSETS ? (
                <tbody>{rowsFixedAssets}</tbody>
              ) : (
                <tbody>{rowsInventoryObjects}</tbody>
              )}
            </Table>
          )
        ) : (
          <>
            <Text align={"center"} pt="md">
              <Text align="center">Nu exista obiecte de inventar!</Text>
            </Text>

            <Image
              maw={isTablet ? 450 : 550}
              mx="auto"
              alt="No found inventory objects"
              radius="lg"
              src="/inventory.svg"
            />
          </>
        )}
        <Modal
          opened={showImage}
          onClose={() => {
            setShowImage(false)
          }}
          centered
          size={isMobile ? "calc(100vw - 3rem)" : "md"}
          xOffset={0}
          zIndex={2000}
        >
          <Image src={displayPhoto} alt="Display photo" />
        </Modal>
      </Flex>
    </>
  )
}
