import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import clientsDataReducer from './clients/clientsSlice'
import paginationReducer from './pagination/slice'
import notificationReducer from './notification/slice'
import workItemsReducer from './workItems/worksItemsSlice'
import employeeReducer from './employee/employee-slice'
import itemsInvetoryReducer from './item-inventory/item-inventory-slice'
import deleteFilesReducer from './delete-files/deleteFilesSilce'

const rootReducer = combineReducers({
  clients: clientsDataReducer,
  pagination: paginationReducer,
  notification: notificationReducer,
  workItems: workItemsReducer,
  employees: employeeReducer,
  itemsInventory: itemsInvetoryReducer,
  deleteFiles: deleteFilesReducer,
})

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
