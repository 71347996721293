import { LoadingOverlay, Modal } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { FunctionComponent, useEffect, useState } from "react"
import { useGetUser } from "../../../api/user/use-get-user"
import { IUser } from "../../../types/user-types"
import { maxWidthMobile } from "../../../utils/constants"
import { CustomizeElements } from "../../../utils/enum"
import { PriceFormulasPanel } from "../customize-page-price-formulas-panel"
import { StatusPanel } from "../customize-page-status-panel"
import { TypeWorkitemPanel } from "../customize-page-type-workitem-panel"

export const AddCustomizeElements: FunctionComponent<{
  dataUserResponse?: IUser
  isOpenModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  customizeElement: string
  precompleteField?: any
}> = ({ isOpenModal, setOpenModal, customizeElement, precompleteField }) => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const [valueCalculFormula, setValueCalculFormula] = useState<any>()
  const [valueStatus, setValueStatus] = useState<any>()
  const [valueWorkitemType, setValueWorkitemType] = useState<any>()
  const { data, refetch, isLoading } = useGetUser()

  useEffect(() => {
    refetch()
  }, [])

  const userDataResponse = data && data.data.response

  const onCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    if (precompleteField) {
      if (customizeElement === CustomizeElements.STATUS)
        setValueStatus(precompleteField)
      if (customizeElement === CustomizeElements.WORK_ITEM_TYPE)
        setValueWorkitemType(precompleteField)
      if (customizeElement === CustomizeElements.CALC_FORMULA) {
        const array = [JSON.parse(precompleteField)]
        setValueCalculFormula(array)
      }
    }
  }, [isOpenModal])

  return (
    <Modal
      size={isMobile ? "xs" : "md"}
      opened={isOpenModal}
      onClose={() => onCloseModal()}
      centered
      xOffset={0}
      zIndex={2000}
    >
      {data ? (
        <>
          {customizeElement === CustomizeElements.WORK_ITEM_TYPE ? (
            <TypeWorkitemPanel
              workitemsTypesState={valueWorkitemType}
              setWorkitemsTypes={setValueWorkitemType}
              isModal={true}
              setOpenModal={setOpenModal}
            />
          ) : null}

          {customizeElement === CustomizeElements.STATUS ? (
            <StatusPanel
              workitemStatus={valueStatus}
              setWorkitemStatus={setValueStatus}
              isModal={true}
              setOpenModal={setOpenModal}
            />
          ) : null}

          {customizeElement === CustomizeElements.CALC_FORMULA ? (
            <PriceFormulasPanel
              workItemsFormulas={valueCalculFormula}
              measurementUnits={
                userDataResponse?.workItemsMeasurementUnits &&
                userDataResponse?.workItemsMeasurementUnits[0]
                  ? userDataResponse?.workItemsMeasurementUnits.split(",")
                  : []
              }
              workItemsTypes={
                userDataResponse?.workItemsTypes &&
                userDataResponse?.workItemsTypes.split(",")
              }
              setWorkItemsFormulas={setValueCalculFormula}
              isModal={true}
              setOpenModal={setOpenModal}
            />
          ) : null}
        </>
      ) : null}

      <LoadingOverlay visible={isLoading} />
    </Modal>
  )
}
