import { Button, Group, Modal, Paper, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { FunctionComponent } from "react"
import { maxWidthMobile } from "../../utils/constants"

export const CustomizePageModal: FunctionComponent<{
  isOpenCancelModal: boolean
  onSave: () => void
  onCancel: () => void
}> = ({ isOpenCancelModal, onSave, onCancel }) => {
  const isMobile = useMediaQuery(maxWidthMobile)

  return (
    <Modal
      opened={isOpenCancelModal}
      onClose={onCancel}
      title={"Salvează modificările"}
      centered
      size={isMobile ? "calc(100vw - 2rem)" : "md"}
      xOffset={0}
      zIndex={2000}
    >
      <Paper p="xs">
        <Text>
          Există modificări nesalvate pe această pagină. Îți recomandăm să
          salvezi datele înainte de a pleca. Ești sigur(ă) că vrei să renunți la
          modificări?
        </Text>
      </Paper>
      <Group position="center" mt="md" spacing={5} noWrap>
        <Button radius="xl" type="submit" onClick={onSave} size="sm">
          Salvează datele
        </Button>
        <Button
          variant={"light"}
          size="sm"
          radius="xl"
          type="submit"
          onClick={onCancel}
        >
          Renunță
        </Button>
      </Group>
    </Modal>
  )
}
