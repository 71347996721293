import {
  Flex,
  Stack,
  TextInput,
  Button,
  SimpleGrid,
  Badge,
  Text,
  ActionIcon,
  rem,
  ScrollArea,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconX } from "@tabler/icons-react"
import React, { FunctionComponent, useEffect, useState } from "react"
import { maxWidthLaptopSmall, maxWidthMobile } from "../../utils/constants"

import { useStyles } from "./customize-page.styles"
import { duplicateLabel, panelWidth } from "./customize-page.utils"
import { DividerVerticalHorizontal } from "./shared-component/component-divider-vertical-horizontal"
import { ComponentNoItems } from "./shared-component/component-no-items"

export const MeasurementUnitsPanel: FunctionComponent<{
  measurementUnits: string[]
  setMeasurementUnits: React.Dispatch<React.SetStateAction<string[]>>
  setActiveButtons: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ measurementUnits, setMeasurementUnits, setActiveButtons }) => {
  const { classes } = useStyles()
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isMobile = useMediaQuery(maxWidthMobile)
  const [duplicateMessage, setDuplicateMessage] = useState("")
  const [valueMeasurementUnits, setValueMeasurementUnits] = useState("")

  const labelAreaWidth = isMobile ? "100%" : isLaptopS ? "50%" : "70%"
  const removeButton = (name: string, hex?: string) => (
    <ActionIcon
      size="xs"
      radius="xl"
      variant="transparent"
      onClick={() => handleDeleteMeasurementUnitsBadge(name)}
    >
      <IconX size={rem(10)} color={"#4a6643"} />
    </ActionIcon>
  )
  const hasNoItems = !measurementUnits.length

  const handleDeleteMeasurementUnitsBadge = (labelBadge: string) => {
    let newMeasurementUnit = measurementUnits.filter(
      (a: string) => a !== labelBadge
    )
    setMeasurementUnits(newMeasurementUnit)
    setActiveButtons(true)
  }
  const handleAddMeasurementUnits = () => {
    if (
      duplicateLabel(valueMeasurementUnits, measurementUnits, "measurementUnit")
    )
      setDuplicateMessage("Există deja acestă unitate de măsură.")
    else {
      let newMeasurementUnit = [...measurementUnits, valueMeasurementUnits]
      setMeasurementUnits(newMeasurementUnit)
      setValueMeasurementUnits("")
      setActiveButtons(true)
    }
  }

  useEffect(() => {
    setDuplicateMessage("")
  }, [valueMeasurementUnits === ""])

  return (
    <Flex w="100%" direction="column" mt="xs">
      <Text pl="1rem">Personalizare unități de măsură</Text>
      <Flex
        p="1rem"
        gap="2rem"
        align={hasNoItems ? "center" : "flex-start"}
        direction={isMobile ? "column" : "row"}
      >
        <Stack align="left" spacing={10} w={panelWidth()}>
          <TextInput
            label="Unități de măsură"
            value={valueMeasurementUnits}
            placeholder="Introduceți noua unitatea de măsură"
            onChange={(e) =>
              setValueMeasurementUnits(
                e.currentTarget.value.toLocaleUpperCase()
              )
            }
            error={duplicateMessage ? duplicateMessage : null}
          />

          <Button
            size="xs"
            radius="xl"
            onClick={handleAddMeasurementUnits}
            disabled={valueMeasurementUnits.length > 0 ? false : true}
          >
            Adaugă
          </Button>
        </Stack>
        <DividerVerticalHorizontal />
        {hasNoItems ? (
          <ComponentNoItems text="Nu există unități de măsură adăugate." />
        ) : (
          <ScrollArea
            h={"100px"}
            type="scroll"
            offsetScrollbars
            scrollbarSize={6}
            styles={{ root: { width: labelAreaWidth } }}
          >
            <SimpleGrid cols={isLaptopS ? 2 : 4} w={"100%"}>
              {measurementUnits.map(
                (itemMeasurementUnit: string, index: number) => {
                  return (
                    <Badge
                      key={index}
                      variant="light"
                      w="100%"
                      pr={3}
                      h="2rem"
                      rightSection={removeButton(itemMeasurementUnit)}
                      className={classes.badge}
                    >
                      {itemMeasurementUnit}
                    </Badge>
                  )
                }
              )}
            </SimpleGrid>
          </ScrollArea>
        )}
      </Flex>
    </Flex>
  )
}
