import { Button, Flex, Group } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import React, { FunctionComponent } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { maxWidthMobile, Mode, RoutesOptions } from "../../../utils/constants"
import { WorkItemStatuses } from "../../../utils/enum"
import { reverseFormaterDiacritics } from "../../../utils/strings"
import BreadcrumbsComponent from "../../breadcrumbs/breadcrumbs"

export const HeaderAddEditWorkItem: FunctionComponent<{
  isModeView: boolean
  isModeAdd: boolean
  response?: any
  setIsModeView?: (value: React.SetStateAction<boolean>) => void
  setOpenModalConfirmationCancel: (value: React.SetStateAction<boolean>) => void
}> = (props) => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const { workItemUuid } = useParams()
  const navigate = useNavigate()
  const {
    isModeView,
    isModeAdd,
    response,
    setIsModeView,
    setOpenModalConfirmationCancel,
  } = props
  const redirectPageEdit = () => {
    setIsModeView && setIsModeView(false)
    workItemUuid &&
      navigate(
        RoutesOptions.WORKITEMS.concat("/", workItemUuid).concat("/", Mode.EDIT)
      )
  }
  return (
    <Flex
      justify={isMobile ? "end" : "space-between"}
      h="3rem"
      align="flex-end"
    >
      {isMobile ? null : isModeAdd ? (
        <BreadcrumbsComponent currentPage={"Adăugare lucrare"} />
      ) : (
        response && (
          <BreadcrumbsComponent
            currentPage={reverseFormaterDiacritics(response?.name)}
          />
        )
      )}
      <Flex h="100%" align="center">
        {isModeView && !isModeAdd ? (
          response?.status === WorkItemStatuses.FINALIZAT ? null : (
            <Button
              variant="light"
              w="10rem"
              mx="xs"
              radius="xl"
              size="xs"
              onClick={() => redirectPageEdit()}
            >
              Editare
            </Button>
          )
        ) : (
          <Group position="right" spacing={0} w={"100%"} h="100%">
            <Button w="auto" radius="xl" size="xs" type="submit">
              Salvează
            </Button>
            <Button
              variant={"light"}
              w="auto"
              mx="xs"
              radius="xl"
              size="xs"
              onClick={() => setOpenModalConfirmationCancel(true)}
            >
              Anulează
            </Button>
          </Group>
        )}
      </Flex>
    </Flex>
  )
}
