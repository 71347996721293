import { Stack, Text, Flex, Button, Modal, Paper } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { FunctionComponent } from "react"
import {
  maxWidthLaptopSmall,
  maxWidthMobile,
  maxWidthTablet,
} from "../../../utils/constants"
import { freeDays, priceAfterDiscount } from "../utils-account-details"

export const TrialCardComponent: FunctionComponent<{
  triggerPayment: () => void
  affiliateCode?: string
}> = ({ triggerPayment, affiliateCode }) => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isTablet = useMediaQuery(maxWidthTablet)
  const subscriptionExpireDate = sessionStorage.getItem(
    "subscriptionExpireDate"
  )
  return (
    <Paper
      w={isTablet ? "90%" : isLaptopS ? "320px" : "400px"}
      bg="rgb(246, 248, 249)"
      p="xl"
      radius="lg"
      mb={isMobile ? "xl" : 0}
      mt="xs"
    >
      <Stack spacing="xs" align="center" w="100%">
        <Text size={23} color="brand.1" transform="uppercase" align="center">
          Perioadă de probă
        </Text>
        <Flex gap={5} align="flex-end">
          <Text c="#4b5663" size={28}>
            {freeDays(subscriptionExpireDate as string)}
          </Text>
          <Text c="#4b5663" size={14}>
            / 30 de zile gratuite
          </Text>
        </Flex>
        <Stack spacing={5} w="55%" align="center">
          <Text c="#4b5663" size="xs" fw="lighter">
            Dată încheiere
          </Text>
          <Text c="#4b5663" size="sm" align="center" w="100%">
            {subscriptionExpireDate}
          </Text>
        </Stack>

        <Button
          name="buttonCancelSubscrition"
          variant="light"
          w="fit-content"
          radius="xl"
          mt="md"
          onClick={triggerPayment}
        >
          Abonează-te
        </Button>
        {affiliateCode ? (
          <Text c="#8c9195" size={10} align="center">
            *Beneficiezi de codul de reducere {affiliateCode}, la abonare ai{" "}
            {priceAfterDiscount(affiliateCode).discount}% reducere.
          </Text>
        ) : null}
      </Stack>
    </Paper>
  )
}
