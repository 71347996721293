import { Stack, Title } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { FunctionComponent } from "react"
import { maxWidthMobile } from "../../utils/constants"

import { SubscriptionCardComponent } from "./subscription-details-component/subscription-card-component"
import { TrialCardComponent } from "./subscription-details-component/trial-card-component"

export const SubscriptionDetails: FunctionComponent<{
  triggerPayment: () => void
  affiliateCode?: string
}> = (props) => {
  const { triggerPayment, affiliateCode } = props
  const isMobile = useMediaQuery(maxWidthMobile)
  const isTrial = sessionStorage.getItem("isTrial") === "true" ? true : false

  return (
    <Stack w="100%" align="center" spacing={isMobile ? "1rem" : "3rem"}>
      <Title order={4} mt={isMobile ? 0 : "xl"}>
        Detalii abonament
      </Title>
      {isTrial ? (
        <TrialCardComponent
          triggerPayment={triggerPayment}
          affiliateCode={affiliateCode}
        />
      ) : (
        <SubscriptionCardComponent
          triggerPayment={triggerPayment}
          affiliateCode={affiliateCode}
        />
      )}
    </Stack>
  )
}
